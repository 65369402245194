import React from 'react';
import { Box } from 'jsxstyle';

export default function Subtitle({ children, ...rest }) {
    return (
        <Box
            fontSize={14}
            textOverflow="ellipsis"
            opacity={0.5}
            lineHeight={1.43}
            letterSpacing={1}
            {...rest}
        >
            {children}
        </Box>
    );
}
