import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { pushToDataLayer } from 'utils/dataLayer';

import { THEME } from '../api/constants';
import Icon from './Icon';

export default function SearchLink(props) {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();

    const onClick = () => {
        pushToDataLayer({ event: 'searchIcon_click', button_name: 'clicked' });
        navigate('/search');
    };
    return (
        <Row cursor="pointer" minWidth={20} props={{ onClick }}>
            <Box marginRight={10} fontSize={14} fontWeight={700}>
                {!isMobileOnly && t('searchLink.search')}
            </Box>
            {theme === THEME.DARK ? <Icon name="searchLight" /> : <Icon name="search" />}
        </Row>
    );
}
