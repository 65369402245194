import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function cancelThread(user, cancel_reason, cancel_reason_added_text) {
    const payload = {
        user_id: user.id,
        thread_id: user.threadId,
        organization_id: user.organizationId,
        cancel_reason,
        cancel_reason_added_text
    };
    return invokeLambda(Lambda.CancelThread, payload);
}
