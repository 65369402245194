import React, { Component } from 'react';

import Error from '../components/GlobalError';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }
    static getDerivedStateFromError(error) {
        console.log('[e]', error);
        return {
            hasError: true
        };
    }
    render() {
        if (this.state.hasError) {
            // Our error boundary includes the Navbar which does things like fetch organization
            // therefore we have functionality that can't be error wrapped which kind of defeats the purpose
            return <Error />;
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
