import React, { createContext, useEffect, useState } from 'react';
import { log } from 'debug/src/browser';

import { darkTheme, lightTheme } from '../utils/Theme';

const themeColors = {
    light: lightTheme,
    dark: darkTheme
};
export const ThemeContext = createContext();

export default function Theme({ storeTheme, children }) {
    const [themeName, setThemeName] = useState('dark');

    ///get the defined mode from the browser
    useEffect(() => {
        // temporary fix; this would come from admin settings
        // const darkOS = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setTheme(storeTheme);
    }, [storeTheme]);

    const setTheme = name => {
        ///turn my configurations to css variables
        const keys = Object.keys(themeColors[name]);
        keys.map(key => {
            const constructVar = `--${key}`;
            document.body.style.setProperty(constructVar, themeColors[name][key]);
            return false;
        });

        setThemeName(name);
    };
    return (
        <ThemeContext.Provider value={{ theme: themeName, setTheme, themeColors }}>
            {children}
        </ThemeContext.Provider>
    );
}
