import useSWR from 'swr';

import useCustomerData from 'hooks/useCustomerData';
import useFilterComponent from 'hooks/useFilterComponent';

import { isScheduledOrder } from 'api/utils';

import api from '../api';
import useStoreSettings from './useStoreSettings';

export default function useCategorizedCatalog(
    deliveryModel,
    category_size = 10,
    params,
    selectedSorting
) {
    const { data: filters } = useFilterComponent();
    const { data: mappedData } = useCustomerData();
    const { session, thread, zone } = mappedData || {};

    const { data: storeSettings } = useStoreSettings();
    const supportsDelivery =
        storeSettings?.supports_scheduled_menu || storeSettings?.supports_ondemand_menu;
    const supportsPickup = storeSettings?.supports_pickup;
    const pickupOnly = !supportsDelivery && supportsPickup;

    const menu_kit = thread?.menu_kit || sessionStorage.getItem('menu_kit') || null;

    const key = () => [
        'categorizedCatalog',
        thread.zoneId,
        thread.associated_hub,
        session.authenticated,
        storeSettings?.organization_id,
        deliveryModel,
        menu_kit,
        thread?.pickup_destination?.destination_id,
        params.sort,
        filters,
        selectedSorting
    ];

    const fetch = params => {
        const [
            url,
            zoneId,
            associatedHub,
            authenticated,
            organization_id,
            deliveryModel,
            menu_kit
        ] = params;

        const useFrontStock = Boolean(
            thread?.pickup_destination || isScheduledOrder(deliveryModel) || pickupOnly
        );
        const { isOpen, categories } = storeSettings || {};

        const categoryIds = categories?.length ? categories.map(c => c.category_id) : [];
        return storeSettings && thread && filters
            ? api.getCategorizedCatalog({
                  organization_id,
                  zone_id: thread?.pickup_destination || pickupOnly ? null : zoneId,
                  redirected_from_zone:
                      thread?.pickup_destination || pickupOnly ? null : zone.redirected_from_zone,
                  category_size,
                  category_ids: categoryIds,
                  isOpen,
                  kit_id: menu_kit,
                  thread_id: authenticated ? thread?.id : null,
                  useHubStock: useFrontStock,
                  associated_hub: associatedHub
              })
            : null;
    };

    const options = {
        revalidateOnFocus: false, // need remove if want revalidate on page focus
        shouldRetryOnError: true,
        errorRetryInterval: 30000,
        dedupingInterval: 120000,
        focusThrottleInterval: 90000
        // errorRetryCount: 30
    };
    return useSWR(key, fetch, options);
}
