import useSWR from 'swr';

import useCustomerData from 'hooks/useCustomerData';
import useStoreSettings from 'hooks/useStoreSettings';

import api from 'api';

export default function useCartAvailability(deliveryModel, paymentType) {
    const { data: mappedData } = useCustomerData();
    const { cart, thread, user, zone } = mappedData || {};

    const { data: storeSettings } = useStoreSettings();
    const { isOpen, organization_id } = storeSettings || {};

    const kit_id = thread?.menu_kit || sessionStorage.getItem('menu_kit') || null;

    const key = () => [
        `cart-availability`,
        isOpen,
        thread.zoneId,
        kit_id,
        deliveryModel,
        paymentType,
        cart?.items
    ];

    const fetch = params => {
        const [url, isOpen, zoneId, kit_id, deliveryModel] = params;

        return api.checkItemsAvailability({
            cart: cart.items,
            isOpen,
            zoneId,
            redirected_from_zone: thread?.pickup_destination ? null : zone.redirected_from_zone,
            associated_hub: thread.associated_hub,
            kit_id,
            organization_id,
            current_delivery_model: deliveryModel,
            promo_code: thread?.promoCode,
            user_id: user?.id,
            return_payment_details: true,
            is_pickup: !!thread?.pickup_destination,
            use_free_delivery: thread.use_free_delivery,
            payment_type: paymentType
        });
    };

    const options = {
        shouldRetryOnError: true,
        errorRetryInterval: 30000,
        dedupingInterval: 30000,
        errorRetryCount: 3
    };
    return useSWR(key, fetch, options);
}
