import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inline } from 'jsxstyle';
import phoneParser from 'libphonenumber-js';

import useTheme from 'hooks/useTheme';

import { useAppContext } from 'store';

import { isHebrew } from '../api/utils';
import bg from './../assets/images/bg-error.jpg';
import Heading from './Heading';
import Navbar from './Navbar';
import P from './Paragraph';
import Wrapper from './Wrapper';

export default function ErrorPage() {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();
    const { state } = useAppContext();
    const ERROR_TIME_KEY = 'ErrorDetectedTimestamp';
    const lastErrorTime = localStorage.getItem(ERROR_TIME_KEY);
    const currentErrorTime = Math.floor(Date.now() / 1000);
    localStorage.setItem(ERROR_TIME_KEY, currentErrorTime);
    if (!lastErrorTime || currentErrorTime - lastErrorTime > 60) {
        window.location.reload();
        return <Box></Box>;
    }
    const chatLink = `tel:${state.botNumber}`;
    const large = 'screen and (min-width: 768px)';
    return (
        <Wrapper>
            <Box minHeight="100vh" maxWidth={375} background={`url(${bg}) 0 200px no-repeat`}>
                <Navbar marginBottom={50} />
                <Heading>{t('globalError.oops')}</Heading>
                <P bold fontWeight={500} marginBottom={70}>
                    {t('globalError.desp')}{' '}
                    <Inline whiteSpace="nowrap">
                        {state.botNumber && isHebrew()
                            ? state.botNumber.replace(/\+/g, '') // '+' and phone formatting is causing line break issues in hebrew
                            : phoneParser(state.botNumber)?.formatInternational() ||
                              state.botNumber}
                        ,
                    </Inline>{' '}
                    {t('globalError.desp2')}
                </P>
                <Box
                    component="a"
                    mediaQueries={{ large }}
                    display="block"
                    largeDisplay="none"
                    padding={15}
                    width={260}
                    border={`1px solid ${themeColors[theme].primaryBorderColor}`}
                    color={themeColors[theme].primaryFontColor2}
                    backgroundColor={themeColors[theme].primaryBgColor2}
                    borderRadius={30}
                    fontSize={16}
                    fontWeight={500}
                    letterSpacing={1}
                    textAlign="center"
                    textDecoration="none"
                    textTransform="none"
                    props={{
                        href: chatLink,
                        target: '_blank'
                    }}
                >
                    {t('globalError.textAgent')}
                </Box>
            </Box>
        </Wrapper>
    );
}
