import useSWR from 'swr';

import api from '../api';
import useSession from './useSession';

export default function useStoreSettings() {
    const session = useSession();
    const associatedHub = localStorage.getItem('associatedHub');

    const key = () => `store-settings?oid=${session.organization_id}&${associatedHub}`;
    const fetch = () => api.getStoreSettings(session, associatedHub);
    const options = {
        dedupingInterval: 60000,
        focusThrottleInterval: 60000,
        revalidateOnFocus: true
    };

    const { data, error } = useSWR(key, fetch, options);

    return { data, error };
}
