import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inline, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { ORDER_TYPE } from 'api/constants';

import Icon from './Icon';

export default function DeliveryPickupTab({
    deliveryOptions,
    activeDeliveryOption,
    setActiveDeliveryOption,
    savedDeliveryAddress,
    autofillDeliveryAddress,
    unselectPickupOption,
    validAddress,
    setError
}) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    return (
        <Row maxWidth={428} marginBottom={15}>
            {deliveryOptions.map(type => (
                <OptionTab
                    key={type}
                    active={activeDeliveryOption === type}
                    onClick={() => {
                        setActiveDeliveryOption(type);
                        setError(null);
                        if (type === ORDER_TYPE.DELIVERY && savedDeliveryAddress && !validAddress) {
                            setTimeout(() => autofillDeliveryAddress(), 300);
                        } else {
                            unselectPickupOption();
                        }
                    }}
                    type={type}
                    sm={'screen and (min-width: 360px)'}
                    theme={theme}
                    themeColors={themeColors}
                >
                    {t(`addressModal.${type}`)}
                </OptionTab>
            ))}
        </Row>
    );
}

const OptionTab = ({ children, active, onClick, type, sm, theme, themeColors }) => (
    <Box
        component="button"
        fontSize={16}
        font-weight="500"
        padding="9px 0px 7px 35px"
        mediaQueries={{ sm }}
        smPaddingRight={40}
        width="50%"
        cursor="pointer"
        background="white"
        border="0"
        outline="0"
        props={{ onClick }}
        borderBottom={
            active
                ? `3px solid ${themeColors[theme].tertiaryBorderColor1}`
                : `1px solid ${themeColors[theme].secondaryBorderColor3}`
        }
        position="relative"
    >
        <Icon
            name={type === ORDER_TYPE.DELIVERY ? 'truckBlack' : 'store'}
            width={type === ORDER_TYPE.DELIVERY && 35}
            position="absolute"
            top={10}
            left={type === ORDER_TYPE.DELIVERY ? 6 : 18}
            opacity={active ? '1' : '0.35'}
        />
        <Inline
            color={
                active ? themeColors[theme].tertiaryFontColor2 : themeColors[theme].popupFontColor2
            }
        >
            {children}
        </Inline>
    </Box>
);
