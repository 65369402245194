import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { priceToString } from 'api/utils';

import { useAppContext } from 'store';

import { Body } from 'components/shared';

import Icon from '../../Icon';

export default function Payment({
    change,
    onChange,
    onFocus,
    onBlur,
    onClear,
    cashBtnOnFocus,
    forwardedRef
}) {
    const { theme, themeColors } = useTheme();
    const { state } = useAppContext();
    const { t } = useTranslation();
    const sm = `screen and (max-width: 480px)`;

    return (
        <Box>
            <Row
                marginTop={18}
                alignItems="center"
                mediaQueries={{ sm }}
                smJustifyContent="space-between"
                smMarginBottom={!cashBtnOnFocus && 20}
            >
                <Body
                    marginRight={18}
                    color={themeColors[theme].tertiaryFontColor2}
                    fontWeight={500}
                >
                    {t('buyNowModal.payment.needChange')}
                </Body>
                <Row
                    padding="9px 9px 9px 20px"
                    background={
                        change
                            ? themeColors[theme].tertiaryBgColor
                            : themeColors[theme].secondaryBgColor6
                    }
                    alignItems="center"
                    width={132}
                    height={52}
                    borderRadius={8}
                    fontSize={16}
                    color={themeColors[theme].secondaryFontColor5}
                    border={cashBtnOnFocus && `2px solid ${themeColors[theme].tertiaryBorderColor}`}
                    position="relative"
                >
                    <Box component="span">{priceToString(0, state.currency).substr(0, 1)}</Box>
                    <Box
                        component="input"
                        color={themeColors[theme].secondaryFontColor5}
                        placeholderColor={themeColors[theme].placeHolderColor2}
                        boxShadow="none"
                        backgroundColor="transparent"
                        border={0}
                        width={100}
                        fontSize={16}
                        height="inherit"
                        fontWeight={500}
                        props={{
                            placeholder: '0.00',
                            step: '.01',
                            type: 'number',
                            inputMode: 'decimal',
                            onChange: onChange,
                            onFocus: onFocus,
                            onBlur: onBlur,
                            value: change,
                            ref: forwardedRef
                        }}
                    ></Box>
                    {change && (
                        <Box
                            position="absolute"
                            right={10}
                            top={11}
                            cursor="pointer"
                            props={{ onClick: onClear }}
                        >
                            <Icon name={'clearChange'} />
                        </Box>
                    )}
                </Row>
            </Row>
        </Box>
    );
}
