import useSWR from 'swr';

import api from '../api';
import useStoreSettings from './useStoreSettings';

export default function usePickupLocations() {
    const { data: storeSettings } = useStoreSettings();
    const key = () => [`pickupLocations`, storeSettings?.organization_id];
    const destination_type = 6;
    const options = {
        shouldRetryOnError: true,
        errorRetryInterval: 30000,
        dedupingInterval: 180000,
        focusThrottleInterval: 180000,
        revalidateOnFocus: true
    };
    const fetch = async () => {
        if (!storeSettings) return null;
        const { destinations } = await api.getPickupLocations(
            storeSettings?.organization_id,
            destination_type
        );

        return destinations;
    };

    const { data, error, mutate, isValidating } = useSWR(key, fetch, options);

    return {
        data,
        error,
        mutate,
        isValidating
    };
}
