export const darkTheme = {
    /* text colors */
    primaryFontColor1: '#fff', // used in all buttons, page titles, category nav bar, etc
    primaryFontColor2: '#000', // texts color when background is dark, in buttons, alerts, refer friend banner, etc

    secondaryFontColor1: '#fff', // used in cart delivery and pickup dropdown text, cart custom consent message, cash and credit tab, age verification etc
    secondaryFontColor2: '#fff', // used in cart summery, promocode input, account page, order status page, refer friend page, send document page, etc
    secondaryFontColor3: '#9E9EAD', // promo code input when no value entered
    secondaryFontColor4: '#3C3C61', // Hint text color on status map and need change
    secondaryFontColor5: '#3C3C61', // buy now popup text color on payment
    secondaryFontColor6: '#3C3C61', // age verification: over 21, delay order popup: too soon
    secondaryFontColor7: '#F8F8F8', // searching delivery address, address list hover color
    secondaryFontColor8: '#fff', // cart summery message
    secondaryFontColor9: '#fff', // subtitle text colors on popup windows, catalog page subtitle
    secondaryFontColor10: '#fff', // home page product scroll bar text for more products
    secondaryFontColor11: '#fff', // account page 'member since', delete account, edit account, login page: 'send sms'
    secondaryFontColor12: '#fff', // direct to agent page, place order fail page
    secondaryFontColor13: '#fff', // login page: 'BACK'
    secondaryFontColor14: '#9E9EAD', // cart item availability note, additional_info for product
    secondaryFontColor15: '#9E9EAD', // product item, 'tax included'
    secondaryFontColor16: '#000', // footer text
    secondaryFontColor17: '#9E9EAD', // need change component
    secondaryFontColor18: '#9E9EAD', // need change component
    secondaryFontColor19: '#9E9EAD', // compliance page
    secondaryFontColor20: '#9E9EAD', // order status notes input, textarea text color on buy now popup
    secondaryFontColor21: '#3C3C61', // account page: 'referral reward'
    secondaryFontColor22: '#9E9EAD', // compliance page: 'customer support Phone number'
    secondaryFontColor23: '#fff', // submit sucess 'ordre number'
    secondaryFontColor24: '#DEDEDE', // search product input component
    secondaryFontColor25: '#fff', // submit success 'update delivery options'
    secondaryFontColor26: '#3C3C61', // in the css file with random class name, not sure if still used
    secondaryFontColor27: '#9e9ead',

    tertiaryFontColor1: '#3C3C61', // cart delivery and pickup dropdown popup, cookie ok button, delivery address pop up
    tertiaryFontColor2: '#3C3C61', // cash and credit payment tab, delivery address pop up, page not found component, refer friend page
    tertiaryFontColor3: '#5b92f7', // delete account hover color
    tertiaryFontColor4: '#0091FF', // footer faq vertical line color
    tertiaryFontColor5: '#0091FF', // footer link color
    tertiaryFontColor6: '#5b92f7', // footer link hover color
    tertiaryFontColor7: '#0091FF',

    quaternaryFontColor2: '#ff596c', // cart total less than minimum amount
    quaternaryFontColor3: '#ff596c', // removing item from cart
    quaternaryFontColor4: '#ff596c', // promotion price line strikethrough

    quinaryFontColor1: '#6dd400', // footer store open, free delivery banner
    quinaryFontColor2: '#00b40c', // cart summery discount, promo etc

    errFontColor: '#ff596c', // promo input error message

    /* background colors */
    primaryBgColor1: '#000', // cookie ok button, page, popup background etc
    primaryBgColor2: '#fff', // alert, product page add to cart button, hover color etc
    primaryBgColor3: 'rgba(255, 255, 255, 0.95)', // cookie background
    primaryBgColor4: '#fff', //cart items background

    inputTextColor: '#3D3D5E',
    inputBgColor: '#eaeaea',
    secondaryBgColor: '#000', // popup windows, home page backround, login input background etc
    secondaryBgColor1: '#000', // status map, add to cart button on home page etc
    secondaryBgColor2: '#F8F8F8', // button general grey background
    secondaryBgColor3: '#DEDEDE', // search result keyword background
    secondaryBgColor4: '#DEDEDE', // promo code input
    secondaryBgColor5: '#DEDEDE', // promo code input
    secondaryBgColor6: '#DEDEDE', // credit card input background
    secondaryBgColor7: '#f6f5f6', // delivery address input background, refer frined page copy url
    secondaryBgColor8: '#DEDEDE', // divider line light grey
    secondaryBgColor9: '#DEDEDE', // divider line dark grey
    secondaryBgColor10: '#9E9EAD', // search result keyword background
    secondaryBgColor11: '#4A4A4A', // product page image item background
    secondaryBgColor12: '#fdfdfd', // cart page buy now, login to buy now section background
    secondaryBgColor13: '#fdfdfd', // refer friend banner
    secondaryBgColor14: '#DEDEDE', // status map circle
    secondaryBgColor15: '#F8F8F8', // edit account text input  secondaryBgColor16: '#F8F8F8', // in css file, might not be used now
    secondaryBgColor17: '#F8F8F8', // mistake, will remove
    secondaryBgColor18: '#2d2d2d', // docs
    secondaryBgColor19: '#D8D8D8',

    tertiaryBgColor: '#DEEDF8', //  payment credit card entered
    tertiaryBgColor1: '#0091ff', // delivery address addtional notes, character limit box
    tertiaryBgColor2: 'rgba(0,145,255,0.1)', // orders list page with on going order
    tertiaryBgColor3: '#f1f9ff', // hint window on status map background

    quaternaryBgColor: '#e02020', // home page cart icon with items in cart

    sfBgColor: '#e02020', // sf banner
    scBgColor: '#192B53', // sc banner
    sfToggleBgColor: '#e02020',
    scToggleBgColor: '#192B53',
    inactiveBgColor: '#E0E0E0',
    scInactiveBgColor: '#EAEAEA',

    infoNoticeBgColor: '#0091FF', // on going order banner on home page
    warningNoticeBgColor: '#f7b500', // store closed banner, product not available banner on cart page
    errNoticeBgColor: '#ff596c', // error message banner

    placeHolderColor: '#c6c6c6', // input placeholder color for change, promo code input etc
    placeHolderColor2: '#DEDEDE', // delivery address input placeholder color
    placeHolderColor3: '#9e9ead', // delivery address input placeholder color
    placeHolderColor4: '#9e9ead', // text input placeholder color

    /* border colors */
    activeBorderColor: '#0091ff', // cancel order focus and hover color
    primaryBorderColor: '#fff', // radio button border color on popuo windows, product image
    primaryBorderColor1: '#000', // compliance image border

    secondaryBorderColor: '#3C3C61', //  credit card btn, divider line on cart page etc
    secondaryBorderColor1: '#DEDEDE', // cookie ok button, footer
    secondaryBorderColor2: '#3C3C61', // cart and order page divider line,
    secondaryBorderColor3: '#9E9EAD', // delivery and pickup tab non active border color
    secondaryBorderColor4: '#3C3C61', // account link border bottom color
    secondaryBorderColor5: '#DEDEDE', // product page image item
    secondaryBorderColor6: '#F6F5F6', // refer friend page copy url input box
    secondaryBorderColor7: '#F6F5F6', // seems mistake from me, don't find it used. will check
    secondaryBorderColor8: '#DEDEDE', // cancel order
    secondaryBorderColor9: '#3C3C61', // cart summery border bottom    secondaryBorderColor10: '#3C3C61', // collapse component used in footer, order status
    secondaryBorderColor11: '#3C3C61', // border top on footer
    secondaryBorderColor12: '#3C3C61', // hover border bottom on footer link
    secondaryBorderColor13: '#9E9EAD', // radio button when unchecked
    secondaryBorderColor14: '#3C3C61', // used in css file

    tertiaryBorderColor: '#0091ff', // payment input activie border color
    tertiaryBorderColor1: '#3e3d5c', // delivery or pic kup tab active border color, cash and credit payment tab active border color
    tertiaryBorderColor2: '#97aebf', // hint window on status map border color

    quaternaryFontColor1: '#e02020',
    quaternaryBorderColor: '#ff596c', // error message border color

    /* box shadow colors */
    boxShadowColor: 'rgba(0, 0, 0, 0.2)',
    boxShadowColor1: 'rgba(0, 0, 0, 0.2)', // share referral button
    boxShadowColor2: 'rgba(0, 0, 0, 0.3)', // add to cart button on product page
    boxShadowColor3: 'rgba(0, 0, 0, 0.03)', // compliance upload image button
    boxShadowColor4: 'rgba(0, 0, 0, 0.5)', // delivery address popup active, home page background
    boxShadowColor5: 'rgba(0, 0, 0, 0.05)',

    /* same style for dark and light theme */

    /* popup styles */
    /* text colors */
    popupFontColor1: '#000', // used in all buttons, page titles, category nav bar, etc
    popupFontColor2: '#9e9ead',
    popupFontColor3: '#868686',
    popupFontColor4: '#3E3D5C',

    popupBgColor1: '#fff',

    productCellBgColor: '#fff',
    categoryBarBorderColor: '#fff',
    profileImgBgColor: '#f7f7f7',
    referInputBgColor: '#202020',

    /* generic colors */
    blackFontColor: '#000',
    blackFontColor2: '#272727',
    blackFontColor3: '#463F4E',
    whitefontColor: '#fff',
    pinkfontColor: '#FFDADE',
    pinkfontColor2: '#F2A5A5',
    greyfontColor1: '#494949',
    greyfontColor2: '#9e9ead',

    whiteBgColor: '#fff',
    whiteBgColor2: '#f7f7f7',
    greyBgColor1: '#cacaca',
    greyBgColor2: '#eaeaea',

    cashInputBgColor: '#5F5F5F',

    /* button colors */
    buttonBlackColor: '#000',
    buttonWhiteColor: '#fff',
    buttonDisableBg: '#313131'
};
export const lightTheme = {
    /* text colors */
    primaryFontColor1: '#000',
    primaryFontColor2: '#fff',

    secondaryFontColor1: '#9e9ead',
    secondaryFontColor2: '#868686',
    secondaryFontColor3: '#979797',
    secondaryFontColor4: '#333',
    secondaryFontColor5: '#3a404c',
    secondaryFontColor6: '#242424',
    secondaryFontColor7: '#f2f2f2',
    secondaryFontColor8: '#8B8B8B',
    secondaryFontColor9: '#494949',
    secondaryFontColor10: '#919191',
    secondaryFontColor11: '#c4c4c4',
    secondaryFontColor12: '#5a5a5a',
    secondaryFontColor13: '#646464',
    secondaryFontColor14: '#7f7f7f',
    secondaryFontColor15: '#8f8f8f',
    secondaryFontColor16: '#8e8b8e',
    secondaryFontColor17: '#797979',
    secondaryFontColor18: '#bbbbbb',
    secondaryFontColor19: '#7b7b7b',
    secondaryFontColor20: '#948f8f',
    secondaryFontColor21: '#4c4c4c',
    secondaryFontColor22: '#818181',
    secondaryFontColor23: '#c6c6c6',
    secondaryFontColor24: '#b4b4b4',
    secondaryFontColor25: '#4d4b4b',
    secondaryFontColor26: '#565656',
    secondaryFontColor27: '#9e9ead',

    tertiaryFontColor1: '#3d3c5f',
    tertiaryFontColor2: '#3e3d5c',
    tertiaryFontColor3: '#5b92f7',
    tertiaryFontColor4: '#a3c6ff',
    tertiaryFontColor5: '#8ab3ff',
    tertiaryFontColor6: '#70a2ff',
    tertiaryFontColor7: '#0091FF',

    quaternaryFontColor1: '#e02020',
    quaternaryFontColor2: '#ff596c',
    quaternaryFontColor3: '#eb2e45',
    quaternaryFontColor4: '#d81400',

    quinaryFontColor1: '#6dd400',
    quinaryFontColor2: '#00b40c',

    errFontColor: '#ff596d',

    /* background colors */
    primaryBgColor1: '#fff',
    primaryBgColor2: '#000',
    primaryBgColor3: 'rgba(0, 0, 0, 0.8)',
    primaryBgColor4: '#fff',

    inputTextColor: '#3D3D5E',
    inputBgColor: '#eaeaea',
    secondaryBgColor: '#eaeaea',
    secondaryBgColor1: '#f7f7f7',
    secondaryBgColor2: '#cacaca',
    secondaryBgColor3: '#d8d8d8',
    secondaryBgColor4: '#e7e7e8',
    secondaryBgColor5: '#dbdbdb',
    secondaryBgColor6: 'rgba(216,216,216,0.4)',
    secondaryBgColor7: '#f6f5f6',
    secondaryBgColor8: '#E0E0E0',
    secondaryBgColor9: '#979797',
    secondaryBgColor10: '#696969',
    secondaryBgColor11: '#fafafa',
    secondaryBgColor12: '#fdfdfd',
    secondaryBgColor13: '#2d2d2d',
    secondaryBgColor14: '#cbcbcb',
    secondaryBgColor15: '#efefef',
    secondaryBgColor16: '#f2f2f2',
    secondaryBgColor17: '#f4f4f4',
    secondaryBgColor18: '#f7f7f7',
    secondaryBgColor19: '#D8D8D8',

    tertiaryBgColor: '#e0eef9',
    tertiaryBgColor1: '#0091ff',
    tertiaryBgColor2: 'rgba(0,145,255,0.1)',
    tertiaryBgColor3: '#f1f9ff',

    quaternaryBgColor: '#e02020',

    sfBgColor: '#e02020',
    scBgColor: '#3e3d5c',
    sfToggleBgColor: '#e02020',
    scToggleBgColor: '#3e3d5c',
    inactiveBgColor: '#E0E0E0',
    scInactiveBgColor: '#EAEAEA',

    infoNoticeBgColor: '#32c5ff',
    warningNoticeBgColor: '#f7b500',
    errNoticeBgColor: '#ff596c',

    placeHolderColor: '#c6c6c6',
    placeHolderColor2: '#bbbbbb',
    placeHolderColor3: '#9e9ead',
    placeHolderColor4: '#ccc',

    /* border colors */
    activeBorderColor: '#7ac2fb',
    primaryBorderColor: '#000',
    primaryBorderColor1: '#fff',

    secondaryBorderColor: '#eaeaea',
    secondaryBorderColor1: '#d9dade',
    secondaryBorderColor2: '#d8d8d8',
    secondaryBorderColor3: '#979797',
    secondaryBorderColor4: '#ededed',
    secondaryBorderColor5: '#dedede',
    secondaryBorderColor6: '#eceaec',
    secondaryBorderColor7: '#e7e7e8',
    secondaryBorderColor8: '#dddddd',
    secondaryBorderColor9: '#ccc',
    secondaryBorderColor10: '#cacaca',
    secondaryBorderColor11: '#e8e8e8',
    secondaryBorderColor12: '#8e8b8e',
    secondaryBorderColor13: '#797979',
    secondaryBorderColor14: '#9e9ead',

    tertiaryBorderColor: '#0091ff',
    tertiaryBorderColor1: '#3e3d5c',
    tertiaryBorderColor2: '#97aebf',

    quaternaryBorderColor: '#ff596c',

    /* box shadow colors */
    boxShadowColor: 'rgba(0, 0, 0, 0.15)',
    boxShadowColor1: 'rgba(0, 0, 0, 0.2)',
    boxShadowColor2: 'rgba(0, 0, 0, 0.3)',
    boxShadowColor3: 'rgba(0, 0, 0, 0.03)',
    boxShadowColor4: 'rgba(0, 0, 0, 0.5)',
    boxShadowColor5: 'rgba(0, 0, 0, 0.05)',

    /* same style for dark and light theme */

    /* popup styles */
    /* text colors */
    popupFontColor1: '#000', // used in all buttons, page titles, category nav bar, etc
    popupFontColor2: '#9e9ead',
    popupFontColor3: '#868686',
    popupFontColor4: '#3E3D5C',

    popupBgColor1: '#fff',

    productCellBgColor: '#fff',
    categoryBarBorderColor: '#3e3d5c',
    profileImgBgColor: '#f7f7f7',
    referInputBgColor: '#f6f5f6',

    /* generic colors */
    blackFontColor: '#000',
    blackFontColor2: '#272727',
    blackFontColor3: '#463F4E',
    whitefontColor: '#fff',
    pinkfontColor: '#FFDADE',
    pinkfontColor2: '#F2A5A5',

    greyfontColor1: '#494949',
    greyfontColor2: '#9e9ead',
    whiteBgColor: '#fff',
    whiteBgColor2: '#f7f7f7',

    greyBgColor1: '#eaeaea',
    greyBgColor2: '#eaeaea',
    cashInputBgColor: '#eaeaea',

    /* button colors */
    buttonBlackColor: '#000',
    buttonDisableBg: '#cacaca'
};
