import React from 'react';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from '../api/constants';

export default function Button(props) {
    const { onClick, disabled, children, ...rest } = props;
    const { theme, themeColors } = useTheme();

    return (
        <Box
            color={theme == THEME.DARK && themeColors[theme].blackFontColor}
            borderRadius={16}
            backgroundColor={themeColors[theme].secondaryBgColor3}
            padding="10px 15px"
            minWidth={80}
            textAlign="center"
            fontSize={12}
            fontWeight={700}
            letterSpacing={1}
            hoverBackgroundColor={themeColors[theme].secondaryBgColor2}
            cursor="pointer"
            props={{ onClick, disabled }}
            {...rest}
        >
            {children}
        </Box>
    );
}
