import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function getFilterComponents(organization_id) {
    const payload = {
        organization_id
    };

    const data = await invokeLambda(Lambda.GetFilterComponent, payload);

    const currentSelectedSortType = sessionStorage.getItem('selectedSortType');

    if (!currentSelectedSortType && data?.sort_types?.[0]) {
        sessionStorage.setItem('selectedSortType', JSON.stringify(data.sort_types[0]));
    }

    return data;
}
