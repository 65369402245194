import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import loadImage from 'blueimp-load-image';
import { Box } from 'jsxstyle';

import useSession from 'hooks/useSession';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';
import DocumentProviderFactory from 'api/documentManager/documentProviderFactory';
import DocumentProviderType from 'api/documentManager/documentProviderType';

import Button from 'components/Button';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import Image from 'components/Image';
import Navbar from 'components/Navbar';
import { Caption, Divider, Subtitle, Title } from 'components/shared';
import Spinner from 'components/Spinner';
import Wrapper from 'components/Wrapper';

import pdfPlaceholder from '../assets/images/pdf-placeholder.png';

const documentProvider = DocumentProviderFactory.create(DocumentProviderType.LAZZY);

export default function SendDocument({ pageTitle }) {
    const { theme, themeColors } = useTheme();

    const { t } = useTranslation();
    const session = useSession();
    const { data: storeSettings } = useStoreSettings();
    const [docSubmitted, setDocSubmitted] = useState(false);
    const [submissionFailed, setSubmissionFailed] = useState(false);
    const [submissionFailedError, setSubmissionFailedError] = useState(null);

    const documentRef = useRef(null);
    const [doc, setDoc] = useState({
        url: null,
        blob: null,
        name: '',
        file: null
    });
    const [loading, setLoading] = useState(false);

    if (!session || !storeSettings) {
        return (
            <Wrapper>
                <Spinner />
            </Wrapper>
        );
    }

    const handleDocumentChanged = async event => {
        const file = event.target.files[0];
        event.target.value = null;

        if (file.type === 'application/pdf') {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = e => {
                setDoc({
                    file,
                    url: e.target.result,
                    blob: { size: file.size, type: file.type },
                    name: file.name
                });
            };
        }

        (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif') &&
            loadImage(
                file,
                img => {
                    const url = img.toDataURL('image/jpeg', 0.5);

                    img.toBlob(
                        blob => {
                            setDoc({
                                file,
                                url,
                                blob,
                                name: file.name
                            });
                        },
                        'image/jpeg',
                        0.5
                    );
                },
                { maxWidth: 1024, maxHeight: 1024, canvas: true }
            );
    };

    const handleDocumentSubmit = async () => {
        const docData = doc.blob.type === 'application/pdf' ? doc.file : doc.blob;
        const docType = doc.blob.type === 'application/pdf' ? 'pdf' : 'jpg';
        try {
            setLoading(true);
            const response = await documentProvider.putObject(
                doc.name,
                docData,
                'documents',
                docType,
                session.userId,
                storeSettings?.organization_id
            );

            if (response.success) {
                setDocSubmitted(true);
            } else {
                setSubmissionFailed(true);
                setSubmissionFailedError(response?.errorMessage);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Wrapper>
            <Navbar pageTitle={pageTitle} />
            <Heading>
                {docSubmitted ? t('sendDocument.submitted') : t('sendDocument.required')}
            </Heading>
            <Title fontWeight={500} opacity={1.0} color={themeColors[theme].secondaryFontColor2}>
                {docSubmitted
                    ? t('sendDocument.thankYou')
                    : t('sendDocument.submitDocs', {
                          organization_name: storeSettings.organization_name
                      })}
            </Title>
            {!docSubmitted && (
                <Caption
                    marginTop={20}
                    fontWeight={500}
                    color={themeColors[theme].secondaryFontColor2}
                >
                    {t('sendDocument.tap')} [ + ] {t('sendDocument.toUpload')}{' '}
                </Caption>
            )}

            <Box display="flex" flexDirection="column" alignItems="center" marginTop={90}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={100}
                    height={100}
                    borderRadius={8}
                    background={themeColors[theme].secondaryBgColor18}
                    border={doc.url && !loading ? 'none' : 'dashed 2px rgba(151, 151, 151, 0.2)'}
                    props={{
                        onClick: () => {
                            documentRef.current.click();
                        }
                    }}
                >
                    {doc.url ? (
                        loading ? (
                            <Spinner white={theme === THEME.DARK} />
                        ) : (
                            <Box position="relative" width={100} height={100}>
                                <Image
                                    src={
                                        doc.blob.type === 'application/pdf'
                                            ? pdfPlaceholder
                                            : doc.url
                                    }
                                    objectFit="cover"
                                    width={100}
                                    height={100}
                                    borderRadius={8}
                                />
                                {docSubmitted && (
                                    <Icon name="checkbox" position="absolute" top={28} left={28} />
                                )}

                                {submissionFailed && (
                                    <Icon
                                        name="circleXRed"
                                        position="absolute"
                                        top={28}
                                        left={28}
                                    />
                                )}
                            </Box>
                        )
                    ) : (
                        <Icon name="add" width={13} />
                    )}

                    <input
                        type="file"
                        accept="application/pdf,image/jpeg,image/png,image/gif"
                        ref={documentRef}
                        style={{
                            display: 'none'
                        }}
                        onChange={handleDocumentChanged}
                    />
                </Box>
                {doc.name ? (
                    <Caption opacity={0.5} marginTop={15} height={36} lineHeight={1}>
                        {loading ? t('sendDocument.uploading') : doc.name}
                    </Caption>
                ) : (
                    <Caption
                        fontWeight={500}
                        textAlign="center"
                        marginTop={21}
                        height={36}
                        opacity={0.5}
                        lineHeight={1}
                        color={themeColors[theme].secondaryFontColor2}
                    >
                        {t('sendDocument.imgFormat')}
                    </Caption>
                )}
            </Box>
            <Divider marginTop={105} width="100%" opacity={0.15} />
            <Box display="flex" flexDirection="column" alignItems="center">
                {docSubmitted ? (
                    <Subtitle
                        marginTop={85}
                        cursor="pointer"
                        color={themeColors[theme].primaryFontColor1}
                        opacity={1.0}
                        fontWeight={700}
                        props={{
                            onClick: () => {
                                setDocSubmitted(false);
                                setDoc({
                                    file: null,
                                    name: '',
                                    url: null,
                                    blob: null
                                });
                            }
                        }}
                    >
                        {t('sendDocument.submitAnother')}
                    </Subtitle>
                ) : submissionFailed ? (
                    <Caption
                        textAlign="center"
                        marginTop={31}
                        opacity={0.5}
                        lineHeight={1}
                        color={themeColors[theme].quaternaryFontColor2}
                    >
                        {submissionFailedError}
                    </Caption>
                ) : (
                    <Button
                        disabled={!doc.url || loading}
                        marginTop={35}
                        onClick={handleDocumentSubmit}
                    >
                        {t('sendDocument.submitDoc')}
                    </Button>
                )}
            </Box>
        </Wrapper>
    );
}
