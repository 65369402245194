import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function ValidateAddress(address, organizationId) {
    const payload = {
        organization_id: organizationId,
        input: address
    };
    return await invokeLambda(Lambda.UpdateAddress, payload);
}
