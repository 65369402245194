import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { cacheHidePromotionBar } from '../api/utils';

export default function PromotionBar({
    promotionRelay,
    showPromotionBar,
    setShowPromotionBar,
    applyPromoCode,
    setPromoBarHeight,
    showPromoAppliedAlert,
    isAuth
}) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    useEffect(() => {
        let bar = document.querySelector('.promotion-bar');
        setPromoBarHeight(bar.offsetHeight);
    }, [setPromoBarHeight]);

    return (
        <Row
            className="promotion-bar"
            background={themeColors[theme].blackFontColor2}
            minHeight={40}
            justifyContent="space-between"
            alignItems="center"
            cursor={'pointer'}
            props={
                showPromotionBar && {
                    onClick: () => {
                        applyPromoCode();
                        setShowPromotionBar(false);
                        showPromoAppliedAlert();
                    }
                }
            }
        >
            <Box
                flex={1}
                fontSize={isMobileOnly ? 12 : 14}
                padding="10px 40px"
                textAlign="center"
                fontWeight="bold"
                letterSpacing={1}
                color={themeColors[theme].whitefontColor}
            >
                👉 {promotionRelay.message}{' '}
                <Box
                    component="a"
                    textDecoration="underline"
                    cursor="pointer"
                    color={themeColors[theme].whitefontColor}
                    display={isMobileOnly && !isAuth && 'block'}
                >
                    {isAuth ? t('catalogPage.clickHere') : t('catalogPage.loginPromo')}
                </Box>
            </Box>

            <Box
                fontSize={14}
                fontWeight="bold"
                letterSpacing={1}
                color={themeColors[theme].whitefontColor}
                marginRight={0}
                padding="15px 25px 15px 10px"
                cursor="pointer"
                className="promoBar-close"
                props={{
                    onMouseDown: () => {
                        cacheHidePromotionBar(promotionRelay.promo_code);
                        setShowPromotionBar(false);
                    }
                }}
            >
                x
            </Box>
        </Row>
    );
}
