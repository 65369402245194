import React, { useEffect, useState } from 'react';
import { isAndroid, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { ThreadDeliveryModel, WindowSubType } from 'api/constants';
import { formatDeliveryWindowText } from 'api/utils';

import closeIcon from 'assets/images/black-close-icon.svg';
import unselected from 'assets/images/unselected.svg';

import Image from 'components/Image';
import RadioButton from 'components/shared/RadioButton';

import Icon from '../../components/Icon';
import ModalPage from '../../components/ModalPage';

function ScheduledWindowModal({
    showModal,
    onClose,
    onSaveScWindow,
    deliveryWindows,
    defaultWindow,
    cartPage,
    sfAvailable,
    scAvailable,
    supportsBothDeliveryModels,
    productNotAvailable,
    storeClosed
}) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const [windowId, setWindowId] = useState(defaultWindow?.window_id);
    const [selectedWindow, setSelectedWindow] = useState();
    const [saveEnabled, setSaveEnabled] = useState(true);
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [deviceHeight, setDeviceHeight] = useState(
        isAndroid ? window.screen.height : window.innerHeight
    );

    useEffect(() => {
        if (defaultWindow) {
            setSelectedWindow(defaultWindow);
        }
    }, [defaultWindow]);

    useEffect(() => {
        window.addEventListener('resize', function () {
            setDeviceWidth(window.innerWidth);
            setDeviceHeight(isAndroid ? window.screen.height : window.innerHeight);
        });
    }, []);

    const modalStyle = () => {
        const isSmallScreen = isMobileOnly || deviceWidth <= 480 || deviceHeight <= 480;
        if (isSmallScreen) {
            return {
                content: {
                    position: 'absolute',
                    top: `${deviceWidth < 330 ? '10%' : deviceHeight <= 480 ? '10%' : 'auto'}`,
                    left: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    right: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    bottom: '0',
                    backgroundColor: themeColors[theme].popupBgColor1,
                    border: 'none',
                    borderRadius: '20px 20px 0 0',
                    maxHeight: '80vh',
                    color: themeColors[theme].blackFontColor
                }
            };
        } else {
            return {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    padding: '50px 60px 55px',
                    borderRadius: '20px',
                    height: '630px',
                    width: '658px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'hidden',
                    color: themeColors[theme].blackFontColor
                }
            };
        }
    };

    const modalStyles = modalStyle();

    const sfText = (checked, notAvailable) => (
        <Row alignItems="center">
            {checked ? (
                <Col>
                    <Icon name="superFast" width={28} height={30} />
                </Col>
            ) : (
                <Icon name="sfGrey" />
            )}

            <Box marginLeft={15}>
                {t('cart.deliveryOption.superFast')}

                <Box fontSize={12} fontWeight="normal">
                    {checked
                        ? t('scheduledModal.receiveEta')
                        : notAvailable
                          ? t('scheduledModal.notAvailable')
                          : null}
                </Box>
            </Box>
        </Row>
    );

    const DisabledButton = () => (
        <Row
            width={46}
            height={44}
            objectFit={'contain'}
            justifyContent="center"
            alignItems="center"
        >
            <Box
                margin={'auto auto'}
                width={22}
                height={22}
                borderRadius="50%"
                background={`url(${unselected}) center no-repeat`}
            ></Box>
        </Row>
    );

    const sfUnChecked = (checked, notAvailable) => (
        <Row component="label" alignItems="center" marginBottom={20}>
            <DisabledButton />
            <Box fontSize={16} fontWeight={900} opacity={0.5}>
                {sfText(checked, notAvailable)}
            </Box>
        </Row>
    );

    const ScheduledNotAvailable = ({ rangeWindowType, window }) => (
        <Row component="label" alignItems="center" marginBottom={20}>
            <DisabledButton />
            <Box fontSize={16} fontWeight={900} opacity={0.5}>
                {rangeWindowType === WindowSubType.range
                    ? t('scheduledModal.scheduledDelivery')
                    : formatDeliveryWindowText(window, storeClosed, t)}

                <Box fontSize={12} fontWeight="normal">
                    {t('scheduledModal.notAvailable')}
                </Box>
            </Box>
        </Row>
    );

    const windowChecked = `${selectedWindow?.window_id}${selectedWindow?.deliver_from}`;

    const hasRangeWindow = deliveryWindows?.find(
        window => window.window_subtype === WindowSubType.range
    );

    return (
        <Box>
            <Modal
                style={modalStyles}
                isOpen={showModal}
                onRequestClose={onClose}
                overlayClassName="buy-now-modal-overlay"
                bodyOpenClassName="ReactModal__Body--no_scroll"
                closeTimeoutMS={500}
                preventScroll={true}
                shouldFocusAfterRender={false}
                shouldCloseOnOverlayClick={true}
            >
                <Row
                    justifyContent="flex-end"
                    boxShadow="0 4px 10px 4px rgb(255 255 255 / 90%)"
                    position="relative"
                    zIndex={10}
                >
                    <Image
                        cursor="pointer"
                        src={closeIcon}
                        width={30}
                        height={30}
                        padding={7}
                        onClick={onClose}
                    />
                </Row>

                <ModalPage
                    padding="10px 0 0 0"
                    title={cartPage ? t('scheduledModal.title2') : t('scheduledModal.title')}
                    subtitle={
                        cartPage ? t('scheduledModal.chooseBest') : t('scheduledModal.subtitle')
                    }
                    buttonTitle={t('buyNowModal.save')}
                    onSave={() => {
                        onSaveScWindow(selectedWindow);
                    }}
                    saveEnabled={saveEnabled}
                >
                    <Col
                        marginTop={30}
                        justifyContent="flex-end"
                        background={themeColors[theme].popupBgColor1}
                    >
                        {deliveryWindows && cartPage && sfAvailable && !productNotAvailable && (
                            <RadioButton
                                scWindow
                                onChange={e => {
                                    setWindowId(e.target.value);
                                    setSelectedWindow(ThreadDeliveryModel.sfWindowId);
                                    setSaveEnabled(true);
                                }}
                                checked={ThreadDeliveryModel.sfWindowId === windowId}
                                value={ThreadDeliveryModel.sfWindowId}
                                marginBottom={10}
                            >
                                <Box>
                                    <Box
                                        fontSize={16}
                                        fontWeight={900}
                                        opacity={
                                            ThreadDeliveryModel.sfWindowId === windowId ? 1 : 0.5
                                        }
                                    >
                                        {sfText(ThreadDeliveryModel.sfWindowId === windowId)}
                                    </Box>
                                </Box>
                            </RadioButton>
                        )}
                        {((!sfAvailable && cartPage && supportsBothDeliveryModels) ||
                            productNotAvailable) &&
                            sfUnChecked(ThreadDeliveryModel.sfWindowId, !sfAvailable)}

                        {deliveryWindows?.map(item => {
                            return scAvailable && !productNotAvailable ? (
                                <RadioButton
                                    scWindow
                                    key={`${item.window_id}${item.deliver_from}`}
                                    onChange={e => {
                                        setWindowId(e.target.value);
                                        setSelectedWindow(item);
                                        setSaveEnabled(true);
                                    }}
                                    checked={
                                        `${item.window_id}${item.deliver_from}` === windowChecked
                                    }
                                    value={item.window_id}
                                    marginBottom={10}
                                    cursor="pointer"
                                >
                                    <Box>
                                        <Box
                                            fontSize={16}
                                            fontWeight={900}
                                            opacity={
                                                `${item.window_id}${item.deliver_from}` ===
                                                windowChecked
                                                    ? 1
                                                    : 0.5
                                            }
                                        >
                                            {formatDeliveryWindowText(item, storeClosed, t)}
                                        </Box>
                                        {`${item.window_id}${item.deliver_from}` ===
                                            windowChecked && (
                                            <Box fontSize={12}>
                                                {t('scheduledModal.receiveEta')}
                                            </Box>
                                        )}
                                    </Box>
                                </RadioButton>
                            ) : (
                                item.window_subtype !== WindowSubType.range && (
                                    <ScheduledNotAvailable
                                        rangeWindowType={item.window_subtype}
                                        window={item}
                                    />
                                )
                            );
                        })}
                        {((!scAvailable && hasRangeWindow) || productNotAvailable) && (
                            <ScheduledNotAvailable rangeWindowType={WindowSubType.range} />
                        )}
                    </Col>
                </ModalPage>
            </Modal>
        </Box>
    );
}

export default ScheduledWindowModal;
