import useSWR from 'swr';

import api from '../api';
import useUser from './useUser';

export default function useThread(id) {
    const { data: user } = useUser();
    const key = () => `thread?uid=${user.id}&tid=${id ? id : user.threadId}&a=${user.address}`;
    const fetch = () => api.getThread(user, id);
    const { data, error, mutate } = useSWR(key, fetch);

    return {
        data,
        error,
        mutate
    };
}
