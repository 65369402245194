import useSWR from 'swr';

import useCustomerData from 'hooks/useCustomerData';

import { isScheduledOrder } from 'api/utils';

import api from '../api';
import useStoreSettings from './useStoreSettings';

export default function useCatalog(
    deliveryModel,
    category = '',
    query,
    filtersParams,
    sortParams,
    skusParams,
    skusNotParams,
    categoriesParams,
    categoriesNotParams,
    lastKey
) {
    const { data: mappedData } = useCustomerData();
    const { session, thread, zone } = mappedData || {};

    const { data: storeSettings } = useStoreSettings();
    const supportsDelivery =
        storeSettings?.supports_scheduled_menu || storeSettings?.supports_ondemand_menu;
    const supportsPickup = storeSettings?.supports_pickup;
    const pickupOnly = !supportsDelivery && supportsPickup;

    const { isOpen, organization_id } = storeSettings || {};

    const PAGE_SIZE = 25;
    const kit_id = thread?.menu_kit || sessionStorage.getItem('menu_kit') || null;
    const pickup_destination_id = thread?.pickup_destination?.destination_id;

    const key = () => [
        `catalog`,
        session.authenticated,
        thread.zoneId,
        isOpen,
        deliveryModel,
        query,
        category,
        lastKey || '',
        pickup_destination_id,
        filtersParams,
        sortParams,
        skusParams,
        skusNotParams,
        categoriesParams,
        categoriesNotParams
    ];

    const shouldUseFrontStock = Boolean(
        isScheduledOrder(deliveryModel) || pickup_destination_id || pickupOnly
    );

    const fetch = params => {
        const [url, authenticated, zoneId, isOpen, deliveryModel, query, category, lastKey] =
            params;
        return storeSettings || pickup_destination_id
            ? api.getCatalog({
                  organization_id,
                  query,
                  isOpen,
                  zone_id: thread?.pickup_destination || pickupOnly ? null : zoneId,
                  redirected_from_zone:
                      thread?.pickup_destination || pickupOnly ? null : zone.redirected_from_zone,
                  menu_kit_id: kit_id,
                  thread_id: authenticated ? thread.id : null,
                  useHubStock: shouldUseFrontStock,
                  category_ids: category && category.length ? [category] : [],
                  size: PAGE_SIZE,
                  lastKey,
                  preference: mappedData?.thread?.id,
                  associated_hub: mappedData?.thread?.associated_hub,
                  filtersParams,
                  skusParams,
                  skusNotParams,
                  categoriesParams,
                  categoriesNotParams
              })
            : false;
    };

    const options = {
        revalidateOnFocus: false, // need remove if want revalidate on page focus
        shouldRetryOnError: true,
        errorRetryInterval: 30000,
        dedupingInterval: 120000,
        errorRetryCount: 3
    };
    return useSWR(key, fetch, options);
}
