import React, { useState } from 'react';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from '../api/constants';
import info from '../assets/images/info.svg';
import infoWhite from '../assets/images/info-white.svg';

export default function Hint({ noWhite, children }) {
    const [visible, setVisible] = useState(false);
    const { theme, themeColors } = useTheme();

    return (
        <Box position="relative">
            {visible ? (
                <Box
                    position="absolute"
                    left={-120}
                    bottom={61}
                    zIndex={25}
                    width={300}
                    maxWidth={320}
                    background={themeColors[theme].tertiaryBgColor3}
                    boxShadow={`0 2px 100px 0 ${themeColors[theme].boxShadowColor1}`}
                    border={`1px solid ${themeColors[theme].tertiaryBorderColor2}`}
                    borderRadius={10}
                    padding="50px 30px"
                    color={themeColors[theme].secondaryFontColor4}
                    fontSize={16}
                    lineHeight={1.25}
                    letterSpacing={1}
                    fontWeight={800}
                >
                    {children}
                </Box>
            ) : null}
            <Box
                position="relative"
                cursor="pointer"
                top={-5}
                zIndex={30}
                width={40}
                height={40}
                background={`url(${
                    theme === THEME.DARK && !noWhite ? infoWhite : info
                }) center no-repeat`}
                className={visible ? 'hintAngle' : ''}
                props={{
                    onClick: () => {
                        setVisible(!visible);
                    }
                }}
            />
        </Box>
    );
}
