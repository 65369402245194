import React, { useEffect, useState } from 'react';
import { isAndroid, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { isHebrew } from 'api/utils';

import closeIcon from 'assets/images/black-close-icon.svg';

import SaveButton from 'components/DeliveryOptionsModal/components/SaveButton';
import Icon from 'components/Icon';
import Image from 'components/Image';
import { Divider } from 'components/shared';
import LocationsList from 'components/shared/LocationsList';

import ModalPage from '../../components/ModalPage';

export default function PickupLocationsModal({
    showModal,
    onClose,
    currentPickupLocation,
    setCurrentPickupLocation,
    pickupLocations,
    timeZone,
    updatingLocation,
    setUpdatingLocation
}) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const [activePickupLocation, setActivePickupLocation] = useState(currentPickupLocation);
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [deviceHeight, setDeviceHeight] = useState(
        isAndroid ? window.screen.height : window.innerHeight
    );
    const [saveEnabled, setSaveEnabled] = useState(false);

    useEffect(() => {
        const activeLocationChanged =
            activePickupLocation?.destination_id &&
            activePickupLocation?.destination_id !== currentPickupLocation?.destination_id;
        setSaveEnabled(activeLocationChanged);
    }, [activePickupLocation?.destination_id]);

    useEffect(() => {
        window.addEventListener('resize', function () {
            setDeviceWidth(window.innerWidth);
            setDeviceHeight(isAndroid ? window.screen.height : window.innerHeight);
        });
    }, []);

    async function onLocationSave() {
        setUpdatingLocation(true);
        await setCurrentPickupLocation(activePickupLocation);
        setUpdatingLocation(false);
        onClose();
    }

    const modalStyle = () => {
        const isSmallScreen = isMobileOnly || deviceWidth <= 480 || deviceHeight <= 480;
        if (isSmallScreen) {
            return {
                content: {
                    position: 'absolute',
                    top: `${deviceWidth < 330 ? '10%' : deviceHeight <= 480 ? '10%' : 'auto'}`,
                    left: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    right: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    bottom: '0',
                    backgroundColor: themeColors[theme].primaryBgColor4,
                    border: 'none',
                    borderRadius: '20px 20px 0 0',
                    maxHeight: '85vh'
                }
            };
        } else {
            return {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    padding: '50px 60px 55px',
                    borderRadius: '20px',
                    height: '630px',
                    width: '658px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'hidden'
                }
            };
        }
    };

    const modalStyles = modalStyle();

    return (
        <Box>
            <Modal
                style={modalStyles}
                isOpen={showModal}
                onRequestClose={onClose}
                overlayClassName="buy-now-modal-overlay"
                closeTimeoutMS={500}
            >
                <Row
                    justifyContent="flex-end"
                    boxShadow="0 4px 10px 4px rgb(255 255 255 / 90%)"
                    position="relative"
                    zIndex={10}
                >
                    <Image
                        cursor="pointer"
                        src={closeIcon}
                        width={30}
                        height={30}
                        padding={7}
                        onClick={onClose}
                    />
                </Row>

                <ModalPage
                    spaceBetween
                    secondColor
                    padding="10px 0 0 0"
                    title={t('cart.pickupDropdown.dialogTitle')}
                    subtitle={t('cart.pickupDropdown.dialogSubtitle')}
                    loading={updatingLocation}
                    saveEnabled={saveEnabled}
                    hideFooter={true}
                >
                    <Col
                        justifyContent="flex-end"
                        background={themeColors[theme].primaryBgColor4}
                        padding="55px 0"
                    >
                        <LocationsList
                            locations={pickupLocations}
                            timeZone={timeZone}
                            setActiveLocation={setActivePickupLocation}
                            activeLocation={activePickupLocation}
                        />
                    </Col>
                    <Col
                        position="fixed"
                        bottom={0}
                        right={0}
                        left={0}
                        margin="0 auto"
                        flex="1 1"
                        justifyContent="flex-end"
                        marginTop={isMobileOnly ? 30 : 40}
                        zIndex={10}
                        boxShadow="inset 10em 10em rgb(255 255 255 / 90%)"
                    >
                        <Divider marginBottom={20} light />
                        <Row
                            justifyContent="space-between"
                            alignItems="center"
                            padding="0 20px 35px 20px"
                        >
                            <Row marginRight={20}>
                                <Icon name="cart" width={20} />
                                <Box
                                    color={themeColors[theme].secondaryFontColor1}
                                    fontSize={12}
                                    fontWeight={500}
                                    lineHeight={1.43}
                                    letterSpacing={1}
                                    margin="0px 25px 0 10px"
                                    width={isMobileOnly && 155}
                                >
                                    {isHebrew() ? (
                                        <Box>
                                            {t('addressModal.pickupReadyNote')}
                                            <Box>
                                                {activePickupLocation?.default_pickup_estimate}
                                            </Box>
                                        </Box>
                                    ) : (
                                        t('addressModal.pickupReadyNote', {
                                            est_order_ready_time:
                                                activePickupLocation?.default_pickup_estimate
                                        })
                                    )}
                                </Box>
                            </Row>

                            <SaveButton
                                width={150}
                                loading={updatingLocation}
                                disabled={!saveEnabled}
                                onClick={onLocationSave}
                                title={t('addressModal.save')}
                                noShadow
                                small
                            />
                        </Row>
                    </Col>
                </ModalPage>
            </Modal>
        </Box>
    );
}
