import React, { useEffect, useState } from 'react';
import { isAndroid, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';

import closeIcon from 'assets/images/black-close-icon.svg';

import Image from 'components/Image';

import Button from '../Button';

function ProductAvailabilityModal({ showModal, onClose, onCancel, onConfirm }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [deviceHeight, setDeviceHeight] = useState(
        isAndroid ? window.screen.height : window.innerHeight
    );

    useEffect(() => {
        window.addEventListener('resize', () => {
            setDeviceWidth(window.innerWidth);
            setDeviceHeight(isAndroid ? window.screen.height : window.innerHeight);
        });
    }, []);

    const modalStyle = () => {
        const isSmallScreen = isMobileOnly || deviceWidth <= 480 || deviceHeight <= 480;
        if (isSmallScreen) {
            return {
                content: {
                    position: 'absolute',
                    top: `${deviceWidth < 330 ? '10%' : deviceHeight <= 480 ? '10%' : 'auto'}`,
                    left: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    right: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    bottom: '0',
                    backgroundColor: themeColors[theme].popupBgColor1,
                    border: 'none',
                    padding: '30px',
                    borderRadius: '20px 20px 0 0',
                    maxHeight: '80vh'
                }
            };
        } else {
            return {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    padding: '30px',
                    borderRadius: '20px',
                    height: '325px',
                    width: '652px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'hidden'
                }
            };
        }
    };

    const modalStyles = modalStyle();

    return (
        <Box>
            <Modal
                style={modalStyles}
                isOpen={showModal}
                onRequestClose={onClose}
                overlayClassName="buy-now-modal-overlay"
                closeTimeoutMS={500}
                padding="15px 15px 130px"
            >
                <Row justifyContent="flex-end">
                    <Image
                        cursor="pointer"
                        src={closeIcon}
                        width={30}
                        height={30}
                        padding={7}
                        onClick={onClose}
                    />
                </Row>
                <Box textAlign="center" color={themeColors[theme].tertiaryFontColor2}>
                    <Box fontSize={30} fontWeight={900} letterSpacing={1} marginBottom={10}>
                        {t('productAvailabilityModal.title')}
                    </Box>
                    <Box fontWeight={700} fontSize={16} letterSpacing={1} marginBottom={30}>
                        {t('productAvailabilityModal.desp')}
                    </Box>
                    <Col alignItems="center">
                        <Button
                            white={theme === THEME.DARK}
                            width={isMobileOnly ? ' 100%' : '250px'}
                            marginBottom="20px"
                            backgroundSize={15}
                            noHover
                            hoverWhite={isMobileOnly}
                            onClick={onConfirm}
                        >
                            {t('productAvailabilityModal.changeBtn')}
                        </Button>
                        <Button
                            noHover
                            width={isMobileOnly ? ' 100%' : '250px'}
                            white={theme !== THEME.DARK}
                            onClick={onCancel}
                        >
                            {t('productAvailabilityModal.notNowBtn')}
                        </Button>
                    </Col>
                </Box>
            </Modal>
        </Box>
    );
}

export default ProductAvailabilityModal;
