import { ApplicationId, CHANNEL_TYPE, Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function submitOrder(
    user_id,
    thread_id,
    organization_id,
    delivery_model,
    delivery_window,
    payment_type,
    order_type,
    pickup_destination
) {
    const payload = {
        user_id,
        thread_id,
        organization_id,
        order_assistant: CHANNEL_TYPE.WEB,
        delivery_model,
        delivery_window,
        payment_type,
        application_id: ApplicationId,
        order_type,
        pickup_destination
    };
    return invokeLambda(Lambda.submitOrder, payload);
}
