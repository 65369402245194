import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';
import phoneParser from 'libphonenumber-js';

import useCustomerData from 'hooks/useCustomerData';
import useTheme from 'hooks/useTheme';

import { validateInput } from '../api/utils';
import Close from '../components/CloseBar';
import Heading from '../components/Heading';
import Head from '../components/HeadManager';
import Loading from '../components/Loading';
import P from '../components/Paragraph';
import Wrapper from '../components/Wrapper';

export default function Account({ pageTitle }) {
    const { theme, themeColors } = useTheme();

    const { t } = useTranslation();
    const { data: mappedData, store } = useCustomerData();
    const { user } = mappedData || {};

    const [validEmail, setValidEmail] = useState(true);
    const [validName, setValidName] = useState(true);

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [saving, setSaving] = useState();
    const isLoading = !user;
    if (isLoading) {
        return (
            <Wrapper white>
                <Loading />
            </Wrapper>
        );
    }

    const onClose = () => {
        navigate(`/account`);
    };

    const onDelete = () => {
        navigate(`/delete-account`);
    };

    const onSave = async () => {
        setSaving(true);
        const fullName = name || user.name;
        await store.updateCustomerProfileData(user.username, fullName, email);
        setSaving(false);
        onClose();
    };

    const phoneNumber = user?.username && phoneParser(`+${user.username}`).formatInternational();

    const saveDisabled =
        (!name && !email) ||
        name === '' ||
        email === '' ||
        (name === user.name && email === user.email) ||
        (name === user.name && !email) ||
        (!name && email === user.email) ||
        (email && !validEmail);

    return (
        <Wrapper white>
            <Head pageTitle={pageTitle} />
            <Row
                alignItems="center"
                fontSize={17}
                fontWeight={500}
                justifyContent="space-between"
                marginBottom={60}
                maxWidth={600}
            >
                <Close theme={theme} themeColors={themeColors} onClose={onClose} />
                <Box
                    padding="10px 0 15px 20px"
                    cursor="pointer"
                    pointerEvents={saveDisabled ? 'none' : 'auto'}
                    opacity={saveDisabled ? '0.3' : '1'}
                    color={themeColors[theme].primaryFontColor1}
                    props={{ onClick: onSave }}
                >
                    {saving ? t('account.saving') : t('account.saveChange')}
                </Box>
            </Row>
            <Heading>{t('account.editAccount')}</Heading>
            <Box
                fontSize={18}
                fontWeight={500}
                color={themeColors[theme].secondaryFontColor2}
                marginBottom={25}
            >
                {t('account.updateAccount')}
            </Box>
            <TextInput
                placeholder={t('account.fullName')}
                defaultValue={user.name}
                onChange={e => {
                    setValidName(validateInput('name', e.target.value));
                    setName(e.target.value);
                }}
                theme={theme}
                themeColors={themeColors}
            />
            {!validName && (
                <Error theme={theme} themeColors={themeColors}>
                    {t('account.nameError')}
                </Error>
            )}
            <TextInput
                marginTop={20}
                placeholder={t('account.email')}
                defaultValue={user && user.email}
                onChange={e => {
                    setValidEmail(validateInput('email', e.target.value));
                    setEmail(e.target.value);
                }}
                theme={theme}
                themeColors={themeColors}
            />
            {!validEmail && (
                <Error theme={theme} themeColors={themeColors}>
                    {t('account.emailError')}
                </Error>
            )}
            <Box padding={20} maxWidth={335}>
                <Box
                    color={themeColors[theme].primaryFontColor1}
                    fontSize={18}
                    fontWeight={500}
                    marginBottom={10}
                >
                    {phoneNumber}
                </Box>
                <P lineHeight="1" color={themeColors[theme].secondaryFontColor11}>
                    {t('account.phoneError')}{' '}
                    <Box
                        display="inline"
                        textDecoration="underline"
                        cursor="pointer"
                        props={{ onClick: onDelete }}
                    >
                        {t('account.deleteAccount.title')}
                    </Box>{' '}
                    {t('account.openAccount')}
                </P>
            </Box>
        </Wrapper>
    );
}

const TextInput = ({ placeholder, defaultValue, onChange, theme, themeColors, ...rest }) => {
    return (
        <Box
            component="input"
            display="block"
            background={themeColors[theme].secondaryBgColor15}
            border="none"
            padding="14px 20px 17px"
            width="100%"
            maxWidth={335}
            borderRadius={8}
            fontSize={18}
            fontWeight={500}
            {...rest}
            props={{
                placeholder,
                defaultValue,
                onChange
            }}
        />
    );
};

const Error = ({ children, theme, themeColors }) => (
    <P color={themeColors[theme].quaternaryFontColor2} fontWeight={400} paddingLeft={20}>
        {children}
    </P>
);
