import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { getOperationHourText, isStoreOpen } from 'api/utils';

import RadioButton from 'components/shared/RadioButton';

export default function LocationsList({ locations, timeZone, activeLocation, setActiveLocation }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    if (locations?.length === 1) {
        setActiveLocation(locations[0]);
    }

    return (
        <Box overflow="auto">
            {locations.map(item => {
                const { destination_id, name, location, availability } = item;
                const isOpen = isStoreOpen(availability, timeZone);

                return (
                    <Box marginBottom={40} key={item.destination_id}>
                        <RadioButton
                            key={destination_id}
                            onChange={e => {
                                setActiveLocation(item);
                            }}
                            checked={item.destination_id === activeLocation?.destination_id}
                            value={item.name}
                            marginBottom={10}
                            cursor="pointer"
                        >
                            <Box color={themeColors[theme].tertiaryFontColor2}>
                                <Box fontWeight="bold" fontSize={16}>
                                    {name}
                                </Box>
                                <Box fontSize={12} fontWeight={500}>
                                    {location.address}
                                </Box>
                                <Box
                                    fontSize={12}
                                    fontWeight={500}
                                    color={
                                        isOpen
                                            ? themeColors[theme].quinaryFontColor1
                                            : themeColors[theme].secondaryFontColor1
                                    }
                                >
                                    {getOperationHourText(availability, timeZone, t)}
                                </Box>
                            </Box>
                        </RadioButton>
                    </Box>
                );
            })}
        </Box>
    );
}
