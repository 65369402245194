import React, { useEffect, useState } from 'react';
import { isAndroid, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import DeliveryModel from 'constants/DeliveryModel';
import { Box, Col, Inline, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';

import { useAppContext } from 'store';

import closeIcon from 'assets/images/black-close-icon.svg';

import Icon from 'components/Icon';
import Image from 'components/Image';

import Button from '../../components/Button';

function DeliveryTypeModal({ showModal, onClose, onDeliveryModelChange, eta }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [deviceHeight, setDeviceHeight] = useState(
        isAndroid ? window.screen.height : window.innerHeight
    );

    const { appContextActions } = useAppContext();

    useEffect(() => {
        window.addEventListener('resize', () => {
            setDeviceWidth(window.innerWidth);
            setDeviceHeight(isAndroid ? window.screen.height : window.innerHeight);
        });
    }, []);

    const modalStyle = () => {
        const isSmallScreen = isMobileOnly || deviceWidth <= 480 || deviceHeight <= 480;
        if (isSmallScreen) {
            return {
                content: {
                    position: 'absolute',
                    top: `${deviceWidth < 330 ? '10%' : deviceHeight <= 480 ? '10%' : 'auto'}`,
                    left: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    right: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    bottom: '0',
                    backgroundColor: themeColors[theme].popupBgColor1,
                    border: 'none',
                    padding: '20px',
                    borderRadius: '20px 20px 0 0',
                    maxHeight: '80vh',
                    color: themeColors[theme].blackFontColor
                }
            };
        } else {
            return {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    padding: '20px',
                    borderRadius: '20px',
                    height: '542px',
                    width: '652px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'hidden',
                    backgroundColor: themeColors[theme].popupBgColor1,
                    color: themeColors[theme].blackFontColor
                }
            };
        }
    };

    const modalStyles = modalStyle();

    return (
        <Box>
            <Modal
                style={modalStyles}
                isOpen={showModal}
                onRequestClose={onClose}
                overlayClassName="buy-now-modal-overlay"
                bodyOpenClassName="ReactModal__Body--no_scroll"
                shouldCloseOnOverlayClick={true}
                closeTimeoutMS={500}
            >
                <Row justifyContent="flex-end">
                    <Image
                        cursor="pointer"
                        src={closeIcon}
                        width={30}
                        height={30}
                        padding={7}
                        onClick={onClose}
                    />
                </Row>
                <Box textAlign="center" padding={!isMobileOnly && '30px 50px'}>
                    <Icon name="deliveryType" />
                    <Box
                        fontSize={30}
                        fontWeight={900}
                        letterSpacing={1}
                        marginTop={20}
                        marginBottom={15}
                        color={themeColors[theme].popupFontColor4}
                    >
                        {t('deliveryTypeModal.title')}
                    </Box>
                    <Box
                        fontWeight={500}
                        fontSize={18}
                        letterSpacing={1}
                        color={themeColors[theme].popupFontColor2}
                        marginBottom={40}
                    >
                        <Box marginBottom={10}>
                            {t('deliveryTypeModal.content1')}
                            <Inline fontWeight={900} color={themeColors[theme].popupFontColor4}>
                                {t('deliveryTypeModal.superFast')}
                            </Inline>
                            {t('deliveryTypeModal.content2', { org_eta_est: eta })}
                            <Inline fontWeight={900} color={themeColors[theme].popupFontColor4}>
                                {t('deliveryTypeModal.scheduled')}
                            </Inline>
                            {t('deliveryTypeModal.content3')}
                        </Box>
                    </Box>
                    <Col alignItems="center">
                        <Button
                            white={theme === THEME.DARK}
                            width={isMobileOnly ? ' 100%' : '334px'}
                            marginBottom="20px"
                            noHover
                            hoverWhite={isMobileOnly}
                            props={{
                                onClick: () => {
                                    appContextActions.deliveryTypeModal.close();
                                    onDeliveryModelChange(DeliveryModel.OnDemand);
                                }
                            }}
                        >
                            <Row justifyContent="center" alignItems="center">
                                <Icon name="superFast" width={20} />
                                <Box marginLeft={10}>{t('deliveryTypeModal.sfBtn')}</Box>
                            </Row>
                        </Button>
                        <Button
                            white={theme === THEME.DARK}
                            width={isMobileOnly ? ' 100%' : '334px'}
                            noHover
                            hoverWhite={isMobileOnly}
                            props={{
                                onClick: () => {
                                    appContextActions.deliveryTypeModal.close();
                                    onDeliveryModelChange(DeliveryModel.Scheduled);
                                }
                            }}
                        >
                            <Row justifyContent="center" alignItems="center">
                                <Icon name="scheduled" width={20} />
                                <Box marginLeft={15}> {t('deliveryTypeModal.scBtn')}</Box>
                            </Row>
                        </Button>
                    </Col>
                </Box>
            </Modal>
        </Box>
    );
}

export default DeliveryTypeModal;
