import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';

import Button from 'components/Button';
import Spinner from 'components/Spinner';

export default function SaveButton({
    loading,
    onBlackBg,
    onClick,
    disabled,
    noShadow,
    title = 'Save',
    small,
    noPadding,
    ...rest
}) {
    const { theme } = useTheme();
    return (
        <Box maxHeight={50} {...rest}>
            {loading && (
                <Row justifyContent="center">
                    <Spinner
                        margin="0 0"
                        marginRight={15}
                        marginBottom={0}
                        white={theme === THEME.DARK}
                    />
                </Row>
            )}
            <Button
                visibility={loading ? 'hidden' : 'display'}
                disabled={disabled}
                onClick={onClick}
                width="100%"
                maxWidth="100%"
                minWidth="100%"
                padding={small ? '10px 35px' : '15px 25px'}
                noShadow={noShadow}
                white={!onBlackBg && theme === THEME.DARK}
                hoverWhite={isMobileOnly}
            >
                {title}
            </Button>
        </Box>
    );
}
