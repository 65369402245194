import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Icon from '../components/Icon';
import PickupLocationsModal from '../components/Modals/PickupLocationsModal';
import Spinner from '../components/Spinner';

export default function CartPickupDropdown({
    currentPickupLocation,
    setCurrentPickupLocation,
    pickupLocations,
    timeZone
}) {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [updatingLocation, setUpdatingLocation] = useState(false);
    const { theme, themeColors } = useTheme();

    return (
        <Box>
            <Box marginBottom={50}>
                <Box
                    marginBottom={10}
                    fontSize={12}
                    fontWeight={500}
                    color={themeColors[theme].secondaryFontColor27}
                >
                    {t('cart.pickupDropdown.pickupAt')}
                </Box>
                <Row
                    width="100%"
                    minHeight={52}
                    padding="17px 10px 15px 12px"
                    borderRadius={8}
                    backgroundColor={themeColors[theme].inputBgColor}
                    cursor={'pointer'}
                    props={{ onClick: () => setShowModal(true) }}
                    alignItems="center"
                    justifyContent="space-between"
                    fontSize={16}
                    fontWeight="500"
                >
                    <Row alignItems="center" justifyContent="space-between">
                        {updatingLocation ? (
                            <Spinner noMargin />
                        ) : (
                            <Icon name="store" width={29} height={29} />
                        )}
                        <Box
                            marginLeft={10}
                            fontSize={16}
                            fontWeight="bold"
                            color={themeColors[theme].tertiaryFontColor1}
                            opacity={updatingLocation && 0.5}
                        >
                            {currentPickupLocation?.name}
                        </Box>
                    </Row>

                    <Icon name="arrowBottom" />
                </Row>
            </Box>

            {showModal && !updatingLocation && (
                <PickupLocationsModal
                    showModal={showModal}
                    onClose={() => {
                        setShowModal(false);
                    }}
                    currentPickupLocation={currentPickupLocation}
                    setCurrentPickupLocation={setCurrentPickupLocation}
                    pickupLocations={pickupLocations}
                    timeZone={timeZone}
                    setUpdatingLocation={setUpdatingLocation}
                    updatingLocation={updatingLocation}
                />
            )}
        </Box>
    );
}
