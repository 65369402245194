import React from 'react';
import { Box, Col } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Footer from './Footer';

export default function Modal(props) {
    const { theme, themeColors } = useTheme();

    return (
        <Col minHeight="100vh" minWidth={320} background={themeColors[theme].primaryBgColor1}>
            <Box
                flex="1 0 auto"
                margin="0 auto"
                maxWidth={800}
                padding="15px 15px 130px"
                width="100%"
                minHeight={600}
            >
                {props.children}
            </Box>
            <Footer flex="0 0 auto" narrow />
        </Col>
    );
}
