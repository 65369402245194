import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';
import { navigate, useQueryParams } from '@patched/hookrouter';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { useAppContext } from 'store';

import background from 'assets/images/refer-friend-illustration.svg';

import CloseBar from 'components/CloseBar';
import Heading from 'components/Heading';
import { Notification } from 'components/shared';
import Wrapper from 'components/Wrapper';

import { THEME } from '../api/constants';
import { isHebrew, priceToStringRound } from '../api/utils';
import Icon from '../components/Icon';
import Spinner from '../components/Spinner';
import useCustomerData from '../hooks/useCustomerData';
import useStoreSettings from '../hooks/useStoreSettings';

export default function ReferFriend() {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();
    const [showNotification, setShowNotification] = useState(null);
    const { data: storeSettings } = useStoreSettings();
    const { data: mappedData } = useCustomerData();
    const { user } = mappedData || {};
    const { state } = useAppContext();
    const formatPrice = price => priceToStringRound(price, state.currency);
    const [params] = useQueryParams();
    const onClose = () => {
        const prevPage = window.location.href;
        !params.hid && window.history.back();
        setTimeout(() => {
            if (window.location.href === prevPage) navigate('/');
        }, 100);
    };

    if (!storeSettings || !user) {
        return (
            <Wrapper white narrow>
                <Spinner />
            </Wrapper>
        );
    }

    if (!user.authenticated) {
        navigate('/login', false, { from: 'refer' });
    }

    if (!storeSettings.referral_program) {
        navigate('/');
    }

    const referringCustomerReward = storeSettings?.referral_program?.referring_customer_reward
        ?.cents
        ? formatPrice(storeSettings.referral_program.referring_customer_reward.cents)
        : `${storeSettings?.referral_program?.referring_customer_reward?.percentage || ''}%`;

    const referredCustomerReward = storeSettings?.referral_program?.referred_customer_reward?.cents
        ? formatPrice(storeSettings.referral_program.referred_customer_reward.cents)
        : `${storeSettings?.referral_program?.referred_customer_reward?.percentage || ''}%`;

    const url = `${storeSettings?.store_domain}/?ref=${user.referral_code}`;
    const shareUrl = `https://${url}`;
    const sharedContent = isHebrew()
        ? `${t('referFriend.sharedContent1', {
              storeName: storeSettings.name
          })} ${t('referFriend.sharedContent2')} ${t('referFriend.sharedContent3', {
              referral_code: user.referral_code
          })} ${t('referFriend.sharedContent4', {
              reward: referredCustomerReward
          })} הנחה \n`
        : t('referFriend.sharedContent', {
              storeName: storeSettings.name,
              referral_code: user.referral_code,
              reward: referredCustomerReward
          });

    const smsContent = `sms:?&body=${sharedContent} ${shareUrl}`;

    const offerDetails = [
        t('referFriend.offerDetail1'),
        isHebrew()
            ? `${t('referFriend.offerDetail2a', {
                  referredCustomerReward: referredCustomerReward
              })} ${t('referFriend.offerDetail2b')}`
            : t('referFriend.offerDetail2', {
                  referredCustomerReward: `${referredCustomerReward}`
              }),
        isHebrew()
            ? `${t('referFriend.offerDetail3a')} ${t('referFriend.offerDetail3b', {
                  referringCustomerReward: `${referringCustomerReward}`
              })} ${t('referFriend.offerDetail3c')}`
            : t('referFriend.offerDetail3', {
                  referringCustomerReward: `${referringCustomerReward}`
              })
    ];

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareUrl);
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 5000);
    };
    const handleReferralBtn = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: storeSettings?.name,
                    text: sharedContent,
                    url: shareUrl
                })
                .catch(console.error);
        } else {
            // TODO: desktop modal
        }
    };

    return (
        <Wrapper white narrow>
            {showNotification && (
                <Notification iconName="whiteCheck" content={t('referFriend.clipboard')} />
            )}
            <Box
                minHeight={215}
                marginBottom={15}
                padding="25px 0"
                background={`url(${background}) center center no-repeat`}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <CloseBar
                    closeIconName={theme === THEME.DARK ? 'backWhite' : 'back'}
                    onClose={onClose}
                ></CloseBar>
                {navigator.share && (
                    <Icon
                        name={theme === THEME.DARK ? 'shareBtnWhite' : 'shareBtn'}
                        cursor="pointer"
                        onClick={handleReferralBtn}
                    />
                )}
            </Box>

            <Heading
                color={themeColors[theme].primaryFontColor1}
                marginBottom={0}
                textAlign="center"
            >
                {t('referFriend.spreadLove')}
            </Heading>
            <Col color={themeColors[theme].secondaryFontColor2} fontWeight={500}>
                <Box fontSize={18} textAlign="center">
                    {referringCustomerReward === referredCustomerReward ? (
                        <Box>
                            {t('referFriend.customerReward', {
                                referringCustomerReward: `\u200E${referringCustomerReward}`
                            })}
                            <br />
                            {!isHebrew() && t('referFriend.customerReward1')}
                        </Box>
                    ) : (
                        <Box>
                            {t('referFriend.customerReward2', {
                                referringCustomerReward: `\u200E${referringCustomerReward}`
                            })}
                            <br />
                            {t('referFriend.customerReward3', {
                                referredCustomerReward: `\u200E${referredCustomerReward}`
                            })}
                        </Box>
                    )}
                </Box>

                <Col maxWidth={285} margin="auto">
                    <Row
                        margin="40px auto"
                        width="100%"
                        padding="10px 0"
                        background={themeColors[theme].referInputBgColor}
                        border={`2px dashed ${themeColors[theme].primaryBorderColor}`}
                        borderRadius={10}
                    >
                        <Box
                            textAlign="center"
                            flex="1"
                            color={themeColors[theme].primaryFontColor1}
                            fontSize={12}
                            fontWeight={500}
                            letterSpacing="1px"
                            padding="7px 0"
                            borderRight={`2px solid ${themeColors[theme].secondaryBorderColor6}`}
                        >
                            {url}
                        </Box>
                        <Box
                            padding="10px 20px 0"
                            cursor="pointer"
                            props={{ onClick: copyToClipboard }}
                        >
                            <Icon name={theme === THEME.DARK ? 'copyCodeWhite' : 'copyCode'} />
                        </Box>
                    </Row>
                    {navigator.share && (
                        <Row width="100%" marginBottom={40} justifyContent="center">
                            <Box
                                component="button"
                                backgroundColor={themeColors[theme].primaryBgColor2}
                                width={214}
                                height={48}
                                borderRadius={24}
                                color={themeColors[theme].primaryFontColor2}
                                fontSize={14}
                                fontWeight={900}
                                letterSpacing="1px"
                                border="none"
                                boxShadow={`0 10px 19px 0 ${themeColors[theme].boxShadowColor1}`}
                                props={{ onClick: handleReferralBtn }}
                            >
                                {t('referFriend.shareReferral')}
                            </Box>
                        </Row>
                    )}
                    <Row width="100%" justifyContent="space-evenly">
                        <EmailShareButton url={shareUrl} body={sharedContent}>
                            <Icon
                                name={theme === THEME.DARK ? 'mailWhite' : 'mail'}
                                cursor="pointer"
                            />
                        </EmailShareButton>
                        <FacebookShareButton url={shareUrl} quote={sharedContent}>
                            <Icon
                                name={theme === THEME.DARK ? 'facebookWhite' : 'facebook'}
                                cursor="pointer"
                            />
                        </FacebookShareButton>
                        {isMobileOnly && (
                            <Box component="a" props={{ href: smsContent }}>
                                <Icon
                                    name={theme === THEME.DARK ? 'smsWhite' : 'sms'}
                                    cursor="pointer"
                                />
                            </Box>
                        )}
                        <WhatsappShareButton url={shareUrl} title={sharedContent}>
                            <Icon
                                name={theme === THEME.DARK ? 'whatsappWhite' : 'whatsapp'}
                                cursor="pointer"
                            />
                        </WhatsappShareButton>
                        <TwitterShareButton url={shareUrl} title={sharedContent}>
                            <Icon
                                name={theme === THEME.DARK ? 'twitterWhite' : 'twitter'}
                                cursor="pointer"
                            />
                        </TwitterShareButton>
                    </Row>

                    <Box fontSize={12} marginTop={40} lineHeight="1.7" textAlign="center">
                        {t('referFriend.sendEmail')}
                    </Box>

                    <Box fontSize={12} marginTop={60} lineHeight="1.7" letterSpacing="1px">
                        <Box
                            fontSize={18}
                            lineHeight="1.11"
                            letterSpacing="1px"
                            color={
                                theme === THEME.DARK
                                    ? themeColors[theme].primaryFontColor1
                                    : themeColors[theme].tertiaryFontColor2
                            }
                        >
                            {t('referFriend.offerTitle')}
                        </Box>
                        <Box margin="15px 0 27px">
                            {t('referFriend.offerDesp')}
                            {isHebrew() && '\u202a!'}
                        </Box>
                        <Box component="ul" listStyle="disc inside">
                            {offerDetails.map((item, i) => (
                                <Box component="li" marginBottom={10} key={i}>
                                    {item}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Col>
            </Col>
        </Wrapper>
    );
}
