import React, { useEffect, useState } from 'react';
import { isAndroid, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useQueryParams } from '@patched/hookrouter';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { home } from 'api/constants';

import closeIcon from 'assets/images/black-close-icon.svg';

import Collapse from 'components/Collapse';
import SaveButton from 'components/DeliveryOptionsModal/components/SaveButton';
import FiltersCategories from 'components/FiltersCategories';
import FiltersCount from 'components/FiltersCount';
import Image from 'components/Image';
import ModalPage from 'components/ModalPage';
import { Divider } from 'components/shared';
import RadioButton from 'components/shared/RadioButton';

function FilterModal({
    showModal,
    onClose,
    filterComponent,
    setFilterComponent,
    setShowFilterModal,
    totalFiltersCount,
    setTotalFiltersCount,
    selectedSortType,
    setSelectedSortType,
    sortType,
    setSortType,
    defaultSortType,
    setSelectedCategory,
    appliedFilters
}) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();
    const [selectedFilters, setSelectedFilters] = useState([]);

    const [unsavedTotalCount, setUnsavedTotalCount] = useState(totalFiltersCount);
    const [initFilterComponent, setInitFilterComponent] = useState(
        JSON.parse(JSON.stringify(filterComponent))
    );
    // const [enableButtons, setEnableButtons] = useState();

    const [params, setParams] = useQueryParams();
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [deviceHeight, setDeviceHeight] = useState(
        isAndroid ? window.screen.height : window.innerHeight
    );

    useEffect(() => {
        let total = 0;
        let selectedFilters = [];

        const mapCount = initFilterComponent?.filterCategories
            ? initFilterComponent?.filterCategories.map(({ categories }) => {
                  const filtersCount = categories
                      ? categories.reduce((count, cat) => (cat.selected ? ++count : count), 0)
                      : 0;
                  total = total + filtersCount;
                  const selectedCategories = categories
                      .filter(cat => {
                          if (cat.selected) {
                              return [cat.category_id];
                          }
                      })
                      .map(({ category_id }) => category_id);
                  selectedFilters.push(selectedCategories);
              })
            : 0;
        setSelectedFilters(selectedFilters);
        setUnsavedTotalCount(total);
    }, [initFilterComponent, filterComponent, params?.filter]);

    useEffect(() => {
        window.addEventListener('resize', function () {
            setDeviceWidth(window.innerWidth);
            setDeviceHeight(isAndroid ? window.screen.height : window.innerHeight);
        });
    }, []);

    const modalStyle = () => {
        const isSmallScreen = isMobileOnly || deviceWidth <= 480 || deviceHeight <= 480;
        if (isSmallScreen) {
            return {
                content: {
                    position: 'absolute',
                    top: `${deviceWidth < 330 ? '10%' : deviceHeight <= 480 ? '10%' : '5%'}`,
                    left: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    right: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    bottom: '0',
                    backgroundColor: themeColors[theme].popupBgColor1,
                    border: 'none',
                    borderRadius: '20px 20px 0 0',
                    // maxHeight: '85vh',
                    overflow: 'unset',
                    color: themeColors[theme].blackFontColor
                }
            };
        } else {
            return {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    padding: '50px 60px 55px',
                    borderRadius: '20px',
                    height: '630px',
                    width: '658px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'unset',
                    color: themeColors[theme].blackFontColor
                }
            };
        }
    };

    const modalStyles = modalStyle();

    const scrollCategoryType = filterCategory => {
        const categoryType = document.querySelectorAll(`.${filterCategory} .category-name`)[0];
        categoryType &&
            categoryType.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    };

    const applyFilters = () => {
        const filtersParams = selectedFilters
            .filter(cates => cates.length > 0)
            .map(item => item.join('&'))
            .join(',');

        if (selectedSortType) {
            sessionStorage.setItem('selectedSortType', JSON.stringify(selectedSortType));
        }
        setParams({
            filters: filtersParams,
            category: params?.filters ? undefined : params?.category,
            sort: selectedSortType?.sort_type_id
        });
        params.filters && setSelectedCategory(home);
        setShowFilterModal(false);
        setTotalFiltersCount(unsavedTotalCount);
    };

    const clearInitFilters = filterComponent => {
        const unselectCategories = filterComponent.filterCategories.map(filter => {
            return {
                ...filter,
                categories: filter.categories.map(cate => {
                    return {
                        ...cate,
                        selected: false
                    };
                })
            };
        });

        const unselctSortType = filterComponent.sort_types.map(sort => {
            return {
                ...sort,
                selected: false
            };
        });

        setSelectedSortType(defaultSortType);

        setInitFilterComponent({
            ...filterComponent,
            filterCategories: unselectCategories,
            sort_types: unselctSortType
        });
    };

    const checkButtons = () => {
        const flatSelectedFilters = [].concat.apply([], selectedFilters);
        if (!appliedFilters && flatSelectedFilters.length === 0) {
            return false;
        } else {
            return flatSelectedFilters?.join('') !== appliedFilters?.join('');
        }
    };

    const enableButtons =
        checkButtons() || selectedSortType?.sort_type_id !== defaultSortType?.sort_type_id;
    const enableClearButton = unsavedTotalCount > 0;

    return (
        <Box>
            <Modal
                style={modalStyles}
                isOpen={showModal}
                onRequestClose={onClose}
                overlayClassName="buy-now-modal-overlay"
                closeTimeoutMS={500}
                preventScroll={true}
                shouldFocusAfterRender={false}
                shouldCloseOnOverlayClick={true}
            >
                <Row
                    justifyContent="flex-end"
                    boxShadow=" 0 4px 10px 4px rgb(255 255 255 / 100%)"
                    position="relative"
                    zIndex={10}
                >
                    <Image
                        cursor="pointer"
                        src={closeIcon}
                        width={30}
                        height={30}
                        padding={7}
                        onClick={onClose}
                    />
                </Row>

                <ModalPage
                    padding="10px 0 0 0"
                    buttonTitle={t('filterModal.apply')}
                    hideFooter={true}
                    fullHeight
                    filterModal
                >
                    <Col
                        marginTop={30}
                        justifyContent="flex-end"
                        background={themeColors[theme].popupBgColor1}
                    >
                        <Row fontSize={30} fontWeight={900} alignItems="center">
                            {t('filterModal.title')} <FiltersCount count={unsavedTotalCount} />
                        </Row>
                        <Col marginTop={30} justifyContent="flex-end">
                            <Collapse
                                showBorder
                                bigArrow
                                addSpace
                                title={<Title>{t('filterModal.sort')}</Title>}
                            >
                                {initFilterComponent?.sort_types?.map(item => {
                                    return (
                                        <RadioButton
                                            key={item.sort_type_id}
                                            value={item.sort_type_id}
                                            onChange={() => {
                                                const otherSortTypes =
                                                    initFilterComponent.sort_types.filter(
                                                        ({ sort_type_id }) =>
                                                            sort_type_id !== item.sort_type_id
                                                    );
                                                setSelectedSortType(item);
                                                setSortType(item.sort_type_id);
                                                item.selected = !item.selected;
                                                otherSortTypes.forEach(sortType => {
                                                    sortType.selected = false;
                                                });
                                                setFilterComponent({
                                                    ...filterComponent
                                                });
                                                if (!item.selected) {
                                                    setSelectedSortType(null);
                                                }
                                            }}
                                            checked={item.selected}
                                            cursor="pointer"
                                        >
                                            <Box>
                                                <Box
                                                    fontSize={12}
                                                    fontWeight={500}
                                                    opacity={
                                                        item.sort_type_id === sortType?.sort_type_id
                                                            ? 1
                                                            : 0.5
                                                    }
                                                >
                                                    {t(
                                                        `filterModal.${item.product_property}_${item.sort_order}`
                                                    )}
                                                </Box>
                                            </Box>
                                        </RadioButton>
                                    );
                                })}
                            </Collapse>

                            {initFilterComponent?.filterCategories?.map(
                                ({ filterName, categories, web_filter_searchable }, i) => {
                                    const filtersCount = categories
                                        ? categories.reduce(
                                              (count, cat) => (cat.selected ? ++count : count),
                                              0
                                          )
                                        : 0;

                                    return (
                                        categories.length > 0 && (
                                            <Collapse
                                                key={filterName}
                                                showBorder
                                                bigArrow
                                                addSpace
                                                noBorder={
                                                    i ===
                                                    initFilterComponent.filterCategories.length - 1
                                                }
                                                title={
                                                    <Title>
                                                        {filterName}
                                                        {filtersCount > 0 && (
                                                            <FiltersCount count={filtersCount} />
                                                        )}
                                                    </Title>
                                                }
                                                filterCategory={`filterCategory-${i}`}
                                                scrollCategoryType={scrollCategoryType}
                                            >
                                                <FiltersCategories
                                                    categories={categories}
                                                    initFilterComponent={initFilterComponent}
                                                    setInitFilterComponent={setInitFilterComponent}
                                                    searchBar={web_filter_searchable}
                                                />
                                            </Collapse>
                                        )
                                    );
                                }
                            )}
                        </Col>
                        <Col
                            position="fixed"
                            bottom={0}
                            right={0}
                            left={0}
                            margin="0 auto"
                            flex="1 1"
                            justifyContent="flex-end"
                            marginTop={isMobileOnly ? 30 : 40}
                            zIndex={10}
                            boxShadow="inset 10em 10em rgb(255 255 255 / 95%)"
                        >
                            <Divider marginBottom={20} light />
                            <Row
                                justifyContent={'space-between'}
                                alignItems="center"
                                padding="0 20px 35px 60px"
                                background={themeColors[theme].popupBgColor1}
                            >
                                <Box
                                    textDecoration="underline"
                                    fontWeight={700}
                                    fontSize={12}
                                    opacity={enableClearButton ? 1 : 0.3}
                                    cursor={enableClearButton && 'pointer'}
                                    props={
                                        enableClearButton && {
                                            onClick: () => {
                                                clearInitFilters(initFilterComponent);
                                            }
                                        }
                                    }
                                >
                                    {t('filterModal.clearALl')}
                                </Box>
                                <SaveButton
                                    width={!isMobileOnly ? 260 : 180}
                                    disabled={!enableButtons}
                                    onClick={applyFilters}
                                    title={t('filterModal.apply')}
                                    small
                                />
                            </Row>
                        </Col>
                    </Col>
                </ModalPage>
            </Modal>
        </Box>
    );
}

function Title(props) {
    const { children, ...rest } = props;
    return (
        <Row color="#000" fontSize={18} fontWeight={500} {...rest} alignItems="center">
            {children}
        </Row>
    );
}

export default FilterModal;
