import React from 'react';
import { Box } from 'jsxstyle';

export default function FlexImage({ src, alt, imageContainerFit = 'cover', ...rest }) {
    return (
        <Box
            component="img"
            maxWidth="100%"
            objectFit={imageContainerFit}
            objectPosition="center"
            height="auto"
            props={{ src, alt }}
            {...rest}
        />
    );
}
