import React from 'react';
import { Box } from 'jsxstyle';

export default function ImageInput({ onChange }) {
    return (
        <Box
            component="input"
            width={0.1}
            height={0.1}
            opacity={0}
            overflow="hidden"
            position="absolute"
            zIndex={-1}
            props={{
                type: 'file',
                name: 'file',
                id: 'file',
                accept: 'image/*',
                onChange
            }}
        />
    );
}
