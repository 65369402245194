// <!-- Google Tag Manager -->
// <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','GTM-XXXXXX');</script>
// <!-- End Google Tag Manager -->

function addGtmContainerCode(GTMContainerId) {
    // Create comment nodes for the start and end of GTM Container Code
    const startComment = document.createComment(' GTM Container Code ');
    const endComment = document.createComment(' End GTM Container Code ');

    // Create script element
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];
w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTMContainerId}');`;

    // Append start comment, script, and end comment to head
    document.head.appendChild(startComment);
    document.head.appendChild(scriptElement);
    document.head.appendChild(endComment);
}

// <!-- Google Tag Manager (noscript) -->
// <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M4GZFVS"
// height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
// <!-- End Google Tag Manager (noscript) -->

function addGoogleTagManagerNoscript(GTMContainerId) {
    // Create comment nodes for the start and end of Google Tag Manager (noscript) code
    const startComment = document.createComment(' Google Tag Manager (noscript) ');
    const endComment = document.createComment(' End Google Tag Manager (noscript) ');

    // Create noscript element
    const noscriptElement = document.createElement('noscript');

    // Create iframe element
    const iframeElement = document.createElement('iframe');
    iframeElement.src = `https://www.googletagmanager.com/ns.html?id=${GTMContainerId}`;
    iframeElement.height = '0';
    iframeElement.width = '0';
    iframeElement.style.display = 'none';
    iframeElement.style.visibility = 'hidden';

    // Append start comment, iframe, and end comment to noscript
    noscriptElement.appendChild(startComment);
    noscriptElement.appendChild(iframeElement);
    noscriptElement.appendChild(endComment);

    // Append noscript to body
    document.body.appendChild(noscriptElement);
}

/**
 *
 * @param {*} GTMContainerId string
 * @returns null
 */
export const installGTM = GTMContainerId => {
    if (!GTMContainerId) return;

    addGtmContainerCode(GTMContainerId);
    addGoogleTagManagerNoscript(GTMContainerId);
};

//Before sending any events to data layer, clear the the object
const clearDataLayer = () => {
    window.dataLayer.push({ ecommerce: null });
};

export const pushToDataLayer = data => {
    if (window.dataLayer) {
        clearDataLayer();
        window.dataLayer.push(data);
    }
};

export const initializeDataLayer = () => {
    window.dataLayer.push({ event: 'dataLayerInitialized' });
};
