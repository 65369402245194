import useSWR from 'swr';

import useCustomerData from 'hooks/useCustomerData';

import { isScheduledOrder } from 'api/utils';

import api from '../api';
import useStoreSettings from './useStoreSettings';

export default function useProduct(id, deliveryModel, zone_id) {
    const { data: mappedData } = useCustomerData();
    const { session, thread, zone } = mappedData || {};

    const { data: storeSettings } = useStoreSettings();
    const supportsDelivery =
        storeSettings?.supports_scheduled_menu || storeSettings?.supports_ondemand_menu;
    const supportsPickup = storeSettings?.supports_pickup;
    const pickupOnly = !supportsDelivery && supportsPickup;

    const kit_id = thread?.kit_id || sessionStorage.getItem('menu_kit') || null;

    const isPickup = Boolean(thread?.pickup_destination) || pickupOnly;
    const zoneId = isPickup ? null : zone_id || thread?.zoneId;

    const shouldUseFrontStock = Boolean(
        (deliveryModel && isScheduledOrder(deliveryModel)) || isPickup
    );

    const queryOptions = shouldUseFrontStock ? `${deliveryModel}` : `${kit_id}`;

    const key = () => [
        `product`,
        thread?.id,
        storeSettings?.isOpen,
        queryOptions,
        id,
        zoneId,
        thread?.pickup_destination?.destination_id
    ];
    const { organization_id, isOpen } = storeSettings || {};

    const fetch = () => {
        return api.getProduct({
            organization_id,
            product_id: id,
            isOpen,
            zone_id: zoneId,
            redirected_from_zone: isPickup ? null : zone.redirected_from_zone,
            menu_kit_id: kit_id,
            useHubStock: shouldUseFrontStock,
            thread_id: session.authenticated ? thread.id : null,
            associated_hub: thread?.associated_hub
        });
    };

    const options = {
        shouldRetryOnError: true,
        errorRetryInterval: 2000,
        dedupingInterval: 60000
    };
    return useSWR(key, fetch, options);
}
