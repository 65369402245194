import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';
import { useLottie } from 'lottie-react';

import useTheme from 'hooks/useTheme';

import { THREAD_STATUS, WindowSubType } from 'api/constants';
import {
    formatDeliveryWindowText,
    formatFutureDate,
    getOperationHourText,
    isHebrew,
    shortDateFormat
} from 'api/utils';

import background from '../assets/images/background-status.jpg';
import cancel from '../assets/images/delete-button.svg';
import dashed from './../assets/images/dashed-line.svg';
import problem from './../assets/images/problem.svg';
import blueCircle from './../assets/images/ripples_blue.json';
import greenCircle from './../assets/images/ripples_green.json';
import redCircle from './../assets/images/ripples_red.json';
import selected from './../assets/images/selected.svg';
import success from './../assets/images/success.svg';
import van from './../assets/images/van-icon.svg';
import Hint from './Hint';
import Paragraph from './Paragraph';

export default function StatusMap(props) {
    const {
        status,
        closedTime,
        etaTime,
        delayTime,
        deliverBy,
        deliverFrom,
        delayedDeliverBy,
        placedTime,
        visibleFrom,
        deliveryWindow,
        pickupLocation,
        timeZone,
        readyForPickup,
        isDeliveryOrder,
        thread,
        storeClosed
    } = props;
    const { theme, themeColors } = useTheme();

    const { t } = useTranslation();
    const delayed = deliverBy && delayedDeliverBy && !etaTime;

    const scheduledTimestamp = deliverBy;

    const scheduledTime = scheduledTimestamp ? formatFutureDate(scheduledTimestamp, t) : null;
    const deliveryDate = scheduledTimestamp ? shortDateFormat(scheduledTimestamp, t) : null;

    const shiftHours = deliveryWindow
        ? formatDeliveryWindowText(deliveryWindow, storeClosed, t)
        : '';

    const isOrderScheduledForLater = deliverFrom && thread.visible_from === deliverFrom - 10 * 60;
    const isPostponed = visibleFrom && !isOrderScheduledForLater;

    function renderView() {
        switch (status) {
            case THREAD_STATUS.LOCKED:
                return (isDeliveryOrder && (!etaTime || visibleFrom)) ||
                    (pickupLocation && !readyForPickup) ? (
                    <Processing
                        shiftHours={shiftHours}
                        scheduledTime={scheduledTime}
                        delayed={delayed}
                        deliveryDate={deliveryDate}
                        t={t}
                        visibleFrom={isPostponed ? visibleFrom : null}
                        placedTime={placedTime}
                        pickupLocation={pickupLocation}
                        timeZone={timeZone}
                        theme={theme}
                        themeColors={themeColors}
                        storeClosed={storeClosed}
                        window={deliveryWindow}
                    />
                ) : (
                    <InTransit
                        scheduledTime={scheduledTime}
                        time={etaTime}
                        delayTime={delayTime}
                        pickupLocation={pickupLocation}
                        readyForPickup={readyForPickup}
                        t={t}
                        isHebrew={isHebrew}
                        theme={theme}
                        themeColors={themeColors}
                        queuePosition={thread.queuePosition}
                    />
                );
            case THREAD_STATUS.OPEN:
                return (
                    <Processing
                        shiftHours={shiftHours}
                        scheduledTime={scheduledTime}
                        delayed={delayed}
                        deliveryDate={deliveryDate}
                        t={t}
                        placedTime={placedTime}
                        pickupLocation={pickupLocation}
                        timeZone={timeZone}
                        theme={theme}
                        themeColors={themeColors}
                        storeClosed={storeClosed}
                        window={deliveryWindow}
                    />
                );
            case THREAD_STATUS.CLOSED:
                return (
                    <Delivered
                        time={closedTime}
                        delayTime={delayTime}
                        t={t}
                        theme={theme}
                        themeColors={themeColors}
                    />
                );
            case THREAD_STATUS.WITHDRAWN:
                return <Problem t={t} theme={theme} themeColors={themeColors} />;
            case THREAD_STATUS.CANCELED:
                return (
                    <Cancelled theme={theme} themeColors={themeColors} time={closedTime} t={t} />
                );
            default:
                navigate('/', false);
        }
    }
    return <StatusBox>{status != null ? renderView() : null}</StatusBox>;
}

const Problem = (t, theme, themeColors) => (
    <Box>
        <Line
            theme={theme}
            themeColors={themeColors}
            black
            height={45}
            top={0}
            left="calc(50% - 2px)"
        />
        <Line theme={theme} themeColors={themeColors} height={47} top={90} left="calc(50% - 2px)" />
        <Center marginTop={70}>
            <StatusIcon img={problem} />
            <Paragraph>{t('orderStatus.problem')}</Paragraph>
        </Center>
        <Van animation={redCircle} top={40} left={166} />
    </Box>
);

const Cancelled = ({ time, t, theme, themeColors }) => (
    <Box>
        <Box
            position="absolute"
            left={60}
            top={-5}
            width={130}
            height={65}
            borderWidth={4}
            borderRadius="0 0 10px"
            borderStyle="solid"
            borderColor="transparent black black transparent"
        />
        <Line
            theme={theme}
            themeColors={themeColors}
            height={35}
            transform="rotate(90deg)"
            top={40}
            left={20}
        />
        <Center marginTop={50}>
            <StatusIcon img={cancel} />
            <Paragraph bold> {t('orderStatus.orderCancelled')}</Paragraph>
            {time && (
                <Paragraph>
                    {t('orderStatus.at')} {time}
                </Paragraph>
            )}
        </Center>
        <Van top={19} left={40} rotate />
    </Box>
);

const Delivered = ({ delayTime, t, theme, themeColors }) => (
    <Box>
        <Line
            theme={theme}
            themeColors={themeColors}
            black
            top={0}
            left="calc(50% - 2px)"
            height={67}
        />
        <Center marginTop={55}>
            <StatusIcon img={success} width={90} />
            <Paragraph bold>{t('orderStatus.arrived')}</Paragraph>
            <Paragraph>
                {delayTime ? `${t('orderStatus.delayed')}` : `${t('orderStatus.onTime')}`}
            </Paragraph>
        </Center>
        <Van top={63} left="calc(50% - 22px)" animation={greenCircle} />
    </Box>
);

const Processing = ({
    scheduledTime,
    storeClosed,
    delayedShiftHours,
    delayed,
    shiftHours,
    visibleFrom,
    placedTime,
    pickupLocation,
    timeZone,
    t,
    theme,
    themeColors,
    window
}) => (
    <Box position="relative">
        <Line theme={theme} themeColors={themeColors} top={0} left={8} height="90%" />
        <Row marginBottom={40}>
            {scheduledTime || visibleFrom ? (
                <Circle theme={theme} themeColors={themeColors} black />
            ) : (
                <Circle theme={theme} themeColors={themeColors} />
            )}
            <Paragraph bold>
                {scheduledTime ? (
                    <Box>
                        {t('orderStatus.orderPlaced')}
                        <br />
                        <Box fontSize={12} fontWeight={500}>
                            {shortDateFormat(placedTime, t)}
                        </Box>
                    </Box>
                ) : visibleFrom ? (
                    t('orderStatus.orderProcessed')
                ) : (
                    t('orderStatus.processingOrder')
                )}
            </Paragraph>
        </Row>
        <Row marginBottom={90}>
            <Circle theme={theme} themeColors={themeColors} />
            <Paragraph bold opacity={0.3}>
                {pickupLocation ? t('orderStatus.readyPickup') : t('orderStatus.inTransit')}
            </Paragraph>
        </Row>
        <Row alignItems="center">
            <Circle theme={theme} themeColors={themeColors} />
            {visibleFrom ? (
                <Box>
                    {' '}
                    <Paragraph bold opacity={0.3}>
                        {t('orderStatus.orderPostponed')}
                    </Paragraph>
                    <Paragraph fontSize={12} opacity={0.5} fontWeight={600}>
                        {t('orderStatus.etaWillUpdate')}
                    </Paragraph>
                </Box>
            ) : (
                <Box>
                    <Paragraph fontSize={12} opacity={0.5} fontWeight={600}>
                        {scheduledTime ? t('orderStatus.scheduledFor') : ''}
                    </Paragraph>
                    <Paragraph bold opacity={0.3}>
                        {pickupLocation
                            ? t('orderStatus.pickup')
                            : delayedShiftHours && scheduledTime
                              ? delayedShiftHours
                              : scheduledTime
                                ? shiftHours
                                : t('orderStatus.arrival')}
                    </Paragraph>
                    <Paragraph fontSize={12} opacity={0.5} fontWeight={600}>
                        {delayed ? `${t('orderStatus.delayed')}` : ''}
                        {pickupLocation &&
                            getOperationHourText(pickupLocation.availability, timeZone, t)}
                    </Paragraph>
                </Box>
            )}
            {((scheduledTime && !visibleFrom) || pickupLocation) && (
                <Box position="relative" top="3px">
                    <Hint noWhite>
                        {pickupLocation ? (
                            isHebrew() ? (
                                <Box>
                                    {t('orderStatus.pickupTip')}
                                    <Box>{pickupLocation?.default_pickup_estimate}</Box>
                                </Box>
                            ) : (
                                t('orderStatus.pickupTip', {
                                    time: pickupLocation?.default_pickup_estimate
                                })
                            )
                        ) : storeClosed && window?.window_subtype === WindowSubType.asap ? (
                            t('orderStatus.asapWindowStoreClosed')
                        ) : isHebrew() ? (
                            <Box>
                                {t('orderStatus.deliveredBetweenDesp')}
                                <br />
                                {delayedShiftHours || shiftHours}
                            </Box>
                        ) : (
                            t('orderStatus.deliveredBetweenDesp', {
                                shift_hours: delayedShiftHours || shiftHours
                            })
                        )}
                    </Hint>
                </Box>
            )}
        </Row>
    </Box>
);

const InTransit = ({
    scheduledTime,
    time,
    delayTime,
    pickupLocation,
    t,
    isHebrew,
    readyForPickup,
    theme,
    themeColors,
    queuePosition
}) => {
    return (
        <Box>
            <Line
                theme={theme}
                themeColors={themeColors}
                top={readyForPickup ? 140 : 205}
                height={readyForPickup ? 120 : 50}
                left={27}
            />
            <Row marginBottom={40}>
                <Circle black theme={theme} themeColors={themeColors} />
                <Paragraph bold>
                    {scheduledTime ? t('orderStatus.orderPlaced') : t('orderStatus.orderProcessed')}
                </Paragraph>
            </Row>
            <Row marginBottom={95}>
                <Circle black theme={theme} themeColors={themeColors} />
                <Paragraph bold>
                    {pickupLocation ? t('orderStatus.readyPickup') : t('orderStatus.inTransit')}
                </Paragraph>
            </Row>

            <Row>
                <Circle theme={theme} themeColors={themeColors} />
                {pickupLocation ? (
                    <Box maxWidth={240}>
                        <Paragraph bold opacity={0.3}>
                            {t('orderStatus.pickup')}
                        </Paragraph>
                    </Box>
                ) : (
                    <Box maxWidth={240}>
                        <Paragraph bold opacity={0.3}>
                            {isHebrew() ? (
                                <Box>
                                    {t('orderStatus.estimatedArrival')}
                                    <br />
                                    {time}
                                </Box>
                            ) : (
                                `${t('orderStatus.estimatedArrival')} ${time}`
                            )}
                        </Paragraph>
                        <Paragraph opacity="0.3">
                            {delayTime
                                ? `${t('orderStatus.delayed')}`
                                : `${t('orderStatus.onTime')}`}
                            {queuePosition && ` (${queuePosition.message})`}
                        </Paragraph>
                    </Box>
                )}
                {!pickupLocation && (
                    <Box position="relative" top="-5px" textAlign="center">
                        <Hint>
                            <Box>{t('orderStatus.estimatedArrivalUpdate')}</Box>
                            {queuePosition && (
                                <Box
                                    fontWeight={400}
                                    fontSize={12}
                                    fontStyle="italic"
                                    marginTop={15}
                                >
                                    {t('orderStatus.numberOfCustomersBeforeMe')}
                                </Box>
                            )}
                        </Hint>
                    </Box>
                )}
            </Row>
            <Line
                theme={theme}
                themeColors={themeColors}
                black
                top={75}
                left={27}
                height={readyForPickup ? 60 : 120}
            />
            {!pickupLocation && <Van top={167} left={14} animation={blueCircle} small />}
        </Box>
    );
};

const Van = ({ animation, height, width, small, top, left, rotate }) => {
    const animationOptions = {
        animationData: animation,
        loop: true,
        autoplay: true
    };

    const { View } = useLottie(animationOptions);

    return (
        <Box position="absolute" top={top} left={left}>
            <Box
                component="img"
                width={small ? '25px' : 'auto'}
                height={small ? 'auto' : '75px'}
                position="absolute"
                zIndex={10}
                top={small ? '6px' : 0}
                left={small ? '3px' : 0}
                transform={rotate ? 'rotate(90deg)' : 'none'}
                props={{ src: van, alt: '' }}
            />
            {animation && (
                <Box position="absolute" top={small ? '-7px' : '0'} left={small ? '-15px' : '-9px'}>
                    <Box height={height || 62} width={width || 62}>
                        {View}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const Circle = ({ black, theme, themeColors, ...rest }) => (
    <Box
        width={20}
        height={20}
        background={
            black ? `url(${selected}) -13px -13px no-repeat` : themeColors[theme].secondaryBgColor14
        }
        borderRadius="50%"
        marginRight={20}
        display="block"
        position="relative"
        zIndex={3}
        {...rest}
    />
);

const StatusBox = ({ children }) => {
    return (
        <Box
            mediaQueries={{
                md: 'screen and (min-width: 550px)'
            }}
            position="relative"
            background={`url(${background}) center no-repeat`}
            backgroundSize="cover"
            padding="60px 20px"
            mdBorderRadius={10}
            mdBoxShadow="0 5px 15px 0 rgba(0, 0, 0, 0.2)"
        >
            {children}
        </Box>
    );
};

const StatusIcon = ({ img, ...rest }) => (
    <Box
        component="img"
        width={50}
        position="relative"
        zIndex={5}
        props={{
            src: img,
            alt: ''
        }}
        {...rest}
    />
);

const Line = ({ black, theme, themeColors, ...rest }) => (
    <Box
        width={4}
        background={black ? themeColors[theme].primaryBgColor2 : `url(${dashed}) repeat-y`}
        position="absolute"
        zIndex={dashed ? '1' : '0'}
        {...rest}
    />
);

const Center = ({ children, ...rest }) => (
    <Box textAlign="center" maxWidth={300} margin="0 auto" {...rest}>
        {children}
    </Box>
);
