import React, { useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Icon from 'components/Icon';

import { THEME } from '../api/constants';

export default function CookiesAlert({ showCookiesAlert, handleAcceptCookies, addBottomSpace }) {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();

    return (
        showCookiesAlert && (
            <Box
                zIndex={10000}
                padding={isMobileOnly ? '20px 18px' : '28px 18px'}
                borderRadius={10}
                position="fixed"
                bottom={addBottomSpace ? 100 : 20}
                right={0}
                left={0}
                maxWidth={1024}
                backgroundColor={themeColors[theme].primaryBgColor3}
                color={themeColors[theme].primaryFontColor2}
                margin={isMobileOnly ? '0 10px' : '0 auto'}
            >
                <Row justifyContent="space-between" alignItems="center" margin="0 auto">
                    <Icon
                        width={64}
                        height={55}
                        objectFit="contain"
                        name="cookies"
                        marginRight={15}
                    />

                    <Box
                        lineHeight="normal"
                        letterSpacing={1}
                        fontWeight="bold"
                        fontSize={12}
                        maxWidth={636}
                    >
                        {t('catalogPage.cookie')}
                        {isMobileOnly && (
                            <Box marginTop={10}>
                                <OkButton
                                    t={t}
                                    handleAcceptCookies={handleAcceptCookies}
                                    theme={theme}
                                    themeColors={themeColors}
                                />
                            </Box>
                        )}
                    </Box>
                    {!isMobileOnly && (
                        <OkButton
                            t={t}
                            handleAcceptCookies={handleAcceptCookies}
                            theme={theme}
                            themeColors={themeColors}
                        />
                    )}
                </Row>
            </Box>
        )
    );
}

const OkButton = ({ t, handleAcceptCookies, theme, themeColors }) => (
    <Box
        width={95}
        borderRadius={4}
        boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.1)"
        border={`solid 1px ${themeColors[theme].secondaryBorderColor1}`}
        backgroundColor={themeColors[theme].primaryBgColor1}
        marginLeft={!isMobile && 180}
        cursor="pointer"
        props={{ onClick: handleAcceptCookies }}
    >
        <Box
            textAlign="center"
            margin="4px 0 4px 1px"
            letterSpacing={1}
            fontSize={12}
            color={
                theme === THEME.DARK
                    ? themeColors[theme].whitefontColor
                    : themeColors[theme].tertiaryFontColor1
            }
            fontWeight="normal"
        >
            {t('catalogPage.ok')}
        </Box>
    </Box>
);
