import DocumentProviderType from './documentProviderType';
import AWSDocumentProvider from './providers/awsDocumentProvider';
import LazzyDocumentProvider from './providers/lazzyDocumentProvider';

export default class DocumentProviderFactory {
    static create(type, options) {
        switch (type) {
            case DocumentProviderType.LAZZY:
                return new LazzyDocumentProvider(options);
            case DocumentProviderType.AWS:
                return new AWSDocumentProvider(options);
            default:
                return null;
        }
    }
}
