import React from 'react';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

export default function Heading({ noMarginBottom, children, ...rest }) {
    const { theme, themeColors } = useTheme();
    return (
        <Box
            fontSize={30}
            fontWeight={900}
            letterSpacing={1}
            marginBottom={!noMarginBottom && 20}
            color={themeColors[theme].primaryFontColor1}
            {...rest}
        >
            {children}
        </Box>
    );
}
