import React from 'react';
import { Box, Col } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Footer from './Footer';

export default function Wrapper(props) {
    const { narrow, children, noPadding, white, hideFooter, ...rest } = props;
    const { theme, themeColors } = useTheme();

    return (
        <Col minHeight="100vh" {...rest}>
            <Box
                flex="1 0 auto"
                background={
                    white
                        ? themeColors[theme].primaryBgColor1
                        : themeColors[theme].secondaryBgColor1
                }
                minWidth={320}
            >
                <Box
                    maxWidth={narrow ? 800 : 1000}
                    minHeight={600}
                    margin="0 auto"
                    position="relative"
                    padding={noPadding ? '15px 0 75px ' : '15px 15px 75px'}
                >
                    {children}
                </Box>
            </Box>
            {!hideFooter && <Footer flex="0 0 auto" narrow={narrow} />}
        </Col>
    );
}
