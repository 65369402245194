import { updateAddress } from '../utils/cacheDataManager';
import { CHANNEL_TYPE, Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function UpdateAddress(address, userId, organizationId, isAuth) {
    sessionStorage.removeItem('menu_kit');
    if (isAuth) {
        const payload = {
            user_id: userId,
            organization_id: organizationId,
            input: address,
            channelType: CHANNEL_TYPE.WEB
        };
        return invokeLambda(Lambda.UpdateAddress, payload);
    } else {
        const updateAddressPayload = {
            organization_id: organizationId,
            input: address
        };
        const { location, error, errorCode } = await invokeLambda(
            Lambda.UpdateAddress,
            updateAddressPayload
        );

        const payload = {
            organization_id: organizationId,
            geoData: location
        };
        const response = await invokeLambda(Lambda.GetZoneForLocation, payload);
        if (response.zone) {
            updateAddress(location, response.zone, organizationId);
        }
        return { location, error, errorCode, ...response };
    }
}
