import { useEffect, useState } from 'react';

const useInfiniteScroll = (callback, fetchMore) => {
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const FOOTER_HEIGHT = 900;
        function handleScroll() {
            const eligibleGet =
                document.getElementsByClassName('productGrid')[1]?.offsetHeight || 0;

            if (
                window.innerHeight + document.documentElement.scrollTop >
                    document.documentElement.offsetHeight - FOOTER_HEIGHT - eligibleGet &&
                !isFetching
            ) {
                setIsFetching(true);
            }
        }
        if (fetchMore) {
            window.addEventListener('scroll', handleScroll);
        }

        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFetching, fetchMore]);

    useEffect(() => {
        if (isFetching) callback();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);

    return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
