import useSWR from 'swr';

import api from '../api';
import useStoreSettings from './useStoreSettings';

export default function useFilterComponent() {
    const { data: storeSettings } = useStoreSettings();
    const key = () => [`filterComponent`, storeSettings?.organization_id];

    const options = {
        shouldRetryOnError: true,
        errorRetryInterval: 30000,
        dedupingInterval: 180000,
        focusThrottleInterval: 180000,
        revalidateOnFocus: true
    };
    const fetch = async () => {
        if (!storeSettings?.organization_id) return null;
        const data = await api.getFilterComponents(storeSettings?.organization_id);
        return data;
    };

    const { data, error, mutate, isValidating } = useSWR(key, fetch, options);

    return {
        data,
        error,
        mutate,
        isValidating
    };
}
