/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { isAndroid, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentProviderType from 'constants/PaymentProvider';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { SUPPORTED_TRANSACTION_TYPE, TransactionsTypes } from 'api/constants';
import getPaymentMethods from 'api/getPaymentMethods';
import { isStoreOpen, priceToString } from 'api/utils';

import { useAppContext } from 'store';

import arrowLeft from 'assets/images/arrow-left.svg';
import closeIcon from 'assets/images/black-close-icon.svg';

import CreditCardBody from 'components/CreditCardBody';
import CashAndCreditPayment from 'components/DeliveryOptionsModal/components/CashAndCreditPayment';
import Delivery from 'components/DeliveryOptionsModal/components/Delivery';
import Main from 'components/DeliveryOptionsModal/components/Main';
import Notes from 'components/DeliveryOptionsModal/components/Notes';
import Page from 'components/DeliveryOptionsModal/components/Page';
import Payment from 'components/DeliveryOptionsModal/components/Payment';
import StripePaymentCredit from 'components/DeliveryOptionsModal/components/StripePaymentCredit';
import TranzilaPaymentCredit from 'components/DeliveryOptionsModal/components/TranzilaPaymentCredit';
import Image from 'components/Image';

Modal.setAppElement('#root');

export default function BuyNowModal({
    showModal,
    onRequestClose,
    thread,
    charges,
    paymentProviderSupplier,
    supportedTransactionTypes,
    supportedDeliveryOptions,
    organizationId,
    storeSettings,
    onComplete,
    submitting,
    etaEstimation,
    deliveryDefaults,
    currentDeliveryModel,
    store,
    availableDeliveryTimes,
    requireExactChange,
    supportDebitAndCash,
    pinTerminalFee,
    pickupLocation,
    revalidateAvailability
}) {
    let stripePromise = null;

    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const { state } = useAppContext();
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
    const [savePaymentMethodDisabled, setSavePaymentMethodDisabled] = useState(true);

    const [placeOrderEnabled, setPlaceOrderEnabled] = useState(false);
    const [activePayment, setActivePayment] = useState(
        sessionStorage.getItem('activePayment') ||
            (storeSettings?.supported_transaction_types[0] === SUPPORTED_TRANSACTION_TYPE.CASH
                ? TransactionsTypes.Cash
                : TransactionsTypes.Credit)
    );
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(activePayment);

    const onPaymentMethodUpdate = () => {
        sessionStorage.setItem('activePayment', selectedPaymentMethod);
        setActivePayment(selectedPaymentMethod);
        setCurrentPage(MODAL_PAGES.main);
    };

    useEffect(() => {
        // update savePaymentMethodDisabled if paymentMethod changed
        setSavePaymentMethodDisabled(selectedPaymentMethod === activePayment);
    }, [selectedPaymentMethod, activePayment]);

    const MODAL_PAGES = {
        main: 'main',
        delivery: 'delivery',
        notes: 'notes',
        payment: 'payment',
        paymentCredit: 'paymentCredit',
        paymentCashAndCredit: 'paymentCashAndCredit'
    };

    const supportedPaymentMethods =
        supportedTransactionTypes.length === 1
            ? supportedTransactionTypes[0] === SUPPORTED_TRANSACTION_TYPE.CREDIT
                ? TransactionsTypes.Credit
                : TransactionsTypes.Cash
            : TransactionsTypes.CreditAndCash;

    const getThreadNote = () => {
        if (thread && thread.note) {
            return thread.note.toLowerCase() === deliveryDefaults.note.toLowerCase()
                ? ''
                : thread.note;
        } else {
            return '';
        }
    };

    const getPaymentChange = (convert = false) => {
        if (thread.change > 0 && convert) {
            return thread.change / 100;
        } else {
            return thread.change > 0 ? thread.change : '';
        }
    };

    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const [currentPage, setCurrentPage] = useState(MODAL_PAGES.main);
    const [savingNotes, setSavingNotes] = useState(false);
    const [savingDeliveryType, setSavingDeliveryType] = useState(false);
    const [savingPayment, setSavingPayment] = useState(false);
    const [savingPaymentMethod, setSavingPaymentMethod] = useState(false);
    const [paymentOnFocus, setPaymentOnFocus] = useState(false);

    const [canAddPaymentMethod, setCanAddPaymentMethod] = useState(false);

    const [currentNote, setCurrentNote] = useState(getThreadNote());
    const [currentPaymentChange, setCurrentPaymentChange] = useState(getPaymentChange(true));
    const [currentDeliveryType, setCurrentDeliveryType] = useState(thread.deliveryType);

    const [saveNotesEnabled, setSaveNotesEnabled] = useState(false);
    const [savePaymentEnabled, setSavePaymentEnabled] = useState(false);
    const [saveDeliveryEnabled, setSaveDeliveryEnabled] = useState(false);

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [deviceHeight, setDeviceHeight] = useState(
        isAndroid ? window.screen.height : window.innerHeight
    );

    const isPaymentProviderEnabled = paymentProviderId => {
        return (
            supportedPaymentMethods !== TransactionsTypes.Cash &&
            storeSettings?.payment_provider === paymentProviderId
        );
    };

    // lazy load stripe on demand
    if (isPaymentProviderEnabled(PaymentProviderType.Stripe) && !stripePromise) {
        stripePromise = loadStripe(sessionStorage.getItem('payment_provider_publishable_key'));
    }

    const resetFields = () => {
        setCurrentNote(getThreadNote());
        setCurrentDeliveryType(thread.deliveryType);
        setCurrentPaymentChange(getPaymentChange(true));
    };

    const saveNote = async () => {
        const deliveryInstructions = {
            delivery_type: thread.deliveryType,
            note: currentNote || deliveryDefaults.note,
            change: thread.change
        };

        try {
            setSavingNotes(true);
            await store.updateDeliveryInstructions(deliveryInstructions);
            setCurrentPage(MODAL_PAGES.main);
            setSaveNotesEnabled(false);
        } finally {
            setSavingNotes(false);
        }
    };

    const saveDeliveryType = async () => {
        const deliveryInstructions = {
            delivery_type: currentDeliveryType,
            note: thread.note,
            change: thread.change
        };

        try {
            setSavingDeliveryType(true);
            await store.updateDeliveryInstructions(deliveryInstructions);
            setCurrentPage(MODAL_PAGES.main);
            setSaveDeliveryEnabled(false);
        } finally {
            setSavingDeliveryType(false);
        }
    };

    const saveCreditPayment = async event => {
        setSavingPaymentMethod(true);
        try {
            const paymentMethod = await paymentCreditRef.current.savePaymentMethod();
            setPaymentMethod(paymentMethod);
            setSavingPaymentMethod(false);
            setCurrentPage(MODAL_PAGES.main);
        } finally {
            setSavingPaymentMethod(false);
        }
    };

    const savePayment = async e => {
        const changeInCents = currentPaymentChange
            ? Math.round(parseFloat(currentPaymentChange) * 100)
            : 0;
        const deliveryInstructions = {
            delivery_type: thread.deliveryType,
            note: thread.note,
            change: changeInCents
        };
        try {
            setSavingPayment(true);
            await store.updateDeliveryInstructions(deliveryInstructions);
            setCurrentPage(MODAL_PAGES.main);
            setSavePaymentEnabled(false);
        } finally {
            setSavingPayment(false);
        }
    };

    useEffect(() => {
        const revalidateTotal = async () => {
            await revalidateAvailability();
        };
        revalidateTotal();
    }, [activePayment]);

    useEffect(() => {
        // if the supported transaction type is credit card
        // then we need to load all payment method of the current customer
        if (supportedPaymentMethods === TransactionsTypes.Cash) {
            return;
        }

        async function _getPaymentMethods() {
            setLoadingPaymentMethod(true);
            const { cards } = await getPaymentMethods(organizationId);
            setLoadingPaymentMethod(false);
            if (cards && cards.length) {
                setPaymentMethod(cards[0]);
            }
        }

        _getPaymentMethods();
    }, [organizationId, supportedPaymentMethods]);

    useEffect(() => {
        setSaveNotesEnabled(currentNote !== getThreadNote());
    }, [currentNote, getThreadNote]);

    useEffect(() => {
        setSavePaymentEnabled(currentPaymentChange !== getPaymentChange(true));
    }, [currentPaymentChange, getPaymentChange, savePaymentEnabled]);
    useEffect(() => {
        setSaveDeliveryEnabled(currentDeliveryType !== thread.deliveryType);
    }, [currentDeliveryType, thread.deliveryType]);

    useEffect(() => {
        function _getValue() {
            if (!loadingPaymentMethod && paymentMethod) {
                return <CreditCardBody brand={paymentMethod.brand} last4={paymentMethod.last4} />;
            } else if (loadingPaymentMethod) {
                return t('buyNowModal.loading');
            } else {
                return (
                    <Box color={themeColors[theme].quaternaryFontColor1}>
                        {supportedPaymentMethods === TransactionsTypes.CreditAndCash
                            ? t('buyNowModal.choosePaymentMethod')
                            : t('buyNowModal.addPaymentMethod')}
                    </Box>
                );
            }
        }

        if (organizationId && thread) {
            const deliveryOptions = [
                {
                    title: t('buyNowModal.main.deliveryType'),
                    value: thread?.deliveryType
                        ? t(`deliveryType.${thread?.deliveryType}`)?.replace(' Delivery', '')
                        : '',
                    page: MODAL_PAGES.delivery
                },
                {
                    title: t('buyNowModal.main.notes'),
                    value:
                        thread.note === deliveryDefaults.note
                            ? pickupLocation
                                ? t('buyNowModal.notes.pickupPlaceholder')
                                : t('buyNowModal.notes.placeholder')
                            : thread.note,
                    page: MODAL_PAGES.notes
                }
            ];

            if (pickupLocation) {
                deliveryOptions.shift();
            }

            if (supportedTransactionTypes && supportedTransactionTypes.length) {
                const cashCaption =
                    currentPaymentChange > 0
                        ? `${priceToString(currentPaymentChange * 100, state.currency)}`
                        : supportDebitAndCash
                          ? ''
                          : t('buyNowModal.main.addChangeCaption');

                switch (supportedPaymentMethods) {
                    case TransactionsTypes.Credit:
                        deliveryOptions.push({
                            title: t('buyNowModal.main.paymentType'),
                            value: _getValue(supportedPaymentMethods),
                            caption: '',
                            opacity: 1,
                            page: MODAL_PAGES.paymentCredit
                        });

                        // when payment method is credit card
                        // the place order will be enabled only when we're not loading it
                        // and the payment method exsist
                        setPlaceOrderEnabled(!loadingPaymentMethod && paymentMethod);
                        break;
                    case TransactionsTypes.Cash:
                        deliveryOptions.push({
                            title: t('buyNowModal.main.paymentType'),
                            value: supportDebitAndCash
                                ? t('buyNowModal.payment.cashOrDebit')
                                : t('buyNowModal.payment.cash'),
                            caption: requireExactChange && cashCaption,
                            page: MODAL_PAGES.payment
                        });

                        // when payment method is cash only
                        // the place order button is always enabled
                        setPlaceOrderEnabled(true);

                        break;

                    case TransactionsTypes.CreditAndCash:
                        deliveryOptions.push({
                            title: t('buyNowModal.main.paymentType'),
                            value:
                                activePayment === TransactionsTypes.Credit
                                    ? _getValue(supportedPaymentMethods)
                                    : t('buyNowModal.payment.cash'),
                            caption:
                                activePayment === TransactionsTypes.Cash &&
                                requireExactChange &&
                                cashCaption,
                            opacity: 1,
                            page: MODAL_PAGES.paymentCashAndCredit
                        });
                        if (activePayment === TransactionsTypes.Credit) {
                            setPlaceOrderEnabled(!loadingPaymentMethod && paymentMethod);
                        } else {
                            setPlaceOrderEnabled(true);
                        }

                        break;
                }
            }

            setDeliveryOptions(deliveryOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thread, paymentMethod, loadingPaymentMethod, activePayment]);

    useEffect(() => {
        window.addEventListener('resize', function () {
            setDeviceWidth(window.innerWidth);
            setDeviceHeight(isAndroid ? window.screen.height : window.innerHeight);
        });
    }, []);

    const paymentCreditRef = useRef();

    const modalStyle = () => {
        const isSmallScreen = isMobileOnly || deviceWidth <= 480 || deviceHeight <= 480;
        if (isSmallScreen) {
            return {
                content: {
                    position: 'absolute',
                    top: `${deviceWidth < 330 ? '10%' : deviceHeight <= 480 ? '10%' : 'auto'}`,
                    left: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    right: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    bottom: '0',
                    backgroundColor: themeColors[theme].primaryBgColor4,
                    border: 'none',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    color: themeColors[theme].blackFontColor
                }
            };
        } else {
            return {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    padding: '50px 60px',
                    borderRadius: '20px',
                    height: '630px',
                    width: '779px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'hidden',
                    color: themeColors[theme].blackFontColor
                }
            };
        }
    };

    const modalStyles = modalStyle();

    const storeOpen = pickupLocation
        ? isStoreOpen(pickupLocation?.availability, storeSettings.time_zone)
        : storeSettings.isOpen;

    const inputEl = useRef(null);
    const cashPayment = !requireExactChange ? (
        <Box marginBottom={40} fontWeight={500} color={themeColors[theme].tertiaryFontColor2}>
            {t('buyNowModal.payment.noChange')}
        </Box>
    ) : (
        <Payment
            onChange={e => {
                const value = e.target.value;
                if (!value) {
                    setCurrentPaymentChange('');
                } else if (value > 0 && value < 100 && value.toString().length <= 5) {
                    setCurrentPaymentChange(e.target.value);
                }
            }}
            onFocus={() => setPaymentOnFocus(true)}
            onBlur={() => setTimeout(() => setPaymentOnFocus(false), 300)}
            change={currentPaymentChange}
            cashBtnOnFocus={paymentOnFocus}
            forwardedRef={inputEl}
            onClear={() => {
                setCurrentPaymentChange('');
                setTimeout(() => {
                    inputEl.current.focus();
                    setPaymentOnFocus(true);
                }, 300);

                inputEl.current.focus();
                setPaymentOnFocus(true);
            }}
        ></Payment>
    );

    const creditPayment = (
        <Fragment>
            {storeSettings?.payment_provider === PaymentProviderType.Stripe ? (
                <Elements stripe={stripePromise}>
                    <StripePaymentCredit
                        ref={paymentCreditRef}
                        paymentMethod={paymentMethod}
                        paymentMethodDeleted={() => {
                            // called when payment method has been deleted
                            setPaymentMethod(null);
                            setCanAddPaymentMethod(false);
                        }}
                        cardNumberChanged={event => {
                            setCanAddPaymentMethod(event.complete);
                        }}
                        onFocus={() => setPaymentOnFocus(true)}
                        onBlur={() => setTimeout(() => setPaymentOnFocus(false), 300)}
                        creditPaymentOnFocus={paymentOnFocus}
                        canAddPaymentMethod={canAddPaymentMethod}
                    />
                </Elements>
            ) : (
                <TranzilaPaymentCredit
                    ref={paymentCreditRef}
                    onCardTypeChanged={isValid => {
                        setCanAddPaymentMethod(isValid);
                    }}
                    supplierId={paymentProviderSupplier}
                    paymentMethod={paymentMethod}
                    onCardDeleted={() => {
                        setPaymentMethod(null);
                        setCanAddPaymentMethod(false);
                    }}
                    onFocus={() => setPaymentOnFocus(true)}
                    onBlur={() => setTimeout(() => setPaymentOnFocus(false), 300)}
                    creditPaymentOnFocus={paymentOnFocus}
                    canAddPaymentMethod={canAddPaymentMethod}
                />
            )}
        </Fragment>
    );

    return (
        <Modal
            style={modalStyles}
            isOpen={showModal}
            onAfterClose={() => {
                resetFields();
                setCurrentPage(MODAL_PAGES.main);
            }}
            closeTimeoutMS={500}
            onRequestClose={onRequestClose}
            overlayClassName="buy-now-modal-overlay"
        >
            <Row
                justifyContent={currentPage === MODAL_PAGES.main ? 'flex-end' : 'space-between'}
                marginBottom={10}
            >
                {currentPage === MODAL_PAGES.main ? (
                    <Image
                        cursor="pointer"
                        src={closeIcon}
                        width={32}
                        height={32}
                        padding={8}
                        onClick={onRequestClose}
                    />
                ) : (
                    <Image
                        cursor="pointer"
                        src={arrowLeft}
                        width={32}
                        height={32}
                        padding={8}
                        onClick={() => {
                            resetFields();
                            setCurrentPage(MODAL_PAGES.main);
                        }}
                    />
                )}

                {currentPage === MODAL_PAGES.paymentCashAndCredit && (
                    <Box
                        padding="10px 0 15px 20px"
                        cursor="pointer"
                        pointerEvents={savePaymentMethodDisabled ? 'none' : 'auto'}
                        opacity={savePaymentMethodDisabled ? '0.3' : '1'}
                        color={themeColors[theme].primaryFontColor1}
                        props={{
                            onClick: onPaymentMethodUpdate
                        }}
                    >
                        {t('buyNowModal.done')}
                    </Box>
                )}
            </Row>

            <ReactCSSTransitionReplace
                transitionName={currentPage === 'main' ? 'carousel-swap-back' : 'carousel-swap'}
                transitionEnterTimeout={isMobileOnly ? 300 : 1000}
                transitionLeaveTimeout={isMobileOnly ? 300 : 1000}
                className={!isMobileOnly ? 'buy-now-modal-content' : 'buy-now-modal-content-mobile'}
            >
                {
                    {
                        main: (
                            <Main
                                key="mainComponent"
                                pickupLocation={pickupLocation}
                                storeOpen={storeOpen}
                                timeZone={storeSettings?.timeZone}
                                thread={thread}
                                charges={charges}
                                availableDeliveryTimes={availableDeliveryTimes}
                                submitting={submitting}
                                onOptionSelected={page => {
                                    if (
                                        page === MODAL_PAGES.paymentCredit &&
                                        loadingPaymentMethod
                                    ) {
                                        return;
                                    }

                                    setCurrentPage(page);
                                }}
                                onSubmit={() => {
                                    // if the transation type is credit card payment
                                    // and payment method not exsist then we will auto route the user
                                    // to the add payment method page
                                    if (
                                        supportedPaymentMethods !== TransactionsTypes.Cash &&
                                        !paymentMethod &&
                                        activePayment === TransactionsTypes.Credit
                                    ) {
                                        supportedPaymentMethods === TransactionsTypes.CreditAndCash
                                            ? setCurrentPage(MODAL_PAGES.paymentCashAndCredit)
                                            : setCurrentPage(MODAL_PAGES.paymentCredit);
                                    } else {
                                        const paymentType =
                                            activePayment === TransactionsTypes.Cash
                                                ? SUPPORTED_TRANSACTION_TYPE.CASH
                                                : SUPPORTED_TRANSACTION_TYPE.CREDIT;
                                        onComplete(paymentMethod, paymentType);
                                    }
                                }}
                                deliveryOptions={deliveryOptions}
                                saveEnabled={placeOrderEnabled}
                                etaEstimation={etaEstimation}
                                currentDeliveryModel={currentDeliveryModel}
                            />
                        ),
                        delivery: (
                            <Page
                                key="deliveryTypePage"
                                title={t('buyNowModal.delivery.title')}
                                info={t('buyNowModal.delivery.info')}
                                subtitle={t('buyNowModal.delivery.subtitle', {
                                    address: thread.address
                                })}
                                buttonTitle={t('buyNowModal.save')}
                                loading={savingDeliveryType}
                                onSave={saveDeliveryType}
                                saveEnabled={saveDeliveryEnabled}
                            >
                                <Delivery
                                    marginTop={20}
                                    deliveryType={currentDeliveryType}
                                    supportedDeliveryOptions={supportedDeliveryOptions}
                                    onChange={e => {
                                        setCurrentDeliveryType(e.target.value);
                                    }}
                                />
                            </Page>
                        ),
                        notes: (
                            <Page
                                key="notesComponent"
                                loading={savingNotes}
                                saveEnabled={saveNotesEnabled}
                                title={t('buyNowModal.notes.title')}
                                info={
                                    pickupLocation
                                        ? t('buyNowModal.notes.pickupInfo')
                                        : t('buyNowModal.notes.info')
                                }
                                subtitle={
                                    pickupLocation
                                        ? t('buyNowModal.notes.pickupNotesTxt')
                                        : t('buyNowModal.notes.subtitle')
                                }
                                buttonTitle={t('buyNowModal.save')}
                                onSave={saveNote}
                            >
                                <Notes
                                    onChange={e => {
                                        setCurrentNote(e.target.value);
                                    }}
                                    note={getThreadNote()}
                                    pickupLocation={pickupLocation}
                                ></Notes>
                            </Page>
                        ),
                        payment: (
                            <Page
                                key="paymentComponent"
                                loading={savingPayment}
                                saveEnabled={savePaymentEnabled}
                                saveButtonVisible={paymentOnFocus}
                                title={t('buyNowModal.payment.title')}
                                info={
                                    supportedPaymentMethods === TransactionsTypes.Cash &&
                                    supportDebitAndCash
                                        ? t('buyNowModal.payment.infoCashOrDebit', {
                                              fee_amount: pinTerminalFee
                                          })
                                        : t('buyNowModal.payment.info')
                                }
                                subtitle={t('buyNowModal.payment.subtitle', {
                                    total: priceToString(charges.total, state.currency)
                                })}
                                buttonTitle={t('buyNowModal.save')}
                                onSave={savePayment}
                                paymentStyles={true}
                            >
                                {cashPayment}
                            </Page>
                        ),
                        paymentCredit: (
                            <Page
                                key="paymentWithCreditComponent"
                                loading={savingPaymentMethod}
                                saveEnabled={paymentMethod ? true : canAddPaymentMethod}
                                title={t('buyNowModal.paymentWithCredit.title')}
                                subtitle={
                                    paymentMethod
                                        ? t('buyNowModal.paymentWithCredit.subtitle')
                                        : t('buyNowModal.paymentWithCredit.subtitle2')
                                }
                                buttonTitle={t('buyNowModal.paymentWithCredit.addCard')}
                                saveButtonVisible={!paymentMethod}
                                onSave={saveCreditPayment}
                                paymentStyles={true}
                            >
                                {creditPayment}
                            </Page>
                        ),
                        paymentCashAndCredit: (
                            <Page
                                key="paymentWithCashAndCreditComponent"
                                loading={
                                    activePayment === TransactionsTypes.Credit
                                        ? savingPaymentMethod
                                        : savingPayment
                                }
                                saveEnabled={
                                    activePayment === TransactionsTypes.Credit
                                        ? paymentMethod
                                            ? true
                                            : canAddPaymentMethod
                                        : savePaymentEnabled
                                }
                                title={t('buyNowModal.paymentWithCredit.title')}
                                subtitle={t('buyNowModal.payment.subtitle', {
                                    total: priceToString(charges.total, state.currency)
                                })}
                                buttonTitle={
                                    activePayment === TransactionsTypes.Credit
                                        ? t('buyNowModal.paymentWithCredit.addCard')
                                        : t('buyNowModal.save')
                                }
                                saveButtonVisible={
                                    (paymentOnFocus && activePayment === TransactionsTypes.Cash) ||
                                    (!paymentMethod && activePayment === TransactionsTypes.Credit)
                                }
                                onSave={
                                    activePayment === TransactionsTypes.Credit
                                        ? saveCreditPayment
                                        : savePayment
                                }
                                paymentStyles={true}
                            >
                                <CashAndCreditPayment
                                    cashPayment={cashPayment}
                                    creditPayment={creditPayment}
                                    selectedPaymentMethod={selectedPaymentMethod}
                                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                                    paymentMethod={paymentMethod}
                                    requireExactChange={requireExactChange}
                                />
                            </Page>
                        )
                    }[currentPage]
                }
            </ReactCSSTransitionReplace>
        </Modal>
    );
}
