import React, { useState } from 'react';
import { Box, Inline, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';
import { isConsentChecked } from 'api/utils';

import Icon from './Icon';
import RadioButton from './shared/RadioButton';

export default function CustomConsent({ customConsent, consentChecked, setConsentChecked }) {
    const { name, description, url, is_required } = customConsent;
    const { theme, themeColors } = useTheme();

    return (
        <Row marginRight={10} paddingBottom={50} justifyContent="space-between">
            <RadioButton
                alignEnd
                noSpace
                checkbox
                onChange={() => {
                    setConsentChecked(!consentChecked);
                    localStorage.setItem('consentChecked', !consentChecked);
                }}
                checked={isConsentChecked()}
                value={name}
                cursor="pointer"
            >
                <Box paddingLeft={10}>
                    <Box
                        fontSize={16}
                        fontWeight="bold"
                        letterSpacing={1}
                        color={themeColors[theme].primaryFontColor1}
                    >
                        {name} {is_required && <Inline> * </Inline>}
                    </Box>
                    {description && (
                        <Box
                            fontSize={12}
                            letterSpacing={1}
                            color={themeColors[theme].primaryFontColor1}
                            opacity={0.7}
                        >
                            {description}
                        </Box>
                    )}
                </Box>
            </RadioButton>

            {url && (
                <Box
                    component="a"
                    props={{
                        href: url,
                        target: '_blank'
                    }}
                    cursor="pointer"
                >
                    <Icon name={theme === THEME.DARK ? 'arrowRightWhite2' : 'arrowRight'} />
                </Box>
            )}
        </Row>
    );
}
