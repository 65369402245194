import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { actionTypes, contextActions } from 'actions';

export const AppContext = createContext();

const initialState = {
    catalogScrollYOffset: 0,
    paymentMethod: null,
    currency: '',
    botNumber: '',
    currentDeliveryModel: null,
    countryCode: null,
    storeDomain: null,
    storeDescription: null,
    showScWindowModal: false,
    pendingAction: null,
    loadingDeliveryTypePopup: true,
    showAgeVerificationModal: false
};

const contextReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_CATALOG_SCROLL_Y_OFFSET:
            return { ...state, catalogScrollYOffset: action.catalogScrollYOffset };
        case actionTypes.SET_PAYMENT_METHOD:
            return { ...state, paymentMethod: action.paymentMethod };
        case actionTypes.SET_CURRENCY:
            return { ...state, currency: action.currency };
        case actionTypes.SET_BOT_NUMBER:
            return { ...state, botNumber: action.botNumber };
        case actionTypes.SET_COUNTRY_CODE:
            return { ...state, countryCode: action.countryCode };
        case actionTypes.SET_STORE_DOMAIN:
            return { ...state, storeDomain: action.storeDomain };
        case actionTypes.SET_CURRENT_DELIVERY_MODEL:
            return { ...state, currentDeliveryModel: action.model };
        case actionTypes.SET_STORE_DESCRIPTION:
            return { ...state, storeDescription: action.storeDescription };
        case actionTypes.SET_SHOW_SC_WINDOW_MODAL:
            return { ...state, showScWindowModal: action.show };
        case actionTypes.SET_SHOW_DELIVERY_ADDRESS_MODAL:
            return { ...state, showDeliveryAddressModal: action.show };
        case actionTypes.SET_SHOW_AGE_VERIFICATION_MODAL:
            return { ...state, showAgeVerificationModal: action.show };
        case actionTypes.SET_SHOW_DELIVERY_TYPE_MODAL:
            return { ...state, showDeliveryTypeModal: action.show };
        case actionTypes.SET_LOADING_SHOW_DELIVERY_TYPE_MODAL:
            return { ...state, loadingDeliveryTypePopup: action.loading };
        case actionTypes.SET_PENDING_ACTION:
            return { ...state, pendingAction: action.pendingAction };
        default:
            return state;
    }
};

function AppContextProvider({ children }) {
    const [state, dispatch] = useReducer(contextReducer, initialState);
    const value = useMemo(() => [state, dispatch], [state]);

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useAppContext() {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within a AppContextProvider');
    }
    const [state, dispatch] = context;
    const appContextActions = contextActions(dispatch);
    // const appContextSelector = contextSelectors(state);
    return { state, appContextActions };
}

export { AppContextProvider, useAppContext };
