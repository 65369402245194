import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import DeliveryModel from 'constants/DeliveryModel';
import { Box, Col, Row } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useDeliveryWindows from 'hooks/useDeliveryWindows';
import usePopupManager from 'hooks/usePopupManager';
import useTheme from 'hooks/useTheme';
import useWindows from 'hooks/useWindows';

import { getCachedSelectedWindow } from 'api/utils';

import { useAppContext } from 'store';

import Spinner from 'components/Spinner';

import { ThreadDeliveryModel } from '../api/constants';
import { isHebrew, isRussian, nextOpenTimeText } from '../api/utils';
import Icon from './Icon';
import ScheduledWindowModal from './Modals/ScheduledWindowModal';

export default function DeliveryModelNotification({
    bothModelsSupported,
    onlySuperFastSupported,
    onlyScheduledSupported,
    orderIsSuperFast,
    orderInTransit,
    onDeliveryModelChange,
    currentDeliveryModel,
    onlySuperFastAndStoreClosed,
    noProductsAvailable,
    productCounts,
    showProductAvailabilityModal,
    storeClosed,
    whenStoreOpen
}) {
    const [savingWindow, setSavingWindow] = useState(false);

    const { state, appContextActions } = useAppContext();

    const { updateThreadDeliveryModel } = useWindows();
    const { deliveryWindows, defaultWindowShortText, currentWindow } =
        useDeliveryWindows(orderIsSuperFast);

    const { t } = useTranslation();

    const displayScCount = productCounts.allScheduled;
    const displaySfCount = productCounts.allSuperFast;

    const availableProductsRussian = `${
        noProductsAvailable
            ? `${t('stickyBar.no')}${t('stickyBar.productsAvailable')}`
            : displaySfCount === 1
              ? `${t('stickyBar.productAvailable')}: ${displaySfCount}`
              : `${t('stickyBar.productsAvailable')}: ${displaySfCount}`
    }`;

    const availableProductsOthers = `${displaySfCount} ${
        displaySfCount === 1
            ? `${t('catalogPage.availableProduct')} ${t(`catalogPage.toBe`)}`
            : `${t('catalogPage.availableProducts')} ${t(`catalogPage.toBe`)}`
    }`;
    const availableProductsHebrew = `${
        noProductsAvailable
            ? `${t('stickyBar.noProductsAvailable')}`
            : `${t('stickyBar.productsAvailable')} \u200E${displaySfCount}`
    }`;

    const availableProductsLabelSF = `${
        isRussian
            ? availableProductsRussian
            : isHebrew()
              ? !isMobileOnly
                  ? availableProductsHebrew
                  : ''
              : availableProductsOthers
    } ${isHebrew() ? `\u200E${availableProductsHebrew}` : ''}`;

    const deliveryModelLabels = {
        superFast: {
            title: t('stickyBar.superFast'),
            mobileTitle: t('stickyBar.superFast2'),
            subtitle: '',
            desp1: availableProductsLabelSF,
            desp2: t('catalogPage.deliveredRightAway'),

            count: displaySfCount
        },
        scheduled: {
            subtitle: t('stickyBar.scheduled'),
            title: defaultWindowShortText || '',
            mobileTitle: t('stickyBar.scheduled'),
            count: displayScCount,
            desp1: deliveryWindows?.length > 1 ? t('catalogPage.chooseTime') : '',
            desp2: t('catalogPage.deliveredRightAway')
        }
    };

    const onSaveScWindow = async window => {
        try {
            setSavingWindow(true);
            await updateThreadDeliveryModel(ThreadDeliveryModel.scheduled, window);
        } finally {
            setTimeout(() => {
                setSavingWindow(false);
            }, 300);
        }
    };

    return (
        <Box maxWidth={983} marginTop={isMobileOnly ? 10 : 20} marginBottom={15}>
            {bothModelsSupported ? (
                <BothModels
                    orderIsSuperFast={orderIsSuperFast}
                    onDeliveryModelChange={onDeliveryModelChange}
                    orderInTransit={orderInTransit}
                    currentDeliveryModel={currentDeliveryModel}
                    onlySuperFastAndStoreClosed={onlySuperFastAndStoreClosed}
                    noProductsAvailable={noProductsAvailable}
                    scProductsCount={displayScCount}
                    defaultWindowShortText={defaultWindowShortText}
                    savingWindow={savingWindow}
                    deliveryModelLabels={deliveryModelLabels}
                    deliveryWindows={deliveryWindows}
                />
            ) : (
                <SingleModel
                    onlySuperFastSupported={onlySuperFastSupported}
                    onlyScheduledSupported={onlyScheduledSupported}
                    deliveryLabel={
                        onlySuperFastSupported
                            ? deliveryModelLabels.superFast
                            : deliveryModelLabels.scheduled
                    }
                    orderIsSuperFast={orderIsSuperFast}
                    orderInTransit={orderInTransit}
                    productCount={onlySuperFastSupported ? displaySfCount : displayScCount}
                    savingWindow={savingWindow}
                    deliveryWindows={deliveryWindows}
                    onlySuperFastAndStoreClosed={onlySuperFastAndStoreClosed}
                    whenStoreOpen={whenStoreOpen}
                />
            )}

            {state.showScWindowModal && !showProductAvailabilityModal && (
                <ScheduledWindowModal
                    showModal={state.showScWindowModal}
                    onClose={() => {
                        appContextActions.scWindowModal.close();
                    }}
                    deliveryWindows={deliveryWindows}
                    defaultWindow={currentWindow}
                    onSaveScWindow={window => {
                        appContextActions.scWindowModal.close();
                        onSaveScWindow(window);
                    }}
                    scAvailable={true}
                    storeClosed={storeClosed}
                />
            )}
        </Box>
    );
}

const BothModels = ({
    orderIsSuperFast,
    onDeliveryModelChange,
    orderInTransit,
    currentDeliveryModel,
    onlySuperFastAndStoreClosed,
    noProductsAvailable,
    scProductsCount,
    deliveryModelLabels,
    savingWindow,
    deliveryWindows
}) => {
    const { theme, themeColors } = useTheme();

    const deliveryDespLabel = orderIsSuperFast
        ? deliveryModelLabels.superFast
        : deliveryModelLabels.scheduled;

    const sfNotAvailable = orderIsSuperFast && deliveryModelLabels.superFast.count < 1;
    const scNotAvailable = !orderIsSuperFast && deliveryModelLabels.scheduled.count < 1;

    return (
        <>
            <Row
                margin="15px 0px"
                marginLeft={!isMobileOnly && 15}
                justifyContent={isMobileOnly && 'center'}
            >
                <MenuBox
                    deliveryModel={DeliveryModel.OnDemand}
                    title={
                        isMobileOnly
                            ? deliveryModelLabels.superFast.mobileTitle
                            : deliveryModelLabels.superFast.title
                    }
                    productCount={deliveryModelLabels.superFast.count}
                    currentDeliveryModel={currentDeliveryModel}
                    onDeliveryModelChange={onDeliveryModelChange}
                    onlySuperFastAndStoreClosed={onlySuperFastAndStoreClosed}
                    noProductsAvailable={noProductsAvailable}
                    orderInTransit={orderInTransit}
                    savingWindow={savingWindow}
                    paddingRight={12}
                    marginRight={!isMobileOnly && 12}
                    borderRight={
                        isMobileOnly && `1px solid ${themeColors[theme].secondaryBorderColor2}`
                    }
                    deliveryWindows={deliveryWindows}
                />
                <MenuBox
                    deliveryModel={DeliveryModel.Scheduled}
                    title={
                        isMobileOnly
                            ? deliveryModelLabels.scheduled.mobileTitle
                            : deliveryModelLabels.scheduled.title
                    }
                    productCount={deliveryModelLabels.scheduled.count}
                    currentDeliveryModel={currentDeliveryModel}
                    onDeliveryModelChange={onDeliveryModelChange}
                    onlySuperFastAndStoreClosed={onlySuperFastAndStoreClosed}
                    noProductsAvailable={noProductsAvailable}
                    orderInTransit={orderInTransit}
                    savingWindow={savingWindow}
                    deliveryWindows={deliveryWindows}
                />
            </Row>
            {isMobileOnly && !sfNotAvailable && !scNotAvailable && (
                <Box>
                    <Box
                        textAlign="center"
                        padding="0 0px"
                        margin="0 20px 15px"
                        borderRadius={10}
                        color={themeColors[theme].whitefontColor}
                        position="relative"
                        height={80}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        background={
                            orderIsSuperFast
                                ? themeColors[theme].sfBgColor
                                : themeColors[theme].scBgColor
                        }
                        // mediaQueries={{ landscape }}
                        landscapeMaxWidth={375}
                        landscapeMargin="0 auto"
                    >
                        <Box
                            background={
                                orderIsSuperFast
                                    ? themeColors[theme].sfBgColor
                                    : themeColors[theme].scBgColor
                            }
                            width={20}
                            height={20}
                            position="absolute"
                            top={-7}
                            left={orderIsSuperFast ? 'calc(25% - 13px)' : 'calc(75% + 13px)'}
                            transform="rotate(45deg)"
                        ></Box>
                        <Box
                            fontSize={16}
                            fontWeight={500}
                            color={
                                orderIsSuperFast
                                    ? themeColors[theme].pinkfontColor2
                                    : themeColors[theme].whitefontColor
                            }
                            opacity={!orderIsSuperFast && 0.5}
                        >
                            {deliveryDespLabel.desp1}
                        </Box>

                        {orderIsSuperFast ? (
                            <Box fontSize={16} fontWeight={900} fontStyle="italic">
                                {deliveryDespLabel.desp2}
                            </Box>
                        ) : (
                            <ScheduledWindowsDropdown
                                hideArrow={orderInTransit || orderIsSuperFast}
                                productCount={scProductsCount}
                                savingWindow={savingWindow}
                                isScheduledOrder={!orderIsSuperFast}
                            />
                        )}

                        <Box
                            // opacity={theme !== THEME.DARK && 0.6}
                            fontSize={16}
                            fontWeight={500}
                            width="70%"
                        >
                            {deliveryModelLabels.superFast.available}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

const MenuBox = ({
    deliveryModel,
    title,
    productCount,
    currentDeliveryModel,
    onDeliveryModelChange,
    showScWindowModal,
    onlySuperFastAndStoreClosed,
    noProductsAvailable,
    orderInTransit,
    savingWindow,
    deliveryWindows,
    ...rest
}) => {
    const { t } = useTranslation();
    const { appContextActions } = useAppContext();
    const isActive = currentDeliveryModel === deliveryModel;
    const isSuperFast = deliveryModel === DeliveryModel.OnDemand;
    const isScheduled = deliveryModel === DeliveryModel.Scheduled;
    const orderIsSuperFast = currentDeliveryModel === DeliveryModel.OnDemand;
    const sfActive = orderIsSuperFast && isActive;

    const scActive = !orderIsSuperFast && isActive;
    const scInactive = !orderIsSuperFast && !isActive;
    const { theme, themeColors } = useTheme();

    const showScheduledWindowsDropdown =
        !onlySuperFastAndStoreClosed && currentDeliveryModel && !noProductsAvailable;

    const boxBgColor = sfActive
        ? themeColors[theme].sfBgColor
        : scActive
          ? themeColors[theme].scBgColor
          : scInactive
            ? themeColors[theme].scInactiveBgColor
            : themeColors[theme].inactiveBgColor;

    const boxIcon =
        deliveryModel === DeliveryModel.OnDemand
            ? 'sfLargeRed'
            : scInactive
              ? 'scGrey'
              : 'scheduled';

    const onBoxClick = () => {
        if (isActive && isScheduled && !showScWindowModal && deliveryWindows?.length > 1) {
            appContextActions.scWindowModal.open();
        } else if (isActive) {
            return null;
        } else if (isSuperFast || deliveryWindows?.length <= 1) {
            //only update delivery model
            onDeliveryModelChange(deliveryModel);
        } else if (deliveryWindows?.length > 1) {
            //show SC window popup after update
            onDeliveryModelChange(deliveryModel, () => {
                appContextActions.scWindowModal.open();
            });
        }
    };

    const smSize = 840;
    const sm = `screen and (max-width: ${smSize}px)`;

    const availabilityLabel =
        productCount === 0
            ? t('catalogPage.notAvailable')
            : productCount
              ? `${productCount || ''}  ${
                    productCount === 1 ? t('catalogPage.product') : t('catalogPage.products')
                }`
              : '';

    return (
        <>
            {isMobileOnly ? (
                <Row
                    justifyContent="center"
                    alignItems="center"
                    height={46}
                    width="46%"
                    cursor="pointer"
                    props={{
                        onClick: onBoxClick
                    }}
                    {...rest}
                >
                    <Row alignItems="center" opacity={!sfActive && !scActive && 0.5}>
                        <Icon name={boxIcon} width={25} />

                        <Col color={themeColors[theme].blackFontColor} marginLeft={5}>
                            <Box
                                fontSize={14}
                                fontWeight={700}
                                fontStyle={orderIsSuperFast && 'italic'}
                            >
                                {title}
                            </Box>
                            <Box
                                color={
                                    sfActive
                                        ? themeColors[theme].greyfontColor2
                                        : themeColors[theme].greyfontColor2
                                }
                                fontWeight={500}
                                fontSize={12}
                            >
                                {availabilityLabel}
                            </Box>
                        </Col>
                    </Row>
                </Row>
            ) : (
                showScheduledWindowsDropdown && (
                    <Box
                        mediaQueries={{ sm }}
                        width={398}
                        maxWidth={398}
                        smWidth="46%"
                        height={76}
                        padding="19px 16px 19px 20px"
                        borderRadius={8}
                        backgroundColor={boxBgColor}
                        opacity={!sfActive && !scActive && 0.5}
                        cursor="pointer"
                        props={
                            !showScWindowModal && {
                                onClick: onBoxClick
                            }
                        }
                        {...rest}
                    >
                        <Row alignItems="center">
                            <Box width={41} height={40} marginRight={15}>
                                <Icon name={boxIcon} width={41} />
                            </Box>

                            <Col
                                color={
                                    sfActive || scActive
                                        ? themeColors[theme].whitefontColor
                                        : deliveryModel === DeliveryModel.OnDemand
                                          ? themeColors[theme].quaternaryFontColor1
                                          : themeColors[theme].greyfontColor2
                                }
                                width={'100%'}
                            >
                                {isSuperFast ? (
                                    <Box>
                                        <Box fontWeight={700} fontStyle="italic">
                                            {title}
                                        </Box>
                                        <Box
                                            color={
                                                sfActive
                                                    ? themeColors[theme].whitefontColor
                                                    : themeColors[theme].greyfontColor2
                                            }
                                            fontWeight={500}
                                        >
                                            {availabilityLabel}
                                        </Box>
                                    </Box>
                                ) : (
                                    <ScheduledWindowsDropdown
                                        hideArrow={orderInTransit || sfActive || orderIsSuperFast}
                                        productCount={productCount}
                                        savingWindow={savingWindow}
                                        isScheduledOrder={!orderIsSuperFast}
                                        subtitle={availabilityLabel}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Box>
                )
            )}
        </>
    );
};

const SingleModel = ({
    onlyScheduledSupported,
    deliveryLabel,
    orderInTransit,
    savingWindow,
    productCount,
    deliveryWindows,
    onlySuperFastAndStoreClosed,
    whenStoreOpen
}) => {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();
    const { state, appContextActions } = useAppContext();
    const availableProductsLabel = `${productCount} ${
        productCount === 1 ? t('catalogPage.availableProduct') : t('catalogPage.availableProducts')
    }`;

    const availabilityText = !productCount
        ? t('catalogPage.notAvailable')
        : onlySuperFastAndStoreClosed
          ? nextOpenTimeText(whenStoreOpen?.day, whenStoreOpen?.time, t)
          : availableProductsLabel +
            (onlyScheduledSupported
                ? deliveryWindows?.length > 1
                    ? ` ${t('catalogPage.scheduledDelivery')}`
                    : ` ${t('catalogPage.scheduledDeliveryNoSee')}`
                : ` ${t('catalogPage.toBeDelivered')}`);

    const deliveryAvailableText = (
        <Box
            fontWeight={500}
            fontSize={isMobileOnly ? 12 : 14}
            color={
                onlyScheduledSupported
                    ? themeColors[theme].greyfontColor2
                    : themeColors[theme].pinkfontColor
            }
            display="inline"
            paddingLeft={!isMobileOnly && 15}
        >
            {availabilityText}
        </Box>
    );

    const smSize = 990;
    const sm = `screen and (max-width: ${smSize}px)`;

    const shouldShowScheduledWindows =
        deliveryWindows?.length > 1 && onlyScheduledSupported && !state.showScWindowModal;

    return (
        <Box mediaQueries={{ sm }} paddingLeft={15} smPaddingRight={15} paddingTop={isMobileOnly}>
            <Row
                width="100%"
                height={isMobileOnly ? 80 : 42}
                alignItems="center"
                borderRadius={8}
                background={
                    onlyScheduledSupported
                        ? themeColors[theme].scBgColor
                        : themeColors[theme].sfBgColor
                }
                padding={20}
                paddingRight={10}
                color={themeColors[theme].whitefontColor}
                cursor={shouldShowScheduledWindows && 'pointer'}
                props={
                    shouldShowScheduledWindows && {
                        onClick: () => appContextActions.scWindowModal.open()
                    }
                }
            >
                <Icon name={onlyScheduledSupported ? 'scheduled' : 'sfLargeRed'} width={20} />
                <Box marginLeft={15}>
                    {onlyScheduledSupported ? (
                        <ScheduledWindowsDropdown
                            hideArrow={orderInTransit}
                            productCount={productCount}
                            savingWindow={savingWindow}
                            isScheduledOrder={onlyScheduledSupported}
                            subtitle={isMobileOnly ? deliveryAvailableText : null}
                            singleMode
                        />
                    ) : (
                        <Box>
                            <Box fontStyle="italic" fontWeight={900} fontSize={16}>
                                {deliveryLabel.title}
                            </Box>
                            {isMobileOnly && <Box>{deliveryAvailableText}</Box>}
                        </Box>
                    )}
                </Box>
                {!isMobileOnly ? <Box>{deliveryAvailableText}</Box> : null}
            </Row>
        </Box>
    );
};

const ScheduledWindowsDropdown = React.memo(
    ({ hideArrow, savingWindow, productCount, isScheduledOrder, subtitle, singleMode }) => {
        const { theme, themeColors } = useTheme();
        const [showArrow, setShowArrow] = useState(true);
        const [title, setTitle] = useState();

        const { updateThreadDeliveryModel } = useWindows();
        const { deliveryWindows, defaultWindowShortText } = useDeliveryWindows();
        const { data: mappedData } = useCustomerData();
        const { thread } = mappedData || {};
        const { delayPopup } = usePopupManager();

        const { state, appContextActions } = useAppContext();

        const { showScWindowModal } = state;

        useEffect(() => {
            const getTitle = async () => {
                if (thread && defaultWindowShortText) {
                    setShowArrow(!hideArrow && deliveryWindows?.length > 1);
                    setTitle(defaultWindowShortText.toUpperCase());

                    if (isScheduledOrder && !thread.delivery_window) {
                        await updateThreadDeliveryModel(
                            ThreadDeliveryModel.scheduled,
                            getCachedSelectedWindow()
                        );

                        const isNewCustomer = localStorage.getItem('isNewCustomer');
                        if (isNewCustomer && deliveryWindows?.length > 1) {
                            appContextActions.scWindowModal.open();
                            localStorage.removeItem('isNewCustomer');
                        }
                    }
                }
            };
            getTitle();
        }, [
            deliveryWindows?.length,
            defaultWindowShortText,
            hideArrow,
            thread,
            thread?.deliver_by
        ]);

        useEffect(() => {
            const htmlElm = document.querySelector('html');
            htmlElm.style.overflow =
                !productCount && showScWindowModal && showArrow ? 'hidden' : 'auto';
        }, [showScWindowModal, showArrow, productCount]);

        return (
            <Box minHeight={isMobileOnly || singleMode ? 20 : 40} width="100%">
                {title?.length ? (
                    <Row
                        cursor={showArrow && 'pointer'}
                        props={
                            showArrow && { onClick: () => appContextActions.scWindowModal.open() }
                        }
                        justifyContent={isMobileOnly || singleMode ? 'center' : 'space-between'}
                    >
                        <Box>
                            <Box fontWeight={700} fontStyle="italic" opacity={savingWindow && 0.5}>
                                {title}
                            </Box>
                            <Box color={themeColors[theme].greyfontColor2} fontWeight={500}>
                                {subtitle}
                            </Box>
                        </Box>

                        <Box minWidth={24} display="flex" alignItems="center">
                            {savingWindow ? (
                                <Spinner noMargin white marginLeft={10} width={20} height={20} />
                            ) : showArrow && title ? (
                                <Icon
                                    name="arrowDownWhite"
                                    margin="2px 0px 2px 9px"
                                    objectFit="contain"
                                />
                            ) : null}
                        </Box>
                    </Row>
                ) : null}
            </Box>
        );
    }
);
