import { addItemToCart } from '../utils/cacheDataManager';
import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function addToCart(user, product_id, quantity, product) {
    if (user.authenticated) {
        const payload = {
            item_id: product_id,
            quantity: parseInt(quantity),
            user_id: user.id,
            organization_id: user.organizationId
        };

        const response = await invokeLambda(Lambda.AddToCart, payload);

        return response.updated_cart;
    } else {
        return addItemToCart(product_id, parseInt(quantity), user.organizationId, product);
    }
}
