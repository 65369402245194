import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import bg from '../assets/images/errorPage-bg.png';
import Image from '../components/Image';

export default function ErrorPage({ pageTitle }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            width="100vw"
        >
            <Box textAlign="center" margin="0 20px">
                <Box
                    fontSize={30}
                    fontWeight={900}
                    lineHeight={1}
                    color={themeColors[theme].tertiaryFontColor2}
                    marginBottom={22}
                >
                    {t('errorPage.oops')}
                </Box>
                <Box
                    fontSize={16}
                    fontWeight={500}
                    lineHeight={1.25}
                    letterSpacing="1px"
                    color={themeColors[theme].secondaryFontColor1}
                    marginBottom={14}
                >
                    {t('errorPage.subtitle1')}
                </Box>
                <Box
                    fontSize={16}
                    fontWeight={500}
                    lineHeight={1.25}
                    letterSpacing="1px"
                    color={themeColors[theme].secondaryFontColor1}
                    marginBottom={14}
                    maxWidth={400}
                >
                    {t('errorPage.subtitle2')}
                </Box>
                <Image src={bg} maxWidth={350} borderRadius={8} />
            </Box>
        </Box>
    );
}
