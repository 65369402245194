// #region Mantis Pixels

/**
 *
 * @param {*} advertiserId string
 * @returns scriptElement
 */
export const getMantisTransactionScript = (advertiserId, transactionId = '', revenue = '') => {
    /**
     * <script type="text/javascript">
        var mantis = mantis || [];
        mantis.push(['analytics', 'load', {
               advertiser: {advertiserId},
               transaction: '',
               revenue: ''
        }]);
        </script>
     */
    // Create script element
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.innerHTML = `var mantis = mantis || [];mantis.push(["analytics", "load", {"advertiser": "${advertiserId}","transaction": "${transactionId}","revenue": "${revenue}"}]);`;
    return scriptElement;
};

/**
 *
 * @param {*} advertiserId string
 * @returns null
 */
export const installMantisScript = advertiserId => {
    const scriptElement = getMantisTransactionScript(advertiserId);
    document.head.appendChild(scriptElement);
    //<script type="text/javascript" data-cfasync="false" src=https://assets.mantisadnetwork.com/analytics.min.js async></script>
    const scriptElement2 = document.createElement('script');
    scriptElement2.type = 'text/javascript';
    scriptElement2.setAttribute('data-cfasync', 'false');
    scriptElement2.src = 'https://assets.mantisadnetwork.com/analytics.min.js';
    scriptElement2.async = true;
    document.head.appendChild(scriptElement2);

    // <noscript><img src="//direct.mantisadnetwork.com/analytics/pixel?advertiser={advertiserId}&transaction=" /></noscript>
    const noscriptElement = document.createElement('noscript');
    const imgElement = document.createElement('img');
    imgElement.src = `//direct.mantisadnetwork.com/analytics/pixel?advertiser=${advertiserId}&transaction=`;
    noscriptElement.appendChild(imgElement);
    document.head.appendChild(noscriptElement);
};

export const trackPurchaseWithMantis = (advertiserId, transactionId, revenue) => {
    if (!advertiserId) return;
    const scriptElement = getMantisTransactionScript(advertiserId, transactionId, revenue);

    // Append script to head
    document.head.appendChild(scriptElement);
};

// #endregion Mantis Pixels
