import React from 'react';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import CloseBar from 'components/CloseBar';
import Heading from 'components/Heading';
import { Subtitle } from 'components/shared';
import Wrapper from 'components/Wrapper';

import { THEME } from '../api/constants';

export default function TitleAndSubtitlePageLayout({
    children,
    title,
    subtitle = undefined,
    closeable = true,
    closeIconName = 'close',
    onClose,
    customerName,
    hideFooter = false
}) {
    const { theme, themeColors } = useTheme();

    return (
        <Wrapper white hideFooter={hideFooter}>
            {closeable && <CloseBar closeIconName={closeIconName} onClose={onClose}></CloseBar>}
            {customerName && (
                <Box
                    color={themeColors[theme].primaryFontColor1}
                    marginTop={10}
                    opacity={0.5}
                    fontSize={18}
                    fontWeight={500}
                >
                    {customerName}
                </Box>
            )}
            <Heading
                color={themeColors[theme].primaryFontColor1}
                marginTop={!customerName && 60}
                noMarginBottom
            >
                {title}
            </Heading>
            {subtitle && (
                <Subtitle
                    color={themeColors[theme].primaryFontColor1}
                    fontWeight={500}
                    fontSize={18}
                >
                    {subtitle}
                </Subtitle>
            )}
            <Box paddingTop={30}>{children}</Box>
        </Wrapper>
    );
}
