import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useTheme from 'hooks/useTheme';

import { threadSubmitted } from '../api/utils';
import Button from '../components/Button';
import Close from '../components/CloseBar';
import Heading from '../components/Heading';
import Head from '../components/HeadManager';
import Loading from '../components/Loading';
import P from '../components/Paragraph';
import Wrapper from '../components/Wrapper';
import useStoreSettings from '../hooks/useStoreSettings';

export default function Account({ pageTitle }) {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();
    const { data: mappedData, store, mutate } = useCustomerData();

    const { thread, user } = mappedData || {};

    const { data: storeSettings } = useStoreSettings();
    const isSubmittedOrder = thread && threadSubmitted(thread);

    const isLoading = !user;

    const [deleting, setDeleting] = useState();
    if (isLoading) {
        return (
            <Wrapper white>
                <Loading />
            </Wrapper>
        );
    }

    if (!user.authenticated) {
        navigate('/');
    }
    const onClose = () => {
        if (deleting) {
            try {
                mutate();
                window.location.replace('/');
            } catch (e) {
                console.log(e);
            }
        } else {
            navigate(`/account`, true);
        }
    };

    const onDelete = async () => {
        setDeleting(true);
        window.localStorage.clear();
        await store.deleteAccount(user.username);
    };

    return (
        <Wrapper white>
            <Head pageTitle={pageTitle} />
            <Box marginBottom={60}>
                <Close onClose={onClose} />
            </Box>
            <Heading>
                {deleting ? t('account.deleteAccount.heading') : t('account.deleteAccount.ifSure')}
            </Heading>
            <Box
                fontSize={18}
                fontWeight={500}
                color={themeColors[theme].secondaryFontColor2}
                marginBottom={5}
            >
                {deleting ? t('account.deleteAccount.deleted') : t('account.deleteAccount.ifSure2')}
            </Box>
            <P marginBottom={120} color={themeColors[theme].secondaryFontColor11} lineHeight={1}>
                {!deleting && t('account.deleteAccount.cannotDelete')}
                <Link
                    goTo={storeSettings.webstore_footer.terms_link}
                    theme={theme}
                    themeColors={themeColors}
                >
                    {t('account.deleteAccount.terms')}{' '}
                </Link>
                {t('account.deleteAccount.and')}{' '}
                <Link
                    goTo={storeSettings.webstore_footer.privacy_link}
                    theme={theme}
                    themeColors={themeColors}
                >
                    {t('account.deleteAccount.privacyPolicy')}
                </Link>{' '}
                {t('account.deleteAccount.applies')}
            </P>
            {!deleting && (
                <Box opacity={isSubmittedOrder ? '0.3' : '1'}>
                    <Button
                        big
                        display="block"
                        marginBottom={20}
                        hoverBackgroundColor={
                            isSubmittedOrder
                                ? themeColors[theme].primaryBgColor2
                                : themeColors[theme].primaryBgColor1
                        }
                        hoverColor={
                            isSubmittedOrder
                                ? themeColors[theme].primaryFontColor2
                                : themeColors[theme].primaryFontColor1
                        }
                        props={{ onClick: onClose, disabled: isSubmittedOrder }}
                    >
                        {t('account.deleteAccount.notNow')}
                    </Button>
                    <Button
                        big
                        display="block"
                        boxShadow="none"
                        backgroundColor={themeColors[theme].primaryBgColor1}
                        border={`1px solid ${themeColors[theme].secondaryBorderColor3}`}
                        color={themeColors[theme].primaryFontColor1}
                        hoverBackgroundColor={
                            isSubmittedOrder
                                ? themeColors[theme].primaryBgColor1
                                : themeColors[theme].primaryBgColor2
                        }
                        hoverColor={
                            isSubmittedOrder
                                ? themeColors[theme].primaryFontColor1
                                : themeColors[theme].primaryFontColor2
                        }
                        props={{ onClick: onDelete, disabled: isSubmittedOrder }}
                    >
                        {t('account.deleteAccount.deleteAccount')}
                    </Button>
                </Box>
            )}
        </Wrapper>
    );
}

const Link = ({ goTo, children, theme, themeColors }) => {
    return (
        <Box
            component="a"
            textDecoration="underline"
            cursor="pointer"
            color={themeColors[theme].secondaryFontColor11}
            hoverColor={themeColors[theme].tertiaryFontColor3}
            props={{ href: goTo, target: '_blank' }}
        >
            {children}
        </Box>
    );
};
