import { clearCart, setZone } from '../utils/cacheDataManager';
import { Lambda, THREAD_STATUS } from './constants';
import invokeLambda from './invokeLambda';

export default async function getZone(
    zone_id,
    user,
    location,
    associated_hub,
    thread_status,
    isDeliveryOrder
) {
    if (!user || !location) return {};
    const { organizationId: organization_id, threadId: thread_id, authenticated } = user;

    if (authenticated && thread_status !== THREAD_STATUS.PENDING) {
        const payload = { organization_id, zone_id };
        let { zone } = await invokeLambda(Lambda.GetZoneById, payload);
        return zone;
    } else {
        const payload = {
            organization_id,
            geoData: location
        };

        let { zone } = await invokeLambda(Lambda.GetZoneForLocation, payload);
        const shouldUpdateCustomerZone =
            !zone ||
            !zone.zone_id ||
            zone.zone_id !== zone_id ||
            !associated_hub ||
            (associated_hub !== zone.associated_hub && isDeliveryOrder);

        if (shouldUpdateCustomerZone) {
            const payload = {
                organization_id,
                location,
                ...(authenticated && {
                    thread_id
                })
            };
            const data = await invokeLambda(Lambda.updateCustomerZone, payload);
            zone = data.zone || {};
        }

        if (isDeliveryOrder && !authenticated && zone && zone.zone_id) {
            setZone(zone, organization_id);
        }

        return zone;
    }
}
