import { updateThreadDeliveryModel } from '../utils/cacheDataManager';
import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function UpdateThreadDeliveryModel(
    user,
    organization_id,
    thread_id,
    delivery_model,
    menu_kit,
    window,
    thread_status
) {
    const window_id = window?.window_id;

    if (window) {
        sessionStorage.setItem('lastSelectedDeliveryWindow', JSON.stringify(window));
    }

    if (user.authenticated && thread_status === 5) {
        const payload = {
            organization_id,
            thread_id,
            delivery_model,
            menu_kit,
            window,
            window_id
        };
        return invokeLambda(Lambda.UpdateThreadDeliveryModel, payload);
    } else if (!user.authenticated) {
        return updateThreadDeliveryModel(organization_id, delivery_model, window);
    }
}
