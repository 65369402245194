import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Icon from '../components/Icon';

export default function FiltersCategories({
    categories,
    initFilterComponent,
    setInitFilterComponent,
    searchBar
}) {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');

    const filteredCategories =
        categories &&
        categories.filter(
            ({ categoryName }) =>
                categoryName.toUpperCase().includes(inputValue.toUpperCase()) || inputValue === ''
        );

    return (
        <Box>
            {searchBar && (
                <SearchBar
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    t={t}
                    theme={theme}
                    themeColors={themeColors}
                />
            )}

            <Row
                flexWrap="wrap"
                alignItems="flex-start"
                alignContent="flex-start"
                marginTop={filteredCategories?.length ? 35 : 10}
                className="category-name"
            >
                {filteredCategories?.length ? (
                    filteredCategories.map(
                        (
                            { category_id: filteredCateId, categoryName, categoryType, selected },
                            i
                        ) => {
                            return (
                                <Box
                                    key={i}
                                    cursor="pointer"
                                    background={selected ? '#000' : '#fff'}
                                    color={selected ? '#fff' : '#b2b2b2'}
                                    border={selected ? '1px solid #000' : '1px solid #b2b2b2'}
                                    borderRadius="60px"
                                    padding="6px 15px"
                                    margin="0 15px 15px 0"
                                    fontSize={14}
                                    fontWeight={900}
                                    props={{
                                        onClick: () => {
                                            const selectedCategory =
                                                initFilterComponent.filterCategories
                                                    .find(
                                                        ({ filterName }) =>
                                                            filterName === categoryType
                                                    )
                                                    .categories.find(
                                                        ({ category_id }) =>
                                                            category_id === filteredCateId
                                                    );
                                            if (selectedCategory) {
                                                selectedCategory.selected =
                                                    !selectedCategory.selected;
                                                setInitFilterComponent({ ...initFilterComponent });
                                            }
                                        }
                                    }}
                                >
                                    {categoryName}
                                </Box>
                            );
                        }
                    )
                ) : (
                    <Box
                        fontSize={12}
                        fontWeight={500}
                        opacity={0.5}
                        color={themeColors[theme].blackFontColor3}
                    >
                        {t('filterModal.noResult')}
                    </Box>
                )}
            </Row>
        </Box>
    );
}

function SearchBar({ inputValue, setInputValue, t, theme, themeColors }) {
    const [clearVisible, setClearVisible] = useState(false);

    return (
        <Box position="relative">
            <Box
                component="input"
                fontSize={16}
                fontWeight={600}
                color={
                    inputValue
                        ? themeColors[theme].blackFontColor
                        : themeColors[theme].tertiaryFontColor2
                }
                placeholderColor={themeColors[theme].placeHolderColor3}
                cursor="pointer"
                border="none"
                // backgroundColor={themeColors[theme].secondaryBgColor19}
                opacity={!inputValue && '0.5'}
                background="rgba(216, 216, 216, 0.5)"
                width={isMobileOnly ? '100%' : 332}
                height={48}
                borderRadius={8}
                paddingLeft={40}
                display="inline-block"
                props={{
                    value: inputValue,
                    placeholder: t('filterModal.search'),

                    onChange: e => {
                        setClearVisible(e.target.value);
                        setInputValue(e.target.value);
                    }
                }}
            />
            <Icon name="searchGrey" position="absolute" left={12} top={14} />
            <Box
                cursor="pointer"
                right={isMobileOnly ? 13 : 217}
                top={15}
                position="absolute"
                props={{
                    onClick: e => {
                        e.stopPropagation();
                        setInputValue('');

                        setClearVisible(false);
                    }
                }}
            >
                <Icon
                    className="input-clear"
                    display={clearVisible ? 'block' : 'none'}
                    width={19}
                    height={19}
                    name="clearSearch"
                />
            </Box>
        </Box>
    );
}
