import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function uploadCustomerPicture(data, user_id, organization_id, bucket) {
    const filename = uuidv4();
    const s3 = new AWS.S3();

    await s3
        .upload({
            Bucket: bucket,
            Key: `${filename}.jpg`,
            Body: data,
            ContentType: 'image/jpeg',
            ACL: 'public-read',
            CacheControl: 'no-cache'
        })
        .promise();
    const payload = { filename, user_id, organization_id };
    return invokeLambda(Lambda.uploadCustomerPicture, payload);
}
