import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeliveryModel from 'constants/DeliveryModel';
import { Box, Row } from 'jsxstyle';

import useDeliveryWindows from 'hooks/useDeliveryWindows';
import useTheme from 'hooks/useTheme';
import useWindows from 'hooks/useWindows';

import {
    cacheDeliveryModel,
    formatDeliveryWindowText,
    isScheduledOrder,
    isSuperFastOrder,
    supportsOnlySuperfastMenu
} from 'api/utils';

import NoticeMessage, { NoticeMessageType } from 'components/NoticeMessage';

import { SuperfastWindow, ThreadDeliveryModel } from '../api/constants';
import Icon from '../components/Icon';
import ScheduledWindowModal from '../components/Modals/ScheduledWindowModal';
import Spinner from '../components/Spinner';

export default function CartDeliveryDropdown({
    orderSuperFast,
    availableDeliveryTimes,
    storeOpen,
    supportsBothDeliveryModels,
    supportsOndemandMenu,
    supportsScheduledMenu,
    currentDeliveryModel,
    setCurrentDeliveryModel,
    isAuth,
    thread,
    productNotAvailable,
    noWindowNotice,
    setNoWindowNotice,
    currentDeliveryNotAvailable
}) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();
    const { deliveryWindows, currentWindowName, currentWindow } =
        useDeliveryWindows(orderSuperFast);
    const { updateThreadDeliveryModel } = useWindows();
    const [showScWindowModal, setShowScWindowModal] = useState(false);
    const [savingWindow, setSavingWindow] = useState(false);
    const [windowText, setWindowText] = useState(null);
    const [windowAvailable, setWindowAvailable] = useState(true);

    useEffect(() => {
        const buildDeliveryWindows = async () => {
            if (deliveryWindows) {
                setWindowText(currentWindowName);
                orderSuperFast &&
                    thread?.delivery_window &&
                    (await updateThreadDeliveryModel(
                        ThreadDeliveryModel.superfast,
                        ThreadDeliveryModel.sfWindowId
                    ));
            }
        };
        buildDeliveryWindows();
    }, [deliveryWindows, currentWindowName]);

    const [availableDeliveryOptions, setAvailableDeliveryOptions] = useState(null);

    useEffect(() => {
        if (availableDeliveryTimes) {
            const shouldHideOndemandOption =
                supportsOnlySuperfastMenu(supportsOndemandMenu, supportsScheduledMenu) &&
                !storeOpen;
            const showOndemandOption =
                !shouldHideOndemandOption &&
                ((supportsOndemandMenu && availableDeliveryTimes?.ONDEMAND != null && storeOpen) ||
                    isSuperFastOrder(currentDeliveryModel));
            const showScheduledOption =
                (supportsScheduledMenu && availableDeliveryTimes?.SCHEDULED != null) ||
                isScheduledOrder(currentDeliveryModel);

            setAvailableDeliveryOptions({
                superFast: {
                    available: showOndemandOption
                },
                scheduled: {
                    available: showScheduledOption
                }
            });
        }
    }, [availableDeliveryTimes, supportsOndemandMenu, supportsScheduledMenu]);

    useEffect(() => {
        const checkWindowAvailable = async () => {
            setNoWindowNotice('');

            if (deliveryWindows) {
                const windowExist =
                    deliveryWindows.find(
                        window =>
                            `${window.window_id}${window.deliver_by}${window.deliver_from}` ===
                            `${thread?.delivery_window?.window_id}${thread?.delivery_window?.deliver_by}${thread?.delivery_window?.deliver_from}`
                    ) || availableDeliveryOptions?.superFast.available;
                setWindowAvailable(windowExist);
                if (!windowExist) {
                    setWindowAvailable(false);
                    setNoWindowNotice(t('message.windowNotAvailable'));
                    availableDeliveryOptions?.superFast.available &&
                        updateThreadDeliveryModel(
                            ThreadDeliveryModel.superfast,
                            SuperfastWindow.window_id
                        );
                }
            }
        };
        checkWindowAvailable();
    }, [deliveryWindows]);

    useEffect(() => {
        const htmlElm = document.querySelector('html');
        htmlElm.style.overflow = showScWindowModal ? 'hidden' : 'auto';
    }, [showScWindowModal]);

    const updateDeliveryModel = dm => {
        setCurrentDeliveryModel(dm);
        cacheDeliveryModel(dm);
    };

    const onSaveScWindow = async window => {
        const deliveryModel = window.window_id
            ? ThreadDeliveryModel.scheduled
            : ThreadDeliveryModel.superfast;
        try {
            setWindowAvailable(true);
            setNoWindowNotice('');
            setSavingWindow(true);
            const res = await updateThreadDeliveryModel(deliveryModel, window);
            deliveryModel === ThreadDeliveryModel.scheduled
                ? updateDeliveryModel(DeliveryModel.Scheduled)
                : updateDeliveryModel(DeliveryModel.OnDemand);

            setWindowText(
                deliveryModel === ThreadDeliveryModel.scheduled
                    ? formatDeliveryWindowText(res.thread.delivery_window, !storeOpen, t)
                    : t(`scheduledModal.superFast`)
            );
        } finally {
            if (!isAuth) {
                setTimeout(() => {
                    setSavingWindow(false);
                }, 300);
            } else {
                setSavingWindow(false);
            }
        }
    };

    const showDownArrow =
        supportsBothDeliveryModels ||
        (availableDeliveryOptions?.scheduled?.available && deliveryWindows?.length > 1);

    const shouldShowScWindowModal = showScWindowModal && showDownArrow;

    return (
        <Box>
            {noWindowNotice && (
                <NoticeMessage
                    message={noWindowNotice}
                    type={NoticeMessageType.Warning}
                    marginTop={15}
                    marginBottom={15}
                />
            )}
            <Box marginBottom={50}>
                <Box
                    marginBottom={10}
                    fontSize={12}
                    fontWeight={500}
                    color={themeColors[theme].secondaryFontColor27}
                >
                    {windowAvailable
                        ? t('cart.deliveryDropdown.willDelivered')
                        : t('cart.deliveryDropdown.delivery')}
                </Box>
                <Row
                    width="100%"
                    minHeight={61}
                    padding="17px 10px 15px 12px"
                    borderRadius={8}
                    backgroundColor={themeColors[theme].inputBgColor}
                    cursor={showDownArrow && 'pointer'}
                    props={showDownArrow && { onClick: () => setShowScWindowModal(true) }}
                    alignItems="center"
                    justifyContent="space-between"
                    fontSize={16}
                    fontWeight="500"
                >
                    {windowAvailable ? (
                        <Row alignItems="center" justifyContent="space-between">
                            {savingWindow ? (
                                <Spinner noMargin />
                            ) : windowText ? (
                                <Icon
                                    name={orderSuperFast ? 'superFast' : 'scheduled'}
                                    width={29}
                                    height={29}
                                />
                            ) : null}
                            <Box
                                marginLeft={5}
                                fontSize={16}
                                fontWeight="bold"
                                color={themeColors[theme].tertiaryFontColor1}
                                opacity={savingWindow && 0.5}
                            >
                                {windowText}
                            </Box>
                        </Row>
                    ) : (
                        <Box color={themeColors[theme].secondaryFontColor27}>
                            {t('cart.deliveryDropdown.chooseDelivery')}
                        </Box>
                    )}

                    <Icon name="arrowBottom" opacity={!showDownArrow && 0.3} />
                </Row>
                {windowAvailable && (
                    <Box
                        marginTop={10}
                        fontSize={12}
                        fontWeight={500}
                        color={themeColors[theme].secondaryFontColor27}
                    >
                        {t('cart.deliveryDropdown.receiveEta')}
                    </Box>
                )}
            </Box>

            {shouldShowScWindowModal && !savingWindow && (
                <ScheduledWindowModal
                    sfAvailable={availableDeliveryOptions?.superFast.available}
                    scAvailable={availableDeliveryOptions?.scheduled.available}
                    supportsBothDeliveryModels={supportsBothDeliveryModels}
                    cartPage
                    deliveryWindows={deliveryWindows}
                    defaultWindow={currentWindow}
                    showModal={showScWindowModal}
                    onClose={() => {
                        setShowScWindowModal(false);
                    }}
                    onSaveScWindow={window => {
                        setShowScWindowModal(false);
                        onSaveScWindow(window);
                    }}
                    productNotAvailable={productNotAvailable}
                    currentDeliveryNotAvailable={currentDeliveryNotAvailable}
                    storeClosed={!storeOpen}
                />
            )}
        </Box>
    );
}
