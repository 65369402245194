import { removeItemFromCart } from '../utils/cacheDataManager';
import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function removeFromCart(user, item_id, productId) {
    if (user.authenticated) {
        const payload = {
            item_id,
            organization_id: user.organizationId,
            thread_id: user.threadId
        };
        return invokeLambda(Lambda.RemoveFromCart, payload);
    } else {
        return removeItemFromCart(item_id, user.organizationId);
    }
}
