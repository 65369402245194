import React from 'react';
import { Box } from 'jsxstyle';

export default function Caption({ children, ...rest }) {
    return (
        <Box fontSize={12} opacity={0.5} lineHeight={1.67} {...rest}>
            {children}
        </Box>
    );
}
