import React from 'react';
import { Inline } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

export default function Link({ children, goto, ...rest }) {
    const { theme, themeColors } = useTheme();

    return (
        <Inline
            whiteSpace="nowrap"
            textDecoration="underline"
            color={themeColors[theme].primaryFontColor1}
            cursor="pointer"
            props={goto && { onClick: goto }}
            {...rest}
        >
            {children}
        </Inline>
    );
}
