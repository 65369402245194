import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box, Col, Row } from 'jsxstyle';
import { useLottie } from 'lottie-react';

import useTheme from 'hooks/useTheme';

import { priceToStringRound } from 'api/utils';

import { useAppContext } from 'store';

import giftAnimation from 'assets/images/referral-gift.json';

export default function ReferFriendBanner({ discount }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const { state } = useAppContext();
    const options = {
        animationData: giftAnimation,
        loop: true,
        autoplay: true
    };

    const { View } = useLottie(options);

    const formattedDiscount = discount.cents
        ? priceToStringRound(discount.cents, state.currency)
        : `${discount.percentage}%`;
    return (
        <Row
            maxWidth={350}
            background={themeColors[theme].secondaryBgColor13}
            color={themeColors[theme].primaryFontColor2}
            borderRadius={10}
            margin={0}
            boxShadow={`0 8px 14px 0 ${themeColors[theme].boxShadowColor2}`}
            cursor="pointer"
            props={{ onClick: () => navigate('/refer') }}
        >
            <Box height="90px" width="90px">
                {View}
            </Box>
            <Col padding="30px 0" flex={1}>
                <Box fontSize={18} fontWeight={600}>
                    {t('referFriend.earn')} {formattedDiscount} {t('referFriend.discount')}
                </Box>
                <Box fontSize={14} opacity="0.8" textDecoration="underline">
                    {t('referFriend.bannerTitle')} &gt;
                </Box>
            </Col>
        </Row>
    );
}
