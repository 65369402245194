import React, { useEffect, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import Sticky from 'react-stickynode';
import { useQueryParams } from '@patched/hookrouter';
import DeliveryModel from 'constants/DeliveryModel';
import { Box, Row } from 'jsxstyle';

import usePrevious from 'hooks/custom/usePrevious';
import useCustomerData from 'hooks/useCustomerData';
import useDeliveryModels from 'hooks/useDeliveryModels';
import usePopupManager from 'hooks/usePopupManager';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';
import useWindows from 'hooks/useWindows';

import {
    cacheDeliveryModel,
    clearCachedSelectedWindow,
    getAnotherDeliveryModel,
    getCachedPromoCode,
    getCurrentDeliveryModel,
    getHidePromotionBar,
    getThreadDeliveryModel,
    getZonePreferableDeliveryModel,
    isHebrew,
    isScheduledOrder,
    isSuperFastOrder,
    isThreadSubmitted,
    supportsBothMenus,
    supportsOnlyScheduledMenu,
    supportsOnlySuperfastMenu,
    timestamp
} from 'api/utils';

import { useAppContext } from 'store';

import { countProductsInCart } from 'utils/cartUtils';

import DeliveryInfo from 'components/DeliveryInfo';
import DeliveryModelNotification from 'components/DeliveryModelNotification';
import Image from 'components/Image';
import InTransitNotice from 'components/InTransitNotice';
import Loading from 'components/Loading';
import DeliveryTypeModal from 'components/Modals/DeliveryTypeModal';
import ProductAvailabilityModal from 'components/Modals/ProductAvailabilityModal';
import NavBar from 'components/Navbar';
import PromotionBar from 'components/PromotionBar';
import SearchQuery from 'components/SearchQuery';
import Wrapper from 'components/Wrapper';

import { ThreadDeliveryModel } from '../api/constants';
import useAvailableProductsCount from '../hooks/useAvailableProductsCount';
import CategoryCarousel from './CategoryCarousel';

import 'react-toggle/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function CatalogLayout({
    pageTitle,
    children,
    onCategorySelected,
    orderInTransit = false,
    resetCategoriesAfterLoad = false,
    zone,
    catalogProductCount,
    selectedCategory,
    setSelectedCategory,
    promotionRelay,
    applyPromoCode,
    showPromoAppliedAlert,
    totalFiltersCount,
    setTotalFiltersCount,
    selectedSortType,
    setSelectedSortType,
    filterComponent,
    setFilterComponent,
    clearFilters,
    categoriesProductEmpty
}) {
    const { theme, themeColors } = useTheme();

    const [params, setParams] = useQueryParams();
    const { data: mappedData, store } = useCustomerData();

    const { user, thread, cart } = mappedData || {};
    const [userData, setUserData] = useState(user);
    const [threadData, setThreadData] = useState(thread);
    const { data: storeSettings } = useStoreSettings();
    const { data: windows, updateThreadDeliveryModel } = useWindows();

    const [coverImagesLoaded, setCoverImagesLoaded] = useState(false);

    const [address, setAddress] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const {
        state: { currentDeliveryModel },
        appContextActions
    } = useAppContext();

    const setShowScWindowModal = show => appContextActions.scWindowModal[show ? 'open' : 'close']();
    const setCurrentDeliveryModel = model => appContextActions.deliveryModel.set(model);
    const setShowDeliveryTypeModal = show =>
        appContextActions.deliveryTypeModal[show ? 'open' : 'close']();

    const [coverImages, setCoverImages] = useState([]);

    const [hasCoverImages, setHasCoverImages] = useState(null);
    const [showProductAvailabilityModal, setShowProductAvailabilityModal] = useState(false);
    const [initialAddress, setInitialAddress] = useState();

    const [initialCatalogLoadedFlag, setInitialCatalogLoadedFlag] = useState();
    const [forceUpdateDeliveryModelFlag, setForceUpdateDeliveryModelFlag] = useState();

    const [showPromotionBar, setShowPromotionBar] = useState(false);
    const { data: superFastCount } = useAvailableProductsCount(DeliveryModel.OnDemand);
    const { data: scheduledCount } = useAvailableProductsCount(DeliveryModel.Scheduled);
    const [availableProductsCount, setAvailableProductsCount] = useState();
    const [productCounts, setProductCounts] = useState({});
    const [searchCount, setSearchCount] = useState();
    const filtersApplied = params.filters || params.sort;

    const [saveBtnEnabled, setSaveBtnEnable] = useState(true);
    const [promoBarHeight, setPromoBarHeight] = useState();
    const orderIsSuperFast = isSuperFastOrder(currentDeliveryModel);
    const [settings, setSettings] = useState();
    const { supportsOndemandMenu, supportsScheduledMenu } = useDeliveryModels(zone, settings);
    const { delayPopup, delaySeconds } = usePopupManager();

    const storeOpen = settings?.isOpen;

    useEffect(() => {
        if (scheduledCount != null && superFastCount != null) {
            const sfCount = !storeOpen && supportsScheduledMenu ? 0 : superFastCount;

            const counts = { ...productCounts };

            counts.allSuperFast = sfCount;
            counts.allScheduled = scheduledCount;

            if (!params.filters && !params.query && !params.category) {
                counts.superFast = sfCount;
                counts.scheduled = scheduledCount;
            } else if (catalogProductCount != null) {
                if (orderIsSuperFast) {
                    counts.superFast =
                        !storeOpen && supportsScheduledMenu ? 0 : catalogProductCount;
                } else {
                    counts.scheduled = catalogProductCount;
                }
            }

            setProductCounts(counts);
        }
    }, [
        catalogProductCount,
        orderIsSuperFast,
        params.filters,
        params.query,
        params.category,
        scheduledCount,
        superFastCount,
        storeOpen,
        supportsScheduledMenu
    ]);

    useEffect(() => {
        if (storeSettings) {
            setCoverImages(storeSettings?.cover_images || []);
            setSettings(storeSettings);
        }
    }, [storeSettings?.cover_images]);

    useEffect(() => {
        if (user) {
            setUserData(user);
        }
    }, [user]);

    useEffect(() => {
        if (thread) {
            setThreadData(thread);
        }
    }, [thread]);

    useEffect(() => {
        const updateProductsCounts = () => {
            const sfCount = !storeOpen && supportsScheduledMenu ? 0 : superFastCount;

            const onDemandResultEmpty =
                supportsBothMenus(supportsScheduledMenu, supportsOndemandMenu) &&
                orderIsSuperFast &&
                !storeOpen;

            const availableCount = orderIsSuperFast ? sfCount : scheduledCount;

            params.query || filtersApplied
                ? setSearchCount(onDemandResultEmpty ? 0 : catalogProductCount)
                : params.category || params.page_description
                  ? setAvailableProductsCount(catalogProductCount || availableCount)
                  : setAvailableProductsCount(availableCount);
        };

        updateProductsCounts();
    }, [
        catalogProductCount,
        searchCount,
        onCategorySelected,
        superFastCount,
        scheduledCount,
        filtersApplied,
        params,
        orderIsSuperFast
    ]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const updateDeliveryModel = dm => {
        setCurrentDeliveryModel(dm);
        cacheDeliveryModel(dm);
    };

    const { t } = useTranslation();

    useEffect(() => {
        if (user && settings && ((hasCoverImages && coverImagesLoaded) || !hasCoverImages)) {
            setTimeout(() => {
                setIsLoading(false);
            }, 100);
        }
    }, [hasCoverImages, coverImagesLoaded, settings, user]);

    const prevDeliveryModel = usePrevious(currentDeliveryModel);

    useEffect(() => {
        function updateThreadDeliveryModel() {
            if (isScheduledOrder(currentDeliveryModel)) {
                const shouldUpdateThreadDeliverBy = thread && !thread?.deliver_by;
                if (shouldUpdateThreadDeliverBy) {
                    store.updateThreadDeliverBy(thread?.delivery_window?.deliver_by);
                }
                if (prevDeliveryModel === DeliveryModel.OnDemand) {
                    setSaveBtnEnable(true);
                }
            }
        }

        updateThreadDeliveryModel();
    }, [currentDeliveryModel]);

    useEffect(() => {
        if (
            settings &&
            thread &&
            zone?.zone_id &&
            !currentDeliveryModel &&
            supportsOndemandMenu != null &&
            supportsScheduledMenu != null &&
            superFastCount != null &&
            scheduledCount != null
        ) {
            const supportsDelivery =
                settings.supports_scheduled_menu || settings.supports_ondemand_menu;
            const supportsPickup = settings.supports_pickup;

            if (supportsDelivery) {
                //here we setting initial delivery model based on zone and organization settings
                //cached and thread delivery model
                const _deliveryModel = getCurrentDeliveryModel(
                    thread,
                    storeOpen,
                    supportsOndemandMenu,
                    supportsScheduledMenu
                );
                updateDeliveryModel(_deliveryModel);
            } else if (supportsPickup && !thread.pickup_destination) {
                //todo
            }
        }
    }, [
        currentDeliveryModel,
        storeOpen,
        supportsOndemandMenu,
        supportsScheduledMenu,
        superFastCount,
        scheduledCount,
        zone?.zone_id
    ]);

    useEffect(() => {
        if (
            thread &&
            currentDeliveryModel &&
            supportsOndemandMenu != null &&
            supportsScheduledMenu != null &&
            !forceUpdateDeliveryModelFlag
        ) {
            const _deliveryModel = getThreadDeliveryModel(
                thread,
                supportsOndemandMenu,
                supportsScheduledMenu
            );

            updateDeliveryModel(_deliveryModel);
        }
    }, [thread?.deliver_by, thread?.id]);

    useEffect(() => {
        async function updateDeliveryModelOnAddressChange() {
            setInitialAddress(address);
            let _deliveryModel = getZonePreferableDeliveryModel(
                supportsOndemandMenu,
                supportsScheduledMenu,
                storeOpen
            );

            if (_deliveryModel === DeliveryModel.Scheduled) {
                setShowScWindowModal(true);
            }

            if (
                _deliveryModel === DeliveryModel.OnDemand &&
                supportsOndemandMenu &&
                supportsScheduledMenu
            ) {
                if (!superFastCount === 0) {
                    _deliveryModel = DeliveryModel.Scheduled;
                }
            }

            updateDeliveryModel(_deliveryModel);

            if (_deliveryModel === DeliveryModel.Scheduled) {
                setTimeout(() => {
                    windows?.length > 1 && setShowScWindowModal(true);
                }, 1500);
            }
        }

        //should be called every time when zone supported delivery models
        //changed, or thread zone was changed (user changed address)
        if (
            supportsOndemandMenu != null &&
            supportsScheduledMenu != null &&
            address &&
            initialAddress &&
            address !== initialAddress
        ) {
            updateDeliveryModelOnAddressChange();
        }

        if (
            address &&
            !initialAddress &&
            supportsOndemandMenu != null &&
            supportsScheduledMenu != null
        ) {
            setInitialAddress(address);
        }
    }, [supportsOndemandMenu, supportsScheduledMenu]);

    useEffect(() => {
        if (userData && settings && window.google) {
            setHasCoverImages(!!coverImages.length);
            setAddress(userData.location?.address);
        }
    }, [userData?.location, settings, zone]);

    useEffect(() => {
        function handleSupportedDeliveryModelChanged() {
            const hasChanged =
                thread &&
                currentDeliveryModel &&
                supportsOndemandMenu != null &&
                supportsScheduledMenu != null &&
                ((currentDeliveryModel === DeliveryModel.OnDemand && !supportsOndemandMenu) ||
                    (currentDeliveryModel === DeliveryModel.Scheduled && !supportsScheduledMenu));

            if (hasChanged) {
                const _deliveryModel = getThreadDeliveryModel(
                    thread,
                    supportsOndemandMenu,
                    supportsScheduledMenu
                );

                updateDeliveryModel(_deliveryModel);
            }
        }

        handleSupportedDeliveryModelChanged();
    }, [thread, supportsOndemandMenu, supportsScheduledMenu]);

    useEffect(() => {
        function handleForceUpdateDeliveryModel() {
            const catalogLoadingFirstTime =
                (!initialCatalogLoadedFlag || localStorage.getItem('addressUpdated')) &&
                settings &&
                supportsOndemandMenu != null &&
                supportsScheduledMenu != null &&
                superFastCount != null &&
                scheduledCount != null;

            const shouldForceUpdateDeliveryModel =
                supportsOndemandMenu &&
                supportsScheduledMenu &&
                !params.query &&
                (!params.category || localStorage.getItem('addressUpdated')) &&
                (!storeOpen || superFastCount === 0);

            if (catalogLoadingFirstTime) {
                if (shouldForceUpdateDeliveryModel) {
                    // here we force updating delivery model for customer to Scheduled
                    // if store closed or no products available in super fast menu
                    setForceUpdateDeliveryModelFlag(true);
                    updateDeliveryModel(DeliveryModel.Scheduled);
                }

                localStorage.removeItem('addressUpdated');

                setInitialCatalogLoadedFlag(true);
            }

            const shouldShowDeliveryTypeModal =
                supportsOndemandMenu &&
                supportsScheduledMenu &&
                storeOpen &&
                superFastCount > 0 &&
                scheduledCount > 0 &&
                !sessionStorage.getItem('deliveryTypeModal');

            if (shouldShowDeliveryTypeModal) {
                setShowDeliveryTypeModal(true);
                sessionStorage.setItem('deliveryTypeModal', true);
            }
        }

        handleForceUpdateDeliveryModel();
    }, [superFastCount, scheduledCount, settings, supportsOndemandMenu, supportsScheduledMenu]);

    useEffect(() => {
        const shouldShowDeliveryTypeModal =
            supportsOndemandMenu &&
            supportsScheduledMenu &&
            storeOpen &&
            productCounts.allSuperFast > 0 &&
            productCounts.allScheduled > 0 &&
            !sessionStorage.getItem('deliveryTypeModal');

        if (shouldShowDeliveryTypeModal) {
            delayPopup(() => {
                setShowDeliveryTypeModal(true);
                sessionStorage.setItem('deliveryTypeModal', true);
            });
        }

        if (thread?.pickup_destination || address) {
            if (thread?.pickup_destination) {
                appContextActions.deliveryTypeModal.setLoading(false);
            } else {
                setTimeout(
                    () => appContextActions.deliveryTypeModal.setLoading(false),
                    3000 + delaySeconds
                );
            }
        }
    }, [
        productCounts?.allScheduled,
        productCounts?.allSuperFast,
        settings,
        supportsOndemandMenu,
        supportsScheduledMenu
    ]);

    const onAddressUpdate = async (value, organizationId) => {
        await store.updateAddress(value, organizationId);
        setInitialCatalogLoadedFlag(false);
    };

    const handleConfirmChange = async () => {
        //as page will be refreshed need to scroll to top for better experience
        scrollToTop();
        if (isSuperFastOrder(getAnotherDeliveryModel(currentDeliveryModel))) {
            await updateThreadDeliveryModel(
                ThreadDeliveryModel.superfast,
                ThreadDeliveryModel.sfWindowId
            );
        }
        setTimeout(() => {
            updateDeliveryModel(getAnotherDeliveryModel(currentDeliveryModel));
        }, 50);

        clearCachedSelectedWindow();
        setShowProductAvailabilityModal(false);
    };

    const handleDeclineChange = () => {
        setShowProductAvailabilityModal(false);
    };

    const smSize = 480;
    const sm = `screen and (max-width: ${smSize}px)`;
    const isSubmitted = thread && isThreadSubmitted(thread);
    const cartCount = !isSubmitted && cart?.items && countProductsInCart(cart);

    useEffect(() => {
        if ((thread && cart, currentDeliveryModel)) {
            const now = timestamp();
            const lastVisitTime = localStorage.getItem('last_visit_time');
            localStorage.setItem('last_visit_time', now);

            const remindOnReturnTime = 3 * 60 * 60;

            if (
                !orderInTransit &&
                isScheduledOrder(currentDeliveryModel) &&
                cartCount > 0 &&
                now - lastVisitTime > remindOnReturnTime
            ) {
                //show modal with current delivery model selected
                setShowScWindowModal(true);
            }
        }
    }, [orderInTransit, thread, cart, currentDeliveryModel]);

    useEffect(() => {
        const shouldShowPromotionBar =
            promotionRelay?.show_bar &&
            !orderInTransit &&
            !getCachedPromoCode() &&
            !getHidePromotionBar(promotionRelay?.promo_code);
        setShowPromotionBar(shouldShowPromotionBar);
    }, [promotionRelay, getCachedPromoCode()]);

    const onDeliveryModelChange = async (menu, callback) => {
        const isOndemand = menu === DeliveryModel.OnDemand;

        if (menu === currentDeliveryModel) {
            return null;
        }

        const hideItemsInCartPopup = sessionStorage.getItem('hideItemsInCartPopup');

        const shouldConfirmChange = !hideItemsInCartPopup && cartCount && currentDeliveryModel;

        if (shouldConfirmChange) {
            sessionStorage.setItem('hideItemsInCartPopup', true);
            setShowProductAvailabilityModal(true);
        } else {
            // scrollToTop();
            updateDeliveryModel(isOndemand ? DeliveryModel.OnDemand : DeliveryModel.Scheduled);
            if (isOndemand) {
                await updateThreadDeliveryModel(
                    ThreadDeliveryModel.superfast,
                    ThreadDeliveryModel.sfWindowId
                );
            } else {
                sessionStorage.removeItem('menu_kit');
                setSaveBtnEnable(true);
                if (windows.length > 1) {
                    setShowScWindowModal(true);
                }
            }
        }

        if (callback) {
            callback();
        }
    };

    const onDeliveryModelToggleChange = e => {
        const menu = e.target.checked ? DeliveryModel.OnDemand : DeliveryModel.Scheduled;
        onDeliveryModelChange(menu);
    };

    const noProductsAvailable =
        availableProductsCount === 0 ||
        (orderIsSuperFast && settings && !storeOpen && supportsScheduledMenu);

    const onlySuperFastSupported = supportsOnlySuperfastMenu(
        supportsOndemandMenu,
        supportsScheduledMenu
    );
    const onlyScheduledSupported = supportsOnlyScheduledMenu(
        supportsOndemandMenu,
        supportsScheduledMenu
    );
    const bothModelsSupported = supportsBothMenus(supportsOndemandMenu, supportsScheduledMenu);

    const refCode = localStorage.getItem('ref');

    return (
        <Box
            background={themeColors[theme].secondaryBgColor1}
            minHeight={'100vh'}
            maxHeight={isLoading ? '100vh' : null}
        >
            {isLoading && (
                <Wrapper noPadding position="relative">
                    <Loading title={t('categories.loading')} />
                </Wrapper>
            )}

            <Box>
                {showPromotionBar && (
                    <PromotionBar
                        promotionRelay={promotionRelay}
                        showPromotionBar={showPromotionBar}
                        setShowPromotionBar={setShowPromotionBar}
                        applyPromoCode={applyPromoCode}
                        setPromoBarHeight={setPromoBarHeight}
                        showPromoAppliedAlert={showPromoAppliedAlert}
                        isAuth={user?.authenticated}
                    />
                )}
                <NavBar
                    mediaQueries={{ sm }}
                    pageTitle={pageTitle}
                    padding={showPromotionBar ? '0px 22px' : '0px 22px'}
                    smPadding={showPromotionBar ? '0px 32px' : '0px 32px'}
                    marginTop={showPromotionBar ? 75 : 20}
                    smMarginTop={showPromotionBar ? 20 + promoBarHeight : 20}
                    zIndex={2}
                    top={0}
                    maxWidth={1015}
                    margin="0 auto"
                    position={hasCoverImages ? 'absolute' : 'relative'}
                    color={
                        hasCoverImages
                            ? themeColors[theme].whitefontColor
                            : themeColors[theme].primaryFontColor1
                    }
                    left={hasCoverImages ? '50%' : 0}
                    transform={hasCoverImages ? 'translate(-50%, 0)' : 'none'}
                    promoCode={refCode}
                    setSelectedCategory={setSelectedCategory}
                    categoriesProductEmpty={categoriesProductEmpty}
                />

                <CoverImagesSlider
                    coverImages={coverImages}
                    smallSize={smSize}
                    hasCoverImages={hasCoverImages}
                    setImagesLoaded={setCoverImagesLoaded}
                    isLoading={isLoading}
                    showPromotionBar={showPromotionBar}
                    promoBarHeight={promoBarHeight}
                />
                <Wrapper noPadding position="relative" top={hasCoverImages ? 0 : 40}>
                    <Box
                        zIndex={100}
                        backgroundColor={themeColors[theme].secondaryBgColor1}
                        // paddingTop={15}
                        paddingBottom={20}
                        position="relative"
                        top={hasCoverImages ? -85 : -45}
                    >
                        <OptionsBar
                            loading={isLoading}
                            productCounts={productCounts}
                            hasCoverImages={hasCoverImages}
                            address={address}
                            zone={zone}
                            cart={cart}
                            user={userData}
                            storeSettings={settings}
                            onlySuperFastSupported={onlySuperFastSupported}
                            isSubmitted={isSubmitted}
                            store={store}
                            orderInTransit={orderInTransit}
                            sm={sm}
                            orderIsSuperFast={orderIsSuperFast}
                            noProductsAvailable={noProductsAvailable}
                            availableProductsCount={availableProductsCount}
                            superFastCount={superFastCount}
                            scheduledCount={scheduledCount}
                            searchCount={searchCount}
                            bothModelsSupported={bothModelsSupported}
                            onDeliveryModelToggleChange={onDeliveryModelToggleChange}
                            onDeliveryModelChange={onDeliveryModelChange}
                            onlyScheduledSupported={onlyScheduledSupported}
                            params={params}
                            setParams={setParams}
                            filtersApplied={filtersApplied}
                            onCategorySelected={onCategorySelected}
                            resetCategoriesAfterLoad={resetCategoriesAfterLoad}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            saveBtnEnabled={saveBtnEnabled}
                            setSaveBtnEnable={setSaveBtnEnable}
                            thread={threadData}
                            theme={theme}
                            themeColors={themeColors}
                            totalFiltersCount={totalFiltersCount}
                            setTotalFiltersCount={setTotalFiltersCount}
                            selectedSortType={selectedSortType}
                            setSelectedSortType={setSelectedSortType}
                            filterComponent={filterComponent}
                            setFilterComponent={setFilterComponent}
                            clearFilters={clearFilters}
                            updatePickupLocation={store.updatePickupLocation}
                            showProductAvailabilityModal={showProductAvailabilityModal}
                            onAddressUpdate={onAddressUpdate}
                            categoriesProductEmpty={categoriesProductEmpty}
                        />
                        <Box overflowX="hidden">{children}</Box>
                    </Box>
                </Wrapper>
                <ProductAvailabilityModal
                    showModal={showProductAvailabilityModal}
                    onClose={() => {
                        setShowProductAvailabilityModal(false);
                    }}
                    onConfirm={handleConfirmChange}
                    onCancel={handleDeclineChange}
                />
            </Box>
        </Box>
    );
}

export default React.memo(CatalogLayout);

const TopSlider = ({
    coverImages,
    smallSize,
    setImagesLoaded,
    isLoading,
    showPromotionBar,
    promoBarHeight
}) => {
    const [deviceWidth] = useState(isAndroid ? window.screen.width : window.innerWidth);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1100,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        easing: 'ease-in-out',
        dotsClass: 'cover-images-slider-dots',
        lazyLoad: 'progressive',
        onLazyLoad: slides => {
            if (slides.length === coverImages.length) {
                setImagesLoaded(true);
            }
        }
    };

    const sm = `screen and (max-width: ${smallSize}px)`;
    const mobileHeight = Math.round((deviceWidth * 9) / 16);

    return (
        <Box paddingTop={10}>
            {coverImages && (
                <Box
                    mediaQueries={{ sm }}
                    maxWidth={1150}
                    margin="0 auto"
                    smMargin="0 10px"
                    marginBottom={70}
                    smMarginBottom={70}
                    height={400}
                    smHeight={mobileHeight}
                    borderRadius={10}
                    zIndex={0}
                    overflow="hidden"
                    position="relative"
                >
                    <Slider {...sliderSettings}>
                        {coverImages.map(({ url, refer_to, open_style }, index) => {
                            if (index < 5) {
                                return (
                                    <Box
                                        key={url}
                                        component="a"
                                        props={{
                                            href: refer_to ? refer_to : null,
                                            target: open_style === 0 && '_blank'
                                        }}
                                        cursor={refer_to ? 'pointer' : 'unset'}
                                    >
                                        <Image
                                            mediaQueries={{ sm }}
                                            key={`cover_${index}`}
                                            src={url}
                                            height={400}
                                            smHeight={mobileHeight}
                                            minHeight={400}
                                            smMinHeight={mobileHeight}
                                            width="100%"
                                            objectFit="cover"
                                        ></Image>
                                    </Box>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </Slider>
                </Box>
            )}

            <Box
                mediaQueries={{ sm }}
                position="absolute"
                top={showPromotionBar ? 10 + promoBarHeight : 10}
                left={0}
                right={0}
                smLeft={10}
                smRight={10}
                margin="0 auto"
                height={76}
                maxWidth={1150}
                minHeight={76}
                borderRadius={10}
                background="linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))"
                opacity={isLoading ? 0 : 1}
            />
        </Box>
    );
};

const CoverImagesSlider = React.memo(TopSlider);

const OptionsBar = ({
    address,
    zone,
    user,
    storeSettings,
    onlySuperFastSupported,
    isSubmitted,
    store,
    orderInTransit,
    sm,
    orderIsSuperFast,
    updatePickupLocation,
    bothModelsSupported,
    onDeliveryModelChange,
    onlyScheduledSupported,
    params,
    setParams,
    filtersApplied,
    cart,
    loading,
    searchCount,
    noProductsAvailable,
    onCategorySelected,
    resetCategoriesAfterLoad,
    selectedCategory,
    setSelectedCategory,
    thread,
    theme,
    themeColors,
    totalFiltersCount,
    setTotalFiltersCount,
    selectedSortType,
    setSelectedSortType,
    filterComponent,
    setFilterComponent,
    clearFilters,
    productCounts,
    showProductAvailabilityModal,
    onAddressUpdate,
    categoriesProductEmpty
}) => {
    const { t } = useTranslation();
    const [categories, setCategories] = useState(storeSettings?.categories);

    useEffect(() => {
        if (storeSettings?.categories) {
            setCategories(storeSettings?.categories);
        }
    }, [storeSettings]);

    const {
        state: { currentDeliveryModel, showDeliveryTypeModal },
        appContextActions
    } = useAppContext();

    const setShowDeliveryTypeModal = show =>
        appContextActions.deliveryTypeModal[show ? 'open' : 'close']();
    const storeClosed = !storeSettings?.isOpen;
    const onlySuperFastAndStoreClosed = storeSettings && onlySuperFastSupported && storeClosed;

    const resultText =
        searchCount >= 0 &&
        (isHebrew()
            ? `${
                  searchCount === 1 ? t('catalogPage.result') : t('catalogPage.results')
              } \u200e${searchCount}`
            : `${searchCount} ${
                  searchCount === 1 ? t('catalogPage.result') : t('catalogPage.results')
              }`);

    const filterText = `${totalFiltersCount} ${
        totalFiltersCount === 1
            ? t('catalogPage.filters.filterApplied')
            : t('catalogPage.filters.filtersApplied')
    }`;

    const onClearFilters = () => {
        if (filtersApplied) {
            clearFilters();
            setTotalFiltersCount(0);
        }
        setParams({ ...params, filters: undefined, sort: undefined }, true);
    };

    const onClearSearch = () => {
        setParams({ ...params, query: undefined }, true);
    };
    const supportsPickup = storeSettings?.supports_pickup;
    const supportsDelivery =
        storeSettings?.supports_ondemand_menu || storeSettings?.supports_scheduled_menu;

    const isPickup = thread?.pickup_destination?.location || (!supportsDelivery && supportsPickup);

    const noProductsAvailableInAllMenus =
        productCounts.allSuperFast < 1 && productCounts.allScheduled < 1;
    return (
        <Box>
            {user ? (
                <Box>
                    {!loading && (
                        <DeliveryInfo
                            address={address}
                            isInvalidZone={
                                (address && address.length && zone && !zone.zone_id) ||
                                user.error === 'InvalidZone'
                            }
                            isSubmitted={isSubmitted}
                            organizationId={user.organizationId}
                            updateAddress={onAddressUpdate}
                            validateAddress={store.validateAddress}
                            cart={cart}
                            deliveryDefaults={storeSettings?.delivery_defaults}
                            thread={thread}
                            timeZone={storeSettings?.timeZone}
                            isAuth={user?.authenticated}
                            supportPickup={supportsPickup}
                            supportsDelivery={supportsDelivery}
                            requiredAge={storeSettings?.requires_age_verification}
                            totalFiltersCount={totalFiltersCount}
                            setTotalFiltersCount={setTotalFiltersCount}
                            selectedSortType={selectedSortType}
                            setSelectedSortType={setSelectedSortType}
                            filterComponent={filterComponent}
                            setFilterComponent={setFilterComponent}
                            clearFilters={clearFilters}
                            filtersApplied={filtersApplied}
                            setSelectedCategory={setSelectedCategory}
                            updatePickupLocation={updatePickupLocation}
                        />
                    )}
                </Box>
            ) : null}

            {!isPickup &&
                // !onlySuperFastAndStoreClosed &&
                currentDeliveryModel &&
                (productCounts.allSuperFast >= 1 || productCounts.allScheduled >= 1) && (
                    <DeliveryModelNotification
                        bothModelsSupported={bothModelsSupported}
                        onlySuperFastSupported={onlySuperFastSupported}
                        onlyScheduledSupported={onlyScheduledSupported}
                        productCounts={productCounts}
                        orderIsSuperFast={orderIsSuperFast}
                        orderInTransit={orderInTransit}
                        onDeliveryModelChange={onDeliveryModelChange}
                        currentDeliveryModel={currentDeliveryModel}
                        onlySuperFastAndStoreClosed={onlySuperFastAndStoreClosed}
                        noProductsAvailable={noProductsAvailableInAllMenus}
                        showProductAvailabilityModal={showProductAvailabilityModal}
                        storeClosed={storeClosed}
                        whenStoreOpen={storeSettings?.whenOpen}
                    />
                )}

            {categories ? (
                <Sticky innerZ={10000} top={72} releasedClass="catalog-sticky">
                    <Box
                        // height={75}
                        // className={
                        //     theme === THEME.DARK ? 'sticky-options-raw-dark' : 'sticky-options-raw'
                        // }
                        boxShadow="inset 10em 10em rgb(247 247 247 / 90%)"
                    >
                        {!noProductsAvailable && !categoriesProductEmpty && (
                            <CategoryCarousel
                                onCategorySelected={selectedCategory => {
                                    onCategorySelected(selectedCategory);
                                }}
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                                resetCategoryAfterLoad={resetCategoriesAfterLoad}
                                categoriesList={categories}
                            />
                        )}
                    </Box>
                </Sticky>
            ) : null}
            {orderInTransit && (
                <InTransitNotice
                    margin="20px 15px 15px 15px"
                    pickupOrder={thread?.pickup_destination}
                    readyForPickup={!!thread?.pickup_ready_time}
                />
            )}

            {showDeliveryTypeModal && (
                <DeliveryTypeModal
                    showModal={true}
                    showDeliveryTypeModal={showDeliveryTypeModal}
                    setShowDeliveryTypeModal={setShowDeliveryTypeModal}
                    onDeliveryModelChange={menu => onDeliveryModelChange(menu)}
                    eta={storeSettings?.eta_estimation}
                    onClose={() => {
                        setShowDeliveryTypeModal(false);
                    }}
                />
            )}

            {
                <Row
                    mediaQueries={{ sm }}
                    // paddingTop={20}
                    smPaddingTop={0}
                    paddingLeft={15}
                    paddingBottom={20}
                >
                    {params.filters && (
                        <SearchQuery text={filterText} onClear={onClearFilters} filter />
                    )}
                    {params.filters && <Box marginRight={10}> </Box>}
                    {params.query && (
                        <Row alignItems="center">
                            <SearchQuery text={params.query} onClear={onClearSearch} searchIcon />
                        </Row>
                    )}
                    {(params.query || params.filters) && (
                        <Row
                            alignItems="center"
                            marginLeft={10}
                            color={themeColors[theme].secondaryBgColor10}
                            fontWeight={500}
                        >
                            {resultText}
                        </Row>
                    )}
                </Row>
            }
        </Box>
    );
};
