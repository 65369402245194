import React from 'react';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from '../api/constants';
import Spinner from '../components/Spinner';

export default function Loading({ title = 'Loading...', center = null }) {
    const { theme, themeColors } = useTheme();
    return (
        <Box padding={15}>
            {title}
            <Spinner center={center} white={theme === THEME.DARK} />
        </Box>
    );
}
