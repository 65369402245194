/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import {
    cacheDoNotAutoApplyPromo,
    cachePromoCode,
    clearCachedPromoCode,
    clearHidePromotionBar,
    currentTime,
    getCachedPromoCode,
    isThreadSubmitted,
    validateInput
} from '../api/utils';
import failedIcon from '../assets/images/promo-code-faild.svg';
import successIcon from '../assets/images/promo-code-sucess.svg';
import alertIcon from '../assets/images/small-alert-warning.svg';
import Button from '../components/Button';
import Spinner from '../components/Spinner';

export default function PromoCodeInput({
    isInvalidPromo,
    referralProgram,
    setShowPromoNotification,
    currentDeliveryModel,
    user,
    thread,
    cart,
    store,
    applyPromocode,
    updateState,
    setUpdateState,
    promoSubmitted,
    UpdateStates,
    setPromoSubmitted,
    promoInputOnFocus,
    setPromoInputOnFocus,
    alertError,
    buttonState,
    setButtonState,
    ButtonStates,
    error,
    setError
}) {
    const { theme, themeColors } = useTheme();

    const [inputValue, setInputValue] = useState(thread?.promoCode || '');

    const [isMouseDown, setIsMouseDown] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);

    const { t } = useTranslation();
    const savedPromoCode = getCachedPromoCode() || thread?.promoCode;

    const clearInput = () => {
        setInputValue('');
        // setButtonState(ButtonStates.Hidden);
        cacheDoNotAutoApplyPromo(getCachedPromoCode());
        setPromoInputOnFocus(true);
        setPromoSubmitted(false);
        inputEl.current.focus();
        setError(null);
        clearCachedPromoCode();
        clearHidePromotionBar();
        setShowPromoNotification(false);
        setUpdateState(UpdateStates.Default);
        store.applyPromocode('');
    };

    const onChange = e => {
        setError(null);
        if (isThreadSubmitted(thread)) return null;

        setButtonState(ButtonStates.Visible);
        setUpdateState(UpdateStates.Default);
        validateInput('promoCode', e.target.value.trim()) &&
            setInputValue(e.target.value.trim().toUpperCase());
    };

    const onSubmit = async updatedByCustomer => {
        if (isThreadSubmitted(thread)) return null;

        if (isInvalidPromo && !updatedByCustomer) return null;

        if (buttonState !== ButtonStates.Disabled) {
            setUpdateState(UpdateStates.Updating);

            const value = updatedByCustomer ? inputValue : savedPromoCode;

            if (value && value.length > 0) {
                cachePromoCode(value);
            } else {
                clearCachedPromoCode();
            }

            if (user.authenticated) {
                await applyPromocode(value);
            } else if (updatedByCustomer) {
                navigate('/login');
            } else {
                setUpdateState(UpdateStates.Default);
            }
        }
    };

    const PromoExpiredTime = cart?.promo_code ? cart?.promo_code?.expire_time : currentTime();
    const isPromoExpired = PromoExpiredTime < currentTime();

    useEffect(() => {
        const applyPromoFromCache = () => {
            setTimeout(() => setSubmitClicked(false), 500);
            if (
                (thread &&
                    !isThreadSubmitted(thread) &&
                    savedPromoCode?.length &&
                    !submitClicked) ||
                isPromoExpired
            ) {
                //when customer entered promo before login,
                //promo was saved in cache and now
                //it will be applied to thread
                setInputValue(savedPromoCode);
                console.log('applyPromoFromCache');
                onSubmit(false);
            }
        };

        setTimeout(() => applyPromoFromCache(), 300);
    }, [savedPromoCode, thread?.status, isPromoExpired, currentDeliveryModel]);

    const inputEl = useRef(null);

    return (
        <Box padding="30px 0 50px">
            <Box
                color={themeColors[theme].primaryFontColor1}
                fontSize={16}
                fontWeight={700}
                marginBottom={5}
            >
                {referralProgram
                    ? t('cart.promoCode.titleWithReferral')
                    : t('cart.promoCode.title')}
            </Box>
            <Box fontSize={12} marginBottom={15} color={themeColors[theme].secondaryFontColor2}>
                {t('cart.promoCode.subTitle')}
            </Box>
            <Box position="relative">
                <Box
                    component="input"
                    background={themeColors[theme].inputBgColor}
                    color={themeColors[theme].inputTextColor}
                    placeholderColor={themeColors[theme].placeHolderColor3}
                    fontWeight={600}
                    fontSize={16}
                    width="100%"
                    padding="15px 20px"
                    border={
                        promoInputOnFocus
                            ? `2px solid ${themeColors[theme].tertiaryBorderColor}`
                            : 'none'
                    }
                    borderRadius={8}
                    marginBottom={15}
                    props={{
                        placeholder: t('cart.promoCode.placeholder'),
                        onChange,
                        value: inputValue,
                        onFocus: () => {
                            setButtonState(ButtonStates.Visible);
                            setPromoInputOnFocus(true);
                        },
                        onBlur: () => {
                            setTimeout(() => {
                                if (isMouseDown) {
                                    inputEl.current && inputEl.current.focus();
                                    setIsMouseDown(false);
                                } else {
                                    setButtonState(ButtonStates.Hidden);
                                    setPromoInputOnFocus(false);
                                }
                            }, 300);
                        },
                        ref: inputEl
                    }}
                />

                {updateState !== UpdateStates.Default && (
                    <Box position="absolute" top={15} right={10}>
                        {updateState === UpdateStates.Updating ? (
                            <Spinner margin={0} />
                        ) : (
                            <Box
                                component="img"
                                props={{
                                    src:
                                        updateState === UpdateStates.Success
                                            ? successIcon
                                            : updateState === UpdateStates.Alert
                                              ? alertIcon
                                              : failedIcon,
                                    alt: '',
                                    onClick: updateState === UpdateStates.Failed ? clearInput : null
                                }}
                            />
                        )}
                    </Box>
                )}
                {error && (
                    <Box
                        fontSize={12}
                        color={
                            alertError
                                ? themeColors[theme].secondaryFontColor27
                                : themeColors[theme].errFontColor
                        }
                        marginBottom={18}
                    >
                        {error}
                    </Box>
                )}
            </Box>

            {buttonState !== ButtonStates.Hidden && updateState !== UpdateStates.Updating && (
                <Row justifyContent="flex-end">
                    <Button
                        small
                        fontSize={12}
                        padding="5px 10px"
                        minWidth={80}
                        disabled={!inputValue}
                        backgroundColor={
                            !inputValue
                                ? themeColors[theme].secondaryBgColor4
                                : themeColors[theme].secondaryBgColor5
                        }
                        color={
                            !inputValue
                                ? themeColors[theme].secondaryFontColor3
                                : themeColors[theme].secondaryFontColor4
                        }
                        border="none"
                        noHover={!inputValue}
                        marginRight={15}
                        props={{
                            onClick: () => {
                                setTimeout(() => {
                                    clearInput();
                                    setInputValue('');
                                    setPromoInputOnFocus(true);
                                    inputEl.current.focus();
                                }, 200);
                            },
                            onMouseDown: () => {
                                setIsMouseDown(true);
                            },
                            disabled: !inputValue
                        }}
                    >
                        {t('cart.promoCode.clear')}
                    </Button>

                    {(!promoSubmitted || inputValue !== savedPromoCode) && (
                        <Button
                            small
                            fontSize={12}
                            padding="5px 10px"
                            minWidth={80}
                            disabled={!inputValue}
                            props={{
                                onClick: () => {
                                    setSubmitClicked(true);
                                    onSubmit(true);
                                },
                                onMouseDown: () => {
                                    user.authenticated && setIsMouseDown(true);
                                },
                                disabled: !inputValue
                            }}
                        >
                            {t('cart.promoCode.submit')}
                        </Button>
                    )}
                </Row>
            )}
        </Box>
    );
}
