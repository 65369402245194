import React, { useEffect, useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { withSize } from 'react-sizeme';
import { Box } from 'jsxstyle';

import { isSuperFastOrder } from 'api/utils';

import { useAppContext } from 'store';

import Loading from 'components/Loading';
import NoProductsAvailable from 'components/Menu/NoProductsAvailable';

import ProductCell from './ProductCell';

function ProductGrid({
    products,
    onSelect,
    size,
    priceIncludesTax,
    loading,
    storeOpen,
    supportsOndemandMenu,
    supportsScheduledMenu,
    whenOpen,
    cart,
    isSubmitted,
    isPickup,
    noOrderType,
    clearAppliedFilters,
    imageContainerFit
}) {
    const { t } = useTranslation();
    const grid = useRef(null);

    const { state } = useAppContext();
    const { currentDeliveryModel } = state;

    useEffect(() => {
        if (grid.current) {
            grid.current.updateLayout();
        }
    }, [size, grid]);

    const lg = 'screen and (max-width: 1000px)';
    const md = 'screen and (max-width: 800px)';
    const sm = 'screen and (max-width: 600px)';

    const noProductsAvailable =
        (!isPickup &&
            !storeOpen &&
            supportsScheduledMenu &&
            isSuperFastOrder(currentDeliveryModel)) ||
        (products && !products.length);

    return (
        <Box className="productGrid" marginTop={`${isMobileOnly ? '0px' : '5px'}`}>
            {products ? (
                <Box>
                    {noProductsAvailable ? (
                        <NoProductsAvailable
                            supportsOndemandMenu={supportsOndemandMenu}
                            supportsScheduledMenu={supportsScheduledMenu}
                            nextOpenTime={whenOpen}
                            storeOpen={storeOpen}
                            cart={cart}
                            isSubmitted={isSubmitted}
                            isPickup={isPickup}
                            clearAppliedFilters={clearAppliedFilters}
                        />
                    ) : (
                        <Box
                            mediaQueries={{ lg, md, sm }}
                            display="grid"
                            gridTemplateColumns="repeat(5, 1fr)"
                            lgGridTemplateColumns="repeat(4, 1fr)"
                            mdGridTemplateColumns="repeat(3, 1fr)"
                            smGridTemplateColumns="repeat(2, 1fr)"
                            columnGap={2}
                            padding="0 20px"
                        >
                            {products.map(product => {
                                const { sku } = product;
                                const onClick = () => onSelect(sku);
                                return (
                                    <Box marginBottom="2px" key={sku}>
                                        <ProductCell
                                            onClick={onClick}
                                            product={product}
                                            priceIncludesTax={priceIncludesTax}
                                            isOrderInTransit={isSubmitted}
                                            noOrderType={noOrderType}
                                            imageContainerFit={imageContainerFit}
                                        />
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Box>
            ) : null}
            {!noProductsAvailable && loading ? (
                <Loading title={t('categories.loadingProduct')} />
            ) : null}
        </Box>
    );
}

export default withSize()(ProductGrid);
