import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Col, Row } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';
import { priceToString } from 'api/utils';

import { useAppContext } from 'store';

import { Caption } from 'components/shared';
import Spinner from 'components/Spinner';

import Button from './ButtonGrey';
import Hint from './Hint';

export default function NeedChange({ change, updateChange, hideBtn, pinTerminalFee, alignRight }) {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();
    const { state } = useAppContext();
    const { data: mappedData, store } = useCustomerData();

    const { thread } = mappedData || {};
    const formatPrice = price => {
        return `${(price / 100).toFixed(2)}`;
    };
    const getPriceInCents = value => {
        return Math.round(parseFloat(value) * 100);
    };

    const defaultChange = formatPrice(change || thread.change || 0);

    const [newChange, setNewChange] = useState('');
    const [updateDisabled, setUpdateDisabled] = useState(true);
    const [updatingChange, setUpdatingChange] = useState(false);
    const [updateChangeButtonVisible, setUpdateChangeButtonVisible] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(undefined);

    const isValid = value => {
        return value.match(/^(\d{1,2})(\.\d{0,2})?$/);
    };

    const onChange = e => {
        const longValue = e.target.value.substr(0, 5);
        const shortValue = e.target.value.substr(0, 4);
        const value =
            longValue === ''
                ? ''
                : isValid(longValue)
                  ? longValue
                  : isValid(shortValue)
                    ? shortValue
                    : newChange;
        setNewChange(value);
        if (updateChange) {
            updateChange(getPriceInCents(value));
        }

        setUpdateDisabled(getPriceInCents(value) === thread.change);
    };

    return (
        <Box>
            {pinTerminalFee && (
                <Col fontWeight={700} marginBottom={20}>
                    <Box
                        fontSize={16}
                        color={themeColors[theme].primaryFontColor1}
                        paddingBottom={10}
                    >
                        {t('cart.payWith')}
                    </Box>
                    <Box color={themeColors[theme].secondaryFontColor2} fontSize={12}>
                        {t('cart.haveCashOrDebit', { fee_amount: pinTerminalFee })}
                    </Box>
                </Col>
            )}
            <Row
                position="relative"
                alignItems="baseline"
                justifyContent="space-between"
                width="100%"
            >
                <Row alignItems="center">
                    <Box
                        fontSize={16}
                        fontWeight={700}
                        color={themeColors[theme].primaryFontColor1}
                        paddingBottom={10}
                    >
                        {t('cart.needChange.title')}
                    </Box>
                    <Hint>{t('cart.needChange.desp')}</Hint>
                </Row>
                <Box position="relative">
                    <Box
                        position="absolute"
                        height={40}
                        left={newChange ? 83 : alignRight ? 68 : 10}
                        top={0}
                        font="500 16px / 44px Heebo, sans-serif"
                        color={
                            newChange.length
                                ? themeColors[theme].secondaryFontColor17
                                : themeColors[theme].secondaryFontColor18
                        }
                    >
                        {priceToString(0, state.currency).substr(0, 1)}
                    </Box>
                    <Box
                        component="input"
                        color={theme == THEME.DARK && themeColors[theme].whitefontColor}
                        placeholderColor={themeColors[theme].secondaryFontColor17}
                        border="none"
                        boxShadow="none"
                        padding="9px 9px 9px 25px"
                        width={120}
                        height={44}
                        borderRadius={8}
                        font="500 16px / 1.5 Heebo, sans-serif"
                        background={themeColors[theme].cashInputBgColor}
                        textAlign={alignRight && 'right'}
                        props={{
                            placeholder: defaultChange,
                            type: 'number',
                            inputMode: 'decimal',
                            onChange: onChange,
                            value: newChange,
                            onFocus: () => {
                                setUpdateChangeButtonVisible(true);
                                setNewChange('');
                            },
                            onBlur: () => {
                                // need to put delay in order to catch the click event
                                // of the update change button
                                setTimeout(() => {
                                    setUpdateChangeButtonVisible(false);
                                }, 100);
                            }
                        }}
                    ></Box>
                </Box>
            </Row>
            {!hideBtn && (
                <Row justifyContent="flex-end" paddingTop={15}>
                    {updatingChange ? (
                        <Row justifyContent="center" width={124}>
                            <Spinner margin={0} />
                        </Row>
                    ) : (
                        (updateChangeButtonVisible || !updateDisabled) && (
                            <Button
                                padding="10px 20px"
                                // visibility={updateChangeButtonVisible ? 'display' : 'hidden'}
                                opacity={updateDisabled ? 0.3 : 1}
                                onClick={async () => {
                                    try {
                                        setUpdatingChange(true);
                                        await store.updateChange(
                                            getPriceInCents(newChange ? newChange : 0)
                                        );
                                        setUpdateChangeButtonVisible(false);
                                        setUpdateDisabled(true);
                                        setUpdateStatus(t('cart.needChange.updated'));
                                    } catch (_) {
                                        setUpdateStatus(t('cart.needChange.failed'));
                                    } finally {
                                        setUpdatingChange(false);
                                        // hide status message after short delay
                                        setTimeout(() => {
                                            setUpdateStatus(undefined);
                                        }, 2000);
                                    }
                                }}
                                disabled={updateDisabled}
                            >
                                {t('cart.needChange.updateChange')}
                            </Button>
                        )
                    )}
                </Row>
            )}
            {updateStatus && (
                <Row justifyContent="flex-end">
                    <Row width={124} justifyContent="center">
                        <Caption opacity={1} fontWeight={700}>
                            {updateStatus}
                        </Caption>
                    </Row>
                </Row>
            )}
        </Box>
    );
}
