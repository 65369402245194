import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function attachPaymentMethod(paymentMethodId, orgId, paymentMethodDetails = {}) {
    return invokeLambda(Lambda.AttachPaymentMethod, {
        organization_id: orgId,
        payment_method_id: paymentMethodId,
        paymentMethodDetails
    });
}
