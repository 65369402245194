import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import SaveButton from 'components/DeliveryOptionsModal/components/SaveButton';
import Heading from 'components/Heading';
import { Divider, Subtitle } from 'components/shared';

export default function Page({
    children,
    title,
    subtitle,
    info,
    onSave,
    saveEnabled,
    loading,
    buttonTitle,
    saveButtonVisible = true,
    paymentStyles,
    padding
}) {
    const { theme, themeColors } = useTheme();

    return (
        <Col height={isMobileOnly ? 'auto' : '100%'} maxHeight="70vh">
            <Col className="vertical-scrolling" minHeight={380}>
                <Heading
                    marginBottom={!paymentStyles && 10}
                    color={paymentStyles && themeColors[theme].tertiaryFontColor2}
                    padding={padding}
                >
                    {title}
                </Heading>
                {subtitle && (
                    <Subtitle
                        width="100%"
                        color={
                            paymentStyles
                                ? themeColors[theme].tertiaryFontColor2
                                : themeColors[theme].greyfontColor1
                        }
                        fontWeight={paymentStyles && 'bold'}
                        opacity={paymentStyles ? 1 : 0.5}
                    >
                        {subtitle}
                    </Subtitle>
                )}
                {info && (
                    <Box opacity={0.7} fontWeight={700} marginTop={36}>
                        {info}
                    </Box>
                )}
                {children}
            </Col>
            {saveButtonVisible && (
                <Col flex="1 1" justifyContent="flex-end" marginTop={isMobileOnly ? 30 : 40}>
                    {!isMobileOnly && <Divider marginBottom={24} />}
                    <Row justifyContent="flex-end">
                        <SaveButton
                            width={!isMobileOnly ? 260 : 'auto'}
                            loading={loading}
                            disabled={!saveEnabled}
                            onClick={onSave}
                            title={buttonTitle}
                            marginBottom={isMobileOnly && 30}
                        />
                    </Row>
                </Col>
            )}
        </Col>
    );
}
