import React from 'react';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from '../api/constants';
import Icon from './Icon';

export default function Alert({
    message,
    iconName,
    handleAlertClick,
    isFadingOut,
    addedBottomSpace
}) {
    const IconType = {
        clear: 'whiteClear',
        check: 'whiteCheck'
    };
    const { theme, themeColors } = useTheme();

    return (
        <Row
            width={324}
            minHeight={48}
            padding=" 12px 0 12px 12px"
            opacity={theme === THEME.DARK ? '0.98' : '0.7'}
            boxShadow={
                theme === THEME.DARK &&
                '0px 3px 5px rgba(0,0,0, 0.2), 0px 1px 18px rgba(0,0,0, 0.12), 0px 6px 10px rgba(0,0,0, 0.14)'
            }
            borderRadius={10}
            backgroundColor={
                theme === THEME.DARK
                    ? themeColors[theme].primaryBgColor3
                    : themeColors[theme].primaryBgColor2
            }
            cursor="pointer"
            props={{ onClick: handleAlertClick }}
            position="fixed"
            bottom={addedBottomSpace ? addedBottomSpace : 20}
            right={0}
            left={0}
            margin="0 auto"
            zIndex={5}
            className={isFadingOut && 'alert-fadeout'}
            alignItems="center"
        >
            <Icon
                name={iconName}
                width={20}
                height={20}
                margin={`${iconName === IconType.clear ? '8px 12px 8px 0' : '0 12px 0 0'}`}
            />
            <Box
                width={276}
                fontFamily="Helvetica"
                fontSize={14}
                lineHeight={1.43}
                letterSpacing="1px"
                color={themeColors[theme].primaryFontColor2}
            >
                {message}
            </Box>
        </Row>
    );
}
