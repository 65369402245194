import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Button from '../components/Button';
import Heading from '../components/Heading';
import Navbar from '../components/Navbar';
import Wrapper from '../components/Wrapper';

export default function NotFound({ pageTitle }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    return (
        <Wrapper>
            <Navbar pageTitle={pageTitle} />
            <Heading marginTop={100}>
                {t('submitFailed.ohNo')}{' '}
                <span role="img" aria-label="ohh">
                    🙀
                </span>
            </Heading>
            <Box
                fontWeight={500}
                fontSize={16}
                color={themeColors[theme].secondaryFontColor12}
                marginBottom={20}
            >
                {t('submitFailed.notSubmitted')}
            </Box>
            <Box
                fontWeight={500}
                fontSize={16}
                color={themeColors[theme].secondaryFontColor12}
                marginBottom={50}
            >
                {t('submitFailed.tryAgain')}
            </Box>
            <Button props={{ onClick: () => navigate('/cart') }}>
                {t('submitFailed.tryAgainBtn')}
            </Button>
        </Wrapper>
    );
}
