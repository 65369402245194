import React from 'react';
import { Box } from 'jsxstyle';

export default function FiltersCount({ count }) {
    return (
        <Box
            component="span"
            width={20}
            height={20}
            marginLeft={12}
            fontSize={11}
            fontWeight={900}
            textAlign="center"
            paddingTop={3}
            background="#d8d8d8"
            borderRadius="50%"
            display="inline-block"
        >
            {count}
        </Box>
    );
}
