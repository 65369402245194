import { addUsername } from '../utils/cacheDataManager';
import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function sendCode(phoneNumber, organization_id, store_domain, country_code) {
    try {
        const username = phoneNumber.replace('+', '');
        addUsername(username, organization_id);
        const payload = {
            organization_id,
            username,
            store_domain,
            country_code
        };

        return invokeLambda(Lambda.SendVerificationCode, payload);
    } catch (e) {
        // console.log('Error: ', e);
    }
}
