import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';

import Spinner from 'components/Spinner';

import Icon from './Icon';

export default function QuantityControl({
    value,
    disabled,
    onAdd,
    onSubtract,
    available,
    loading,
    isOrderInTransit
}) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    return (
        <Row
            alignItems="center"
            background={themeColors[theme].primaryBgColor2}
            color={themeColors[theme].primaryFontColor2}
            borderRadius={25}
            height={38}
            minWidth={117}
            box-shadow={`0 5px 20px 0 ${themeColors[theme].boxShadowColor2}`}
            cursor={!disabled && 'pointer'}
        >
            {value === 0 || isOrderInTransit ? (
                <Box
                    flex={1}
                    textAlign="center"
                    props={!disabled && { onClick: onAdd }}
                    color={disabled && themeColors[theme].secondaryFontColor3}
                >
                    {t('product.addToCart')}
                </Box>
            ) : (
                <>
                    <Button
                        flex={0}
                        disabled={disabled || value <= 0}
                        onClick={onSubtract}
                        alignItems="center"
                    >
                        {value === 1 ? (
                            <Icon name={theme === THEME.DARK ? 'trash' : 'trashWhite'} width={12} />
                        ) : (
                            <Icon
                                name={theme === THEME.DARK ? 'minus' : 'minusWhite'}
                                width={12}
                                height={12}
                            />
                        )}
                    </Button>

                    <Box flex={1} textAlign="center" position="relative">
                        {loading && (
                            <Spinner
                                white={theme !== THEME.DARK}
                                position="absolute"
                                width={30}
                                height={30}
                                top={-43}
                                right={value > 9 ? -7 : -10}
                            />
                        )}
                        <Box fontWeight="bold">{value}</Box>
                    </Box>

                    <Button
                        flex={0}
                        disabled={disabled || value >= available || !available}
                        onClick={onAdd}
                    >
                        <Icon name={theme === THEME.DARK ? 'plus' : 'plusWhite'} width={12} />
                    </Button>
                </>
            )}
        </Row>
    );
}

function Button(props) {
    const { children, disabled, onClick } = props;
    return (
        <Box
            component="button"
            cursor="pointer"
            border="none"
            outline="none"
            background="none"
            opacity={disabled ? 0.5 : 1}
            margin="0 15px"
            props={{ disabled, onClick }}
        >
            {children}
        </Box>
    );
}
