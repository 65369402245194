import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate, usePath } from '@patched/hookrouter';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { isHebrew, isRussian, isWeightProduct } from 'api/utils';

import removeFromCart from 'assets/images/remove-from-cart.svg';

import Img from 'components/Image';

import arrow from './../assets/images/arrow-bottom.svg';
import arrowDownIcon from './../assets/images/arrow-down.svg';
import placeHolder from './../assets/images/place-holder.svg';
import ItemPrice from './CartItemPrice';
import Image from './FlexImage';
import Icon from './Icon';

export default function CartItem(props) {
    const [loading, setLoading] = useState(false);

    const path = usePath();

    const {
        product,
        updating,
        removing,
        onRemove,
        onChangeQty,
        productCount,
        currentDeliveryModel,
        secondDeliveryModel,
        storeOpen,
        imageContainerFit,
        itemDisplayPrices
    } = props;

    console.log('itemDisplayPrices', itemDisplayPrices);

    const {
        image_url,
        name,
        sku,
        isAvailable,
        availableQuantity,
        priceChanged,
        secondAvailability,
        total_price,
        display_prices
    } = product;
    const onClick = () => {
        sessionStorage.setItem('previousPage', JSON.stringify({ path }));
        navigate(`/p/${sku}`);
    };
    const { theme, themeColors } = useTheme();

    const { t } = useTranslation();

    let availabilityNote = '';
    const priceNote = t('cart.item.priceChange');

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [currentDeliveryModel]);

    if (!isAvailable) {
        availabilityNote =
            availableQuantity < 1
                ? t('cart.item.notAvailable')
                : `${
                      isRussian
                          ? `${t('cart.item.left')}: ${availableQuantity}`
                          : isHebrew() && availableQuantity > 1
                            ? `${availableQuantity} ${t('cart.item.leftPlural')}`
                            : `${availableQuantity} ${t('cart.item.left')}`
                  }`;

        if (secondAvailability && (currentDeliveryModel !== 'Scheduled' || storeOpen)) {
            availabilityNote =
                availableQuantity < 1
                    ? `${t(`clearCartModal.${secondDeliveryModel}`)} ${t('cart.item.only')}`
                    : isHebrew()
                      ? `${t(`clearCartModal.${currentDeliveryModel}`)} \u200E${t(
                            'cart.item.leftFor'
                        )} \u200E${availableQuantity}`
                      : `${availableQuantity} ${t('cart.item.leftFor')} ${t(
                            `clearCartModal.${currentDeliveryModel}`
                        )}`;
        }
        if (priceChanged) {
            availabilityNote = `${availabilityNote} / \u200E${priceNote}`;
        }
    } else if (availableQuantity < 6) {
        availabilityNote = isHebrew()
            ? `\u200E${availableQuantity} ${
                  availableQuantity === 1 ? t('cart.item.left') : t('cart.item.leftPlural')
              }\u200E${priceChanged ? ` / ${priceNote}` : ''}`
            : isRussian
              ? `${t('cart.item.leftCap')}: ${availableQuantity}${
                    priceChanged ? ` / ${priceNote}` : ''
                }`
              : `${availableQuantity} ${t('cart.item.leftCap')}${
                    priceChanged ? ` / ${priceNote}` : ''
                }`;
    } else if (priceChanged) {
        availabilityNote = priceNote;
    }

    const showChangeQty = availableQuantity > 0;

    // build the quantity combobox options
    const maxOption = availableQuantity > productCount ? availableQuantity : productCount;
    const options = Array.from(Array(maxOption).keys(), key => ++key);

    return (
        <Col
            background={themeColors[theme].primaryBgColor4}
            borderRadius={10}
            padding={10}
            marginBottom={20}
            cursor="pointer"
        >
            <Row>
                <Row
                    alignItems="center"
                    flex={1}
                    props={{ onClick }}
                    opacity={!isAvailable ? 0.3 : 1}
                >
                    <Image
                        src={image_url ? image_url : placeHolder}
                        alt={name}
                        width={72}
                        height={72}
                        borderRadius={8}
                        imageContainerFit={imageContainerFit}
                    />
                    <Col marginLeft={20}>
                        <Box
                            color={themeColors[theme].primaryFontColor1}
                            fontSize={22}
                            fontWeight="bold"
                        >
                            <ItemPrice
                                fontSize={22}
                                itemDisplayPrices={itemDisplayPrices}
                                price={total_price}
                                displayPrices={display_prices}
                                isWeightProduct={isWeightProduct(product)}
                                weightValue={product.weight_value}
                                weightUnit={product.weight_unit}
                            />
                        </Box>
                        <Box
                            opacity={0.5}
                            color={themeColors[theme].secondaryFontColor5}
                            fontSize={12}
                            fontWeight="bold"
                            letterSpacing={1}
                        >
                            {name}
                        </Box>
                    </Col>
                </Row>
                <Box>
                    <Box
                        cursor="pointer"
                        color={themeColors[theme].quaternaryFontColor3}
                        opacity={removing ? 0.5 : 1}
                        props={{ disabled: removing, onClick: onRemove }}
                    >
                        {removing ? (
                            t('cart.item.removing')
                        ) : (
                            <Img width={26} height={26} src={removeFromCart} />
                        )}
                    </Box>
                </Box>
            </Row>
            <Row
                borderTop={`1px solid ${themeColors[theme].secondaryBorderColor9}`}
                marginTop={10}
                padding={10}
                minHeight={49}
                alignItems="center"
                justifyContent="flex-end"
                props={{
                    onClick: e => {
                        e.stopPropagation();
                    }
                }}
            >
                {!loading && availabilityNote && (
                    <Box
                        fontSize={12}
                        color={themeColors[theme].secondaryFontColor14}
                        fontWeight={700}
                        flex="2"
                    >
                        <Icon name="smallAlertWarn" verticalAlign="middle" marginRight={5} />
                        {availabilityNote}
                    </Box>
                )}
                {showChangeQty && (
                    <QuantitySelect
                        itemDisplayPrices={itemDisplayPrices}
                        updating={updating}
                        product={product}
                        productCount={productCount}
                        options={options}
                        onChangeQty={onChangeQty}
                        theme={themeColors[theme]}
                        t={t}
                    />
                )}
            </Row>
        </Col>
    );
}

const QuantitySelect = ({
    itemDisplayPrices,
    updating,
    product,
    productCount,
    options,
    onChangeQty,
    theme,
    t
}) => {
    const [selectedValue, setSelectedValue] = useState(productCount);
    const [isOpen, setIsOpen] = useState(false);

    const weightProduct = isWeightProduct(product);

    const handleSelect = option => {
        console.log('selected option', option);
        if (option !== selectedValue) {
            setSelectedValue(option);
            onChangeQty(option);
        }

        setIsOpen(false);
    };

    return (
        <Row alignItems="center">
            <Box marginRight={5} color={theme.blackFontColor}>
                {updating ? (
                    t('cart.item.updating')
                ) : (
                    <Box
                        className="cart-item-qty-select"
                        paddingRight={17}
                        backgroundImage={`url(${arrow})`}
                        backgroundRepeat="no-repeat"
                        backgroundPosition="right"
                        props={{
                            onClick: () => setIsOpen(!isOpen)
                        }}
                    >
                        {/* 

                            if item qty more than 1 select value should be:
                            - for weight product - {xLb / $total}
                            - for non weight product - {Qty X / $total}
                

                        */}

                        <Box className="cart-item-selected-value">
                            {weightProduct
                                ? `${
                                      selectedValue * product.weight_value
                                  }${product.weight_unit.toLowerCase()} `
                                : `${t('cart.item.quantity')} ${selectedValue} `}
                            {selectedValue > 1 ? `/ ${itemDisplayPrices.total}` : ''}
                        </Box>
                        {isOpen && (
                            <Box className="cart-item-select-options">
                                {options.map(option => (
                                    <Box
                                        key={option}
                                        className="cart-item-select-option"
                                        fontWeight={selectedValue === option ? 700 : 400}
                                        backgroundImage={
                                            selectedValue === option ? `url(${arrowDownIcon})` : ''
                                        }
                                        backgroundRepeat="no-repeat"
                                        backgroundPosition="right"
                                        props={{
                                            onClick: () => handleSelect(option)
                                        }}
                                    >
                                        {option}
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Row>
    );
};
