import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';

import useFilterComponent from 'hooks/useFilterComponent';

import Icon from '../components/Icon';
import FilterModal from '../components/Modals/FilterModal';
import FiltersCount from './FiltersCount';

export default function Filter({
    totalFiltersCount,
    setTotalFiltersCount,
    selectedSortType,
    setSelectedSortType,
    filterComponent,
    setFilterComponent,
    clearFilters,
    filtersApplied,
    setSelectedCategory
}) {
    const { t } = useTranslation();
    const { data: filters } = useFilterComponent();
    const [sortType, setSortType] = useState();
    const [defaultSortType, setDefaultSortType] = useState();
    const [appliedFilters, setAppliedFilters] = useState();
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [params, setParams] = useQueryParams();

    useEffect(() => {
        const buildFiltersData = () => {
            if (filters?.success) {
                if (filtersApplied) {
                    let appliedFilters = params.filters.replace(/[^\w\s]/gi, ',').split(',');
                    setAppliedFilters(appliedFilters);

                    let filtersCount = params.filters
                        ? [].concat.apply([], appliedFilters).length
                        : 0;
                    setTotalFiltersCount(filtersCount);
                } else {
                    setTotalFiltersCount(0);
                    setAppliedFilters([]);
                }
                const { mapped_categories, sort_types, categories } = filters;

                const filterCategories = mapped_categories.map(
                    ({ name, web_filter_enabled, web_filter_searchable, categories }) => {
                        return {
                            filterName: name,
                            web_filter_enabled,
                            web_filter_searchable,
                            categories: categories.map(
                                ({ category_id, name, category_type, webstore_visible }) => {
                                    return {
                                        category_id,
                                        categoryName: name,
                                        categoryType: category_type,
                                        webstore_visible,
                                        selected: params?.filters?.includes(category_id) || false
                                    };
                                }
                            )
                        };
                    }
                );

                const cachedSortedType = JSON.parse(sessionStorage.getItem('selectedSortType'));

                const sortType = sort_types.map(
                    ({ sort_order, web_filter_enabled, sort_type_id, product_property }, i) => {
                        return {
                            sort_order,
                            web_filter_enabled,
                            sort_type_id,
                            selected:
                                params?.sort?.includes(sort_type_id) ||
                                cachedSortedType?.sort_type_id === sort_type_id
                                    ? true
                                    : false,
                            product_property
                        };
                    }
                );

                if (params.sort) {
                    const selectedSort = sortType.filter(({ selected }) => selected)[0];
                    setSelectedSortType(selectedSort);
                    setDefaultSortType(selectedSort);
                } else {
                    setDefaultSortType(cachedSortedType);
                    setSelectedSortType(cachedSortedType);
                }
                setFilterComponent({
                    filterCategories,
                    sort_types: sortType
                });
            }
        };
        buildFiltersData();
    }, [filters, params.filters, params.sort]);

    useEffect(() => {
        const htmlElm = document.querySelector('html');
        htmlElm.style.overflow = showFilterModal ? 'hidden' : 'auto';
    }, [showFilterModal]);

    return (
        <Box>
            <Row
                alignItems="center"
                cursor="pointer"
                props={{ onClick: () => setShowFilterModal(true) }}
            >
                <Icon name="filters" />
                {!isMobileOnly && (
                    <Box marginLeft={10} fontWeight={700}>
                        {t('catalogPage.filters.filter')}
                    </Box>
                )}
                {totalFiltersCount > 0 && <FiltersCount count={totalFiltersCount} />}
            </Row>

            {showFilterModal && (
                <FilterModal
                    showModal={showFilterModal}
                    setShowFilterModal={setShowFilterModal}
                    onClose={() => {
                        setShowFilterModal(false);
                    }}
                    filterComponent={filterComponent}
                    setFilterComponent={setFilterComponent}
                    totalFiltersCount={totalFiltersCount}
                    setTotalFiltersCount={setTotalFiltersCount}
                    selectedSortType={selectedSortType}
                    setSelectedSortType={setSelectedSortType}
                    sortType={sortType}
                    setSortType={setSortType}
                    clearFilters={clearFilters}
                    defaultSortType={defaultSortType}
                    setSelectedCategory={setSelectedCategory}
                    appliedFilters={appliedFilters}
                />
            )}
        </Box>
    );
}
