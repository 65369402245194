import React, { useEffect, useState } from 'react';
import { isAndroid, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';
import { isHebrew } from 'api/utils';

import closeIcon from 'assets/images/black-close-icon.svg';

import Button from 'components/Button';
import Image from 'components/Image';
import Loading from 'components/Loading';

import Heading from '../Heading';

function DelayOrderModal({ showModal, onClose, onConfirm, onCancel, cancelling, postponing }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [deviceHeight, setDeviceHeight] = useState(
        isAndroid ? window.screen.height : window.innerHeight
    );

    useEffect(() => {
        window.addEventListener('resize', function () {
            setDeviceWidth(window.innerWidth);
            setDeviceHeight(isAndroid ? window.screen.height : window.innerHeight);
        });
    }, []);

    const modalStyle = () => {
        const isSmallScreen = isMobileOnly || deviceWidth <= 480 || deviceHeight <= 480;
        if (isSmallScreen) {
            return {
                content: {
                    position: 'absolute',
                    top: `${deviceWidth < 330 ? '10%' : deviceHeight <= 480 ? '10%' : 'auto'}`,
                    left: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    right: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    bottom: '0',
                    backgroundColor: themeColors[theme].primaryBgColor1,
                    border: 'none',
                    borderRadius: '20px 20px 0 0'
                }
            };
        } else {
            return {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    padding: '50px 60px',
                    borderRadius: '20px',
                    height: '450px',
                    width: '550px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'hidden'
                }
            };
        }
    };

    const modalStyles = modalStyle();

    return (
        <Modal
            style={modalStyles}
            isOpen={showModal}
            onRequestClose={() => {
                onClose();
            }}
            overlayClassName="buy-now-modal-overlay"
            closeTimeoutMS={500}
        >
            <Row justifyContent="flex-end" marginBottom={10}>
                <Image
                    cursor="pointer"
                    src={closeIcon}
                    width={30}
                    height={30}
                    padding={7}
                    onClick={onClose}
                />
            </Row>
            <Col textAlign="center" alignItems="center">
                <Heading marginBottom={25} color={themeColors[theme].secondaryFontColor6}>
                    {t('orderStatus.tooSoon')}
                </Heading>
                <Col
                    color={themeColors[theme].popupFontColor2}
                    fontSize={18}
                    fontWeight={500}
                    paddingBottom={50}
                >
                    <Box marginBottom={20}>
                        {isHebrew()
                            ? `${t('orderStatus.wouldYouDelay')}`
                            : t('orderStatus.wouldYouDelay')}
                    </Box>
                    <Box>{t('orderStatus.wouldYouDelay2')}</Box>
                </Col>

                <Col maxWidth={335} width="100%" paddingBottom={40}>
                    <Button
                        noHover
                        white={theme !== THEME.DARK}
                        marginBottom={10}
                        onClick={onConfirm}
                        padding="13px 25px"
                        disabled={postponing || cancelling}
                    >
                        {postponing ? t('orderStatus.postponing') : t('orderStatus.yesDelay')}
                    </Button>
                    <Button
                        white
                        noShadow
                        onClick={onCancel}
                        padding="13px 25px"
                        disabled={postponing || cancelling}
                    >
                        {cancelling ? t('orderStatus.cancelling') : t('orderStatus.noCancel')}
                    </Button>
                </Col>
            </Col>
        </Modal>
    );
}

export default DelayOrderModal;
