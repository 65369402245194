import React from 'react';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Icon from '../Icon';

export default function Notification({ title, content, iconName }) {
    const { theme, themeColors } = useTheme();

    return (
        <Row
            width="250px"
            margin="0 auto"
            position="absolute"
            top={15}
            left="50%"
            transform="translateX(-50%)"
            background="rgb(0 0 0 / 70%)"
            color={themeColors[theme].whitefontColor}
            padding="16px 30px"
            borderRadius={10}
            alignItems="center"
        >
            <Icon name={iconName} marginRight={10} />
            <Col>
                {title && <Box>title</Box>}
                <Box fontSize={14}>{content}</Box>
            </Col>
        </Row>
    );
}
