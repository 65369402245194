import { getCacheData, updateZone } from '../utils/cacheDataManager';
import { Lambda, ORDER_TYPE } from './constants';
import getCart from './getCart';
import getThread from './getThread';
import getUser from './getUser';
import getZone from './getZone';
import invokeLambda from './invokeLambda';
import { threadSubmitted } from './utils';

export default async function getCustomerData(
    session,
    fees,
    includeTaxes,
    defaultTaxType,
    isOpen,
    isOndemandOrder,
    store_domain,
    country_code
) {
    if (session.authenticated) {
        const menuKit = sessionStorage.getItem('menu_kit') || null;

        const payload = {
            user_id: session.userId,
            organization_id: session.organization_id,
            check_availability: false,
            isOndemandOrder,
            menu_kit: menuKit,
            store_domain,
            country_code
        };
        const {
            cart,
            customer,
            zone,
            thread,
            payment_provider_publishable_key,
            payment_provider_supplier,
            order_display_prices
        } = await invokeLambda(Lambda.GetCustomerData, payload);

        if (thread?.associated_hub) {
            localStorage.setItem('associatedHub', thread.associated_hub);
        }

        if (thread && !threadSubmitted(thread)) {
            const menu_kit = sessionStorage.getItem('menu_kit');

            if (menu_kit) {
                const payload = {
                    thread_id: thread.thread_id,
                    menu_kit
                };

                await invokeLambda(Lambda.UpdateThread, payload);
            }
        }

        if (payment_provider_publishable_key) {
            sessionStorage.setItem(
                'payment_provider_publishable_key',
                payment_provider_publishable_key
            );
        }

        if (document.referrer && document.referrer.length && !thread?.traffic_referrer) {
            const payload = {
                thread_id: thread?.thread_id,
                traffic_referrer: document.referrer
            };

            await invokeLambda(Lambda.UpdateThread, payload);
        }

        addAffiliate(thread);

        return {
            session,
            zone,
            paymentProviderSupplier: payment_provider_supplier,
            user: mapUser(customer),
            thread: mapThread(thread),
            cart: mapCart(cart),
            order_display_prices
        };
    } else {
        const user = session.userId
            ? await getUser(session.userId, session.organization_id, session.authenticated)
            : null;

        const thread = user ? await getThread(user) : null;

        const menuKit = thread?.menu_kit || sessionStorage.getItem('menu_kit') || null;

        const cart = thread
            ? await getCart(
                  user,
                  fees,
                  includeTaxes,
                  defaultTaxType,
                  thread.thread_id,
                  false,
                  isOpen,
                  thread.zone_id,
                  thread.associated_hub,
                  menuKit,
                  isOndemandOrder
              )
            : null;

        const zone = thread
            ? await getZone(
                  thread.zoneId,
                  user,
                  thread.location,
                  thread.associated_hub,
                  thread.thread_status,
                  thread.order_type === ORDER_TYPE.DELIVERY && !thread.pickup_destination
              )
            : null;

        const { zone: cachedZone } = await getCacheData(session.userId, session.organization_id);
        if (!cachedZone?.zone_id || cachedZone?.zone_id !== zone?.zoneId) {
            updateZone(zone, session.organization_id);
        }

        if (thread?.associated_hub) {
            localStorage.setItem('associatedHub', thread.associated_hub);
        }

        return {
            session,
            user,
            thread,
            zone,
            cart
        };
    }
}

function mapCart(data) {
    const {
        cart,
        subtotal_after_discount,
        fees,
        delivery,
        total,
        orderMeetsRequirements,
        requireAgentVerification,
        promocodeDiscount,
        promoCode
    } = data;
    return {
        promo_code: promoCode,
        orderMeetsRequirements,
        items: cart,
        requireAgentVerification,
        charges: {
            subtotal: subtotal_after_discount,
            fees,
            delivery,
            total,
            promocodeDiscount
        }
    };
}

const addAffiliate = async thread => {
    const aid = localStorage.getItem('aid');
    const cid = localStorage.getItem('cid');

    if (aid && cid && !thread?.affiliate_id && !thread?.campaign_id) {
        const payload = {
            thread_id: thread?.thread_id,
            affiliate_id: aid,
            campaign_id: cid
        };
        const res = await invokeLambda(Lambda.UpdateThread, payload);

        if (!res || !res.errorMessage) {
            localStorage.removeItem('aid');
            localStorage.removeItem('cid');
        }
    } else if (thread?.affiliate_id && thread?.campaign_id) {
        localStorage.removeItem('aid');
        localStorage.removeItem('cid');
    }
};

const mapUser = data => {
    return {
        authenticated: true,
        id: data.user_id,
        name: data.settings.full_name,
        address: data.location.address,
        isCompliant: data.is_compliant,
        organizationId: data.organization_id,
        threadId: data.active_thread,
        username: data.username,
        handoffId: data.handoff_id,
        picture: data.pictureThumbnail,
        email: data.settings.email,
        createdTime: data.created_time,
        isBlocked: data.is_blocked,
        location: data.location,
        referral_code: data.referral_code,
        error: data.error
    };
};

export function mapThread(data) {
    if (!data) return null;
    const { delivery_instructions } = data.location;
    const delayTime = data.eta && data.eta.delay_time;
    const eta = data.eta && data.eta.eta;
    const etaLastUpdateTime = data.eta && data.eta.last_update_time * 1000;

    //need to save zone_id and location for when user logout address will be saved
    if (data.zone_id && data.location) {
        localStorage.setItem(
            'geolocation',
            JSON.stringify({
                geoLocation: data.location,
                zoneId: data.zone_id,
                associated_hub: data.associated_hub
            })
        );
    }
    return {
        ...data,
        id: data?.thread_id,
        status: data.thread_status,
        trackingNumber: data.lookup_id,
        ...(delivery_instructions && {
            deliveryType: delivery_instructions.delivery_type,
            change: delivery_instructions.change,
            note: delivery_instructions.note
        }),

        ...(data.payment_details && {
            charges: {
                //subtotal now is whole cost
                subtotal: data.payment_details.subtotal_after_discount,
                fees: data.payment_details.total_taxes,
                delivery: data.payment_details.delivery_rate,
                total: data.payment_details.total_charge_in_cents,
                promocodeDiscount: data.payment_details.promocode_discount
                    ? data.payment_details.promocode_discount
                    : null,
                roundup: data.payment_details.round_up_addition,
                platformFee: data.payment_details?.platform_fee_total,
                platformFeeDesp: data.payment_details?.platform_fee_description
            },
            paymentDetails: {
                paymentTransactionType: data.payment_details.payment_transaction_type,
                paymentCardBrand: data.payment_details.payment_card_brand,
                paymentCardLast4: data.payment_details.payment_card_last4
            }
        }),
        eta,
        etaLastUpdateTime,
        zoneId: data.zone_id,
        organizationId: data.organization_id,
        closedTime: data.closed_time,
        address: data.location.address,
        promoCode: data.promo_code,
        delayTime
    };
}
