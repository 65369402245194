import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { trackPurchaseWithMantis } from 'analytics/pixels';
import { Box, Col, Inline, Row } from 'jsxstyle';

import useCartAvailability from 'hooks/api/useCartAvailability';
import useCustomerData from 'hooks/useCustomerData';
import useDeliveryModels from 'hooks/useDeliveryModels';
import usePickupLocations from 'hooks/usePickupLocations';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';

import { SUPPORTED_TRANSACTION_TYPE, TransactionsTypes } from 'api/constants';
import {
    cachePromoCode,
    cachePromoLink,
    cachePromoMessage,
    clearCachedPromoCode,
    clearCachedPromoLink,
    clearHidePromotionBar,
    formatCentsPercentageValue,
    getAnotherDeliveryModel,
    getCachedPromoCode,
    getCurrentDeliveryModel,
    getDoNotAutoApplyPromo,
    getThreadDeliveryModel,
    isConsentChecked,
    isHebrew,
    isRussian,
    isScheduledOrder,
    isSuperFastOrder,
    isThreadSubmitted,
    parsePromoParams,
    priceToString,
    supportsBothMenus
} from 'api/utils';

import { useAppContext } from 'store';

import { countProductsInCart } from 'utils/cartUtils';
import { pushToDataLayer } from 'utils/dataLayer';
import { trackPixel } from 'utils/trackingPixels';

import CartActions from 'components/CartActions';
import CartDeliveryDropdown from 'components/CartDeliveryDropdown';
import CartItem from 'components/CartItem';
import CartPickupDropdown from 'components/CartPickupDropdown';
import CartTotal from 'components/CartTotal';
import CustomConsent from 'components/CustomConsent';
import BuyNowModal from 'components/DeliveryOptionsModal/BuyNowModel';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import Navbar from 'components/Navbar';
import NoticeMessage, { NoticeMessageType } from 'components/NoticeMessage';
import P from 'components/Paragraph';
import PromoCodeInput from 'components/PromoCodeInput';
import Spinner from 'components/Spinner';
import Link from 'components/TextLink';
import Wrapper from 'components/Wrapper';

export default function Cart({ pageTitle }) {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();

    const {
        state: { currentDeliveryModel, currency },
        appContextActions
    } = useAppContext();

    const setCurrentDeliveryModel = model => appContextActions.deliveryModel.set(model);
    const setPaymentMethod = paymentMethod => appContextActions.paymentMethod.set(paymentMethod);

    const { data: mappedData, store, mutate, isLoading: customerDataLoading } = useCustomerData();
    const { data: pickupLocations } = usePickupLocations();
    const { user, thread, cart, zone, paymentProviderSupplier, order_display_prices } =
        mappedData || {};

    const { data: storeSettings } = useStoreSettings();

    const supportsDelivery =
        storeSettings?.supports_scheduled_menu || storeSettings?.supports_ondemand_menu;
    const supportsPickup = storeSettings?.supports_pickup;
    const pickupOnly = !supportsDelivery && supportsPickup;

    const activePayment = sessionStorage.getItem('activePayment');
    const paymentType =
        activePayment === TransactionsTypes.Cash
            ? SUPPORTED_TRANSACTION_TYPE.CASH
            : activePayment === TransactionsTypes.Credit
              ? SUPPORTED_TRANSACTION_TYPE.CREDIT
              : storeSettings?.supported_transaction_types[0];

    const {
        data: cartAvailabilityData,
        mutate: revalidateAvailability,
        isLoading: availabilityLoading
    } = useCartAvailability(currentDeliveryModel, paymentType);

    const [cartAvailability, setCartAvailability] = useState();

    useEffect(() => {
        if (cartAvailabilityData) {
            setCartAvailability(cartAvailabilityData);
        }
    }, [cartAvailabilityData]);

    console.log('cartAvailability', cartAvailability);

    const isSubmitted = isThreadSubmitted(thread);

    const [removing, setRemoving] = useState();
    const [updating, setUpdating] = useState();
    const UpdateStates = {
        Default: 'Default',
        Updating: 'Updating',
        Success: 'Success',
        Alert: 'Alert',
        Failed: 'Failed'
    };

    const ButtonStates = {
        Visible: 'Visible',
        Hidden: 'Hidden',
        Disabled: 'Disabled'
    };
    const [buttonState, setButtonState] = useState(ButtonStates.Hidden);
    const [alertError, setAlertError] = useState(false);
    const [promoInputOnFocus, setPromoInputOnFocus] = useState(false);
    const [updateState, setUpdateState] = useState(
        thread?.promoCode && cart && !cart.promocodeInvalid
            ? UpdateStates.Success
            : UpdateStates.Default
    );
    const [error, setError] = useState(null);

    const [promoSubmitted, setPromoSubmitted] = useState(false);

    const [updatingPromocode, setUpdatingPromocode] = useState();
    const [isSubmitting, setSubmitting] = useState();
    const [change] = useState();
    const [showBuyNowModal, setShowBuyNowModal] = useState(false);
    const [noticeMessage, setNoticeMessage] = useState('');
    const [notAllAvailable, setNotAllAvailable] = useState(false);
    const [updatingCartItems, setUpdatingCartItems] = useState(false);
    const [secondAvailabilityAvailable, setSecondAvailabilityAvailable] = useState(null);
    const [otherModelUnavailableItems, setOtherModelUnavailableItems] = useState(null);
    const [cartItems, setCartItems] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [charges, setCharges] = useState({});
    const [noWindowNotice, setNoWindowNotice] = useState('');
    const [consentChecked, setConsentChecked] = useState(isConsentChecked());
    const [amountGap, setAmountGap] = useState({
        minOrder: 0,
        freeDelivery: 0
    });
    const [showPromoNotification, setShowPromoNotification] = useState(false);
    const [promotionRelay, setPromotionRelay] = useState(null);
    const [showNotification, setShowNotification] = useState(false);

    const { supportsOndemandMenu, supportsScheduledMenu } = useDeliveryModels(zone, storeSettings);

    if (user && !thread) navigate('/notfound');

    const isPickupOrder = pickupOnly || thread?.pickup_destination != null;

    const orderSuperFast = isSuperFastOrder(currentDeliveryModel) && !isPickupOrder;
    const orderScheduled = isScheduledOrder(currentDeliveryModel) || isPickupOrder;

    const supportsBothDeliveryModels = supportsBothMenus(
        supportsOndemandMenu,
        supportsScheduledMenu
    );

    const currentDeliveryNotAvailable =
        (orderSuperFast && cartAvailability?.available_delivery_times?.ONDEMAND == null) ||
        (orderSuperFast && !storeSettings?.isOpen && supportsBothDeliveryModels) ||
        (orderScheduled && cartAvailability?.available_delivery_times?.SCHEDULED == null);

    useEffect(() => {
        function loadJointCommerce() {
            // track pixels
            if (storeSettings?.pixel_integrations_config) {
                const { advertiser_id, events, share_with_meta, token } =
                    storeSettings.pixel_integrations_config;

                if (events?.cart?.pixel_id) {
                    trackPixel({
                        isMeta: share_with_meta,
                        advertiser_id,
                        pixel_id: events.cart.pixel_id,
                        token,
                        user_data: user
                    });
                }
            }
        }
        loadJointCommerce();
    }, [storeSettings]);

    useEffect(() => {
        //also may check cart?.isValidating
        if (!cartItems) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [cartItems]);

    useEffect(() => {
        const setOrderDeliveryModelForCustomer = () => {
            // here we determine which zone selected
            // by customer or preferable
            // based on zone settings and opening hours
            // also if updated in admin or other tab will be reflected based on
            // thread.deliver_by

            if (
                thread &&
                supportsDelivery &&
                supportsOndemandMenu != null &&
                supportsScheduledMenu != null
            ) {
                const _deliveryModel =
                    !currentDeliveryModel || !user?.authenticated
                        ? getCurrentDeliveryModel(
                              thread,
                              storeSettings.isOpen,
                              supportsOndemandMenu,
                              supportsScheduledMenu
                          )
                        : getThreadDeliveryModel(
                              thread,
                              supportsOndemandMenu,
                              supportsScheduledMenu
                          );

                setCurrentDeliveryModel(_deliveryModel);
            }
        };

        setOrderDeliveryModelForCustomer();
    }, [thread?.deliver_by, thread?.id, supportsOndemandMenu, supportsScheduledMenu]);

    function getCartWithAvailability() {
        if (!cartAvailability) return {};
        const { onDemandNotice, scheduledNotice } = cartAvailability;

        const [itemsWithAvailable, itemsWithAvailableAnother] = orderSuperFast
            ? [onDemandNotice, scheduledNotice]
            : [scheduledNotice, onDemandNotice];

        const items = cart.items.map(el => {
            const product = itemsWithAvailable?.products?.find(
                i => i?.product?.product_id === el?.product?.product_id
            );
            const productAnotherAvailability = itemsWithAvailableAnother?.products?.find(
                i => i?.product?.product_id === el?.product?.product_id
            );
            const another_availability_notice = productAnotherAvailability?.availability_notice;

            if (product && productAnotherAvailability) {
                product.product.anotherAvailableQuantity =
                    productAnotherAvailability.availableQuantity;
            }

            if (supportsBothDeliveryModels && orderSuperFast && !storeSettings.isOpen && product) {
                product.product.availableQuantity = 0;
                product.availability_notice.change_type = 'available';
                product.availability_notice.is_available = false;
            }

            return {
                ...el,
                product: product?.product,
                display_prices: product?.display_prices,
                availability_notice: product?.availability_notice,
                another_availability_notice
            };
        });

        let has_changes = false;

        if (itemsWithAvailable?.products.find(el => el?.availability_notice)) {
            has_changes = true;
        }

        return {
            items,
            has_changes,
            onDemandAvailable: onDemandNotice?.allAvailable,
            scheduledAvailable: scheduledNotice?.allAvailable
        };
    }

    const checkProductsAvailability = async cart => {
        //cartAvailability

        const cartWithAvailability = getCartWithAvailability();

        const unavailableItem =
            cartWithAvailability.items.find(({ availability_notice }) => {
                return availability_notice && availability_notice.is_available === false;
            }) || currentDeliveryNotAvailable;

        const otherModelUnavailableItems = cartWithAvailability.items.find(
            ({ another_availability_notice }) => {
                return (
                    another_availability_notice &&
                    another_availability_notice.is_available === false
                );
            }
        );

        setOtherModelUnavailableItems(otherModelUnavailableItems);

        // reset notice message
        let message = '';
        setNoticeMessage(message);

        const deliveryTimes = cartAvailability?.available_delivery_times;

        const otherModelAvailable = orderSuperFast
            ? deliveryTimes?.SCHEDULED != null && supportsScheduledMenu
            : deliveryTimes?.ONDEMAND != null && supportsOndemandMenu;

        setSecondAvailabilityAvailable(otherModelAvailable);

        setNotAllAvailable(unavailableItem ? true : false);
        const anotherDeliveryModelLabel = getAnotherDeliveryModel(currentDeliveryModel);

        if (unavailableItem && isPickupOrder) {
            message = t('message.notAvailableForPickup');
        } else if (
            otherModelAvailable &&
            unavailableItem &&
            !otherModelUnavailableItems &&
            (currentDeliveryModel !== 'Scheduled' || storeSettings?.isOpen)
        ) {
            message = isHebrew()
                ? `${t('message.noProductsFor')} ${
                      currentDeliveryModel === 'Scheduled'
                          ? t('clearCartModal.scheduled')
                          : t('clearCartModal.superFast')
                  }. ${t('message.noProductsFor2')} ${
                      anotherDeliveryModelLabel === 'Scheduled'
                          ? t('clearCartModal.scheduled')
                          : t('clearCartModal.superFast')
                  } ${t('message.noProductsFor3')}`
                : `${t('message.noProductsFor')} ${currentDeliveryModel} ${t(
                      'message.noProductsFor2'
                  )} ${anotherDeliveryModelLabel} ${t('message.noProductsFor3')}`;
        } else if (unavailableItem) {
            message = t('message.noProducts');
        }

        if (message) {
            setNoticeMessage(message);
        }
    };

    useEffect(() => {
        appContextActions.pageYOffset.setOffset(0);
    }, []);

    useEffect(() => {
        if (cart && cart.items && cartAvailability) {
            setCharges(cartAvailability.charges);
            setAmountGap({
                minOrder: cartAvailability?.payment_details?.minimum_order_gap,
                freeDelivery: cartAvailability?.payment_details?.free_delivery_gap
            });
            checkProductsAvailability(cart);

            const items = cart.items.map(item => {
                const { item_id } = item;
                const {
                    availability_notice,
                    another_availability_notice,
                    product,
                    display_prices
                } = getCartWithAvailability()?.items?.find(item => item.item_id === item_id) || {};

                const isNotAvailable = availability_notice
                    ? availability_notice.change_type === 'available' &&
                      availability_notice.is_available === false
                    : false;

                const secondAvailability =
                    (another_availability_notice?.change_type !== 'available' &&
                        secondAvailabilityAvailable) ||
                    null;

                const priceChanged = availability_notice
                    ? availability_notice.change_type === 'price' ||
                      another_availability_notice?.change_type === 'price' ||
                      availability_notice.price_change
                    : false;

                const availableQuantity = product?.availableQuantity;

                return {
                    ...item,
                    product: {
                        ...product,
                        availableQuantity,
                        isAvailable: !isNotAvailable,
                        secondAvailability,
                        priceChanged,
                        display_prices
                    }
                };
            });

            console.log('items', items);

            setCartItems(items);
        } else if (cart && !cart.items?.length) {
            setCartItems([]);
        }
    }, [cart?.items?.length, cartAvailability, secondAvailabilityAvailable]);

    useEffect(() => {
        const checkDeliveryTypeUpdated = async () => {
            const deliveryTypeFound = storeSettings?.supported_delivery_options.find(
                item => item === thread?.deliveryType
            );
            if (!deliveryTypeFound) {
                const deliveryInstructions = {
                    delivery_type: storeSettings?.supported_delivery_options[0],
                    note: thread?.note,
                    change: thread?.change
                };
                await store.updateDeliveryInstructions(deliveryInstructions);
            }
        };
        if (user?.authenticated) checkDeliveryTypeUpdated();
    }, [thread?.deliveryType, storeSettings?.supported_delivery_options, user]);

    const minOrder =
        storeSettings?.fees?.order_min && priceToString(storeSettings.fees.order_min, currency);
    const lessThenMinOrder = amountGap.minOrder > 0;

    const freeDeliveryAmount =
        !lessThenMinOrder &&
        amountGap.freeDelivery > 0 &&
        priceToString(amountGap.freeDelivery, currency);

    useEffect(() => {
        if (charges != null) {
            setShowNotification(
                charges?.subtotal > 0 &&
                    (lessThenMinOrder || (!lessThenMinOrder && freeDeliveryAmount))
            );
        }
    }, [charges, lessThenMinOrder, freeDeliveryAmount]);

    useEffect(() => {
        async function getActiveTopPromotion() {
            if (thread && !thread?.promoCode && !getCachedPromoCode() && user?.authenticated) {
                const promotion_relay = await store.getActiveTopPromotion(
                    sessionStorage.getItem('current_selected_campaign')
                );
                if (promotion_relay?.promo_code) {
                    sessionStorage.setItem(
                        'current_selected_campaign',
                        promotion_relay?.promo_code
                    );

                    if (
                        promotion_relay?.auto_apply_to_cart &&
                        promotion_relay?.url &&
                        !getDoNotAutoApplyPromo()
                    ) {
                        cachePromoCode(promotion_relay.promo_code);
                        cachePromoLink(promotion_relay.url);
                    }
                    setPromotionRelay(promotion_relay);
                }
            }
        }
        getActiveTopPromotion();
    }, [user?.authenticated, charges?.subtotal]);

    const onContinue = () => {
        navigate('/', false, {});
    };
    const onOrderStatusView = () => navigate('/track');

    async function onChangeQty(id, qty, productId) {
        console.log('id, qty', id, qty);
        setUpdating(id);
        await store.setQuantity(id, qty, productId);
        const promocode = getCachedPromoCode();

        if (promocode) {
            await applyPromocode(promocode);
        }

        setUpdating(null);
    }

    const applyPromocode = async value => {
        setUpdatingPromocode(true);
        setPromoSubmitted(true);
        const data = await store.applyPromocode(value);
        setPromoInputOnFocus(false);
        if (!data.applied) {
            setAlertError(false);
            const alertErrorType = data.error === 'RequiredItemsNotInCart';
            if (alertErrorType) {
                if (data.error === 'RequiredItemsNotInCart' && data?.promotion_relay?.url) {
                    setShowPromoNotification(true);
                    setPromotionRelay(data?.promotion_relay);
                    cachePromoLink(data?.promotion_relay?.url);
                }
                if (data.error === 'PromotionNotAvailable') {
                    setShowPromoNotification(false);
                    clearCachedPromoLink();
                }
                setAlertError(true);
            }

            setUpdateState(alertErrorType ? UpdateStates.Alert : UpdateStates.Failed);

            if (data.error) {
                setButtonState(ButtonStates.Visible);

                setUpdatingPromocode(false);
                await mutate();
                await revalidateAvailability();
                switch (data.error) {
                    case 'NotFirstOrder':
                        return setError(
                            <Box>
                                {t('cart.promoCode.errorNotFirstOrder')}{' '}
                                <Link
                                    color={themeColors[theme].errFontColor}
                                    goto={() => {
                                        navigate('/refer');
                                    }}
                                >
                                    {t('cart.promoCode.errorNotFirstOrder2')}
                                </Link>{' '}
                                {t('cart.promoCode.errorNotFirstOrder3')}
                            </Box>
                        );

                    case 'CustomerOwnReferralCode':
                        return setError(
                            <Box>
                                {t('cart.promoCode.customerOwnReferralCode')}{' '}
                                <Link
                                    color={themeColors[theme].errFontColor}
                                    goto={() => {
                                        navigate('/refer');
                                    }}
                                >
                                    {t('cart.promoCode.customerOwnReferralCode2')}
                                </Link>{' '}
                                {t('cart.promoCode.customerOwnReferralCode3')}
                            </Box>
                        );

                    case 'NewCustomersOnly':
                        return setError(<Box>{t('cart.promoCode.firstTimeError')}</Box>);

                    case 'RequiredItemsNotInCart':
                        return setError(data.errorMessage);

                    case 'PromotionNotAvailable':
                        if (supportsBothDeliveryModels) {
                            return setError(
                                t('cart.promoCode.notAvailableInCurrentMenuPromo', {
                                    current_menu: currentDeliveryModel?.toLowerCase()
                                })
                            );
                        } else {
                            return setError(t('cart.promoCode.notAvailablePromo'));
                        }

                    case 'PromoBiggerThanTotal':
                        return setError(t('cart.promoCode.valueError'));

                    case 'PromoUsed':
                        return setError(t('cart.promoCode.usedError'));

                    case 'PromoExpired':
                    case 'CampaignNotValid':
                        return setError(t('cart.promoCode.expiredError'));

                    default:
                        return setError(t('cart.promoCode.errorNotFound'));
                }
            }
        } else {
            if (data?.promotion_relay?.url) {
                cachePromoLink(data?.promotion_relay?.url);
                cachePromoMessage(data?.promotion_relay.message);
            }

            setError('');
            setShowPromoNotification(false);
            if (!value || !value.length) {
                setUpdateState(UpdateStates.Default);
            }
            setUpdateState(UpdateStates.Success);
            // setButtonState(ButtonStates.Hidden);
        }
        await mutate();
        await revalidateAvailability();
        setUpdatingPromocode(false);
    };

    async function onRemove(id) {
        setRemoving(id);
        await store.removeItem(id);
        const promocode = getCachedPromoCode();
        if (promocode) {
            await applyPromocode(promocode);
        }
        setRemoving(null);
    }

    async function onSubmit() {
        purchaseStartedPixel();
        store.updateChange(change);

        //user cannot purchase items when we are close
        if (!supportsScheduledMenu && !storeSettings.isOpen && !isPickupOrder) {
            navigate('./we-closed');
            return null;
        }

        if (user.authenticated) {
            if (notAllAvailable) {
                // if not all items available
                // we cannot allow the user to go to the buy now modal
                window.scrollTo(0, 0);
            } else {
                // all items are available
                if (
                    user &&
                    user.name &&
                    user.email &&
                    (user.isCompliant || !storeSettings?.requires_identity_verification)
                ) {
                    if (cart.requireAgentVerification || user.isBlocked) {
                        navigate('/direct-to-agent');
                    } else {
                        setUpdatingCartItems(true);
                        // if the response has failed we simply return
                        const updatedItems = getCartWithAvailability()?.items || [];
                        const { success } = await store.updateCartItems(
                            user.organizationId,
                            thread.id,
                            updatedItems
                        );
                        setUpdatingCartItems(false);
                        if (!success) {
                            return;
                        }

                        // show purchase items modal
                        setShowBuyNowModal(true);
                    }
                } else {
                    navigate('/login', false, { from: 'cart' });
                }
            }
        } else {
            // users which are not logged in cannot purchase items
            navigate('/login', false, { from: 'cart' });
        }
    }

    const centsToDollars = cents => {
        return (cents / 100).toFixed(2);
    };
    const purchaseStartedPixel = () => {
        const items = cart.items.map(item => ({
            item_id: item.product_sku,
            item_name: item?.product?.name,
            // item_brand: 'STIIIZY, LLC',
            // item_category: 'Skateboard Deck',
            price: centsToDollars(item.product?.promotion_price || item.product?.total_price),
            quantity: item.product_count,
            item_sku: item.product_sku
        }));

        const cartQuantity = countProductsInCart(cart);

        pushToDataLayer({
            event: 'begin_checkout',
            ecommerce: {
                cart_quantity: cartQuantity,
                cart_total: centsToDollars(charges?.total), //In dollars not cents
                currency: 'USD',
                items: items
            },
            user_data: {
                email: user?.email,
                user_id: user?.id
            }
        });

        if (storeSettings?.pixel_integrations_config) {
            const { advertiser_id, events, share_with_meta, token } =
                storeSettings.pixel_integrations_config;

            if (events?.initiate_checkout?.pixel_id) {
                trackPixel({
                    isMeta: share_with_meta,
                    advertiser_id,
                    pixel_id: events.initiate_checkout.pixel_id,
                    token,
                    user_data: user
                });
            }
        }
    };

    const purchasePixel = orderId => {
        if (storeSettings?.pixel_integrations_config) {
            const { advertiser_id, events, share_with_meta, token } =
                storeSettings.pixel_integrations_config;

            if (events?.purchase_conversion?.pixel_id) {
                trackPixel({
                    isMeta: share_with_meta,
                    advertiser_id,
                    pixel_id: events.purchase_conversion.pixel_id,
                    token,
                    user_data: user,
                    order_id: orderId,
                    revenue: charges.total,
                    currency: global.currency
                });
            }
        }

        const items = cart.items.map(item => ({
            item_id: item.product_sku,
            item_name: item?.product?.name,
            // item_brand: 'STIIIZY, LLC',
            // item_category: 'Skateboard Deck',
            price: centsToDollars(item.product?.promotion_price || item.product?.total_price),
            quantity: item.product_count,
            item_sku: item.product_sku
        }));

        pushToDataLayer({
            event: 'purchase',
            ecommerce: {
                transaction_id: thread?.lookup_id, //thread_id
                value: centsToDollars(charges.total), //Total order value from payment_details
                tax: centsToDollars(charges.fees), //Total tax from payment_details
                shipping: centsToDollars(charges.delivery), //Delivery fee
                coupon: thread.promoCode, //Promo code
                sub_total: centsToDollars(charges.subtotal), //subtotal from payment_details
                discount_amount: centsToDollars(charges.discount), //from payment_details
                currency: 'USD',
                items: items
            },
            user_data: {
                first_name: user?.name?.split(' ')[0],
                last_name: user?.name?.split(' ')[1],
                email: user?.email,
                phone: user?.username,
                customer_id: user?.id, //user_id
                country: 'US',
                street: user?.location?.street,
                region: user?.location?.state,
                city: user?.location?.city,
                zip: user?.location?.zip,
                new_customer: Boolean(thread?.is_first_order) //is_first I think
            }
        });

        trackPurchaseWithMantis(
            storeSettings?.pixel_integrations_config?.mantis_advertiser_id,
            thread?.lookup_id,
            charges.total
        );
    };

    const onComplete = async (paymentMethod, paymentType) => {
        try {
            //submit order here
            setSubmitting(true);
            clearHidePromotionBar();
            await store.updateChange(change);

            const response = thread?.pickup_destination
                ? await store.submitOrder(
                      '',
                      '',
                      paymentType,
                      thread?.order_type,
                      thread?.pickup_destination
                  )
                : await store.submitOrder(
                      currentDeliveryModel,
                      thread?.delivery_window,
                      paymentType
                  );

            if (response.error === 'StoreClosed') {
                navigate('./we-closed');
                return null;
            } else if (response.error === 'RequiresAgentVerification') {
                navigate('/direct-to-agent');
                return null;
            }

            mutate();

            // store payment method in global store
            setPaymentMethod(paymentMethod);

            setSubmitting(false);
            if (!response.isSubmitted) {
                navigate('/submit-failed');
                setSubmitting(false);
            } else {
                clearCachedPromoCode();
                purchasePixel(thread?.trackingNumber, charges);
                navigate('/order-placed');
                setSubmitting(false);
            }
        } catch (e) {
            console.log('error', e);
        }
    };

    // const isLoading = !cart.data || !thread.data;

    function CatalogLink() {
        return (
            <Box>
                <P fontSize={18}>
                    <Link goto={onContinue}>{t('cart.tapHere')}</Link> {t('cart.browseCatolog')}
                </P>
                <Box textAlign="center" margin="15vh 0 0">
                    <Icon name="emptyCart" />
                </Box>
            </Box>
        );
    }

    const isEmpty = cart && cart.items && cart.items.length ? false : true;

    const navigateToEligible = url => {
        const parsedLink = `${url.split('{store_domain}/').join('')}`;
        if (parsedLink.includes('p/')) {
            navigate(parsedLink);
        } else {
            const promoParams = parsePromoParams(parsedLink);
            navigate('/', false, {
                ...promoParams
            });
        }
    };

    const minAmount = amountGap.minOrder && priceToString(amountGap.minOrder, currency);

    const minOrderText = isHebrew()
        ? `${t('cart.addAmount', { amount: minAmount })} ${t('cart.minOrder', {
              min: minOrder
          })}`
        : t('cart.minOrder', {
              amount: minAmount,
              min: minOrder
          });

    const freeDeliveryText = isHebrew()
        ? `${t('cart.addAmount', { amount: freeDeliveryAmount })} ${t('cart.freeDelivery')}`
        : t('cart.freeDelivery', { amount: freeDeliveryAmount });

    const split = 'screen and (min-width: 700px)';

    return (
        <Wrapper>
            <Navbar pageTitle={pageTitle} />

            {isLoading ? (
                <Box>
                    {t('cart.loadingCart')}
                    <Spinner />
                </Box>
            ) : isSubmitted ? (
                <Box>
                    <P fontSize={18}>
                        {t('cart.orderSubmitted')}{' '}
                        <Link goto={onOrderStatusView}> {t('cart.tapHere')}</Link>{' '}
                        {t('cart.seeOrder')}
                    </P>
                    <CatalogLink />
                </Box>
            ) : isEmpty ? (
                <Box>
                    <Heading>{t('cart.title_emptyCart')}</Heading>
                    <CatalogLink />
                </Box>
            ) : (
                <Box>
                    {' '}
                    <Heading>{t('cart.title')}</Heading>
                    {isHebrew() ? (
                        <Box
                            fontSize={16}
                            color={themeColors[theme].primaryFontColor1}
                            opacity={0.7}
                            marginBottom={20}
                        >
                            {countProductsInCart(cart) === 1
                                ? t('cart.item_singular')
                                : t('cart.item_plural')}{' '}
                            {`\u200E${countProductsInCart(cart)}`}
                        </Box>
                    ) : isRussian ? (
                        <Box
                            fontSize={16}
                            color={themeColors[theme].primaryFontColor1}
                            opacity={0.7}
                            marginBottom={20}
                        >
                            {countProductsInCart(cart) === 1
                                ? t('cart.item_singular')
                                : t('cart.item_plural')}
                            : {countProductsInCart(cart)}
                        </Box>
                    ) : (
                        <Box
                            fontSize={16}
                            color={themeColors[theme].primaryFontColor1}
                            opacity={0.7}
                            marginBottom={20}
                        >
                            {countProductsInCart(cart)}{' '}
                            {countProductsInCart(cart) === 1
                                ? t('cart.item_singular')
                                : t('cart.item_plural')}
                        </Box>
                    )}
                    <Col
                        mediaQueries={{ split }}
                        splitFlexDirection="row"
                        justifyContent="space-between"
                    >
                        <Box width="100%" mediaQueries={{ split }} splitMaxWidth={400}>
                            {noticeMessage && (
                                <NoticeMessage
                                    mediaQueries={{ split }}
                                    message={noticeMessage}
                                    type={NoticeMessageType.Warning}
                                    splitMaxWidth={400}
                                    marginTop={15}
                                    marginBottom={15}
                                />
                            )}

                            <Box mediaQueries={{ split }} flex={1} splitMaxWidth={400}>
                                {isPickupOrder ? (
                                    <CartPickupDropdown
                                        currentPickupLocation={thread?.pickup_destination}
                                        setCurrentPickupLocation={store.updatePickupLocation}
                                        pickupLocations={pickupLocations}
                                        timeZone={storeSettings.timeZone}
                                    />
                                ) : currentDeliveryModel ? (
                                    <CartDeliveryDropdown
                                        orderSuperFast={orderSuperFast}
                                        availableDeliveryTimes={
                                            cartAvailability?.available_delivery_times
                                        }
                                        storeOpen={storeSettings?.isOpen}
                                        supportsOndemandMenu={supportsOndemandMenu}
                                        supportsScheduledMenu={supportsScheduledMenu}
                                        supportsBothDeliveryModels={supportsBothDeliveryModels}
                                        currentDeliveryModel={currentDeliveryModel}
                                        setCurrentDeliveryModel={setCurrentDeliveryModel}
                                        isAuth={user.authenticated}
                                        thread={thread}
                                        productNotAvailable={
                                            notAllAvailable &&
                                            !secondAvailabilityAvailable &&
                                            otherModelUnavailableItems
                                        }
                                        currentDeliveryNotAvailable={currentDeliveryNotAvailable}
                                        noWindowNotice={noWindowNotice}
                                        setNoWindowNotice={setNoWindowNotice}
                                    />
                                ) : null}

                                {cartItems &&
                                    cartItems.map(item => {
                                        let displayPrices = order_display_prices;

                                        if (
                                            !displayPrices &&
                                            cartAvailability?.order_display_prices
                                        ) {
                                            displayPrices = cartAvailability.order_display_prices;
                                        }

                                        console.log('displayPrices', displayPrices);

                                        const { item_id, product_count, product } = item;

                                        const itemDisplayPrices = displayPrices.find(
                                            item => item_id === item.item_id
                                        );

                                        const ch = qty =>
                                            onChangeQty(
                                                item_id,
                                                qty,
                                                product?.product_id,
                                                product?.name,
                                                product_count
                                            );
                                        const rm = () =>
                                            onRemove(item_id, product?.product_id, product?.name);
                                        return (
                                            <CartItem
                                                key={item_id}
                                                removing={removing === item_id}
                                                updating={updating === item_id}
                                                onChangeQty={ch}
                                                onRemove={rm}
                                                product={product}
                                                itemDisplayPrices={
                                                    itemDisplayPrices?.display_prices
                                                }
                                                productCount={product_count}
                                                currentDeliveryModel={currentDeliveryModel}
                                                secondDeliveryModel={getAnotherDeliveryModel(
                                                    currentDeliveryModel
                                                )}
                                                storeOpen={storeSettings?.isOpen}
                                                imageContainerFit={
                                                    storeSettings.webstore_image_container
                                                }
                                            />
                                        );
                                    })}
                            </Box>
                        </Box>

                        <Box
                            mediaQueries={{ split }}
                            flex={1}
                            splitMarginLeft={20}
                            splitMaxWidth={375}
                        >
                            {showNotification && (
                                <Box marginBottom={18}>
                                    <FreeNotification
                                        iconName={freeDeliveryAmount ? 'truck' : 'redAlert'}
                                        message={
                                            freeDeliveryAmount ? freeDeliveryText : minOrderText
                                        }
                                        handleAlertClick={() => navigate('/')}
                                        freeDelivery={freeDeliveryAmount}
                                        t={t}
                                        theme={theme}
                                        themeColors={themeColors}
                                    />
                                </Box>
                            )}
                            {showPromoNotification && (
                                <Box marginBottom={18}>
                                    <FreeNotification
                                        iconName="addBlue"
                                        message={t('cart.promoDesp')}
                                        handleAlertClick={() => {
                                            cachePromoMessage(promotionRelay?.message);
                                            navigateToEligible(promotionRelay?.url);
                                        }}
                                        promoNotification
                                        t={t}
                                        theme={theme}
                                        themeColors={themeColors}
                                    />
                                </Box>
                            )}
                            <Box
                                borderTop={`1px solid ${themeColors[theme].secondaryBorderColor2}`}
                            ></Box>
                            <CartTotal
                                charges={charges}
                                promoCodeId={thread?.promoCode ? thread.promoCode : ''}
                                lessThenMin={lessThenMinOrder}
                                min={minOrder}
                                promoCode={cart.promo_code}
                                productNotAvailable={notAllAvailable}
                                orderType={thread.order_type}
                                roundUpEnabled={storeSettings?.fees?.round_up_enabled}
                                roundUpReason={storeSettings?.fees?.round_up_reason}
                                platformFee={storeSettings?.fees?.platform_fee}
                            />

                            {storeSettings?.cart_summary_msg && (
                                <Box
                                    marginTop={15}
                                    fontSize={12}
                                    fontWeight={700}
                                    letterSpacing={1}
                                    color={themeColors[theme].secondaryFontColor8}
                                >
                                    {storeSettings?.cart_summary_msg}
                                </Box>
                            )}
                            <PromoCodeInput
                                isInvalidPromo={cart.promocodeInvalid}
                                cartCharges={charges}
                                countProductsInCart={countProductsInCart(cart)}
                                referralProgram={storeSettings.referral_program}
                                setShowPromoNotification={setShowPromoNotification}
                                currentDeliveryModel={currentDeliveryModel}
                                user={user}
                                thread={thread}
                                cart={cart}
                                store={store}
                                applyPromocode={applyPromocode}
                                updateState={updateState}
                                setUpdateState={setUpdateState}
                                promoSubmitted={promoSubmitted}
                                setPromoSubmitted={setPromoSubmitted}
                                UpdateStates={UpdateStates}
                                promoInputOnFocus={promoInputOnFocus}
                                setPromoInputOnFocus={setPromoInputOnFocus}
                                alertError={alertError}
                                buttonState={buttonState}
                                setButtonState={setButtonState}
                                ButtonStates={ButtonStates}
                                error={error}
                                setError={setError}
                            />

                            {storeSettings?.custom_consent && (
                                <CustomConsent
                                    customConsent={storeSettings?.custom_consent}
                                    consentChecked={consentChecked}
                                    setConsentChecked={setConsentChecked}
                                />
                            )}

                            <CartActions
                                onContinue={onContinue}
                                onSubmit={onSubmit}
                                loading={
                                    customerDataLoading ||
                                    availabilityLoading ||
                                    updating ||
                                    updatingPromocode
                                }
                                submitting={isSubmitting}
                                lessThenMin={lessThenMinOrder}
                                disabled={updatingCartItems || notAllAvailable || noWindowNotice}
                                consentRequired={
                                    storeSettings?.custom_consent?.is_required
                                        ? !consentChecked
                                        : false
                                }
                                isAuth={user.authenticated}
                            />
                        </Box>
                    </Col>
                </Box>
            )}

            {showBuyNowModal && (
                <BuyNowModal
                    key="deliveryOptionsModal"
                    showModal={showBuyNowModal}
                    thread={thread}
                    charges={charges}
                    paymentProviderSupplier={paymentProviderSupplier}
                    organizationId={user.organizationId}
                    storeSettings={storeSettings}
                    supportedTransactionTypes={storeSettings.supportedTransactionTypes}
                    requireExactChange={storeSettings.require_exact_change}
                    onRequestClose={() => setShowBuyNowModal(false)}
                    onComplete={onComplete}
                    submitting={isSubmitting}
                    etaEstimation={storeSettings.eta_estimation}
                    supportedDeliveryOptions={storeSettings.supported_delivery_options}
                    deliveryDefaults={storeSettings.delivery_defaults}
                    currentDeliveryModel={currentDeliveryModel}
                    store={store}
                    availableDeliveryTimes={cartAvailability?.available_delivery_times}
                    supportDebitAndCash={storeSettings.pin_terminal_fee}
                    pinTerminalFee={formatCentsPercentageValue(
                        storeSettings.pin_terminal_fee,
                        currency
                    )}
                    pickupLocation={thread?.pickup_destination}
                    revalidateAvailability={revalidateAvailability}
                />
            )}
        </Wrapper>
    );
}

const FreeNotification = ({
    message,
    iconName,
    handleAlertClick,
    freeDelivery,
    promoNotification,
    t,
    theme,
    themeColors
}) => (
    <Row
        width="100%"
        minHeight={75}
        padding="12px 0 12px 12px"
        borderRadius={10}
        backgroundColor={themeColors[theme].primaryBgColor1}
        boxShadow={`0 2px 5px 0 ${themeColors[theme].boxShadowColor5}`}
        cursor="pointer"
        props={{ onClick: handleAlertClick }}
        position="static"
        bottom={20}
        right={0}
        left={0}
        margin="0 auto"
        zIndex={5}
        alignItems="center"
    >
        {/* <Icon name={iconName} width={27} height={24} margin="8px 18px 8px 10px" /> */}
        <Icon
            name={iconName}
            margin={promoNotification ? '8px 12px 8px 0px' : '8px 18px 8px 10px'}
        />

        <Box
            width={280}
            fontSize={promoNotification ? 14 : 16}
            fontWeight="bold"
            lineHeight={1.25}
            letterSpacing={'1px'}
            color={
                freeDelivery
                    ? themeColors[theme].quinaryFontColor1
                    : promoNotification
                      ? themeColors[theme].tertiaryFontColor7
                      : themeColors[theme].quaternaryFontColor2
            }
        >
            {message}
            {!freeDelivery && !promoNotification && (
                <Inline fontSize={12} letterSpacing="1px" fontStyle="italic">
                    {t('cart.exclDelivery')}
                </Inline>
            )}
        </Box>
    </Row>
);
