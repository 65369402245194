import React from 'react';

import none from 'assets/images/payment-icons/credit-card-input.svg';

import Image from 'components/Image';

import amex from '../assets/images/payment-icons/stp_card_amex.png';
import diners from '../assets/images/payment-icons/stp_card_diners.png';
import discover from '../assets/images/payment-icons/stp_card_discover.png';
import jcb from '../assets/images/payment-icons/stp_card_jcb.png';
import mastercard from '../assets/images/payment-icons/stp_card_mastercard.png';
import unionpay from '../assets/images/payment-icons/stp_card_unionpay_en.png';
import visa from '../assets/images/payment-icons/stp_card_visa.png';

const Icons = {
    none,
    visa,
    mastercard,
    amex,
    discover,
    diners,
    jcb,
    unionpay
};

export default function PaymentCardIcon({ brand, width, height, useMargins = true }) {
    return (
        <Image src={Icons[brand]} width={width} height={height} marginRight={useMargins ? 10 : 0} />
    );
}
