import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollYPosition } from 'react-use-scroll-position';
import { navigate, usePath, useQueryParams } from '@patched/hookrouter';
import { Box, Col, Row } from 'jsxstyle';

import usePrevious from 'hooks/custom/usePrevious';
import useCatalog from 'hooks/useCatalog';
import useCustomerData from 'hooks/useCustomerData';
import useDeliveryModels from 'hooks/useDeliveryModels';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';

import { allProductsCategory } from 'api/constants';
import {
    getCachedPromoMessage,
    isSuperFastOrder,
    isThreadSubmitted,
    priceToString,
    supportsBothMenus
} from 'api/utils';

import { useAppContext } from 'store';

import log from 'utils/log';

import Loading from 'components/Loading';
import ProductGrid from 'components/ProductGrid';
import { Headline, Subtitle } from 'components/shared';

const Catalog = ({ setCatalogProductCount, catalogProductCount, clearAppliedFilters }) => {
    const { theme, themeColors } = useTheme();

    const { t } = useTranslation();
    const [params, setParams] = useQueryParams();
    const { data: mappedData } = useCustomerData();

    const { session, user, thread, zone, cart } = mappedData || {};

    const { data: storeSettings } = useStoreSettings();

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [products, setProducts] = useState();
    const [eligibleGetProducts, setEligibleGetProducts] = useState();
    const [showLoading, setShowLoading] = useState(true);
    const [loading, setLoading] = useState(!products?.length);
    const [address, setAddress] = useState('');
    const [savedAddress, setSavedAddress] = useState('');

    const path = usePath();

    const supportsDelivery =
        storeSettings?.supports_scheduled_menu || storeSettings?.supports_ondemand_menu;
    const supportsPickup = storeSettings?.supports_pickup;
    const isPickup = thread?.pickup_destination != null;
    const categoryParams = params.category;

    const [queryProductsParams, setQueryProductsParams] = useState({
        category: categoryParams !== allProductsCategory.category_id ? categoryParams : '',
        lastKey: null
    });

    const {
        state: { currentDeliveryModel },
        appContextActions
    } = useAppContext();

    const prevDeliveryModel = usePrevious(currentDeliveryModel);

    const { data: catalog, mutate: revalidateCatalog } = useCatalog(
        currentDeliveryModel,
        queryProductsParams.category,
        params.query,
        params.filters,
        params.sort,
        params.buy_skus,
        params.buy_skus_not,
        params.buy_ctgs,
        params.buy_ctgs_not,
        queryProductsParams.lastKey
    );

    const { data: getCatalog } = useCatalog(
        currentDeliveryModel,
        queryProductsParams.category,
        params.query,
        params.filters,
        params.sort,
        params.get_skus,
        params.get_skus_not,
        params.get_ctgs,
        params.get_ctgs_not
        // queryProductsParams.lastKey
    );

    const getProductParams =
        params.get_skus || params.get_skus_not || params.get_ctgs || params.get_ctgs_not;

    const isOrderInTransit = isThreadSubmitted(thread);

    const scrollYPosition = useScrollYPosition();
    const { supportsOndemandMenu, supportsScheduledMenu } = useDeliveryModels(zone, storeSettings);

    const onDemandResultEmpty =
        supportsBothMenus(supportsScheduledMenu, supportsOndemandMenu) &&
        isSuperFastOrder(currentDeliveryModel) &&
        !storeSettings?.isOpen;

    setCatalogProductCount(onDemandResultEmpty ? 0 : catalog?.count);

    const fetchMoreListItems = () => {
        if (products && catalog && catalog.lastEvaluatedKey) {
            log('FETCHING MORE........');
            setIsFetching(true);

            setQueryProductsParams({
                ...queryProductsParams,
                lastKey: catalog.lastEvaluatedKey
            });
        }
    };
    const [fetchMore, setFetchMore] = useState(true);
    const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems, fetchMore);
    if (user && params.promo_code) {
        localStorage.setItem(`${user.organizationId}_promo_code`, params.promo_code);
        setParams({ promo_code: undefined });
    }

    const eligibleProduct = {
        name: t('catalogPage.eligibleProduct'),
        description: getCachedPromoMessage()
    };

    useEffect(() => {
        if (session && user && user && window.google) {
            setLoading(false);
            setAddress(user && user.address);
        }
    }, [session, user, catalog]);

    const onSelect = id => {
        appContextActions.pageYOffset.setOffset(scrollYPosition);
        sessionStorage.setItem('previousPage', JSON.stringify({ path, params }));
        navigate(`/p/${id}`, false, params);
    };

    useEffect(() => {
        setShowLoading(true);
        if (
            (catalog?.results &&
                !(products || [])?.find(p => p?.sku === catalog?.results[0]?.sku)) ||
            (catalog?.results && (params.filters || params.sort))
        ) {
            if (products && queryProductsParams.lastKey) {
                setProducts([...products, ...catalog.results]);
            } else {
                setProducts(null);
                setProducts([...catalog.results]);
            }
        }

        if (catalog && (!catalog.results || !catalog.lastEvaluatedKey)) {
            setShowLoading(false);
        }

        const shouldLoadMore = catalog?.results?.length && catalog?.lastEvaluatedKey ? true : false;

        setFetchMore(shouldLoadMore);

        setIsFetching(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalog?.results, prevDeliveryModel, queryProductsParams, categoryParams, params.filters]);

    useEffect(() => {
        if (getCatalog?.results && params.page_description) {
            setEligibleGetProducts([...getCatalog.results]);
        }
    }, [params.page_description, getCatalog?.results]);

    useEffect(() => {
        setIsFetching(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.category]);

    // componentDidMount
    useEffect(() => {
        // setTimeout(() => {
        //     scrollToTop();
        // }, 50);
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            setProducts(null);
            setQueryProductsParams({
                ...queryProductsParams,
                lastKey: null
            });
        };
    }, []);

    useEffect(() => {
        function onCategoryChange() {
            setProducts(null);
            setShowLoading(true);
            if (!storeSettings) return null;
            const { categories } = storeSettings;

            // find the selected category
            const currentCategory =
                params.category && params.category !== allProductsCategory.category_id
                    ? categories.find(cat => cat.category_id === params.category) || null
                    : params.page_description
                      ? eligibleProduct
                      : allProductsCategory;
            setQueryProductsParams({
                ...queryProductsParams,
                category:
                    params.category && params.category !== allProductsCategory.category_id
                        ? currentCategory?.category_id !== allProductsCategory.category_id
                            ? currentCategory?.category_id
                            : ''
                        : '',
                lastKey: null
            });

            setSelectedCategory(currentCategory);
        }
        onCategoryChange();
    }, [queryProductsParams.category, storeSettings?.categories, categoryParams]);

    useEffect(() => {
        if (savedAddress && address && address !== savedAddress) {
            setProducts(null);

            setQueryProductsParams({
                ...queryProductsParams,
                lastKey: null
            });

            setShowLoading(true);
            revalidateCatalog();
        }

        setSavedAddress(address);
    }, [address]);

    useEffect(() => {
        function reloadCatalog() {
            setProducts(null);

            setQueryProductsParams({
                ...queryProductsParams,
                lastKey: null
            });

            setShowLoading(true);
        }

        if (currentDeliveryModel && prevDeliveryModel) {
            reloadCatalog();
        }
    }, [currentDeliveryModel]);

    const { display_price_includes_tax: priceIncludesTax } = storeSettings || {};

    const parseDiscountString = () => {
        let discountStr = '';
        if (params.d_percentage) {
            discountStr = `${params.d_percentage}% ${t('catalogPage.discount')}`;
        } else if (params.d_fixed) {
            discountStr = priceToString(params.d_fixed, global.currency);
        } else if (params.d_cents) {
            // discountStr = priceToString(params.d_cents, global.currency);
            discountStr = `${priceToString(params.d_cents, global.currency)} ${t(
                'catalogPage.discount'
            )}`;
        } else if (params.d_free) {
            discountStr = `${t('catalogPage.free')}`;
        } else {
            discountStr = '';
        }

        return discountStr;
    };

    const noOrderType =
        !(thread?.pickup_destination && supportsPickup) &&
        !(thread?.location?.address && supportsDelivery);

    return (
        <Box>
            {loading ? (
                <Loading margin="0 auto" />
            ) : (
                <Box>
                    {selectedCategory && !params.query && !params.filters && (
                        <Col paddingLeft={20} paddingRight={20}>
                            <Row>
                                <Headline color={themeColors[theme].primaryFontColor1}>
                                    {selectedCategory.name === allProductsCategory.name
                                        ? t('categories.allProducts')
                                        : selectedCategory.name}
                                </Headline>
                            </Row>
                            <Subtitle
                                color={themeColors[theme].secondaryFontColor9}
                                opacity={0.5}
                                fontWeight={500}
                            >
                                {selectedCategory.name === allProductsCategory.name
                                    ? ''
                                    : catalogProductCount != null && !params.page_description
                                      ? `${catalogProductCount} ${
                                            catalogProductCount === 1
                                                ? t('catalogPage.product')
                                                : t('catalogPage.products')
                                        }`
                                      : null}
                            </Subtitle>
                            <Subtitle
                                color={themeColors[theme].secondaryFontColor9}
                                opacity={1}
                                marginTop={10}
                                marginBottom={10}
                                fontWeight={500}
                            >
                                {selectedCategory.name === allProductsCategory.name
                                    ? t('categories.allProductsDesp')
                                    : selectedCategory.description}
                            </Subtitle>{' '}
                        </Col>
                    )}

                    {eligibleGetProducts && getProductParams && (
                        <Box
                            paddingLeft={20}
                            paddingRight={20}
                            fontSize={14}
                            fontWeight={700}
                            marginBottom={20}
                        >
                            {t('catalogPage.buyProduct', { qty: params.buy_qty })}
                        </Box>
                    )}

                    <ProductGrid
                        products={products}
                        onSelect={onSelect}
                        priceIncludesTax={priceIncludesTax}
                        loading={showLoading}
                        storeOpen={storeSettings?.isOpen}
                        supportsOndemandMenu={supportsOndemandMenu}
                        supportsScheduledMenu={supportsScheduledMenu}
                        whenOpen={storeSettings?.whenOpen}
                        cart={cart}
                        isSubmitted={isOrderInTransit}
                        isPickup={isPickup}
                        noOrderType={noOrderType}
                        clearAppliedFilters={clearAppliedFilters}
                        imageContainerFit={storeSettings?.webstore_image_container}
                    />

                    {eligibleGetProducts && getProductParams && (
                        <Box
                            paddingLeft={20}
                            paddingRight={20}
                            fontSize={14}
                            fontWeight={700}
                            marginBottom={20}
                            marginTop={100}
                        >
                            {t('catalogPage.getProduct', {
                                qty: params.get_qty,
                                discount: parseDiscountString()
                            })}
                        </Box>
                    )}

                    {eligibleGetProducts && getProductParams && (
                        <ProductGrid
                            products={eligibleGetProducts}
                            onSelect={onSelect}
                            priceIncludesTax={priceIncludesTax}
                            loading={showLoading}
                            storeOpen={storeSettings?.isOpen}
                            supportsOndemandMenu={supportsOndemandMenu}
                            supportsScheduledMenu={supportsScheduledMenu}
                            whenOpen={storeSettings?.whenOpen}
                            cart={cart}
                            isSubmitted={isOrderInTransit}
                            isPickup={isPickup}
                            noOrderType={noOrderType}
                            clearAppliedFilters={clearAppliedFilters}
                            imageContainerFit={storeSettings?.webstore_image_container}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export function FiltersCount({ count, theme, themeColors }) {
    return (
        <Box
            component="span"
            width={20}
            height={20}
            marginLeft={5}
            fontSize={12}
            fontWeight={900}
            textAlign="center"
            paddingTop={3}
            background={themeColors[theme].secondaryBgColor3}
            borderRadius="50%"
            display="inline-block"
        >
            {count}
        </Box>
    );
}

export default React.memo(Catalog);
