import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import translationEN_US from './translations/en.json';
import translationHE from './translations/he.json';
import translationRU from './translations/ru.json';

const resources = {
    // BCP47 standard for languages
    'en-US': {
        translation: translationEN_US
    },
    'ru-RU': {
        translation: translationRU
    },
    'he-IL': {
        translation: translationHE
    }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en-US',
        keySeparator: '.', // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
