import { useEffect, useState } from 'react';

const useDeliveryModels = (zone, organization) => {
    const [supportsOndemandMenu, setSupportsOndemandMenu] = useState();
    const [supportsScheduledMenu, setSupportsScheduledMenu] = useState();

    useEffect(() => {
        if (zone?.zone_id && organization) {
            const {
                supports_ondemand_menu: zoneSupportOndemand,
                supports_scheduled_menu: zoneSupportScheduled
            } = zone;

            const {
                supports_ondemand_menu: orgSupportOndemand,
                supports_scheduled_menu: orgSupportScheduled
            } = organization;

            const scheduledSetting =
                zoneSupportScheduled != null ? zoneSupportScheduled : orgSupportScheduled || false;

            const ondemandSetting =
                zoneSupportOndemand != null
                    ? zoneSupportOndemand
                    : orgSupportOndemand || (scheduledSetting ? false : true);

            setSupportsOndemandMenu(ondemandSetting);
            setSupportsScheduledMenu(scheduledSetting);
        }
    }, [zone?.zone_id, organization]);

    return { supportsOndemandMenu, supportsScheduledMenu };
};

export default useDeliveryModels;
