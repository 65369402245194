import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function getPickupLocations(organization_id, destination_type, lastEvaluatedKey) {
    const payload = {
        organization_id,
        destination_type,
        lastEvaluatedKey
    };
    return invokeLambda(Lambda.GetPickupLocations, payload);
}
