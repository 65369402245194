import React from 'react';
import { Box } from 'jsxstyle';
import { useLottie } from 'lottie-react';

import spinner from './../assets/images/spinner.json';
import spinnerWhite from './../assets/images/spinner-white.json';

const Spinner = ({ center, white, noMargin, width, height, ...rest }) => {
    const options = {
        animationData: white ? spinnerWhite : spinner,
        loop: true,
        autoplay: true
    };

    const { View } = useLottie(options);

    return (
        <Box
            margin={center ? '35px auto' : noMargin ? '0' : '35px 0'}
            width={width || 22}
            height={height || 22}
            {...rest}
        >
            {View}
        </Box>
    );
};

export default Spinner;
