import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Col, Inline } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { isHebrew } from 'api/utils';

export default function Price({ itemDisplayPrices, small, productPage, flexCenter, ...rest }) {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();

    if (!itemDisplayPrices) return null;

    const getPromocodePrices = promocodePriceString => {
        let priceString = '';

        const isFree = promocodePriceString.includes('Free');

        if (isHebrew()) {
            if (isFree) {
                priceString = promocodePriceString.replace(
                    'for Free',
                    `\u200e${t('cart.item.forFree')}`
                );
            } else if (promocodePriceString.includes('for')) {
                priceString = promocodePriceString.replace('for ', `${t('cart.item.for')}`);
            } else if (promocodePriceString.includes('Free')) {
                priceString = promocodePriceString.replace('Free', `\u200e${t('cart.item.free')}`);
            } else {
                priceString = promocodePriceString;
            }
        } else {
            priceString = promocodePriceString;
        }
        return <Box key={promocodePriceString}>{priceString}</Box>;
    };

    const itemHasPromotionDiscount =
        itemDisplayPrices.original_prices != null && itemDisplayPrices.promotion_prices;

    return (
        <Col
            justifyContent={flexCenter ? 'center' : 'flex-end'}
            fontWeight={900}
            fontSize={small ? '16px' : '30px'}
            color={
                productPage
                    ? themeColors[theme].primaryFontColor1
                    : themeColors[theme].blackFontColor
            }
            {...rest}
        >
            {/* Red price crossed through */}
            {itemHasPromotionDiscount ? (
                <Box
                    fontSize={small ? '12px' : '16px'}
                    color={themeColors[theme].quaternaryFontColor4}
                    textDecoration="line-through"
                    marginBottom={2}
                >
                    {itemDisplayPrices.original_prices?.price_string_per_unit}
                </Box>
            ) : null}
            {/* End Red price crossed through */}

            {itemDisplayPrices.promocode_prices ? (
                // Promocode price for DISCOUNTED_PRODUCT or BUY_1_GET_1 or BUY_1_GET_SAME promocode discount type
                <Box>
                    {/* promocode price  */}
                    {getPromocodePrices(itemDisplayPrices.promocode_prices.price_string)}
                    <>
                        {/* regular price, if not all qty discounted */}
                        {itemDisplayPrices.regular_prices
                            ? getPromocodePrices(
                                  itemDisplayPrices.regular_prices.price_string_per_unit
                              )
                            : null}
                    </>
                </Box>
            ) : // end Promocode price for DISCOUNTED_PRODUCT or BUY_1_GET_1 or BUY_1_GET_SAME promocode discount type
            itemDisplayPrices.regular_prices ? (
                <Box>{itemDisplayPrices.regular_prices?.price_string_per_unit}</Box>
            ) : null}
        </Col>
    );
}
