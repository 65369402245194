import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { Caption } from 'components/shared';

export default function TextArea({
    placeholder,
    showCount = true,
    maxLength = 250,
    resize = 'none',
    onChange,
    value,
    inPopUp,
    ...rest
}) {
    const { theme, themeColors } = useTheme();

    const { t } = useTranslation();
    const [charactersLeft, setCharactersLeft] = useState(maxLength);
    const [currentValue, setCurrentValue] = useState(value);

    const onTextChange = e => {
        // update the text counter
        const validValue = e.target.value.match(/[\p{L}\s\d.,!']{0,1000}/u);
        const clean = validValue[0].replace(/\n/g, ' ');
        // if the user exceed the limit of the max length
        // take only the substring based on the max length value
        setCurrentValue(clean.substring(0, maxLength));

        if (validValue?.[0] && showCount && maxLength > 0) {
            setCharactersLeft(maxLength - e.target.value.length);
        }

        // call the event listener
        onChange(e);
    };

    useEffect(() => {
        setCharactersLeft(maxLength - currentValue.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue]);

    return (
        <Box position="relative">
            <Box
                component="textarea"
                maxWidth="100%"
                background={
                    inPopUp ? themeColors[theme].greyBgColor2 : themeColors[theme].greyBgColor1
                }
                color={themeColors[theme].inputTextColor}
                placeholderColor={themeColors[theme].placeHolderColor}
                borderRadius={8}
                resize={resize}
                border="none"
                padding="15px 20px"
                font="500 16px Heebo"
                letterSpacing={1}
                {...rest}
                props={{
                    placeholder,
                    value: currentValue,
                    onChange: onTextChange,
                    maxLength
                }}
            />
            {showCount && maxLength > 0 && (
                <Caption
                    opacity={0.2}
                    fontSize={10}
                    fontStyle="italic"
                    position="absolute"
                    bottom={5}
                    right={11}
                >
                    {charactersLeft} {t('sharedComponents.textArea.char')}
                </Caption>
            )}
        </Box>
    );
}
