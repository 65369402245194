import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useScript from 'react-script-hook';
import { Box, Col, Row } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useTheme from 'hooks/useTheme';

import attachPaymentMethod from 'api/attachPaymentMethod';
import removePaymentMethod from 'api/removePaymentMethod';

import creditCardInput from 'assets/images/payment-icons/credit-card-input.svg';
import idIcon from 'assets/images/payment-icons/id-icon.svg';
import deleteButton from 'assets/images/trash-icon.svg';

import Button from 'components/Button';
import CreditCardBody from 'components/CreditCardBody';
import Image from 'components/Image';
import PaymentCardIcon from 'components/PaymentCardIcon';
import { Caption, Subtitle } from 'components/shared';
import Spinner from 'components/Spinner';
import TextInput from 'components/TextInput';

let tzlFields = null;

const CardType = {
    unknown: 'none',
    ae: 'amex',
    mc: 'mastercard',
    visa: 'visa',
    isracard: 'mastercard',
    diners: 'diners'
};

const TranzilaPaymentCredit = forwardRef(
    (
        {
            onCardTypeChanged,
            onCardDeleted,
            supplierId,
            paymentMethod,
            onFocus,
            onBlur,
            creditPaymentOnFocus,
            canAddPaymentMethod
        },
        ref
    ) => {
        const { theme, themeColors } = useTheme();
        const { t } = useTranslation();
        const { data: mappedData } = useCustomerData();
        const { user } = mappedData || {};

        const creditCardValidationFields = {
            credit_card_number: false,
            cvv: false,
            expiry: false,
            card_holder_id_number: false
        };

        const fieldRef = useRef(null);
        const expiryRef = useRef(null);

        const [showDeletePaymentMethod, setShowDeletePaymentMethod] = useState(false);
        const [deletingPaymentMethod, setDeletingPaymentMethod] = useState(false);
        const [creditCardType, setCreditCardType] = useState(CardType.unknown);
        const [showPaymentMethodErrorInvalid, setShowPaymentMethodInvalidError] = useState(null);

        useImperativeHandle(ref, () => ({
            async savePaymentMethod() {
                return new Promise((resolve, reject) => {
                    tzlFields.charge(
                        {
                            terminal_name: supplierId,
                            tran_mode: 'V', // for validating the payment method that the user entered
                            amount: 1,
                            tokenize: true // we need to payment method token so we can store it in our backend, the token that will be returned will be used as the user encrypted credit card number
                        },
                        async (err, response) => {
                            if (err) {
                                reject(err);
                                return;
                            }

                            // if transaction failed
                            if (response?.transaction_response?.success === false) {
                                setShowPaymentMethodInvalidError(
                                    response?.transaction_response?.error
                                );
                                reject(response?.transaction_response?.error);
                                return;
                            }

                            // here we managed to create the payment method so we need to store it in the backend
                            const data = await attachPaymentMethod(
                                response?.transaction_response.token,
                                user.organizationId,
                                {
                                    last4: response?.transaction_response
                                        ?.credit_card_last_4_digits,
                                    brand: creditCardType,
                                    expdate: `${response?.transaction_response?.expiry_month}${response?.transaction_response?.expiry_year}`,
                                    cardHolderId:
                                        response?.transaction_response?.user_form_data
                                            ?.card_holder_id_number
                                }
                            );

                            setShowDeletePaymentMethod(false);
                            resolve(data);
                        }
                    );
                });
            }
        }));

        const isCreditCardValid = () => {
            let creditCardIsValid = true;
            for (const key in creditCardValidationFields) {
                if (creditCardValidationFields[key] === false) {
                    creditCardIsValid = false;
                }
            }

            return creditCardIsValid;
        };

        const [loadingTranzila, loadingTranzilaError] = useScript({
            src: 'https://hf.tranzila.com/assets/js/thostedf.js'
        });

        const [loadingJquery, loadingJqueryError] = useScript({
            src: 'https://code.jquery.com/jquery-3.6.0.min.js'
        });

        useEffect(() => {
            if (
                !paymentMethod &&
                !loadingJquery &&
                !loadingJqueryError &&
                !loadingTranzila &&
                !loadingTranzilaError
            ) {
                tzlFields = window.TzlaHostedFields.create({
                    sandbox: false,
                    fields: {
                        credit_card_number: {
                            selector: '#number',
                            placeholder: t('buyNowModal.paymentWithCredit.cardNum'),
                            tabIndex: 1,
                            focusable: true
                        },
                        cvv: {
                            selector: '#cvv',
                            placeholder: 'CVV',
                            tabIndex: 2
                        },
                        expiry: {
                            selector: '#expiry',
                            placeholder: t('buyNowModal.paymentWithCredit.expiry'),
                            version: '1',
                            tabIndex: 3
                        },
                        card_holder_id_number: {
                            selector: '#card_holder_id_number',
                            placeholder: t('buyNowModal.paymentWithCredit.idNumber'),
                            tabindex: 4
                        }
                    }
                });

                tzlFields.onEvent('cardTypeChange', ({ cardType }) => {
                    setCreditCardType(CardType[cardType]);
                    console.log(cardType);
                });

                tzlFields.onEvent('validityChange', ({ field, isValid }) => {
                    creditCardValidationFields[field] = isValid;
                    console.log(`field ${field} is valid ${isValid}`);
                    onCardTypeChanged(isCreditCardValid());
                });

                tzlFields.onEvent('blur', () => onBlur());

                tzlFields.onEvent('focus', () => onFocus());

                tzlFields.onEvent('ready', (e, s) => {});
            }
        }, [
            paymentMethod,
            loadingTranzila,
            loadingJquery,
            loadingJqueryError,
            loadingTranzilaError
        ]);

        if (loadingJquery || loadingJqueryError || loadingTranzila || loadingTranzilaError) {
            return null;
        }

        return (
            <Box maxWidth={428}>
                {paymentMethod ? (
                    <Col>
                        <Row
                            marginTop={10}
                            backgroundColor={themeColors[theme].secondaryBgColor6}
                            height={52}
                            borderRadius={8}
                            paddingLeft={10}
                            paddingRight={10}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <CreditCardBody
                                brand={paymentMethod.brand}
                                last4={paymentMethod.last4}
                            />
                            <Image
                                src={deleteButton}
                                width={16}
                                height={16}
                                onClick={() => {
                                    setShowDeletePaymentMethod(true);
                                }}
                            />
                        </Row>
                        {showDeletePaymentMethod && (
                            <Col marginTop={22}>
                                {!deletingPaymentMethod && (
                                    <Subtitle fontWeight={700} marginBottom={16}>
                                        {t('buyNowModal.paymentWithCredit.removeCard')}
                                    </Subtitle>
                                )}
                                {deletingPaymentMethod ? (
                                    <Row marginTop={16} width="100%" justifyContent="center">
                                        <Spinner />
                                    </Row>
                                ) : (
                                    <Row justifyContent="flex-end">
                                        <Button
                                            backgroundColor={themeColors[theme].secondaryBgColor3}
                                            color={themeColors[theme].primaryFontColor1}
                                            border={`1px solid ${themeColors[theme].secondaryBorderColor2}`}
                                            width={125}
                                            minWidth={125}
                                            onClick={async () => {
                                                const { id } = paymentMethod;
                                                const { organizationId } = user;
                                                try {
                                                    setDeletingPaymentMethod(true);
                                                    await removePaymentMethod(id, organizationId);
                                                    if (onCardDeleted) {
                                                        setCreditCardType(CardType.unknown);
                                                        onCardDeleted(id);
                                                    }
                                                } finally {
                                                    setDeletingPaymentMethod(false);
                                                }
                                            }}
                                        >
                                            {t('buyNowModal.paymentWithCredit.yes')}
                                        </Button>
                                        <Button
                                            width={125}
                                            minWidth={125}
                                            marginLeft={15}
                                            onClick={() => {
                                                setShowDeletePaymentMethod(false);
                                            }}
                                        >
                                            {t('buyNowModal.paymentWithCredit.notNow')}
                                        </Button>
                                    </Row>
                                )}
                            </Col>
                        )}
                    </Col>
                ) : (
                    <Box>
                        <Row
                            marginTop={10}
                            alignItems="center"
                            justifyContent="space-between"
                            backgroundColor={
                                canAddPaymentMethod
                                    ? themeColors[theme].tertiaryBgColor
                                    : themeColors[theme].secondaryBgColor
                            }
                            overflow="hidden"
                            borderRadius={8}
                            // border={creditPaymentOnFocus && '2px solid #0091ff'}
                            border="none"
                            paddingLeft={15}
                            paddingRight={15}
                        >
                            <Row alignItems="center">
                                <PaymentCardIcon
                                    brand={creditCardType}
                                    width={23}
                                    height={16}
                                    useMargins={false}
                                />
                                <Box marginLeft={8}>
                                    <div
                                        id="number"
                                        ref={fieldRef}
                                        style={{
                                            height: 40,
                                            maxHeight: 40
                                        }}
                                    ></div>
                                </Box>
                            </Row>
                            <Row alignItems="center">
                                <Box width={51}>
                                    <div
                                        id="expiry"
                                        style={{
                                            height: 40,
                                            maxHeight: 40
                                        }}
                                        ref={expiryRef}
                                    ></div>
                                </Box>
                                <Box width={50}>
                                    <div
                                        id="cvv"
                                        style={{
                                            height: 40,
                                            maxHeight: 40
                                        }}
                                    ></div>
                                </Box>
                            </Row>
                        </Row>
                        <Row
                            height={40}
                            maxHeight={40}
                            marginTop={10}
                            backgroundColor={
                                canAddPaymentMethod
                                    ? themeColors[theme].tertiaryBgColor
                                    : themeColors[theme].secondaryBgColor
                            }
                            overflow="hidden"
                            borderRadius={8}
                            // border={creditPaymentOnFocus && '2px solid #0091ff'}
                            border="none"
                            paddingLeft={15}
                            paddingRight={15}
                            alignItems="center"
                        >
                            <img src={idIcon} width={16} height={16} />
                            <div
                                id="card_holder_id_number"
                                style={{
                                    height: 40,
                                    maxHeight: 40,
                                    marginLeft: 17
                                }}
                            />
                        </Row>
                        {showPaymentMethodErrorInvalid && (
                            <Subtitle
                                marginTop={5}
                                color={themeColors[theme].quaternaryFontColor2}
                                opacity={1}
                                fontWeight={400}
                            >
                                {showPaymentMethodErrorInvalid}
                            </Subtitle>
                        )}
                    </Box>
                )}
            </Box>
        );
    }
);

export default TranzilaPaymentCredit;
