import find from 'lodash.find';
import useSWR from 'swr';

import { ORDER_TYPE, THREAD_STATUS } from 'api/constants';
import { isSuperFastOrder, removeDoNotAutoApplyPromo, timestamp } from 'api/utils';

import { useAppContext } from 'store';

import { pushToDataLayer } from 'utils/dataLayer';
import { trackPixel } from 'utils/trackingPixels';

import api from '../api';
import useSession from './useSession';
import useStoreSettings from './useStoreSettings';

export default function useCustomerData() {
    const session = useSession();
    const { data: storeSettings } = useStoreSettings();
    const { state } = useAppContext();

    const key = `customer-order-data?oid=${session?.organization_id}&on=${storeSettings?.isOpen}`;

    const options = {
        shouldRetryOnError: true,
        errorRetryInterval: 30000,
        dedupingInterval: 60000,
        focusThrottleInterval: 45000
    };

    const fetch = () => {
        if (session && storeSettings) {
            const isOndemandOrder = isSuperFastOrder(state.currentDeliveryModel);

            return api.getCustomerData(
                session,
                storeSettings.fees,
                storeSettings.includeTaxes,
                storeSettings.defaultTaxType,
                storeSettings.isOpen,
                isOndemandOrder,
                state.storeDomain,
                state.countryCode
            );
        }
    };

    const { data, error, mutate, isValidating, isLoading } = useSWR(key, fetch, options);

    async function cancel(cancel_reason, cancel_reason_added_text) {
        let response;
        try {
            response = await api.cancelThread(data.user, cancel_reason, cancel_reason_added_text);
            if (response.success) {
                await mutate({
                    ...data,
                    thread: {
                        ...data.thread,
                        status: THREAD_STATUS.CANCELED,
                        thread_status: THREAD_STATUS.CANCELED,
                        deliver_by: null,
                        delivery_window: null,
                        closedTime: timestamp()
                    },
                    cart: {
                        ...data.cart,
                        items: []
                    }
                });
            }

            return response;
        } catch (error) {
            console.log('error', error);
            return response;
        }
    }

    async function updateDeliveryInstructions(deliveryInstructions) {
        let response;
        try {
            response = await api.updateDeliveryInstructions(data.user, deliveryInstructions);
            mutate();
        } catch (e) {
            console.log('error', error);
            return response;
        }
    }

    async function updateChange(change) {
        let response;
        try {
            if (change !== null && change !== data?.thread?.change) {
                const defaults = storeSettings.delivery_defaults;
                const deliveryInstructions = {
                    note: data?.thread?.note || defaults.note,
                    delivery_type: data?.thread?.deliveryType || defaults.delivery_type,
                    change
                };
                response = api.updateDeliveryInstructions(data.user, deliveryInstructions);
                mutate();
            }
        } catch (e) {
            console.log('error', error);
            return response;
        }
    }

    function submitOrder(
        deliveryModel,
        delivery_window,
        payment_type,
        order_type,
        pickup_destination
    ) {
        return api.submitOrder(
            data.user.id,
            data.user.threadId,
            data.user.organizationId,
            deliveryModel,
            delivery_window,
            payment_type,
            order_type,
            pickup_destination
        );
    }

    async function applyPromocode(promocode) {
        let response;
        try {
            response = await api.applyPromocode(data.thread.id, data.user.id, promocode);
            // await mutate();
        } catch (e) {
            console.log('error', e);
        }

        return response;
    }

    async function updateThreadDeliverBy(deliver_by, deliver_from) {
        try {
            if (data?.user && data.thread) {
                await api.updateThreadDeliverBy(
                    data.user.authenticated,
                    data.thread.id,
                    deliver_by,
                    deliver_from,
                    data.user.organizationId
                );
                mutate();
            }
        } catch (e) {
            console.log('catch error', e);
        }
    }

    async function updatePickupLocation(pickup_destination) {
        let response;
        try {
            response = await api.updateThreadPickupLocation(
                data.user,
                data.thread?.id,
                ORDER_TYPE.PICKUP,
                pickup_destination
            );
            mutate();
            return response;
        } catch (e) {
            console.log('catch error', e);
            return response;
        }
    }

    async function updateAddress(address, organizationId) {
        let response;
        try {
            if (!data?.user?.location?.address) {
                localStorage.setItem('isNewCustomer', true);
            }
            const res = await api.updateAddress(
                address,
                session.userId,
                organizationId,
                session.authenticated
            );

            localStorage.setItem('addressUpdated', true);

            if (data?.thread?.pickup_destination) {
                await api.updateThreadPickupLocation(
                    data?.user,
                    data.thread?.id,
                    ORDER_TYPE.DELIVERY,
                    ''
                );

                delete data.thread.pickup_destination;
            }

            response = { updated: true, location: res.location };

            if (res.error) {
                response = { error: true, errorCode: res.errorCode, updated: false };
            } else {
                mutate({
                    ...data,
                    user: { ...data.user, location: res.location },
                    thread: { ...data.thread, location: res.location },
                    zone: res.zone
                });

                setTimeout(() => {
                    localStorage.removeItem('isNewCustomer');
                }, 30000);
            }

            return response;
        } catch (e) {
            console.log('catch e', e);
        }

        return response;
    }

    async function validateAddress(address, organizationId) {
        const data = await api.validateAddress(address, organizationId);

        if (data.error) {
            return { error: true, errorCode: data.errorCode, updated: false, zone: data.zone };
        } else {
            return { updated: true, location: data.location, zone: data.zone };
        }
    }

    function authenticate(code) {
        return api.authenticateCustomer(
            code,
            session.organization_id,
            data.thread.order_type,
            data.thread.pickup_destination
        );
    }

    async function sendVerificationCode(phoneNumber) {
        await api.sendCode(
            phoneNumber,
            session.organization_id,
            state.storeDomain,
            state.countryCode
        );
    }

    async function updateCustomerProfileData(username, name, email) {
        try {
            await api.updateCustomerData(username, session.organization_id, name, email);
            mutate();
        } catch (e) {
            console.log('error', e);
        }
    }

    async function deleteAccount(username) {
        try {
            await api.deleteCustomer(username, session.organization_id);
            mutate();
        } catch (e) {
            console.log('error', e);
        }
    }

    async function uploadPicture(picture, bucket) {
        try {
            await api.uploadCustomerPicture(
                picture,
                session.userId,
                session.organization_id,
                bucket
            );
            mutate();
        } catch (e) {
            console.log('error', e);
        }
    }

    async function logout() {
        await api.closeSession();
    }

    async function addItem(id, qty, product) {
        try {
            removeDoNotAutoApplyPromo();
            pushToDataLayer({
                event: 'add_to_cart',
                ecommerce: {
                    currency: 'USD',
                    items: [
                        {
                            item_name: product.name,
                            item_id: product.sku, //May be same as sku
                            item_sku: product.sku,
                            price: (
                                (product.display_promotion_price ||
                                    product.display_total_price ||
                                    0) / 100
                            ).toFixed(2), //In dollars not cents
                            //item_brand: 'STIIIZY, LLC', //Only if available
                            //item_category: 'Skateboard Deck', //Only a visble category
                            quantity: qty
                        }
                    ]
                }
            });

            if (storeSettings?.pixel_integrations_config) {
                const { advertiser_id, events, share_with_meta, token } =
                    storeSettings.pixel_integrations_config;

                if (events?.add_to_cart?.pixel_id) {
                    trackPixel({
                        isMeta: share_with_meta,
                        advertiser_id,
                        pixel_id: events.add_to_cart.pixel_id,
                        token,
                        user_data: data.user
                    });
                }
            }

            const updatedCartItems = await api.addToCart(data.user, id, qty, product);

            const cart = {
                ...data.cart,
                items: updatedCartItems
            };
            await mutate({ ...data, cart });
        } catch (e) {
            console.log('error', e);
        }
    }

    async function removeItem(id) {
        try {
            const response = await api.removeFromCart(data.user, id);
            if (response?.updated_cart) {
                mutate({ ...data, cart: { ...data.cart, items: response?.updated_cart } });
            } else {
                mutate();
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    async function clearCart() {
        try {
            const promises = data.cart?.items?.map(({ item_id }) =>
                api.removeFromCart(data.user, item_id)
            );
            await Promise.all(promises);

            mutate({ ...data, cart: { ...data.cart, items: [] } });
        } catch (e) {
            console.log('error', e);
        }
    }

    async function setQuantity(id, qty, productId) {
        try {
            removeDoNotAutoApplyPromo();
            const response = await api.setCartQuantity(data.user, id, qty, productId);

            if (response?.updated_cart) {
                mutate({ ...data, cart: { ...data.cart, items: response?.updated_cart } });
            } else {
                mutate();
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    async function updateCartItems(orgId, threadId, items) {
        removeDoNotAutoApplyPromo();
        return await api.updateCartItems(orgId, threadId, items);
    }

    async function setProductQuantity(product, qty) {
        removeDoNotAutoApplyPromo();
        const item = find(data.cart.items, item => item.product.product_id === product.product_id);
        let response;
        if (!item) {
            response = await addItem(product.product_id, qty, product);
        } else if (qty === 0) {
            response = await removeItem(item.item_id);
        } else {
            response = await setQuantity(item.item_id, qty, product.product_id);
        }

        if (response?.updated_cart) {
            mutate({ ...data, cart: { ...data.cart, items: response?.updated_cart } });
        }
    }

    function sendOrderPlacedMessage() {
        return api.sendMessageOrderPlaced(data.user);
    }

    async function delayOrder() {
        try {
            if (data) {
                const response = await api.delayOrder(
                    data.user.id,
                    data.user.threadId,
                    data.user.organizationId
                );
                mutate();

                return response;
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    async function getActiveTopPromotion(current_selected_campaign) {
        if (data.thread.location?.address || data.thread.pickup_destination) {
            const { promotion } = await api.getActiveTopPromotion(
                data.user,
                storeSettings?.organization_id,
                data.thread.associated_hub,
                data.cart,
                current_selected_campaign
            );
            return promotion;
        }
    }

    return {
        data,
        error,
        mutate,
        isValidating,
        isLoading,
        store: {
            updatePickupLocation,
            cancel,
            updateDeliveryInstructions,
            submitOrder,
            updateChange,
            applyPromocode,
            updateThreadDeliverBy,
            updateAddress,
            validateAddress,
            updateCustomerProfileData,
            deleteAccount,
            uploadPicture,
            sendVerificationCode,
            authenticate,
            logout,
            addItem,
            removeItem,
            setQuantity,
            setProductQuantity,
            sendOrderPlacedMessage,
            updateCartItems,
            clearCart,
            delayOrder,
            getActiveTopPromotion
        }
    };
}
