const isProd = process.env.NODE_ENV === 'production';
const HOST = isProd ? window.location.origin : process.env.REACT_APP_HOST;

export const Endpoint = {
    Handoff: HOST + '/d/'
};

export const Lambda = {
    GetCatalog: 'com_lazzy_salesmain_getProductsWithSearch',
    GetCatalogFromInventory: 'com_lazzy_salesmain_getAvailableProductsWithSearch',
    GetProduct: 'com_lazzy_salesmain_getProduct',
    GetUser: 'com_lazzy_salesmain_getCustomer',
    GetCart: 'com_lazzy_salesmain_getCart',
    GetThread: 'com_lazzy_salesmain_getOrderData',
    GetActiveThread: 'com_lazzy_webstore_getOrderStatus',
    OrdersHistory: 'com_lazzy_salesmain_getOrdersHistory',
    AddToCart: 'com_lazzy_salesmain_addItemToCart',
    RemoveFromCart: 'com_lazzy_salesmain_removeItemFromCart',
    SetCartQuantity: 'com_lazzy_salesmain_updateCartItemCount',
    SendMessageWithKey: 'com_lazzy_salesmain_prepareMessageWithKey',
    CancelThread: 'com_lazzy_salesmain_cancelOrder',
    VerifyCompliance: 'com_lazzy_webstore_verifyCompliance',
    GetZoneById: 'com_lazzy_salesmain_unauth_getZoneById',
    updateCustomerZone: 'com_lazzy_salesmain_unauth_updateCustomerZone',
    UpdateDeliveryInstructions: 'com_lazzy_salesmain_updateDeliveryInstructions',
    checkCartAvailability: 'com_lazzy_salesmain_checkCartAvailability',
    UpdateAddress: 'com_lazzy_salesmain_updateAddress',
    updateCustomerData: 'com_lazzy_salesmain_updateCustomerData',
    deleteCustomer: 'com_lazzy_salesmain_deleteCustomer',
    uploadCustomerPicture: 'com_lazzy_salesmain_uploadCustomerImage',
    submitOrder: 'com_lazzy_salesmain_submitOrder',
    SendVerificationCode: 'com_lazzy_salesmain_unauth_sendVerificationCode',
    GetZoneForLocation: 'com_lazzy_salesmain_unauth_getZoneForLocation',
    findOrCreateCustomer: 'com_lazzy_salesmain_unauth_findOrCreateCustomer',
    AttachPaymentMethod: 'com_lazzy_payments_attachPaymentMethod',
    GetPaymentMethods: 'com_lazzy_payments_getPaymentMethods',
    RemovePaymentMethod: 'com_lazzy_payments_removePaymentMethod',
    UpdateThreadWithPaymentInfo: 'com_lazzy_payments_updateThreadWithPaymentInfo',
    applyPromocode: 'com_lazzy_salesmain_applyPromocode',
    UpdateCartItems: 'com_lazzy_salesmain_updateCartItems',
    GetPaymentCalculation: 'com_lazzy_payments_getPaymentCalculation',
    DeleteHandoff: 'com_lazzy_webstore_deleteHandoff',
    getCategorizedCatalog: 'com_lazzy_webstore_unauth_getCategorizedCatalog',
    getSignedUrl: 'com_lazzy_webstore_getSignedURLForFileOperation',
    UpdateThread: 'com_lazzy_salesmain_updateThread',
    GetAvailableProductsCount: 'com_lazzy_salesmain_unauth_getAvailableProductsCount',
    GetStoreSettings: 'com_lazzy_webstore_unauth_getStoreSettings',
    GetCustomerData: 'com_lazzy_webstore_getCustomerData',
    DelayOrder: 'com_lazzy_salesmain_delayOrder',
    GetWindows: 'com_lazzy_salesmain_getWindows',
    UpdateThreadDeliveryModel: 'com_lazzy_salesmain_updateThreadDeliveryModel',
    GetPickupLocations: 'com_lazzy_salesmain_unauth_getDestinationsForOrganization',
    GetActiveTopPromotion: 'com_lazzy_salesmain_getActiveTopPromotion',
    getActivePromotionsForCatalog: 'com_lazzy_salesmain_getActivePromotionsForCatalog',
    GetFilterComponent: 'com_lazzy_salesmain_unauth_getFilterComponent'
};

export const Page = Object.freeze({
    Refer: 'refer',
    RefLink: 'ref',
    Cart: 'cart',
    PromoRelay: 'promoRelay'
});

export const THREAD_STATUS = Object.freeze({
    /** @return 0 */
    OPEN: 0,
    /** @return 1 */
    LOCKED: 1,
    /** @return 2 */
    CLOSED: 2,
    /** @return 3 */
    WITHDRAWN: 3,
    /** @return 4 */
    CANCELED: 4,
    /** @return 5 */
    PENDING: 5
});

export const KIT_STATUS = Object.freeze({
    /** @return 0 */
    CREATED: 0,
    /** @return 1 */
    IN_TRANSIT: 1,
    /** @return 2 */
    ARRIVED: 2,
    /** @return 3 */
    DAMAGED: 3,
    /** @return 4 */
    UNPACKED: 4
});

export const NOTIFICATION_TYPE = Object.freeze({
    /** @return 0 */
    THREAD_UPDATE: 0,
    /** @return 2 */
    NEW_REQUEST: 2,
    /** @return 3 */
    REQUEST_REMOVED: 3,
    /** @return 4 */
    UPDATED_KIT: 4,
    /** @return 5 */
    UPDATED_ITEM: 5
});

export const ORDER_CHANNEL = Object.freeze({
    /** @return 0 */
    SMS: 0,
    /** @return 1 */
    WHATSAPP: 1,
    /** @return 2 */
    WEB: 2
});

export const ITEM_STATUS = Object.freeze({
    /** @return 0 */
    CREATED: 0,
    /** @return 1 */
    DAMAGED: 1,
    /** @return 2 */
    RETURNED: 2,
    /** @return 3 */
    LOCKED_TO_KIT: 3,
    /** @return 4 */
    DELIVERING: 4,
    /** @return 5 */
    DELIVERED: 5,
    /** @return 6 */
    PICKED_UP: 6,
    /** @return 7 */
    REMOVED_FROM_KIT: 7
});

export const PERSON_ROLES = Object.freeze({
    /** @return 0 */
    NONE: 0,
    /** @return 1 */
    REQUESTER: 1,
    /** @return 2 */
    DELIVERER: 2,
    /** @return 3 */
    OBSERVER: 3
});

export const PAYMENT_STATUS = Object.freeze({
    /** @return 0 */
    UNPAID: 0,
    /** @return 1 */
    PENDING: 1,
    /** @return 2 */
    PENDING_FAILED: 2,
    /** @return 3 */
    SUCCESS: 3
});

export const SUPPORTED_TRANSACTION_TYPE = Object.freeze({
    /** @return 0 */
    CASH: 0,
    /** @return 1 */
    CREDIT: 1,
    /** @return 2 */
    CASH_AND_CREDIT: 2
});

export const DYNAMO_RETURN = Object.freeze({
    /** @return ALL_OLD */
    ALL_OLD: 'ALL_OLD',
    /** @return ALL_NEW */
    ALL_NEW: 'ALL_NEW',
    /** @return NONE */
    NONE: 'NONE'
});

export const CONSUMER_TYPE = Object.freeze({
    /** @return medical */
    MEDICAL: 'medical',
    /** @return regular */
    REGULAR: 'regular'
});

export const ORDER_REQUIREMENTS = Object.freeze({
    /** @return 0 */
    MEETS: 0,
    /** @return 1 */
    LESS_MIN_ORDER: 1
});

export const TAX_TYPE = Object.freeze({
    /** @return 1 */
    COMPLIANCE: 1,
    /** @return 2 */
    STANDARD: 2
});

//DEPRECATED, USE AVAILABILITY_LEVEL
export const SHOW_STATE = Object.freeze({
    /** @return 1 */
    SHOW_AVAILABLE: 1,
    /** @return 2 */
    SHOW_ALWAYS: 2,
    /** @return 3 */
    NOT_SHOW: 3
});

export const AVAILABILITY_LEVEL = Object.freeze({
    /** @return zone */
    ZONE: 'zone',
    /** @return hub */
    HUB: 'hub',
    /** @return hidden */
    HIDDEN: 'hidden'
});

export const INVENTORY_STAGE = Object.freeze({
    /** @return 0 */
    CREATED: 0,
    /** @return 1 */
    IN_BACK: 1,
    /** @return 2 */
    IN_FRONT: 2,
    /** @return 3 */
    IN_INACTIVE_KIT: 3,
    /** @return 4 */
    IN_ACTIVE_KIT: 4,
    /** @return 5 */
    IN_CLOSED_KIT: 5,
    /** @return 8 */
    LOCKED_TO_THREAD: 8,
    /** @return 11 */
    DELETED: 11
});

export const ES_INDEXES = Object.freeze({
    /** @return inventory_items_search */
    INVENTORY: 'inventory_items_search',
    /** @return general_search */
    PRODUCTS: 'general_search',
    /** @return global_keyword_search */
    GLOBAL_SEARCH: 'global_keyword_search'
});

export const DESTINATIONS_TYPE = Object.freeze({
    /** @return 0 */
    WAREHOUSE: 0,
    /** @return 1 */
    STORE: 1,
    /** @return 2 */
    DELIVERY_VEHICLE: 2,
    /** @return 3 */
    DYNAMIC_DELIVERY_VEHICLE: 3,
    /** @return 4 */
    VENDOR: 4,
    /** @return 5 */
    CUSTOMER: 5
});

export const PERMISSIONS = Object.freeze({
    /** @return INVENTORY */
    INVENTORY: 'INVENTORY',
    /** @return DRIVER */
    DRIVER: 'DRIVER',
    /** @return MANAGEMENT_READ */
    MANAGEMENT_READ: 'MANAGEMENT_READ',
    /** @return MANAGEMENT_STANDARD */
    MANAGEMENT_STANDARD: 'MANAGEMENT_STANDARD',
    /** @return MANAGEMENT_ADMIN */
    MANAGEMENT_ADMIN: 'MANAGEMENT_ADMIN',
    /** @return MANAGEMENT_OWNER */
    MANAGEMENT_OWNER: 'MANAGEMENT_OWNER',
    /** @return CUSTOMER_SUPPORT */
    CUSTOMER_SUPPORT: 'CUSTOMER_SUPPORT',
    /** @return ANALYST */
    ANALYST: 'ANALYST',
    /** @return Inventory Handler */
    INVENTORY_LEGACY: 'Inventory Handler',
    /** @return Delivery Driver*/
    DRIVER_LEGACY: 'Delivery Driver',
    /** @return Customer Support Legacy */
    CUSTOMER_SUPPORT_LEGACY: 'Customer Support Rep (Call Center)',
    /** @return  Customer Support Admin */
    CUSTOMER_SUPPORT_ADMIN: 'CUSTOMER_SUPPORT_ADMIN',
    /** @return Management System*/
    MANAGEMENT_LEGACY: 'Management System'
});

export const cipherKeyStoragePath = 'data_encryption_key';

export const ETA_STATUS = Object.freeze({
    /** @return -1 */
    DELAYED: -1,
    /** @return 0 */
    PROCESSING: 0,
    /** @return 1 */
    ON_WAY: 1,
    /** @return 2 */
    CLOSE: 2,
    /** @return 3 */
    ARRIVING: 3
});

export const GEO_HASH_KEY_LENGTH = 6;

export const DEFAULT_REGION = 'us-east-1';

export const KIT_IS_TEMPLATE = 1;

export const PROMOCODE_STATUS = {
    /** @return 'available' */
    AVAILABLE: 'available',
    /** @return 'applied' */
    APPLIED: 'applied',
    /** @return 'consumed' */
    CONSUMED: 'consumed'
};

export const ORDER_ASSISTANT = {
    /** @return 'Bot' */
    BOT: 'Bot'
};

export const CHANNEL_TYPE = {
    /** @return 'sms' */
    SMS: 'sms',
    /** @return 'call' */
    CALL: 'call',
    /** @return 'web' */
    WEB: 'web'
};

export const ERROR_TYPE = {
    /**@returns 'EXPECTED_FAIL' Means that the error is a result of expected behavior and a product of our logic (ie a customer tried to submit an order below the organization order minimum) */
    EXPECTED_FAIL: 'EXPECTED_FAIL',
    /**@returns  'UNEXPECTED_FAIL' Means the error is unexpected (ie a DynamoDB error when doing a query) */
    UNEXPECTED_FAIL: 'UNEXPECTED_FAIL'
};

export const ERROR_SOURCE = {
    /**@returns 'CLIENT'  Means error created by client */
    CLIENT: 'CLIENT',
    /**@returns  'SERVER' Means error created by server*/
    SERVER: 'SERVER'
};

export const CancellationReasons = {
    TL: 'The ETA is too long',
    TS: 'The ETA is too fast. I am not ready',
    MS: 'I ordered by mistake',
    PM: 'I need to change payment method',
    AD: 'I need to change delivery address',
    UP: 'I need to update my order',
    DN: 'I no longer want my order',
    BD: 'I found a better deal somewhere else',
    OT: 'Other'
};
export const ApplicationId = 'WEB_OPS';

export const allProductsCategory = {
    category_id: 'all',
    name: 'All Products',
    description: 'Browse all products'
};

export const home = 'home';

export const sm = 'screen and (max-width: 480px)';

export const SuperfastWindow = {
    description: 'super fast',
    window_id: ''
};

export const ThreadDeliveryModel = {
    superfast: 'superfast',
    sfWindowId: '',
    scheduled: 'scheduled'
};

export const WindowSubType = {
    range: 'range',
    nextday: 'nextday',
    asap: 'asap'
};

export const ORDER_TYPE = {
    PICKUP: 'pickup',
    DELIVERY: 'delivery',
    IN_STORE: 'instore'
};

export const THEME = {
    LIGHT: 'light',
    DARK: 'dark'
};

export const PROMOCODEKEY = 'promoCode';

export const CATEGORY_TYPES = {
    PROMOTIONS_CATEGORY: 'promotionsCategory',
    PRODUCTS_CATEGORY: 'productsCategory'
};

export const TransactionsTypes = {
    Credit: 'Credit Card',
    Cash: 'Cash',
    CreditAndCash: 'Credit and Cash'
};
