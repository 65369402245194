import React, { useState } from 'react';
import { Block, Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from '../api/constants';
import arrow from '../assets/images/arrow-bottom.svg';
import arrowLight from '../assets/images/arrow-bottom-light.svg';
import arrowDown from '../assets/images/arrow-down.svg';
import Image from './Image';

export default function Collapse(props) {
    const {
        title,
        children,
        noBorder,
        noPadding,
        grey,
        bigArrow,
        showBorder,
        addSpace,
        filterCategory,
        scrollCategoryType
    } = props;
    const [open, setOpen] = useState(false);
    const onClick = () => {
        setOpen(!open);
        setTimeout(() => filterCategory && scrollCategoryType(filterCategory), 100);
    };
    const { theme, themeColors } = useTheme();

    return (
        <Block
            borderBottom={
                (open && !showBorder) || noBorder
                    ? 'none'
                    : `1px solid ${themeColors[theme].secondaryBorderColor10}`
            }
            paddingBottom={addSpace && open && 45}
            className={filterCategory && filterCategory}
        >
            <Row
                justifyContent="space-between"
                alignItems="center"
                padding={noPadding ? '0' : '25px 0'}
                cursor="pointer"
                props={{ onClick }}
            >
                <Box maxWidth="calc(100% - 30px)">{title}</Box>
                <Box>
                    <Image
                        opacity={grey ? '.5' : '1'}
                        src={bigArrow ? arrowDown : theme === THEME.DARK ? arrowLight : arrow}
                        width={theme === THEME.DARK && 10}
                        alt="Arrow"
                        transform={open ? 'rotate(180deg)' : 'rotate(0)'}
                        transformOrigin={open ? '50% 50%' : '0 0'}
                    />
                </Box>
            </Row>
            {open && children}
        </Block>
    );
}
