import { getCacheData } from '../utils/cacheDataManager';
import { CHANNEL_TYPE, Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function getThread(customer, thread_id) {
    const { id, authenticated, organizationId } = customer;
    if (!authenticated) {
        return (await getCacheData(id, organizationId)).thread;
    }

    if (thread_id) {
        const payload = {
            thread_id,
            user_id: id,
            username: customer.username
        };
        const { thread } = await invokeLambda(Lambda.GetThread, payload);
        return mapThread(thread);
    } else {
        const payload = {
            user_id: id,
            username: customer.username,
            organization_id: customer.organizationId,
            channelType: CHANNEL_TYPE.WEB
        };
        const thread = await invokeLambda(Lambda.GetActiveThread, payload);
        if (!thread.menu_kit) {
            const menu_kit = sessionStorage.getItem('menu_kit') || null;

            if (menu_kit) {
                const payload = {
                    thread_id: thread.thread_id,
                    menu_kit
                };

                await invokeLambda(Lambda.UpdateThread, payload);
            }
        }
        return mapThread(thread);
    }
}

export function mapThread(data) {
    const { delivery_instructions } = data.location;
    const delayTime = data.eta && data.eta.delay_time;
    const eta = data.eta && data.eta.eta;
    const etaLastUpdateTime = data.eta && data.eta.last_update_time * 1000;

    //need to save zone_id and location for when user logout address will be saved
    if (data.zone_id && data.location) {
        localStorage.setItem(
            'geolocation',
            JSON.stringify({
                geoLocation: data.location,
                zoneId: data.zone_id,
                associated_hub: data.associated_hub
            })
        );
    }

    return {
        ...data,
        id: data.thread_id,
        status: data.thread_status,
        trackingNumber: data.lookup_id,
        ...(delivery_instructions && {
            deliveryType: delivery_instructions.delivery_type,
            change: delivery_instructions.change,
            note: delivery_instructions.note
        }),

        ...(data.payment_details && {
            charges: {
                //subtotal now is whole cost
                subtotal: data.payment_details.subtotal_after_discount,
                fees: data.payment_details.total_taxes,
                delivery: data.payment_details.delivery_rate,
                total: data.payment_details.total_charge_in_cents,
                promocodeDiscount: data.payment_details.promocode_discount
                    ? data.payment_details.promocode_discount
                    : null,
                roundup: data.payment_details.round_up_addition,
                platformFee: data.payment_details?.platform_fee_total,
                platformFeeDesp: data.payment_details?.platform_fee_description
            },
            paymentDetails: {
                paymentTransactionType: data.payment_details.payment_transaction_type,
                paymentCardBrand: data.payment_details.payment_card_brand,
                paymentCardLast4: data.payment_details.payment_card_last4
            }
        }),
        eta,
        etaLastUpdateTime,
        zoneId: data.zone_id,
        organizationId: data.organization_id,
        license: data.license,
        closedTime: data.closed_time,
        address: data.location.address,
        location: data.location,
        promoCode: data.promo_code,
        kit_id: data.kit_id,
        members: data.members,
        associated_hub: data.associated_hub,
        delayTime,
        menu_kit: data.menu_kit,
        deliver_by: data.deliver_by,
        delayed_deliver_by: data.delayed_deliver_by
    };
}
