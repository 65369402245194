import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Icon from 'components/Icon';

const paymentTypes = ['Credit Card', 'Cash'];

export default function CashAndCreditPayment({
    cashPayment,
    creditPayment,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    paymentMethod,
    requireExactChange
}) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    return (
        <Box>
            <Row maxWidth={428}>
                {paymentTypes.map(type => (
                    <PaymentBtn
                        key={type}
                        active={selectedPaymentMethod === type}
                        onClick={() => {
                            setSelectedPaymentMethod(type);
                        }}
                        type={type}
                        sm={'screen and (min-width: 360px)'}
                        theme={theme}
                        themeColors={themeColors}
                    >
                        {t(`paymentMethods.${type}`)}
                    </PaymentBtn>
                ))}
            </Row>
            <Box
                fontSize={12}
                fontWeight={500}
                color={themeColors[theme].secondaryFontColor1}
                letter-spacing="1px"
                line-height="12px"
            >
                {selectedPaymentMethod === 'Cash'
                    ? requireExactChange && t('paymentMethods.cashSubtitle')
                    : paymentMethod
                      ? t('paymentMethods.chageCard')
                      : t('paymentMethods.addCreditCard')}
            </Box>
            <Box marginTop={40}>
                {selectedPaymentMethod === 'Cash' ? cashPayment : creditPayment}
            </Box>
        </Box>
    );
}

const PaymentBtn = ({ children, active, onClick, type, sm, theme, themeColors }) => (
    <Box
        component="button"
        fontSize={16}
        color={themeColors[theme].tertiaryFontColor2}
        font-weight="500"
        padding="9px 0px 9px 35px"
        mediaQueries={{ sm }}
        smPaddingRight={40}
        margin="30px 0 10px"
        width={type === 'Cash' ? '40%' : '60%'}
        cursor="pointer"
        opacity={active ? '1' : '0.4'}
        background={themeColors[theme].primaryBgColor4}
        border="0"
        outline="0"
        props={{ onClick }}
        borderBottom={
            active
                ? `3px solid ${themeColors[theme].tertiaryBorderColor1}`
                : `1px solid ${themeColors[theme].secondaryBorderColor3}`
        }
        position="relative"
    >
        <Icon
            name={type === 'Cash' ? 'cash' : 'paymentCard'}
            position="absolute"
            top={type === 'Cash' ? 14 : 12}
            left={type === 'Cash' ? 15 : 18}
        />
        {children}
    </Box>
);
