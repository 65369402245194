import React, { useEffect, useState } from 'react';
import { isAndroid, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { navigate, useQueryParams } from '@patched/hookrouter';
import { Box, Col, Row } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useDeliveryModels from 'hooks/useDeliveryModels';
import usePickupLocations from 'hooks/usePickupLocations';
import useProduct from 'hooks/useProduct';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';

import { sm } from 'api/constants';
import {
    getCachedPromoCode,
    getCachedPromoLink,
    getCurrentDeliveryModel,
    numberInCart,
    threadSubmitted
} from 'api/utils';

import { useAppContext } from 'store';

import { pushToDataLayer } from 'utils/dataLayer';

import Alert from 'components/Alert';
import CheckoutBar from 'components/CheckoutBar';
import Closebar from 'components/CloseBar';
import ClosedNotice from 'components/ClosedNotice';
import Heading from 'components/Heading';
import Head from 'components/HeadManager';
import Image from 'components/Image';
import InTransitNotice from 'components/InTransitNotice';
import Modal from 'components/Modal';
import DeliveryAddressModal from 'components/Modals/DeliveryAddressModal';
import Price from 'components/Price';
import QuantityControl from 'components/QuantityControl';
import { Divider, Subtitle } from 'components/shared';
import Text from 'components/shared/FormattedText';
import Spinner from 'components/Spinner';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Product(props) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();
    const { data: pickupLocations } = usePickupLocations();

    const [params, setParams] = useQueryParams();
    const {
        state: { currentDeliveryModel, showDeliveryAddressModal },
        appContextActions
    } = useAppContext();

    const { data: mappedData, store } = useCustomerData();
    const { user, thread, zone, cart } = mappedData || {};

    //sometimes agents need to see product info, so we loading page without availability data
    const viewModeOnly = !!params.zid;

    if (viewModeOnly) {
        localStorage.setItem('ageVerified', true);
    }

    const { data: storeSettings } = useStoreSettings();
    const [settings, setSettings] = useState(null);

    const { supportsOndemandMenu, supportsScheduledMenu } = useDeliveryModels(zone, storeSettings);

    const product = useProduct(props.id, currentDeliveryModel, params.zid);

    const [productData, setProductData] = useState(null);

    useEffect(() => {
        if (product.data) {
            setProductData(product.data);
        }
    }, [product.data]);

    useEffect(() => {
        if (storeSettings) {
            setSettings(storeSettings);
        }
    }, [storeSettings]);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [productImages, setProductImages] = useState([]);

    const [qty, setQty] = useState(0);

    const [updatingItem, setUpdatingItem] = useState(false);
    const deviceWidth = isAndroid ? window.screen.width : window.innerWidth;
    const [alertSettings, setAlertSettings] = useState({
        show: false,
        fadeOut: false,
        message: '',
        icon: null
    });

    useEffect(() => {
        if (settings && productData?.product_id) {
            pushToDataLayer({
                event: 'view_item',
                ecommerce: {
                    currency: 'USD',
                    items: [
                        {
                            item_name: productData.name,
                            item_id: productData.sku,
                            item_sku: productData.sku,
                            price: (
                                (productData.display_promotion_price ||
                                    productData.display_total_price ||
                                    0) / 100
                            ).toFixed(2)
                        }
                    ]
                }
            });
        }
    }, [settings, productData]);

    useEffect(() => {
        if (
            settings &&
            thread &&
            !currentDeliveryModel &&
            supportsOndemandMenu != null &&
            supportsScheduledMenu != null
        ) {
            const _deliveryModel = getCurrentDeliveryModel(
                thread,
                settings?.isOpen,
                supportsOndemandMenu,
                supportsScheduledMenu
            );

            appContextActions.deliveryModel.set(_deliveryModel);
        }
    }, [settings, thread, currentDeliveryModel, supportsOndemandMenu, supportsScheduledMenu]);

    useEffect(() => {
        const showPromoAlert = getCachedPromoCode() && getCachedPromoLink();

        if (showPromoAlert && localStorage.getItem('showPromoAppliedNotification')) {
            setAlertSettings({
                show: true,
                fadeOut: false,
                message: t('categories.promoApplied', {
                    promocode: getCachedPromoCode()
                }),
                icon: 'whiteCheck'
            });
            setTimeout(() => {
                setAlertSettings({
                    ...alertSettings,
                    show: false,
                    fadeOut: true
                });
            }, 5000);
            localStorage.removeItem('showPromoAppliedNotification');
        }
    }, [cart, cart?.items, thread?.promoCode]);

    const previousPage = sessionStorage.getItem('previousPage')
        ? JSON.parse(sessionStorage.getItem('previousPage'))
        : null;

    const onClose = () => {
        if (viewModeOnly) {
            setParams({}, true);
            navigate('/');
        } else {
            if (previousPage) {
                navigate(previousPage.path, false, previousPage.params);
            } else {
                navigate('/');
            }
        }
    };

    const onAdd = async () => {
        if (noOrderType) {
            appContextActions.pendingAction.set(() =>
                store.addItem(productData?.product_id, 1, productData)
            );
            appContextActions.deliveryAddressModal.open();
        } else {
            setQty(last => last + 1);
            try {
                setUpdatingItem(true);
                await store.addItem(productData?.product_id, 1, productData);
            } finally {
                setUpdatingItem(false);
            }
        }
    };
    const onRemove = async () => {
        setQty(last => last - 1);
        const quantity = qty - 1;
        try {
            setUpdatingItem(true);
            await store.setProductQuantity(productData, quantity);
        } finally {
            setUpdatingItem(false);
        }
    };

    // useEffect(() => {
    //     const shouldNavigateToMain =
    //         thread && !thread.zoneId && !thread.pickup_destination && !viewModeOnly;
    //     if (shouldNavigateToMain) {
    //         navigate('/');
    //     }
    // }, [thread]);

    const inCart = cart && product && product.data && numberInCart(product.data, cart.items || []);

    useEffect(() => {
        const qty = inCart || 0;
        setQty(qty);
    }, [inCart]);

    useEffect(() => {
        if (productData) {
            const { image_url, additional_image_urls } = productData;

            if (additional_image_urls && additional_image_urls.length) {
                setProductImages([image_url, ...additional_image_urls]);
            } else {
                setProductImages([image_url]);
            }
        }
    }, [productData]);

    const onAddressUpdate = async (value, organizationId) => {
        await store.updateAddress(value, organizationId);
    };

    function Wrapper(props) {
        return (
            <Modal backgroundColor="red">
                <Row justifyContent="space-between" alignItems="center" minHeight={80}>
                    <Closebar
                        theme={theme}
                        onClose={onClose}
                        text={!previousPage && 'Browse Catalog'}
                    />
                </Row>

                <Box marginTop={10}>{props.children}</Box>
            </Modal>
        );
    }

    const isLoading = !productData || (!thread && !viewModeOnly); //|| !cart.data;
    const noOrderType = !thread?.pickup_destination && !thread?.location?.address;
    const isPickup = thread?.pickup_destination;

    if (isLoading) {
        return (
            <Wrapper>
                {t('product.loading')}
                <Spinner />
            </Wrapper>
        );
    }

    const isSubmitted = threadSubmitted({
        ...thread,
        thread_status: thread.status
    });

    const { isOpen, whenOpen } = settings || {};
    const { display_price_includes_tax: priceIncludesTax } = settings || {};

    const {
        name,
        additional_info,
        available_now,
        description,
        display_total_price,
        display_promotion_price
    } = productData;
    const imageHolders = {
        0: [],
        1: []
    };

    for (let i = 0; i < 5; i++) {
        imageHolders[0].push({
            url: productImages[i] || t('product.empty'),
            index: i
        });
    }

    for (let i = 5; i < 10; i++) {
        imageHolders[1].push({
            url: productImages[i] || t('product.empty'),
            index: i
        });
    }

    const inStockCaption = !viewModeOnly
        ? available_now && available_now > 5
            ? `${t('product.inStock')}`
            : `${available_now || 0} ${t('product.left')}`
        : null;

    const AddProduct = () => {
        return (
            <Row
                justifyContent="center"
                marginTop={10}
                marginBottom={30}
                width="100%"
                opacity={isSubmitted || viewModeOnly ? '0.2' : '1'}
            >
                {!viewModeOnly && (
                    <QuantityControl
                        value={qty}
                        onAdd={onAdd}
                        onSubtract={onRemove}
                        disabled={isSubmitted || (!available_now && !noOrderType)}
                        isOrderInTransit={isSubmitted}
                        available={available_now}
                        loading={updatingItem}
                    />
                )}
            </Row>
        );
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1100,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        initialSlide: currentImageIndex,
        easing: 'ease-in-out',
        dotsClass: 'cover-images-slider-dots product-images-paging-dots-container'
    };

    const mobileHeight = Math.round((deviceWidth * 9) / 16);
    const showCheckoutBar = cart?.items?.length > 0 && !isSubmitted;

    return (
        <Wrapper>
            <Head pageTitle={name} />
            <Col>
                <Row width="100%" justifyContent={isMobileOnly ? 'center' : 'flex-start'}>
                    <Box
                        width="100%"
                        maxWidth={340}
                        mediaQueries={{ sm }}
                        smMaxWidth={480}
                        height={isMobileOnly ? mobileHeight : 191}
                    >
                        <Slider {...sliderSettings}>
                            {productImages.map((img, i) => {
                                return (
                                    <Image
                                        key={`product_${i}`}
                                        src={img}
                                        borderRadius={8}
                                        height={isMobileOnly ? mobileHeight : 191}
                                        minHeight={isMobileOnly ? mobileHeight : 191}
                                        width="100%"
                                        objectFit={settings?.webstore_image_container || 'cover'}
                                    />
                                );
                            })}
                        </Slider>
                    </Box>

                    {!isMobileOnly && productImages && productImages?.length > 1 && (
                        <Box borderRadius={8} overflow="hidden" marginLeft={20}>
                            <Row flexWrap="wrap">
                                {productImages.map((image, index) => {
                                    return (
                                        <Box
                                            cursor="pointer"
                                            marginRight={10}
                                            backgroundColor={themeColors[theme].secondaryBgColor11}
                                            borderRadius={9}
                                            width={63}
                                            height={63}
                                            marginBottom={10}
                                            boxSizing="border-box"
                                            border={
                                                currentImageIndex === index
                                                    ? `solid 1.8px ${themeColors[theme].primaryBorderColor}`
                                                    : `solid 0.9px ${themeColors[theme].secondaryBorderColor5}`
                                            }
                                            overflow="hidden"
                                            props={{
                                                onClick: () => {
                                                    setCurrentImageIndex(index);
                                                }
                                            }}
                                            key={index}
                                        >
                                            <Image
                                                height="100%"
                                                width="100%"
                                                objectFit="cover"
                                                src={image}
                                                borderRadius={9}
                                            />
                                        </Box>
                                    );
                                })}
                            </Row>
                        </Box>
                    )}
                </Row>

                <Row
                    justifyContent="space-between"
                    marginTop={65}
                    flexWrap={isMobileOnly ? 'wrap' : 'nowrap'}
                    width="100%"
                >
                    <Col marginRight={!isMobileOnly && 50} order={isMobileOnly && 1}>
                        {!isMobileOnly && (
                            <>
                                <Box
                                    fontSize={10}
                                    fontWeight={700}
                                    color={themeColors[theme].secondaryFontColor14}
                                    marginBottom={5}
                                >
                                    {additional_info}
                                </Box>
                                <Heading
                                    color={themeColors[theme].primaryFontColor1}
                                    marginBottom={0}
                                >
                                    {name}
                                </Heading>
                            </>
                        )}
                        <Box
                            fontSize={18}
                            fontWeight={500}
                            lineHeight={1.11}
                            letterSpacing="1px"
                            color={themeColors[theme].primaryFontColor1}
                            marginTop={40}
                        >
                            {t('product.productDesp')}
                        </Box>
                        <Subtitle fontSize={18} marginTop={10} fontWeight={500}>
                            <Text>{description}</Text>
                        </Subtitle>
                    </Col>

                    <Col mediaQueries={{ sm }} smWidth="100%">
                        {isMobileOnly && (
                            <Box>
                                <Box
                                    fontSize={10}
                                    fontWeight={700}
                                    color={themeColors[theme].secondaryFontColor14}
                                    marginBottom={5}
                                >
                                    {additional_info}
                                </Box>
                                <Heading marginBottom={0}>{name}</Heading>
                                <Divider
                                    height={1}
                                    width="100%"
                                    marginBottom={18}
                                    marginTop={18}
                                    opacity={0.5}
                                />
                            </Box>
                        )}

                        <Row
                            justifyContent="space-between"
                            alignItems="center"
                            minWidth={!isMobileOnly && 350}
                        >
                            <Box>
                                <Price
                                    alignSelf="flex-start"
                                    price={Math.round(display_total_price)}
                                    promotionPrice={
                                        Math.round(display_promotion_price)
                                            ? Math.round(display_promotion_price)
                                            : null
                                    }
                                    big
                                    productPage
                                />
                                <Box
                                    fontSize={12}
                                    fontWeight={600}
                                    color={themeColors[theme].secondaryFontColor15}
                                    marginBottom={20}
                                >
                                    {priceIncludesTax && `${t('product.taxIncluded')}`}
                                    {!noOrderType &&
                                        `${priceIncludesTax ? ' | ' : ''}${inStockCaption}`}
                                </Box>
                            </Box>
                            <Box
                                fontSize={12}
                                fontWeight={600}
                                color={themeColors[theme].secondaryFontColor15}
                            >
                                <AddProduct t={t} />
                            </Box>
                        </Row>

                        <Divider height={1} width="100%" opacity={0.5} />
                        {!isMobileOnly && (
                            <Box marginTop={40}>
                                {isSubmitted && <InTransitNotice />}
                                {!isSubmitted &&
                                    !isOpen &&
                                    !isPickup &&
                                    supportsOndemandMenu === true &&
                                    !supportsScheduledMenu && (
                                        <ClosedNotice day={whenOpen.day} time={whenOpen.time} />
                                    )}
                            </Box>
                        )}
                    </Col>
                    {isMobileOnly && (
                        <Col marginTop={40} order={2} width="100%">
                            {isSubmitted && <InTransitNotice />}
                            {!isSubmitted &&
                                !isOpen &&
                                !isPickup &&
                                supportsOndemandMenu === true &&
                                !supportsScheduledMenu && (
                                    <ClosedNotice day={whenOpen.day} time={whenOpen.time} />
                                )}
                        </Col>
                    )}
                </Row>
            </Col>
            {showCheckoutBar && <CheckoutBar />}

            {alertSettings.show && (
                <Alert
                    message={alertSettings.message}
                    iconName={alertSettings.icon}
                    isFadingOut={false}
                    addedBottomSpace={showCheckoutBar ? 100 : 0}
                />
            )}

            {showDeliveryAddressModal && (
                <DeliveryAddressModal
                    showModal={showDeliveryAddressModal}
                    onClose={() => {
                        appContextActions.deliveryAddressModal.close();
                    }}
                    address={user.location?.address}
                    updateAddress={onAddressUpdate}
                    validateAddress={store.validateAddress}
                    organizationId={user.organizationId}
                    deliveryDefaults={settings?.deliveryDefaults}
                    pickupLocations={pickupLocations}
                    updatePickupLocation={store.updatePickupLocation}
                    currentPickupLocation={thread?.pickup_destination}
                    timeZone={settings?.timeZone}
                    isAuth={user?.authenticated}
                    supportPickup={settings?.supports_pickup}
                    supportsDelivery={
                        settings?.supports_scheduled_menu || settings?.supports_ondemand_menu
                    }
                />
            )}
        </Wrapper>
    );
}
