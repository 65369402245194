import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function updateCustomerData(username, organization_id, name, email) {
    const payload = {
        username,
        organization_id,
        name,
        email
    };
    return invokeLambda(Lambda.updateCustomerData, payload);
}
