import React, { useEffect, useState } from 'react';
import { isAndroid, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import closeIcon from 'assets/images/black-close-icon.svg';

import Icon from 'components/Icon';
import Image from 'components/Image';

import Button from '../../components/Button';

export default function CampaignModal({
    showModal,
    setShowCampaignModal,
    onClose,
    promotionRelay,
    isAuth,
    applyPromoCode,
    showPromoAppliedAlert,
    modalTitle,
    modalBackground,
    isOrderInTransit,
    showClose
}) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [deviceHeight, setDeviceHeight] = useState(
        isAndroid ? window.screen.height : window.innerHeight
    );

    useEffect(() => {
        window.addEventListener('resize', () => {
            setDeviceWidth(window.innerWidth);
            setDeviceHeight(isAndroid ? window.screen.height : window.innerHeight);
        });
    }, []);

    const modalStyle = () => {
        const isSmallScreen = isMobileOnly || deviceWidth <= 480 || deviceHeight <= 480;
        if (isSmallScreen) {
            return {
                content: {
                    position: 'absolute',
                    top: `${deviceWidth < 330 ? '10%' : deviceHeight <= 480 ? '10%' : 'auto'}`,
                    left: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    right: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    bottom: '0',
                    backgroundColor: themeColors[theme].popupBgColor1,
                    border: 'none',
                    padding: '20px',
                    borderRadius: '20px 20px 0 0',
                    maxHeight: '80vh',
                    minHeight: `${isAndroid && '60vh'}`,
                    color: themeColors[theme].blackFontColor
                }
            };
        } else {
            return {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    padding: '20px',
                    borderRadius: '20px',
                    minHeight: '542px',
                    width: '652px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'hidden',
                    backgroundColor: themeColors[theme].popupBgColor1,
                    color: themeColors[theme].blackFontColor
                }
            };
        }
    };

    const modalStyles = modalStyle();

    return (
        <Box>
            <Modal
                style={modalStyles}
                isOpen={showModal}
                onRequestClose={onClose}
                overlayClassName="buy-now-modal-overlay"
                shouldCloseOnOverlayClick={true}
                closeTimeoutMS={500}
                preventScroll={true}
                shouldFocusAfterRender={false}
            >
                {showClose && (
                    <Row justifyContent="flex-end">
                        <Image
                            cursor="pointer"
                            src={closeIcon}
                            width={30}
                            height={30}
                            padding={7}
                            onClick={onClose}
                        />
                    </Row>
                )}
                <Box
                    textAlign="center"
                    padding={!isMobileOnly && '0px 50px'}
                    paddingTop={showClose ? 0 : 20}
                    color={themeColors[theme].popupFontColor4}
                >
                    {modalBackground ? (
                        <Image
                            height={170}
                            minHeight={170}
                            width={300}
                            objectFit="cover"
                            src={modalBackground}
                            alt={promotionRelay.message}
                            borderRadius={8}
                        />
                    ) : (
                        <Icon name="promotion" />
                    )}

                    <Box
                        paddingTop={30}
                        fontSize={30}
                        fontWeight={900}
                        letterSpacing={1}
                        marginBottom={15}
                    >
                        {modalTitle || t('campaignModal.title')}
                    </Box>
                    <Box
                        fontWeight={500}
                        fontSize={18}
                        letterSpacing={1}
                        color={themeColors[theme].popupFontColor2}
                        marginBottom={isOrderInTransit ? 30 : 40}
                    >
                        {promotionRelay.message}
                    </Box>
                    {isOrderInTransit ? (
                        <Col alignItems="center">
                            <Box
                                fontSize={14}
                                fontWeight={500}
                                fontStyle="italic"
                                color={themeColors[theme].popupFontColor2}
                                maxWidth={270}
                                marginBottom={15}
                            >
                                {t('promotionsCategory.cannotApply')}
                            </Box>
                            <Button
                                disableTheme
                                width={isMobileOnly ? ' 100%' : '334px'}
                                marginBottom="20px"
                                noHover
                                hoverWhite={isMobileOnly}
                                props={{
                                    onClick: () => {
                                        onClose();
                                    }
                                }}
                            >
                                <Row justifyContent="center">
                                    <Box> {t('promotionsCategory.gotIt')}</Box>
                                </Row>
                            </Button>
                        </Col>
                    ) : (
                        <Col alignItems="center">
                            <Button
                                disableTheme
                                width={isMobileOnly ? ' 100%' : '334px'}
                                marginBottom="20px"
                                noHover
                                hoverWhite={isMobileOnly}
                                props={{
                                    onClick: () => {
                                        setShowCampaignModal(false);
                                        console.log('campaign modal');
                                        applyPromoCode(promotionRelay);
                                        showPromoAppliedAlert();
                                    }
                                }}
                            >
                                <Row justifyContent="center">
                                    <Box>
                                        {isAuth
                                            ? t('campaignModal.applyBtn')
                                            : t('campaignModal.login')}
                                    </Box>
                                </Row>
                            </Button>
                            <Button
                                disableTheme
                                noHover
                                hoverBlack={isMobileOnly}
                                white
                                width={isMobileOnly ? ' 100%' : '334px'}
                                props={{
                                    onClick: () => {
                                        onClose();
                                    }
                                }}
                            >
                                <Row justifyContent="center">
                                    <Box> {t('campaignModal.laterBtn')}</Box>
                                </Row>
                            </Button>
                        </Col>
                    )}
                </Box>
            </Modal>
        </Box>
    );
}
