import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'jsxstyle';

import { TextArea } from 'components/shared';

export default function Notes({ note, onChange, pickupLocation }) {
    console.log('pickupLocation', pickupLocation);
    console.log('note', note);
    console.log('onChange', onChange);
    const { t } = useTranslation();
    return (
        <Box>
            <TextArea
                marginTop={10}
                width="100%"
                height={160}
                placeholder={
                    pickupLocation
                        ? t('buyNowModal.notes.pickupPlaceholder')
                        : t('buyNowModal.notes.placeholder')
                }
                value={note}
                showCount
                onChange={onChange}
                inPopUp
            />
        </Box>
    );
}
