import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function cancelThread(user_id, thread_id, organization_id) {
    const payload = {
        user_id,
        thread_id,
        organization_id
    };
    return invokeLambda(Lambda.DelayOrder, payload);
}
