import getCart from './getCart';
import getThread from './getThread';
import getUser from './getUser';
import getZone from './getZone';

export default async function getCustomerOrderData(
    session,
    channelPath,
    fees,
    includeTaxes,
    defaultTaxType,
    isOpen,
    isOndemandOrder
) {
    const user = session.userId
        ? await getUser(session.userId, session.organization_id, session.authenticated, channelPath)
        : null;

    const thread = user ? await getThread(user) : null;

    const menuKit = thread?.menu_kit || sessionStorage.getItem('menu_kit') || null;

    const cart = thread
        ? await getCart(
              user,
              fees,
              includeTaxes,
              defaultTaxType,
              thread.thread_id,
              true,
              isOpen,
              thread.zone_id,
              thread.associated_hub,
              menuKit,
              isOndemandOrder
          )
        : null;

    const zone = thread
        ? await getZone(
              thread.zoneId,
              user,
              thread.location,
              thread.associated_hub,
              thread.thread_status
          )
        : null;

    return {
        session,
        user,
        thread,
        zone,
        cart
    };
}
