import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { navigate, useQueryParams } from '@patched/hookrouter';

import { cachePromoCode, cachePromoLink, clearCachedPromoLink } from '../api/utils';
import useCustomerData from '../hooks/useCustomerData';
import useStoreSettings from '../hooks/useStoreSettings';
import Alert from './Alert';

export default function ReferralAlert({
    user,
    promoCode,
    cookiesAlertShown,
    setShowWhitePromoAlert
}) {
    const { t } = useTranslation();
    const { data: storeSettings } = useStoreSettings();
    const { store } = useCustomerData();
    const [errorMessage, setErrorMessage] = useState();
    const [eligible, setEligible] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [params, setParams] = useQueryParams();
    const [show, setShow] = useState(false);

    useEffect(() => {
        const applyPromocode = async () => {
            setShowWhitePromoAlert(false);
            setParams({ ref: undefined });
            setShow(true);
            setLoading(true);
            setEligible(true);
            console.log('referral alert');
            const data =
                user?.authenticated && (await store.applyPromocode(promoCode.toUpperCase()));
            setLoading(false);
            setTimeout(() => setIsFadingOut(true), 5000);

            if (data) {
                data?.promotion_relay?.url
                    ? cachePromoLink(data?.promotion_relay?.url)
                    : clearCachedPromoLink();
            }

            if (!data?.applied && data.error !== 'PromoBiggerThanTotal') {
                setEligible(false);
                if (data.error === 'NotFirstOrder') {
                    setErrorMessage(t('cart.promoCode.firstTimeError'));
                } else if (data.errorMessage) {
                    setErrorMessage(data.errorMessage);
                } else {
                    setErrorMessage(t('catalogPage.referral.codeInvalid'));
                }
            } else {
                setEligible(true);
            }
        };
        if (storeSettings && user) {
            cachePromoCode(promoCode);
            sessionStorage.setItem('refLink', true);
            console.log('ref allert 2');
            params.ref && applyPromocode();
        }
    }, [storeSettings, promoCode, user]);

    const handleAlertClick = () => {
        if (!user.authenticated) {
            navigate('/login', false, { from: 'ref' });
        }
    };

    const message = user?.authenticated
        ? eligible
            ? t('catalogPage.referral.discountApplied')
            : errorMessage
        : t('catalogPage.referral.logIn');

    const iconName = eligible || !user?.authenticated ? 'whiteCheck' : 'whiteClear';

    const addedBottomSpace =
        cookiesAlertShown && isMobileOnly ? 160 : cookiesAlertShown ? 140 : null;
    return user && !loading && show ? (
        <Alert
            message={message}
            iconName={iconName}
            handleAlertClick={handleAlertClick}
            isFadingOut={isFadingOut}
            addedBottomSpace={addedBottomSpace}
        />
    ) : null;
}
