import { getCartData, getCartTotal } from '../utils/cacheDataManager';
import checkCartAvailability from './checkItemsAvailability';
import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function getCart(
    user,
    fees,
    includeTaxes,
    defaultTaxType,
    threadId = undefined,
    checkAvailability,
    isOpen,
    zoneId,
    associated_hub,
    menu_kit,
    isOndemandOrder,
    isPickup
) {
    try {
        if (user.authenticated) {
            if (!user.threadId) return null;

            const payload = {
                thread_id: threadId || user.threadId,
                username: user.username,
                organization_id: user.organizationId,
                check_availability: checkAvailability,
                menu_kit,
                isOndemandOrder
            };
            const {
                cart,
                subtotal_after_discount,
                fees,
                delivery,
                total,
                orderMeetsRequirements,
                requireAgentVerification,
                promocodeDiscount,
                available_delivery_times,
                promoCode,
                round_up_addition,
                platform_fee_total,
                platform_fee_description,
                order_display_prices
            } = await invokeLambda(Lambda.GetCart, payload);
            return {
                available_delivery_times,
                order_display_prices,
                orderMeetsRequirements,
                items: cart,
                requireAgentVerification,
                charges: {
                    subtotal: subtotal_after_discount,
                    fees,
                    delivery,
                    total,
                    promocodeDiscount,
                    roundup: round_up_addition,
                    platformFee: platform_fee_total,
                    platformFeeDesp: platform_fee_description
                },
                promo_code: promoCode
            };
        } else {
            const cart = await getCartData(user.organizationId, fees, includeTaxes, defaultTaxType);
            if (checkAvailability && cart?.items) {
                const { onDemandNotice, scheduledNotice } = await checkCartAvailability(
                    cart.items,
                    isOpen,
                    zoneId,
                    associated_hub,
                    menu_kit,
                    user.organizationId
                );

                const availability =
                    isOndemandOrder && !isPickup ? onDemandNotice : scheduledNotice;
                const withAvailability = cart.items.map(item => {
                    const product = availability?.products?.find(
                        el => el.productId === item.product.product_id
                    );

                    return {
                        ...item,
                        ...product
                    };
                });

                const charges = await getCartTotal(
                    withAvailability,
                    user.organizationId,
                    defaultTaxType,
                    fees,
                    includeTaxes
                );

                return {
                    ...cart,
                    charges,
                    items: withAvailability
                };
            }
            return cart;
        }
    } catch (e) {
        console.log('ERRROR in getCart, ', e);
        return {};
    }
}
