import React from 'react';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

export default function Paragraph(props) {
    const { theme, themeColors } = useTheme();

    const { bold, ...rest } = props;

    return bold ? (
        <Bold theme={theme} themeColors={themeColors} {...rest} />
    ) : (
        <Normal theme={theme} themeColors={themeColors} {...rest} />
    );
}

const Normal = ({ theme, themeColors, children, ...rest }) => (
    <Box
        color={themeColors[theme].secondaryFontColor19}
        fontSize={12}
        fontWeight={500}
        lineHeight={1.67}
        letterSpacing={1}
        {...rest}
    >
        {children}
    </Box>
);

const Bold = ({ theme, themeColors, children, ...rest }) => (
    <Box
        color={themeColors[theme].blackFontColor}
        fontSize={16}
        fontWeight={900}
        lineHeight={1.25}
        letterSpacing={1}
        {...rest}
    >
        {children}
    </Box>
);
