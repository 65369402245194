import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinesEllipsis from 'react-lines-ellipsis';
import { Box, Col, Row } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useTheme from 'hooks/useTheme';

import { numberInCart } from 'api/utils';

import { useAppContext } from 'store';

import placeHolder from 'assets/images/place-holder.svg';

import AddToCartButton from 'components/AddToCartButton';
import Image from 'components/FlexImage';
import Price from 'components/Price';
import { Subtitle } from 'components/shared';

export const ProductCellMode = {
    Category: 0,
    Catalog: 1
};

export default function ProductCell({
    product,
    priceIncludesTax,
    mode = ProductCellMode.Catalog,
    onClick,
    isOrderInTransit,
    noOrderType,
    imageContainerFit,
    ...rest
}) {
    const {
        additional_info,
        image_url,
        name,
        display_promotion_price,
        display_total_price,
        available_now,
        product_id
    } = product;

    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const [numInCart, setNumInCart] = useState(0);
    const [updatingItem, setUpdatingItem] = useState(false);

    const { data: mappedData, store } = useCustomerData();
    const { cart, user } = mappedData || {};
    const { appContextActions } = useAppContext();

    useEffect(() => {
        if (cart) {
            setNumInCart(numberInCart({ product_id }, cart.items || []));
        }
        return () => {
            setNumInCart(null);
        };
    }, [cart, product_id]);

    const Small = ({ children }) => {
        return (
            <Box
                fontSize={10}
                fontWeight={600}
                color={themeColors[theme].secondaryFontColor15}
                marginRight={5}
            >
                {children}
            </Box>
        );
    };

    const InStockCaption = () => {
        return (
            <Small>
                {available_now && available_now > 5
                    ? ''
                    : `${available_now || 0} ${t('product.left')}`}
            </Small>
        );
    };

    const onAdd = async (product_id, product) => {
        if (noOrderType) {
            appContextActions.pendingAction.set(() => store.addItem(product_id, 1, product));
            appContextActions.deliveryAddressModal.open();
        } else {
            try {
                setUpdatingItem(true);
                await store.addItem(product_id, 1, product);
            } finally {
                if (!user?.authenticated) {
                    setTimeout(() => {
                        setUpdatingItem(false);
                    }, 300);
                } else {
                    setUpdatingItem(false);
                }
            }
        }
    };

    const onRemove = async (numInCart, product) => {
        const quantity = numInCart - 1;
        try {
            setUpdatingItem(true);
            await store.setProductQuantity(product, quantity);
        } finally {
            if (!user?.authenticated) {
                setTimeout(() => {
                    setUpdatingItem(false);
                }, 300);
            } else {
                setUpdatingItem(false);
            }
        }
    };

    return available_now ? (
        <Box
            paddingBottom={10}
            borderRadius={0}
            cursor="pointer"
            breakInside="avoid"
            background={themeColors[theme].productCellBgColor}
            display="inline-block"
            width="100%"
            fontSize={14}
            letterSpacing={1}
            props={{ onClick }}
            {...rest}
        >
            <Col height="100%" width="100%" padding={10}>
                <Image
                    height={130}
                    minHeight={130}
                    width="100%"
                    src={image_url ? image_url : placeHolder}
                    alt={name}
                    borderRadius={8}
                    imageContainerFit={imageContainerFit}
                />

                <Col
                    marginTop={15}
                    justifyContent="space-between"
                    justifyItems="space-between"
                    height="100%"
                >
                    <Box
                        minHeight={15}
                        fontSize={10}
                        fontWeight={700}
                        color={themeColors[theme].secondaryFontColor14}
                    >
                        {additional_info}
                    </Box>
                    <Subtitle
                        opacity={0.7}
                        fontWeight={500}
                        minHeight={60}
                        height={60}
                        width="100%"
                        color={themeColors[theme].blackFontColor}
                    >
                        <LinesEllipsis
                            text={name}
                            maxLine={3}
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                        />
                    </Subtitle>

                    <Row justifyContent="space-between" alignItems="center">
                        <Box>
                            {display_total_price ? (
                                <Price
                                    minHeight={37}
                                    price={display_total_price}
                                    promotionPrice={display_promotion_price}
                                    small
                                    flexCenter={!priceIncludesTax}
                                />
                            ) : null}

                            {priceIncludesTax && <Small>{t('product.taxIncluded')}</Small>}
                        </Box>
                        {available_now > 0 && (
                            <Row alignItems="center">
                                {!numInCart && <InStockCaption />}

                                <AddToCartButton
                                    numOfItems={numInCart}
                                    itemsAvailable={available_now}
                                    enabled={!updatingItem}
                                    loading={updatingItem}
                                    isOrderInTransit={isOrderInTransit}
                                    noOrderType={noOrderType}
                                    onItemAdded={() => onAdd(product_id, product)}
                                    onItemRemoved={() => onRemove(numInCart, product)}
                                />
                            </Row>
                        )}
                    </Row>
                </Col>
            </Col>
        </Box>
    ) : null;
}
