import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import DelayOrderModal from 'components/Modals/DelayOrderModal';

import { THEME } from '../api/constants';
import { isHebrew } from '../api/utils';
import x from '../assets/images/delete-button.svg';
import xWhite from '../assets/images/delete-button-white.svg';
import arrowWhite from './../assets/images/arrow-bottom-light.svg';
import arrow from './../assets/images/tiny-arrow-triangle.svg';
import Button from './Button';
import { TextArea } from './shared';

export default function CancelOrder({
    onOrderCancel,
    onOrderDelay,
    supportedTransactionTypes,
    cancelling,
    postponing,
    eta,
    postponed,
    forPickup,
    cancellationReasons
}) {
    const [confirming, setConfirming] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [showTextInput, setShowTextInput] = useState(false);
    const [otherCancelReasonText, setOtherCancelReasonText] = useState('');
    const [showDelayOrderModal, setShowDelayOrderModal] = useState(false);
    const open = () => setConfirming(true);
    const close = () => setConfirming(false);
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const Reasons = {
        Other: 'OT',
        TooSoon: 'TS'
    };
    useEffect(() => {
        if (cancelReason === Reasons.Other) {
            setShowTextInput(true);
        } else {
            setShowTextInput(false);
        }

        if (confirming && cancelReason === Reasons.TooSoon) {
            setTimeout(() => {
                setShowDelayOrderModal(true);
            }, 500);
        } else {
            setShowDelayOrderModal(false);
        }
    }, [cancelReason, confirming]);

    const cancelDisabled =
        cancelling ||
        !cancelReason ||
        (cancelReason === Reasons.Other && !otherCancelReasonText.length);
    return (
        <Box>
            <Box
                background={`url(${theme === THEME.DARK ? xWhite : x}) 0 25px no-repeat`}
                background-size={20}
                padding={`25px 20px ${confirming ? '15px' : '25px'} 30px`}
                cursor="pointer"
                fontWeight={500}
                fontSize={18}
                borderBottom={
                    !confirming && `1px solid ${themeColors[theme].secondaryBorderColor8}`
                }
                borderTop={`1px solid ${themeColors[theme].secondaryBorderColor8}`}
                props={{ onClick: open }}
            >
                {t('orderStatus.cancelOrder')}{' '}
            </Box>

            {confirming && (
                <Col
                    alignItems="flex-end"
                    paddingBottom={40}
                    borderBottom={`1px solid ${themeColors[theme].secondaryBorderColor8}`}
                >
                    <Box
                        opacity={0.5}
                        marginBottom={10}
                        fontSize={14}
                        lineHeight={1.5}
                        fontWeight="bold"
                    >
                        {t('orderStatus.confirmCancel')}
                        {isHebrew() && '\u202a?'}
                    </Box>
                    <CancelReason
                        t={t}
                        cancelReason={cancelReason}
                        setCancelReason={setCancelReason}
                        supportedTransactionTypes={supportedTransactionTypes}
                        postponed={postponed}
                        eta={eta}
                        forPickup={forPickup}
                        cancellationReasons={cancellationReasons}
                        theme={theme}
                        themeColors={themeColors}
                    />
                    {showTextInput && (
                        <Box paddingTop={35} width="100%">
                            <Box
                                fontSize={12}
                                color={themeColors[theme].secondaryFontColor2}
                                letterSpacing="1px"
                                marginBottom={15}
                            >
                                {t('orderStatus.enterCancelReason')}
                            </Box>
                            <TextArea
                                value={otherCancelReasonText}
                                onChange={e => {
                                    setOtherCancelReasonText(e.target.value);
                                }}
                                placeholder={t('orderStatus.cancellationReason')}
                                showCount={false}
                                width="100%"
                                height={160}
                                maxLength={140}
                                color={themeColors[theme].blackFontColor}
                            />
                        </Box>
                    )}

                    <Row marginTop={30}>
                        <Button
                            small
                            noShadow
                            onClick={() => {
                                setCancelReason('');
                                close();
                            }}
                            hoverWhite={isMobileOnly}
                            marginRight={20}
                            minWidth={132}
                            padding="7px 10px"
                        >
                            {t('orderStatus.no')}
                        </Button>
                        <Button
                            small
                            white={theme !== THEME.DARK}
                            noShadow
                            noHoverColor
                            onClick={async () => {
                                await onOrderCancel(cancelReason, otherCancelReasonText);
                                setShowDelayOrderModal(false);
                                setConfirming(false);
                            }}
                            minWidth={132}
                            padding="7px 10px"
                            disabled={cancelDisabled}
                        >
                            {cancelling ? t('orderStatus.cancelling') : t('orderStatus.yes')}
                        </Button>
                    </Row>
                </Col>
            )}
            <DelayOrderModal
                showModal={showDelayOrderModal}
                onConfirm={async () => {
                    await onOrderDelay();
                    setCancelReason(null);
                    setShowDelayOrderModal(false);
                    setConfirming(false);
                }}
                onCancel={async () => {
                    await onOrderCancel(cancelReason, otherCancelReasonText);
                    setShowDelayOrderModal(false);
                    setConfirming(false);
                }}
                onClose={() => {
                    setShowDelayOrderModal(false);
                }}
                cancelling={cancelling}
                postponing={postponing}
                // deliveryModel={currentDeliveryModel}
            />
        </Box>
    );
}

function CancelReason({
    t,
    cancelReason,
    setCancelReason,
    supportedTransactionTypes,
    eta,
    postponed,
    forPickup,
    cancellationReasons,
    theme,
    themeColors
}) {
    if (!cancellationReasons) {
        return null;
    }
    let options = Object.entries(cancellationReasons);

    //all the options that with key 3 length long are for admin only
    options = options.filter(o => {
        return o[0].length === 2;
    });

    //payment method change reason
    if (supportedTransactionTypes?.length < 2) {
        options = options.filter(o => {
            return o[0] !== 'PM';
        });
    }

    //too soon reason
    if (postponed || !eta) {
        options = options.filter(o => {
            return o[0] !== 'TS';
        });
    }

    if (forPickup) {
        options = options.filter(o => {
            return o[0] !== 'TS' && o[0] !== 'AD';
        });
    } else {
        //filter pickup reasons
        options = options.filter(o => {
            return o[0] !== 'PL';
        });
    }

    return (
        <Box
            component="select"
            width="100%"
            border={`2px solid ${themeColors[theme].secondaryBorderColor}`}
            hoverBorder={`2px solid ${themeColors[theme].activeBorderColor}`}
            focusBorder={`2px solid ${themeColors[theme].activeBorderColor}`}
            placeholder={t('orderStatus.chooseCancelReason')}
            backgroundColor={themeColors[theme].secondaryBgColor}
            background={`url(${theme === THEME.DARK ? arrowWhite : arrow}) right center no-repeat`}
            backgroundPosition="right 15px center"
            backgroundSize="10px auto"
            color={
                cancelReason
                    ? themeColors[theme].primaryFontColor1
                    : themeColors[theme].secondaryFontColor1
            }
            padding="8px 15px"
            borderRadius={5}
            font="400 16px Heebo"
            fontWeight={cancelReason ? 500 : 400}
            overflow="hidden"
            overflowWrap="break-word"
            display="inline-block"
            whiteSpace="pre-line"
            props={{
                // disabled: updating,
                value: cancelReason,
                onChange: e => setCancelReason(e.target.value)
            }}
        >
            <Box
                component="option"
                color={themeColors[theme].primaryFontColor2}
                props={{ value: '', disabled: true, hidden: true }}
            >
                {t('orderStatus.chooseCancelReason')}
            </Box>
            {options.map(value => (
                <Box
                    component="option"
                    color={themeColors[theme].primaryFontColor1}
                    key={value[0]}
                    props={{ value: value[0], key: value[0] }}
                >
                    {value[1]}
                </Box>
            ))}
        </Box>
    );
}
