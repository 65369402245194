import React, { createContext, useEffect, useState } from 'react';
import { useQueryParams } from '@patched/hookrouter';

import { clearCache } from 'api/utils';

import { useAppContext } from 'store';

import api from '../api';

export const SessionContext = createContext();

export default function Session({
    storeDomain,
    botNumber,
    storeDescription,
    identityPool,
    organizationId,
    countryCode,
    children
}) {
    const { state, appContextActions } = useAppContext();

    useEffect(() => {
        appContextActions.botNumber.set(botNumber);
        appContextActions.storeDomain.set(storeDomain);
        appContextActions.countryCode.set(countryCode);
        appContextActions.storeDescription.set(storeDescription || '');
    }, []);

    const [params] = useQueryParams();
    const [session, setSession] = useState();

    const hid = params.hid;

    useEffect(() => {
        // The AWS sdk seems to expect you to always authenicate
        // (it probably does its own internal caching)
        // so we cache the handoff instead to save a GET request
        async function createSession() {
            try {
                let handoff = JSON.parse(window.localStorage.getItem('handoff'));
                if (!handoff && !hid) {
                    const session = {
                        authenticated: false,
                        cognitoIdentityPoolId: identityPool,
                        storeDomain,
                        userId: storeDomain,
                        organization_id: organizationId
                    };
                    setSession(session);
                } else {
                    if (!handoff || (hid && handoff.handoff_id !== hid)) {
                        handoff = await api.getHandoff(hid);
                        window.localStorage.setItem('handoff', JSON.stringify(handoff));
                    }

                    const session = await api.createSession(handoff);
                    session.storeDomain = storeDomain;

                    setSession(session);
                }
            } catch (error) {
                console.log(error);
                clearCache();
                window.location.replace('/');
            }
        }

        createSession();
    }, [hid, identityPool, storeDomain, organizationId]);

    return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
}
