import useSWR from 'swr';

import api from '../api';
import useCustomerData from './useCustomerData';

const useActivePromotionsForCatalog = () => {
    const { data: mappedData } = useCustomerData();
    const { user, thread, cart } = mappedData || {};
    const key = () => [
        `active_promotions_for_catalog`,
        user.organizationId,
        thread?.associated_hub
    ];
    const options = {
        shouldRetryOnError: true,
        errorRetryInterval: 30000,
        dedupingInterval: 300000,
        focusThrottleInterval: 60000,
        revalidateOnFocus: true
    };

    const fetch = async () => {
        if (!thread || !user?.organizationId) {
            return null;
        }

        const data = await api.getActivePromotionsForCatalog(
            user,
            user.organizationId,
            thread.associated_hub,
            cart.items
        );

        return data;
    };

    return useSWR(key, fetch, options);
};

export default useActivePromotionsForCatalog;
