import React from 'react';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Icon from 'components/Icon';
import { Body } from 'components/shared';
import Spinner from 'components/Spinner';

import { THEME } from '../api/constants';

export default function AddToCartButton({
    numOfItems,
    onItemAdded,
    onItemRemoved,
    enabled,
    itemsAvailable,
    loading,
    isOrderInTransit,
    noOrderType
}) {
    const { theme, themeColors } = useTheme();

    const IconWrapper = ({ icon, onClick, disabled }) => {
        return (
            <Row
                borderRadius={25}
                backgroundColor={themeColors[theme].secondaryBgColor1}
                justifyContent="center"
                alignItems="center"
                width={38}
                height={38}
                opacity={disabled ? 0.3 : 1}
                cursor={disabled ? 'default' : 'pointer'}
                props={{
                    disabled: { disabled },
                    onClick: e => {
                        e.stopPropagation();
                        if (!enabled || disabled) {
                            return;
                        }
                        onClick(e);
                    }
                }}
            >
                <Icon name={icon} width={12} height={12} />
            </Row>
        );
    };

    return (
        <Box opacity={enabled ? 1 : 0.5}>
            {numOfItems > 0 && !isOrderInTransit ? (
                <Row
                    borderRadius={25}
                    height={38}
                    backgroundColor={themeColors[theme].secondaryBgColor1}
                    justifyContent="center"
                    alignItems="center"
                >
                    <IconWrapper
                        icon={
                            numOfItems === 1
                                ? theme === THEME.DARK
                                    ? 'trashWhite'
                                    : 'trash'
                                : theme === THEME.DARK
                                  ? 'minusWhite'
                                  : 'cartRemove'
                        }
                        onClick={onItemRemoved}
                        disabled={isOrderInTransit}
                    />

                    <Box position="relative">
                        {loading && (
                            <Spinner
                                position="absolute"
                                width={30}
                                height={30}
                                top={-40}
                                right={numOfItems > 9 ? -5 : -9}
                                white={theme === THEME.DARK}
                            />
                        )}
                        <Body
                            fontSize={18}
                            fontWeight="bold"
                            color={themeColors[theme].primaryFontColor1}
                        >
                            {numOfItems}
                        </Body>
                    </Box>

                    <IconWrapper
                        icon={theme === THEME.DARK ? 'cartAddWhite' : 'cartAdd'}
                        onClick={onItemAdded}
                        disabled={isOrderInTransit || numOfItems >= itemsAvailable || noOrderType}
                    />
                </Row>
            ) : (
                <Box position="relative" top={0} bottom={0}>
                    {loading && (
                        <Spinner
                            position="absolute"
                            width={30}
                            height={30}
                            top={-31}
                            left={4}
                            white={theme === THEME.DARK}
                        />
                    )}
                    <IconWrapper
                        icon={theme === THEME.DARK ? 'cartAddWhite' : 'cartAdd'}
                        onClick={onItemAdded}
                        disabled={isOrderInTransit}
                    />
                </Box>
            )}
        </Box>
    );
}
