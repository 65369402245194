import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function getCatalog({
    organization_id,
    query,
    isOpen,
    zone_id,
    redirected_from_zone,
    menu_kit_id,
    thread_id,
    useHubStock,
    category_ids,
    size,
    lastKey,
    preference,
    associated_hub,
    filtersParams,
    skusParams,
    skusNotParams,
    categoriesParams,
    categoriesNorParams
}) {
    try {
        let payload = {
            organization_id,
            text: query,
            isOpen,
            zone_id,
            redirected_from_zone,
            size,
            lastKey,
            kit_id: menu_kit_id,
            useHubStock,
            preference,
            associated_hub,
            sort_type: JSON.parse(sessionStorage.getItem('selectedSortType')) || null,
            ...(filtersParams && {
                filters: filtersParams.split(',').map(filter => filter.split('&'))
            }),
            ...(skusParams && {
                include_skus: skusParams.split(',')
            }),
            ...(skusNotParams && {
                not_include_skus: skusNotParams.split(',')
            }),
            ...(categoriesParams &&
                categoriesParams !== 'all' && {
                    include_categories: categoriesParams.split(',')
                }),
            ...(categoriesNorParams && {
                not_include_categories: categoriesNorParams.split(',')
            })
        };

        if (category_ids && category_ids.length) {
            payload.category_ids = category_ids;
        }

        const { results, lastEvaluatedKey, menu_kit, count, nextShiftEndTime, nextShiftHours } =
            await invokeLambda(Lambda.GetCatalogFromInventory, payload);

        if (menu_kit) {
            sessionStorage.setItem('menu_kit', menu_kit);
        } else if (menu_kit_id) {
            sessionStorage.removeItem('menu_kit');
        }

        if (thread_id && menu_kit_id != menu_kit) {
            const payload = {
                thread_id,
                menu_kit: menu_kit || null
            };

            await invokeLambda(Lambda.UpdateThread, payload);
        }

        const availableProducts =
            results && results.length ? results.filter(el => el.available_now > 0) : [];
        return {
            lastEvaluatedKey,
            results: availableProducts,
            count,
            nextShiftEndTime,
            nextShiftHours
        };
    } catch (e) {
        console.log('e', e);
    }
}
