import addToCart from './addToCart';
import applyPromocode from './applyPromocode';
import authenticateCustomer from './authenticateCustomer';
import cancelThread from './cancelThread';
import checkItemsAvailability from './checkItemsAvailability';
import closeSession from './closeSession';
import createSession from './createSession';
import delayOrder from './delayOrder';
import deleteCustomer from './deleteCustomer';
import getActivePromotionsForCatalog from './getActivePromotionsForCatalog';
import getActiveTopPromotion from './getActiveTopPromotion';
import getAvailableProductsCount from './getAvailableProductsCount';
import getCart from './getCart';
import getCatalog from './getCatalog';
import getCategorizedCatalog from './getCategorizedCatalog';
import getCustomerData from './getCustomerData';
import getCustomerOrderData from './getCustomerOrderData';
import getFilterComponents from './getFilterComponents';
import getHandoff from './getHandoff';
import getOrdersHistory from './getOrdersHistory';
import getPickupLocations from './getPickupLocations';
import getProduct from './getProduct';
import getStoreSettings from './getStoreSettings';
import getThread from './getThread';
import getUser from './getUser';
import getWindows from './getWindows';
import getZone from './getZone';
import removeFromCart from './removeFromCart';
import sendCode from './sendCode';
import sendMessageOrderPlaced from './sendMessageOrderPlaced';
import setCartQuantity from './setCartQuantity';
import submitOrder from './submitOrder';
import updateAddress from './updateAddress';
import updateCartItems from './updateCartItems';
import updateCustomerData from './updateCustomerData';
import updateDeliveryInstructions from './updateDeliveryInstructions';
import updateThreadDeliverBy from './updateThreadDeliverBy';
import updateThreadDeliveryModel from './updateThreadDeliveryModel';
import updateThreadPickupLocation from './updateThreadPickupLocation';
import uploadCustomerPicture from './uploadCustomerPicture';
import uploadImage from './uploadImage';
import validateAddress from './validateAddress';
import verifyCompliance from './verifyCompliance';

export default {
    addToCart,
    cancelThread,
    updateDeliveryInstructions,
    createSession,
    closeSession,
    getUser,
    getHandoff,
    getCart,
    getThread,
    getProduct,
    removeFromCart,
    getCatalog,
    sendMessageOrderPlaced,
    setCartQuantity,
    uploadImage,
    verifyCompliance,
    getZone,
    checkItemsAvailability,
    updateAddress,
    validateAddress,
    updateCustomerData,
    deleteCustomer,
    uploadCustomerPicture,
    sendCode,
    submitOrder,
    authenticateCustomer,
    applyPromocode,
    getOrdersHistory,
    updateCartItems,
    getCategorizedCatalog,
    updateThreadDeliverBy,
    getAvailableProductsCount,
    getCustomerOrderData,
    getStoreSettings,
    getCustomerData,
    delayOrder,
    getWindows,
    updateThreadDeliveryModel,
    getPickupLocations,
    updateThreadPickupLocation,
    getActiveTopPromotion,
    getActivePromotionsForCatalog,
    getFilterComponents
};
