import AWS from 'aws-sdk';

import { Lambda } from './constants';
import invokeLambda from './invokeLambda';
import { isStoreOpen, whenStoreCloses, whenStoreOpen } from './utils';

export default async function getStoreSettings(session, associated_hub) {
    if (!session || !session.organization_id) return null;
    const { organization_id, authenticated, cognitoIdentityPoolId } = session;

    if (authenticated === false) {
        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: cognitoIdentityPoolId
            }),
            region: 'us-east-1'
        });
    }

    const payload = { organization_id, associated_hub };
    const { storeSettings } = await invokeLambda(Lambda.GetStoreSettings, payload);

    if (storeSettings?.customer_support_details?.phone) {
        localStorage.setItem(
            'customerSupportPhone',
            storeSettings?.customer_support_details?.phone
        );
    }
    setWebPage(storeSettings);
    return mapSettings(storeSettings);
}

function mapSettings(data) {
    const getPaymentTransactionType = supportedTransactionTypes => {
        if (supportedTransactionTypes && supportedTransactionTypes.length) {
            return supportedTransactionTypes[0];
        }

        return undefined;
    };

    const isOpen = isStoreOpen(data.availability, data.timezone);

    const category =
        data.categories && data.categories.length ? data.categories.sort(sortByPosition) : [];

    const adjustedTime = new Date().toLocaleString('en-US', {
        timeZone: data.timezone
    });
    const now = new Date(adjustedTime);

    return {
        ...data,
        category,
        isOpen,
        whenOpen: whenStoreOpen(data.availability, data.timezone),
        ...(!isOpen
            ? { whenOpen: whenStoreOpen(data.availability, data.timezone) }
            : { whenCloses: whenStoreCloses(data.availability, now) }),
        supportedTransactionTypes: data.supported_transaction_types,
        paymentTransactionType: getPaymentTransactionType(data.supported_transaction_types),
        complianceBucket: data.compliance_bucket,
        includeTaxes: data.display_price_includes_tax,
        defaultTaxType: data.default_tax_type
    };
}

function setWebPage(customizations) {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = customizations.small_logo_url;
    document.getElementsByTagName('head')[0].appendChild(link);
}

function sortByPosition(a, b) {
    return a.position > b.position ? 1 : -1;
}
