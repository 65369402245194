import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate, useQueryParams } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';

import Button from '../components/Button';
import Head from '../components/HeadManager';
import Icon from '../components/Icon';
import Modal from '../components/Modal';
import SearchTextInput from '../components/SearchTextInput';
import useFocus from '../hooks/useFocus';

export default function Search(props) {
    const { theme, themeColors } = useTheme();

    const { t } = useTranslation();
    const [value, setValue] = useState('');
    const [ref, setFocus] = useFocus();
    const [params] = useQueryParams();

    const onClose = () => window.history.go(-1);
    const onChange = val => setValue(val);
    const onSubmit = () => {
        navigate('/', false, { ...params, category: undefined, query: value });
    };

    const onKeyPress = ev => {
        if (ev.key === 'Enter' && value.length) {
            onSubmit();
        }
    };

    const onClear = () => {
        setValue('');
        setFocus();
    };

    return (
        <Modal>
            <Head pageTitle={props.pageTitle} />
            <Row
                alignItems="center"
                borderBottom={`1px solid ${themeColors[theme].secondaryFontColor23}`}
            >
                <Box cursor="pointer" padding="20px 15px 10px 0" props={{ onClick: onClose }}>
                    <Icon name={theme === THEME.DARK ? 'backWhite' : 'back'} />
                </Box>
                <SearchTextInput
                    ref={ref}
                    autoFocus
                    placeholder={t('searchLink.placeholder')}
                    value={value}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    flex={1}
                    fontSize={16}
                    padding="24px 24px 24px 14px"
                    border="none"
                />
                {!!value.length && (
                    <Box cursor="pointer" props={{ onClick: onClear }}>
                        <Icon name={theme === THEME.DARK ? 'clearDark' : 'clear'} />
                    </Box>
                )}
            </Row>
            <Box padding="40px 0 0" textAlign="right">
                <Button disabled={!value.length} onClick={onSubmit}>
                    {t('searchLink.search')}
                </Button>
            </Box>
        </Modal>
    );
}
