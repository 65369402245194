import AWS from 'aws-sdk';

import log from '../utils/log';

export default async function uploadImage(bucket, filename, data) {
    log(`Uploading image ${filename}`);
    const s3 = new AWS.S3();

    return s3
        .upload({
            Bucket: bucket,
            Key: `${filename}.jpg`,
            Body: data,
            ACL: 'private',
            Tagging: `uploaded_from=${window.location.origin}`
        })
        .promise();
}
