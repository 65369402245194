import useSWR from 'swr';

import api from '../api';
import useCustomerData from './useCustomerData';

export default function useOrders() {
    const { data: mappedData } = useCustomerData();
    const { user } = mappedData || {};

    const key = () =>
        `ordersHistory?uid=${user.id}&username=${user.username}&org=${user.organizationId}`;
    const fetch = () => api.getOrdersHistory(user.id, user.username, user.organizationId);
    const { data, error, mutate } = useSWR(key, fetch);

    async function getNextItems(startKey) {
        return await api.getOrdersHistory(user.id, user.username, user.organizationId, startKey);
    }

    return {
        data,
        error,
        mutate,
        getNextItems
    };
}
