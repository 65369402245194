import { updateDeliveryOptions } from '../utils/cacheDataManager';
import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function updateDeliveryInstructions(user, deliveryInstructions) {
    if (user.authenticated) {
        const payload = {
            user_id: user.id,
            thread_id: user.threadId,
            deliveryInstructions
        };
        return invokeLambda(Lambda.UpdateDeliveryInstructions, payload);
    } else {
        return updateDeliveryOptions(user.organizationId, deliveryInstructions);
    }
}
