import { v4 as uuidv4 } from 'uuid';

const trackPixel = async ({
    isMeta,
    advertiser_id,
    pixel_id,
    token,
    order_id,
    revenue,
    currency,
    user_data
}) => {
    if (isMeta) {
        const event_id = Math.floor(Math.random() * 1000000000000); //random number, used for deduplication
        const pixelScript = document.createElement('script');
        const purchaseScriptText = revenue ? `{'value':${revenue},'currency':'${currency}'}` : '{}';
        const pixelScriptText = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${advertiser_id}');
            fbq('track', '${pixel_id}',${purchaseScriptText}, {eventID: '${event_id}'});
        `;

        pixelScript.appendChild(document.createTextNode(pixelScriptText));

        const pixelNoScript = document.createElement('noscript');
        const pixelImage = document.createElement('img');
        pixelImage.setAttribute('height', '1');
        pixelImage.setAttribute('width', '1');
        pixelImage.setAttribute(
            'src',
            `https://www.facebook.com/tr?id=${advertiser_id}&ev=${pixel_id}&eid=${event_id}&noscript=1`
        );
        pixelNoScript.appendChild(pixelImage);

        try {
            document.head.appendChild(pixelScript);
            document.head.appendChild(pixelNoScript);
        } catch (e) {
            document.head.appendChild(pixelScript);
            document.head.appendChild(pixelNoScript);
        }

        try {
            //send event to conversion API
            const API_VERSION = 'v18.0';
            const PIXEL_ID = advertiser_id;
            const url = `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${token}`;

            const external_id = sessionStorage.getItem('external_id') || uuidv4();
            sessionStorage.setItem('external_id', external_id);

            const client_user_agent = navigator.userAgent;

            //get client ip address
            const ipAddressData = await fetch('https://api.ipify.org?format=json');
            const client_ip_address = (await ipAddressData.json()).ip;

            let formBody = [];
            const encodedKey = encodeURIComponent('data');
            const encodedValue = encodeURIComponent(
                JSON.stringify([
                    {
                        event_name: pixel_id,
                        event_id,
                        event_time: Math.floor(Date.now() / 1000),
                        action_source: 'website',
                        user_data: {
                            country:
                                '79adb2a2fce5c6ba215fe5f27f532d4e7edbac4b6a5e09e1ef3a08084a904621',
                            em: user_data?.email,
                            ph: user_data?.username,
                            client_ip_address,
                            client_user_agent,
                            external_id
                        },
                        ...(revenue && {
                            custom_data: {
                                value: (revenue / 100).toFixed(2),
                                currency: currency,
                                orderId: order_id
                            }
                        })
                    }
                ])
            );
            formBody.push(encodedKey + '=' + encodedValue);

            formBody = formBody.join('&');

            fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: formBody
            });
        } catch (e) {
            console.log('conversion api error:', e);
        }
    } else {
        const pixelScript = document.createElement('script');
        pixelScript.setAttribute('type', 'text/javascript');

        var stringifyPixelFunction = `
        (function() {
        var w = window, d = document;
        var s = d.createElement('script');
        s.setAttribute('async', 'true');
        s.setAttribute('type', 'text/javascript');
        s.setAttribute('src', '//c1.rfihub.net/js/tc.min.js');
        var f = d.getElementsByTagName('script')[0];
        f.parentNode.insertBefore(s, f);
        if (typeof w['_rfi'] !== 'function') {
            w['_rfi']=function() {
                w['_rfi'].commands = w['_rfi'].commands || [];
                w['_rfi'].commands.push(arguments);
            };
        }
        _rfi('setArgs', 'ver', '9');
        _rfi('setArgs', 'rb', '${advertiser_id}');
        _rfi('setArgs', 'ca', '${pixel_id}');
        _rfi('setArgs', '_o', '${advertiser_id}');
        _rfi('setArgs', '_t', '${pixel_id}');
        ${revenue ? `_rfi('setArgs', 'revenue', '${revenue}');` : ''}
        ${order_id ? `_rfi('setArgs', 'orderid', '${order_id}');` : ''}
        _rfi('track');
    })();
    `;
        const noScriptTag = document.createElement('noscript');
        const pixelIframe = document.createElement('iframe');
        pixelIframe.setAttribute(
            'src',
            `//${pixel_id}p.rfihub.com/ca.html?rb=${advertiser_id}&ca=${pixel_id}7&_o=${advertiser_id}&_t=${pixel_id}&ra=YOUR_CUSTOM_CACHE_BUSTER`
        );
        pixelIframe.setAttribute('style', 'display:none;padding:0;margin:0');
        pixelIframe.setAttribute('height', '0');
        pixelIframe.setAttribute('width', '0');
        noScriptTag.appendChild(pixelIframe);

        try {
            pixelScript.appendChild(document.createTextNode(stringifyPixelFunction));
            document.body.appendChild(pixelScript);
            document.body.appendChild(noScriptTag);
        } catch (e) {
            pixelScript.text = stringifyPixelFunction;
            document.body.appendChild(pixelScript);
        }
    }
};

export { trackPixel };
