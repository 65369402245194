import { updateCachePickupLocation } from '../utils/cacheDataManager';
import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function updateThreadPickupLocation(
    user,
    thread_id,
    order_type,
    pickup_destination
) {
    if (user.authenticated) {
        const payload = {
            thread_id,
            order_type,
            pickup_destination
        };
        return await invokeLambda(Lambda.UpdateThread, payload);
    } else if (!user.authenticated) {
        return updateCachePickupLocation(user.organizationId, pickup_destination);
    }
}
