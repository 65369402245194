import { useRef } from 'react';

export default function useFocus() {
    const input = useRef(null);

    function setFocus() {
        input.current && input.current.focus();
    }

    return [input, setFocus];
}
