import React from 'react';
import { navigate, useQueryParams } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { allProductsCategory, home } from '../api/constants';
import useStoreSettings from '../hooks/useStoreSettings';
import Image from './Image';

export default function Logo({ color, setSelectedCategory, categoriesProductEmpty }) {
    const { data: storeSettings } = useStoreSettings();
    const { theme, themeColors } = useTheme();
    const [params, setParams] = useQueryParams();

    if (!storeSettings) return null;
    const { name, small_logo_url } = storeSettings;
    const onClick = () => {
        navigate('/', false, {});
        if (categoriesProductEmpty) {
            setParams({ category: allProductsCategory.category_id });
        } else {
            setParams({}, true);
        }

        setSelectedCategory && setSelectedCategory(home);
    };

    return (
        <Row alignItems="center" cursor="pointer" props={{ onClick }}>
            <Image src={small_logo_url} alt="" width={45} height={45} borderRadius="50%" />
            <Box
                textTransform="uppercase"
                color={color || themeColors[theme].primaryFontColor1}
                fontWeight={900}
                marginLeft={5}
            >
                {name}
            </Box>
        </Row>
    );
}
