import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function getActiveTopPromotion(
    user,
    organization_id,
    associated_hub,
    cart_items,
    current_selected_campaign
) {
    let payload = {};
    if (user.authenticated) {
        payload = {
            user_id: user.id,
            organization_id,
            current_selected_campaign
        };
    } else {
        payload = {
            organization_id,
            associated_hub,
            cart_items: cart_items?.items,
            current_selected_campaign
        };
    }
    return invokeLambda(Lambda.GetActiveTopPromotion, payload);
}
