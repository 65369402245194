import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function applyPromocode(thread_id, user_id, promo_code) {
    console.log('api apply promocode');
    return await invokeLambda(Lambda.applyPromocode, {
        thread_id,
        user_id,
        promo_code
    });
}
