import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { navigate, useQueryParams } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { getCachedPromoLink, parsePromoParams } from '../api/utils';
import Icon from './Icon';

export default function WhitePromoAlert({
    setShowWhitePromoAlert,
    setSelectedCategory,
    promoCode
}) {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();

    const [params, setParams] = useQueryParams();

    const navigateToEligble = () => {
        const parsedLink = `${getCachedPromoLink().split('{store_domain}/').join('')}`;

        if (parsedLink.includes('p/')) {
            navigate(parsedLink);
        } else {
            const promoParams = parsePromoParams(parsedLink);
            setParams({ ...promoParams, category: undefined });
            setSelectedCategory('');
            setShowWhitePromoAlert(false);
        }
    };

    return (
        <Row
            width={isMobileOnly ? '95%' : 355}
            minHeight={63}
            padding=" 12px 20px"
            boxShadow="0px 5px 12px 0 rgba(0,0,0, 0.25)"
            borderRadius={10}
            backgroundColor={themeColors[theme].whiteBgColor}
            cursor="pointer"
            props={{ onClick: navigateToEligble }}
            position="fixed"
            bottom={20}
            right={0}
            left={0}
            margin="0 auto"
            zIndex={5}
            alignItems="center"
        >
            <Icon name="promoBlue" marginRight={20} />
            <Box
                width={355}
                fontSize={12}
                fontWeight={700}
                lineHeight={1.43}
                letterSpacing="1px"
                color={themeColors[theme].tertiaryFontColor7}
            >
                <Box fontWeight={900} fontSize={18}>
                    {promoCode}
                </Box>
                {t('categories.promoDesp')}
            </Box>
            <Icon name="arrowBlue" />
        </Row>
    );
}
