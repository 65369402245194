import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Heading from '../components/Heading';
import Navbar from '../components/Navbar';
import Wrapper from '../components/Wrapper';
import useStoreSettings from '../hooks/useStoreSettings';

export default function SubmitSuccess({ pageTitle }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const { data: storeSettings } = useStoreSettings();

    if (!storeSettings) return null;

    const day = storeSettings?.whenOpen?.day ?? null;
    const time = storeSettings?.whenOpen?.time ?? null;

    return (
        <Wrapper>
            <Navbar pageTitle={pageTitle} />
            <Heading marginTop={100}>
                {t('weClosed.title')}
                <span role="img" aria-label="ohh">
                    😞
                </span>
            </Heading>

            <Box
                fontWeight={500}
                fontSize={16}
                color={themeColors[theme].secondaryFontColor12}
                marginBottom={20}
            >
                {t('weClosed.back')} {day} {t('weClosed.at')} {time} {t('weClosed.comeBack')}
            </Box>
        </Wrapper>
    );
}
