import React from 'react';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import selected from 'assets/images/selected.svg';

export default function RadioButton({
    value,
    onChange,
    checked,
    name,
    alignEnd,
    noSpace,
    checkbox,
    children,
    ...rest
}) {
    const { theme, themeColors } = useTheme();

    return (
        <Row component="label" alignItems={alignEnd ? 'flex-start' : 'center'} {...rest}>
            <Box
                component="input"
                display="none"
                props={{
                    type: checkbox ? 'checkbox' : 'radio',
                    name,
                    checked,
                    value,
                    onChange
                }}
            />
            <Row
                width={!noSpace && 46}
                height={!noSpace && 44}
                objectFit={'contain'}
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    margin={'auto auto'}
                    width={21}
                    height={21}
                    borderRadius="50%"
                    border={
                        checked
                            ? `1px solid ${themeColors[theme].primaryBorderColor}`
                            : `1px solid ${themeColors[theme].secondaryBorderColor13}`
                    }
                    background={checked ? `url(${selected}) center no-repeat` : 'transparent'}
                ></Box>
            </Row>
            <Box paddingLeft={10} flex={2}>
                {children}
            </Box>
        </Row>
    );
}
