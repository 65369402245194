import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import SaveButton from 'components/DeliveryOptionsModal/components/SaveButton';
import Heading from 'components/Heading';
import { Divider, Subtitle } from 'components/shared';

export default function Page({
    children,
    title,
    subtitle,
    onSave,
    saveEnabled,
    loading,
    buttonTitle,
    padding,
    spaceBetween,
    footerText,
    secondColor,
    hideFooter,
    fullHeight,
    filterModal
}) {
    const { theme, themeColors } = useTheme();

    return (
        <Col
            height={isMobileOnly && !fullHeight ? 'auto' : '100%'}
            maxHeight={!hideFooter && !footerText ? '100vh' : filterModal ? '85vh' : 'auto'}
        >
            <Col className="vertical-scrolling" paddingBottom={!footerText && 100}>
                <Heading
                    marginBottom={10}
                    padding={padding}
                    color={secondColor && themeColors[theme].tertiaryFontColor2}
                    fontSize={27}
                >
                    {title}
                </Heading>
                {subtitle && (
                    <Subtitle
                        width="100%"
                        color={themeColors[theme].greyfontColor1}
                        fontWeight="bold"
                        opacity={1}
                    >
                        {subtitle}
                    </Subtitle>
                )}

                {children}
            </Col>
            {!hideFooter && (
                <Col
                    position="fixed"
                    bottom={0}
                    padding="20px 0 30px"
                    right={isMobileOnly ? 20 : 60}
                    left={isMobileOnly ? 0 : 60}
                    margin="0 auto"
                    flex="1 1"
                    justifyContent="flex-end"
                    marginTop={isMobileOnly ? 30 : 40}
                    zIndex={10}
                    boxShadow="inset 10em 10em rgb(255 255 255 / 90%)"
                >
                    {!isMobileOnly && <Divider marginBottom={24} />}
                    <Row
                        justifyContent={spaceBetween && footerText ? 'space-between' : 'flex-end'}
                        alignItems="center"
                    >
                        {!isMobileOnly && footerText && (
                            <Box
                                color={themeColors[theme].secondaryFontColor1}
                                fontSize={14}
                                lineHeight={1.43}
                                letterSpacing={1}
                                marginRight={100}
                                maxWidth={290}
                            >
                                {footerText}
                            </Box>
                        )}

                        {isMobileOnly && footerText ? null : (
                            <SaveButton
                                width={!isMobileOnly ? 260 : 'auto'}
                                minWidth={130}
                                loading={loading}
                                disabled={!saveEnabled}
                                onClick={onSave}
                                title={buttonTitle}
                                noShadow
                            />
                        )}
                    </Row>
                </Col>
            )}
        </Col>
    );
}
