import { Lambda } from './constants';
import { mapThread } from './getThread';
import invokeLambda from './invokeLambda';

export default async function getOrdersHistory(userId, username, orgId, startKey) {
    const payload = {
        user_id: userId,
        username,
        organization_id: orgId,
        start_key: startKey
    };

    const { ordersList, lastEvaluatedKey } = await invokeLambda(Lambda.OrdersHistory, payload);

    const orders = ordersList.map(order => mapThread(order));

    return {
        orders,
        startKey: lastEvaluatedKey
    };
}
