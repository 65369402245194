import React, { useEffect, useRef, useState } from 'react';
import { isAndroid, isMobileOnly } from 'react-device-detect';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Box, Col, Inline, Row } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useTheme from 'hooks/useTheme';

import { ORDER_TYPE } from 'api/constants';
import { isHebrew } from 'api/utils';

import { useAppContext } from 'store';

import addressNotRight from 'assets/images/address-not-right.svg';
import badAddress from 'assets/images/bad-address.svg';
import closeIcon from 'assets/images/black-close-icon.svg';

import SaveButton from 'components/DeliveryOptionsModal/components/SaveButton';
import Image from 'components/Image';
import { Divider } from 'components/shared';
import LocationsList from 'components/shared/LocationsList';

import DeliveryPickupTab from '../../components/DeliveryPickupTab';
import Icon from '../../components/Icon';
import ModalPage from '../../components/ModalPage';
import Spinner from '../../components/Spinner';

const maxNoteLength = 120;

function DeliveryAddressModal({
    showModal,
    onClose,
    updateAddress,
    validateAddress,
    organizationId,
    saveBtnEnabled,
    deliveryDefaults,
    pickupLocations,
    updatePickupLocation,
    currentPickupLocation,
    timeZone,
    isAuth,
    supportPickup,
    supportsDelivery,
    isInvalidZone,
    address
}) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState();
    const [clearVisible, setClearVisible] = useState(false);
    const [value, setValue] = useState('');
    const [showPredictions, setShowPredictions] = useState(false);
    const [validAddress, setValidAddress] = useState(false);
    const [addressLoading, setAddressLoading] = useState(false);
    const [savingAddress, setSavingAddress] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(saveBtnEnabled);
    const [notesValue, setNotesValue] = useState('');
    const [zone, setZone] = useState();

    const [activeDeliveryOption, setActiveDeliveryOption] = useState(
        currentPickupLocation && supportPickup
            ? ORDER_TYPE.PICKUP
            : supportsDelivery
              ? ORDER_TYPE.DELIVERY
              : supportPickup
                ? ORDER_TYPE.PICKUP
                : null
    );
    const [activePickupLocation, setActivePickupLocation] = useState(currentPickupLocation);
    const [addressInputFocus, setAddressInputFocus] = useState(false);

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [deviceHeight, setDeviceHeight] = useState(
        isAndroid ? window.screen.height : window.innerHeight
    );

    const { state, appContextActions } = useAppContext();
    const { pendingAction } = state;

    const { data: mappedData, store } = useCustomerData();
    const { thread } = mappedData || {};

    const inputRef = useRef();
    const ageVerified = localStorage.getItem('ageVerified');

    const notdeliverDesp = (
        <Box>
            <Box>{t('addressBar.noteDeliverBold')}</Box>
            {supportPickup && (
                <Box>
                    {t('addressBar.or')}
                    <Inline
                        cursor="pointer"
                        textDecoration="underline"
                        props={{
                            onClick: () => {
                                setActiveDeliveryOption(ORDER_TYPE.PICKUP);
                                setError(null);
                            }
                        }}
                    >
                        {t('addressBar.noteDeliverBold2')}
                    </Inline>
                </Box>
            )}
        </Box>
    );

    const ERRORS = {
        BadAddress: {
            key: 'BadAddress',
            text: t('addressBar.badAddress'),
            desp: t('addressBar.badAddressBold')
        },
        NotDeliver: {
            key: 'NotDeliver',
            text: t('addressBar.noteDeliver'),
            desp: notdeliverDesp
        }
    };

    const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        debounce: 500
    });

    const handleWindowResize = React.useCallback(() => {
        setDeviceWidth(window.innerWidth);
        setDeviceHeight(isAndroid ? window.screen.height : window.innerHeight);
    }, []);

    const unselectPickupOption = () => {
        setActivePickupLocation(null);
        setSaveEnabled(false);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    useEffect(() => {
        const activeLocationChanged =
            activePickupLocation?.destination_id &&
            activePickupLocation?.destination_id !== currentPickupLocation?.destination_id;
        setSaveEnabled(activeLocationChanged);
    }, [activePickupLocation?.destination_id]);

    useEffect(() => {
        isInvalidZone && handlePlaceSelected(address);
    }, [isInvalidZone, address]);

    const makeInputActiveAndEmpty = async () => {
        setValue('');
        setInputValue('');
        setValidAddress(false);
        setSaveEnabled(false);
        setError(false);
        if (ageVerified && inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleBlur = () => {
        setClearVisible(false);
        setAddressInputFocus(false);
    };

    const handleFocus = async () => {
        setAddressInputFocus(true);
        setClearVisible(inputValue);
    };

    const onClear = e => {
        e.stopPropagation();
        setError(null);
        setShowPredictions(false);
        makeInputActiveAndEmpty();
        setClearVisible(false);
    };

    const handlePlaceSelected = async address => {
        if (address) {
            setValue(address);
            setInputValue(address);
            setShowPredictions(false);
            setClearVisible(true);
            setAddressLoading(true);
            const response = await validateAddress(address, organizationId);

            if (response?.zone) {
                setZone(response.zone);
            }

            if (response.error && response.errorCode === 'NotFound') {
                setError(ERRORS.BadAddress);
            } else if (response.error && response.errorCode === 'InvalidZone') {
                setError(ERRORS.NotDeliver);
            } else {
                setValidAddress(true);
                setSaveEnabled(true);
                setActiveDeliveryOption(ORDER_TYPE.DELIVERY);
            }
            setAddressLoading(false);
        }
    };

    const autofillDeliveryAddress = async () => {
        const address = thread?.location?.address;
        if (address) {
            setValue(address);
            setInputValue(address);
            setShowPredictions(false);

            setAddressLoading(true);
            const response = await validateAddress(address, organizationId);
            if (response.error && response.errorCode === 'NotFound') {
                setError(ERRORS.BadAddress);
            } else if (response.error && response.errorCode === 'InvalidZone') {
                setError(ERRORS.NotDeliver);
            } else {
                setValidAddress(true);
                setSaveEnabled(true);
                setActiveDeliveryOption(ORDER_TYPE.DELIVERY);
            }
            setAddressLoading(false);
            inputRef.current.blur();
        }
    };

    const onSaveAddress = async () => {
        try {
            setSavingAddress(true);
            if (zone?.redirect_url) {
                // redirect to the zone url
                window.location = zone.redirect_url;
                return null;
            }

            await updateAddress(value, organizationId);

            if (notesValue) {
                const deliveryInstructions = {
                    delivery_type: thread?.deliveryType,
                    note: notesValue || deliveryDefaults.note,
                    change: thread?.change
                };
                await store.updateDeliveryInstructions(deliveryInstructions);
            }
            if (pendingAction) {
                try {
                    await pendingAction();
                } catch (e) {
                    console.debug('failing silently', e);
                }
            }

            appContextActions.pendingAction.remove();
        } finally {
            setSavingAddress(false);

            appContextActions.deliveryAddressModal.close();
        }
    };

    const onSavePickup = async () => {
        try {
            setSavingAddress(true);
            await updatePickupLocation(activePickupLocation);

            if (pendingAction) {
                try {
                    await pendingAction();
                } catch (e) {
                    console.debug('failing silently', e);
                }
            }

            appContextActions.pendingAction.remove();
        } finally {
            if (!isAuth) {
                setTimeout(() => {
                    setSavingAddress(false);
                    appContextActions.deliveryAddressModal.close();
                }, 300);
            } else {
                setSavingAddress(false);
                appContextActions.deliveryAddressModal.close();
            }
        }
    };

    const renderItem = item => {
        return (
            <Box
                background="none"
                hoverBackground={themeColors[theme].secondaryFontColor7}
                border="none"
                padding="5px 0"
                fontSize={16}
                color={themeColors[theme].popupFontColor3}
                cursor="pointer"
                margin="0 5px 5px"
                key={item.place_id}
                props={{
                    onClick: async () => {
                        handlePlaceSelected(item.description);
                    }
                }}
            >
                <Inline color={themeColors[theme].popupFontColor1} fontWeight={700}>
                    {item.structured_formatting.main_text}{' '}
                </Inline>
                {item.structured_formatting.secondary_text}
            </Box>
        );
    };

    const deliveryView = (
        <Box position="relative">
            <Box
                component="input"
                fontSize={16}
                fontWeight={600}
                color={themeColors[theme].tertiaryFontColor2}
                paddingRight={clearVisible && 40}
                placeholderColor={themeColors[theme].placeHolderColor3}
                letterSpacing="1px"
                lineHeight="1.25"
                border={
                    validAddress
                        ? 'none'
                        : ` solid 2px  ${
                              error
                                  ? themeColors[theme].quaternaryBorderColor
                                  : themeColors[theme].tertiaryBorderColor
                          }`
                }
                cursor="pointer"
                backgroundColor={
                    validAddress
                        ? themeColors[theme].tertiaryBgColor
                        : themeColors[theme].secondaryBgColor7
                }
                width={isMobileOnly ? '100%' : 450}
                height={52}
                borderRadius={8}
                opacity={!inputValue && '0.5'}
                paddingLeft={34}
                display="inline-block"
                props={{
                    value,
                    placeholder: t('addressBar.placeholder'),
                    ref: inputRef,
                    autoFocus: !currentPickupLocation && !isInvalidZone && true,
                    onBlur: () =>
                        setTimeout(() => {
                            handleBlur();
                        }, 300),
                    onFocus: () => {
                        handleFocus();
                    },

                    onChange: evt => {
                        setClearVisible(evt.target.value);
                        setValue(evt.target.value);
                        setInputValue(evt.target.value);
                        getPlacePredictions({ input: evt.target.value });
                        setShowPredictions(true);
                        setError(null);
                    }
                }}
                loading={isPlacePredictionsLoading}
            />

            {addressLoading ? (
                <Spinner noMargin position="absolute" left={8} top={14} />
            ) : error ? (
                <Icon name="deliverRed" position="absolute" left={12} top={17} />
            ) : (
                <Icon name="deliver" position="absolute" left={12} top={17} />
            )}

            <Box
                cursor="pointer"
                right={isMobileOnly ? 13 : 100}
                top={17}
                position="absolute"
                props={{
                    onClick: onClear
                }}
            >
                <Icon
                    className="input-clear"
                    display={clearVisible ? 'block' : 'none'}
                    width={19}
                    height={19}
                    name="clearSearch"
                />
            </Box>
            <Box
                marginTop={!inputValue && 10}
                color={
                    error
                        ? themeColors[theme].quaternaryFontColor2
                        : themeColors[theme].popupFontColor2
                }
                fontSize={14}
                fontWeight={500}
            >
                {!inputValue ? t('addressBar.searchTip') : error ? error.text : null}
            </Box>

            {showPredictions && inputRef.current?.value?.length ? (
                <Box width="100%" paddingTop={10}>
                    {placePredictions.map(item => renderItem(item))}
                </Box>
            ) : null}

            {validAddress && inputValue && (
                <AddtionalAddressInfo
                    notesValue={notesValue}
                    setNotesValue={setNotesValue}
                    setSaveEnabled={setSaveEnabled}
                    t={t}
                    theme={theme}
                    themeColors={themeColors}
                />
            )}
            {error && (
                <ErrorView
                    t={t}
                    ERRORS={ERRORS}
                    error={error}
                    theme={theme}
                    themeColors={themeColors}
                />
            )}
        </Box>
    );

    const pickupView = (
        <LocationsList
            locations={pickupLocations}
            timeZone={timeZone}
            setActiveLocation={setActivePickupLocation}
            activeLocation={activePickupLocation}
        />
    );

    const deliveryOptionActive = activeDeliveryOption === ORDER_TYPE.DELIVERY;

    const modalStyle = () => {
        const isSmallScreen = isMobileOnly || deviceWidth <= 480 || deviceHeight <= 480;
        if (isSmallScreen) {
            return {
                content: {
                    position: 'absolute',
                    top: `${
                        deviceWidth < 330
                            ? '10%'
                            : deviceHeight <= 480
                              ? '10%'
                              : validAddress && deliveryOptionActive
                                ? 'auto'
                                : '5%'
                    }`,
                    left: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    right: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    bottom: '0',
                    backgroundColor: themeColors[theme].popupBgColor1,
                    border: 'none',
                    borderRadius: '20px 20px 0 0',
                    // maxHeight: '85vh',
                    color: themeColors[theme].blackFontColor
                }
            };
        } else {
            return {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    padding: '50px 60px 55px',
                    borderRadius: '20px',
                    height: '630px',
                    width: '658px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'hidden',
                    backgroundColor: themeColors[theme].popupBgColor1,
                    color: themeColors[theme].blackFontColor
                }
            };
        }
    };

    const modalStyles = modalStyle();

    const deliveryOptions = [];
    if (supportsDelivery) {
        deliveryOptions.push('delivery');
    }
    if (supportPickup) {
        deliveryOptions.push('pickup');
    }

    return (
        <Box>
            <Modal
                style={modalStyles}
                isOpen={showModal}
                onRequestClose={() => {
                    onClose();
                    appContextActions.pendingAction.remove();
                }}
                overlayClassName="buy-now-modal-overlay"
                bodyOpenClassName="ReactModal__Body--no_scroll"
                closeTimeoutMS={500}
            >
                <Row
                    justifyContent="flex-end"
                    boxShadow="0 4px 10px 4px rgb(255 255 255 / 90%)"
                    position="relative"
                    zIndex={10}
                >
                    <Image
                        cursor="pointer"
                        src={closeIcon}
                        width={30}
                        height={30}
                        padding={7}
                        onClick={() => {
                            onClose();
                            appContextActions.pendingAction.remove();
                        }}
                    />
                </Row>

                <ModalPage
                    spaceBetween
                    secondColor
                    padding="10px 0 0 0"
                    title={
                        deliveryOptionActive
                            ? t('addressModal.title')
                            : t('addressModal.choosePickup')
                    }
                    saveEnabled={saveEnabled}
                    footerText={error?.desp}
                    hideFooter={true}
                >
                    <Col justifyContent="flex-end" background={themeColors[theme].popupBgColor1}>
                        {supportPickup && (
                            <DeliveryPickupTab
                                deliveryOptions={deliveryOptions}
                                activeDeliveryOption={activeDeliveryOption}
                                setActiveDeliveryOption={setActiveDeliveryOption}
                                savedDeliveryAddress={thread?.location?.address}
                                autofillDeliveryAddress={autofillDeliveryAddress}
                                unselectPickupOption={unselectPickupOption}
                                validAddress={validAddress}
                                setError={setError}
                            />
                        )}
                        {deliveryOptionActive && supportsDelivery
                            ? deliveryView
                            : supportPickup
                              ? pickupView
                              : null}
                    </Col>
                    <Col
                        position="fixed"
                        bottom={0}
                        right={0}
                        left={0}
                        margin="0 auto"
                        flex="1 1"
                        justifyContent="flex-end"
                        marginTop={isMobileOnly ? 30 : 40}
                        zIndex={10}
                        boxShadow="inset 10em 10em rgb(255 255 255 / 95%)"
                        display={addressInputFocus && isMobileOnly && 'none'}
                        background={themeColors[theme].popupBgColor1}
                    >
                        {isMobileOnly && error ? null : <Divider marginBottom={20} light />}
                        <Row
                            justifyContent={
                                !error && (deliveryOptionActive || !activePickupLocation)
                                    ? 'flex-end'
                                    : 'space-between'
                            }
                            alignItems="center"
                            padding="0 20px 35px 20px"
                        >
                            {!deliveryOptionActive && activePickupLocation && (
                                <Row alignItems="center" marginRight={20}>
                                    <Icon name="cart" height={24} />
                                    <Box
                                        color={themeColors[theme].popupFontColor2}
                                        fontSize={12}
                                        fontWeight={500}
                                        lineHeight={1.43}
                                        letterSpacing={1}
                                        margin="0px 15px 0 10px"
                                        width={isMobileOnly && 155}
                                    >
                                        {isHebrew() ? (
                                            <Box>
                                                {t('addressModal.pickupReadyNote')}
                                                <Box>
                                                    {activePickupLocation?.default_pickup_estimate}
                                                </Box>
                                            </Box>
                                        ) : (
                                            t('addressModal.pickupReadyNote', {
                                                est_order_ready_time:
                                                    activePickupLocation?.default_pickup_estimate
                                            })
                                        )}
                                    </Box>
                                </Row>
                            )}
                            {!isMobileOnly && error?.desp && (
                                <Box
                                    color="#9e9ead"
                                    fontSize={14}
                                    lineHeight={1.43}
                                    letterSpacing={1}
                                    marginRight={100}
                                    maxWidth={290}
                                >
                                    {error?.desp}
                                </Box>
                            )}
                            {isMobileOnly && error?.desp && deliveryOptionActive ? null : (
                                <SaveButton
                                    width={!isMobileOnly ? 260 : 'auto'}
                                    loading={savingAddress}
                                    disabled={!saveEnabled}
                                    onClick={deliveryOptionActive ? onSaveAddress : onSavePickup}
                                    title={
                                        deliveryOptionActive
                                            ? t('addressModal.btnText')
                                            : t('addressModal.save')
                                    }
                                    small
                                />
                            )}
                        </Row>
                    </Col>
                </ModalPage>
            </Modal>
        </Box>
    );
}

const AddtionalAddressInfo = ({
    notesValue,
    setNotesValue,
    setSaveEnabled,
    t,
    theme,
    themeColors
}) => {
    const [noteInputOnFocus, setNoteInputOnFocus] = useState(false);
    const [charactersLeft, setCharactersLeft] = useState(maxNoteLength);
    const [noteError, setNoteError] = useState(false);

    const onTextChange = e => {
        setNotesValue(e.target.value);
        setCharactersLeft(maxNoteLength - e.target.value.length);
        if (maxNoteLength - e.target.value.length === 0) {
            setSaveEnabled(false);
            setNoteError(true);
        } else {
            setSaveEnabled(true);
            setNoteError(false);
        }
    };

    return (
        <Box position="relative">
            {notesValue && noteInputOnFocus && (
                <Box
                    position="absolute"
                    right={isMobileOnly ? 13 : 100}
                    top={7}
                    backgroundColor={
                        noteError
                            ? themeColors[theme].errNoticeBgColor
                            : themeColors[theme].tertiaryBgColor1
                    }
                    width={24}
                    height={14}
                    fontSize={10}
                    textAlign="center"
                    color={themeColors[theme].tertiaryFontColor1}
                    borderRadius="3px 3px 0px 0px"
                >
                    {charactersLeft}
                </Box>
            )}
            <Box
                component="textarea"
                width={isMobileOnly ? '100%' : 450}
                marginTop={20}
                height={137}
                backgroundColor={themeColors[theme].secondaryBgColor7}
                color={themeColors[theme].tertiaryFontColor2}
                placeholderColor={themeColors[theme].placeHolderColor2}
                borderRadius={8}
                resize="none"
                border={
                    noteInputOnFocus
                        ? `solid 2px ${
                              noteError
                                  ? themeColors[theme].quaternaryBorderColor
                                  : themeColors[theme].tertiaryBorderColor
                          }`
                        : 'none'
                }
                padding="15px 40px 15px 20px"
                font="500 16px Heebo"
                letterSpacing={1}
                props={{
                    placeholder: t('addressModal.addtionalInfo'),
                    value: notesValue,
                    onFocus: () => {
                        setNoteInputOnFocus(true);
                    },
                    onBlur: () =>
                        setTimeout(() => {
                            setNoteInputOnFocus(false);
                        }, 300),
                    onChange: onTextChange,
                    maxLength: maxNoteLength
                }}
            />
            {notesValue && (
                <Box
                    position="absolute"
                    right={isMobileOnly ? 13 : 100}
                    top={36}
                    cursor="pointer"
                    props={{
                        onClick: () => {
                            setNotesValue('');
                            setNoteError(false);
                        }
                    }}
                >
                    {noteInputOnFocus && <Icon name={'clearChange'} />}
                </Box>
            )}
            {noteError && (
                <Box
                    color={themeColors[theme].quaternaryFontColor2}
                    fontSize={12}
                    letterSpacing={1}
                    lineHeight={1}
                    marginLeft={10}
                >
                    {t('addressModal.charLimit')}
                </Box>
            )}
        </Box>
    );
};

const ErrorView = ({ t, ERRORS, error, theme, themeColors }) => (
    <Box minHeight={208} marginTop={isMobileOnly ? 100 : 50} maxWidth={325} margin="0 auto 20px">
        <Image
            src={error.key === ERRORS.NotDeliver.key ? badAddress : addressNotRight}
            maxWidth={350}
            borderRadius={8}
        />
        {isMobileOnly && (
            <Box
                marginTop={40}
                color={themeColors[theme].secondaryFontColor1}
                fontSize={14}
                lineHeight={1.43}
                letterSpacing={1}
                textAlign="center"
            >
                {error.desp}
            </Box>
        )}
    </Box>
);

export default DeliveryAddressModal;
