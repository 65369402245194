import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';

import { THEME } from '../api/constants';
import Head from './HeadManager';
import Icon from './Icon';
import Logo from './Logo';

function Navbar({
    concise,
    onLogout,
    color,
    pageTitle,
    promoCode,
    setSelectedCategory,
    categoriesProductEmpty,
    ...rest
}) {
    const { theme, themeColors } = useTheme();
    const { data: mappedData } = useCustomerData();
    const { t } = useTranslation();
    const { session, user } = mappedData || {};

    const { data: storeSettings } = useStoreSettings();

    if (!session || !user || !storeSettings) {
        return null;
    }

    const onClick = () => navigate(`/account`);
    return (
        <Row
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={30}
            {...rest}
        >
            <Head pageTitle={pageTitle} />
            <Logo
                color={color}
                setSelectedCategory={setSelectedCategory}
                categoriesProductEmpty={categoriesProductEmpty}
            />
            {!concise && !onLogout && user && session.authenticated && (
                <Row
                    alignItems="center"
                    fontSize={17}
                    fontWeight={500}
                    color={
                        color || theme === THEME.DARK
                            ? themeColors[theme].whitefontColor
                            : themeColors[theme].blackFontColor
                    }
                    cursor="pointer"
                    props={{ onClick: onClick }}
                >
                    {(user.name && user.name.split(' ')[0]) || t('auth.myInfo')}
                    {user.picture ? (
                        <Box
                            component="img"
                            width={32}
                            height={32}
                            objectFit="cover"
                            objectPosition="center"
                            borderRadius="50%"
                            marginLeft={5}
                            props={{ src: user.picture }}
                        />
                    ) : (
                        <Icon marginLeft={5} name="userPlaceholder" />
                    )}
                </Row>
            )}
            {!concise && session && !session.authenticated && (
                <Box
                    cursor="pointer"
                    fontSize={17}
                    fontWeight={500}
                    padding="15px 0 15px 15px"
                    color={color || themeColors[theme].primaryFontColor1}
                    props={{
                        onClick: () => {
                            if (promoCode) {
                                navigate('/login', false, { from: 'ref' });
                            } else {
                                navigate('/login');
                            }
                        }
                    }}
                >
                    {t('auth.logIn')}
                </Box>
            )}
            {onLogout && (
                <Box
                    cursor="pointer"
                    fontSize={17}
                    fontWeight={500}
                    padding="15px 0 15px 15px"
                    color={color || themeColors[theme].primaryFontColor1}
                    props={{
                        onClick: onLogout
                    }}
                >
                    {t('auth.logOut')}
                </Box>
            )}
        </Row>
    );
}

export default React.memo(Navbar);
