import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import DeliveryModel from 'constants/DeliveryModel';
import { addSeconds, format, fromUnixTime } from 'date-fns';
import { Box, Col, Inline, Row } from 'jsxstyle';
import moment from 'moment/min/moment-with-locales';

import useCustomerData from 'hooks/useCustomerData';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';

import { useAppContext } from 'store';

import Alert from 'components/Alert';
import Button from 'components/ButtonGrey';
import CancelOrder from 'components/CancelOrder';
import CartSummary from 'components/CartSummary';
import CartTotal from 'components/CartTotal';
import Collapse from 'components/Collapse';
import Footer from 'components/Footer';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import Navbar from 'components/Navbar';
import Paragraph from 'components/Paragraph';
import { Caption } from 'components/shared';
import Spinner from 'components/Spinner';
import StatusMap from 'components/StatusMap';

import { ORDER_TYPE, SUPPORTED_TRANSACTION_TYPE, THEME, THREAD_STATUS } from '../api/constants';
import {
    formatCentsPercentageValue,
    isHebrew,
    isThreadCancelled,
    priceToString,
    threadSubmitted,
    timestamp
} from '../api/utils';
import selected from './../assets/images/selected.svg';

export default function OrderStatus({ pageTitle }) {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();

    const {
        state: { currency, paymentMethod },
        appContextActions
    } = useAppContext();

    const { data: mappedData, store } = useCustomerData();

    const { thread, user, cart, zone, order_display_prices } = mappedData || {};

    if (user && !user.authenticated) {
        navigate('/login');
    }

    if (user && !thread) navigate('/notfound');

    const [instructions, setInstructions] = useState({});
    const [instructionsUpdated, setInstructionsUpdated] = useState(false);
    const [saveInstructionsVisible, setSaveInstructionsVisible] = useState(false);
    const [instructionSaveEnabled, setInstructionSaveEnabled] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(undefined);
    const { data: storeSettings } = useStoreSettings();
    const [showAlert, setShowAlert] = useState(false);
    const [fadeOutAlert, setFadeOutAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);
    const [alertIcon, setAlertIcon] = useState(false);
    const [cancelling, setCancelling] = useState(false);
    const [postponing, setPostponing] = useState(false);

    const scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    };

    function showNotification() {
        setShowAlert(true);
        setTimeout(() => {
            setFadeOutAlert(true);
        }, 5000);
        setTimeout(() => {
            setShowAlert(false);
            setFadeOutAlert(false);
        }, 10000);
    }

    async function onCancel(cancelReason, cancelReasonAdditionalText) {
        setCancelling(true);

        const response = await store.cancel(cancelReason, cancelReasonAdditionalText);
        if (!response?.success || response?.error) {
            setAlertIcon('whiteClear');
            setAlertMessage(t('orderStatus.orderCancelFailed'));
            showNotification();
        } else {
            appContextActions.deliveryModel.set(DeliveryModel.OnDemand);
            scrollToTop();
            navigate('/', false, { order_cancelled_success: 'true' });
        }
        setCancelling(false);
    }

    async function onOrderDelay() {
        setPostponing(true);
        const response = await store.delayOrder();
        if (response?.success && !response?.error) {
            setAlertIcon('whiteCheck');
            setAlertMessage(t('orderStatus.orderDelayed'));
        } else {
            setAlertIcon('whiteClear');
            setAlertMessage(t('orderStatus.orderDelayFailed'));
        }

        scrollToTop();
        showNotification();
        setPostponing(false);
    }

    function getHeadline() {
        const locale = localStorage.getItem('language')?.slice(0, 2) || 'en';
        if (thread) {
            switch (thread.status) {
                case THREAD_STATUS.CANCELED:
                    return `${t('orders.cancelled')} ${moment
                        .unix(thread.closedTime)
                        .locale(locale)
                        .format('MMM Do')}`;
                case THREAD_STATUS.CLOSED:
                    return `${t('orders.delivered')} ${moment
                        .unix(thread.closedTime)
                        .locale(locale)
                        .format('MMM Do')}`;
                default:
                    return t('orderStatus.title');
            }
        } else {
            return t('orderStatus.title');
        }
    }

    const isLoading = !thread || !cart;

    const onDeliveryInstructionsChange = props => {
        const deliveryInstructions = {
            delivery_type: props.type || instructions.delivery_type || thread.deliveryType,
            note: props.note || instructions.note || thread.note,
            change: thread.change
        };
        setInstructions(deliveryInstructions);
        setInstructionSaveEnabled(true);
    };

    if (isLoading) {
        return (
            <Wrapper>
                <Navbar pageTitle={pageTitle} />
                <Box>{t('orders.fetchingOrder')}</Box>
                <Spinner />
            </Wrapper>
        );
    }

    const isCancelled = isThreadCancelled(thread);
    const isNotSubmitted = !isCancelled && !threadSubmitted(thread);

    if (isCancelled) {
        cart.charges.total = priceToString(0, currency);
    }
    if (isNotSubmitted) {
        return (
            <Wrapper>
                <Navbar pageTitle={pageTitle} />
                <Box marginTop={60}>
                    {t('orderStatus.noActive')}{' '}
                    <Inline
                        textDecoration="underline"
                        cursor="pointer"
                        color={themeColors[theme].secondaryFontColor2}
                        hoverColor={themeColors[theme].secondaryFontColor2}
                        props={{ onClick: () => navigate('/orders') }}
                    >
                        {t('orderStatus.pastOrders')}
                    </Inline>
                </Box>
            </Wrapper>
        );
    }

    const closedTime = thread.closedTime ? format(fromUnixTime(thread.closedTime), 'h:mm') : null;

    //showing eta range eta - eta + 30 min
    const eta = thread.eta
        ? `${format(addSeconds(new Date(), thread.eta), 'h:mm aa')} - ${format(
              addSeconds(new Date(), thread.eta + 30 * 60),
              'h:mm aa'
          )}`
        : null;

    const delayTime = thread.delayTime;

    const mq = {
        s: 'screen and (min-width: 550px)',
        m: 'screen and (min-width: 700px)'
    };

    const change = priceToString(thread?.change || 0, currency);

    const onInstructionsUpdate = async () => {
        let cleanup = () => {};
        if (!instructionSaveEnabled) {
            return;
        }
        try {
            setInstructionsUpdated(true);
            await store.updateDeliveryInstructions(instructions);
            setInstructionSaveEnabled(false);
            setSaveInstructionsVisible(false);
            setUpdateStatus(t('orderStatus.updated'));
        } catch (_) {
            setUpdateStatus(t('orderStatus.failed'));
        } finally {
            setInstructionsUpdated(false);
            const delayedSideEffect = setTimeout(() => {
                setUpdateStatus(undefined);
            }, 2000);
            cleanup = () => {
                clearTimeout(delayedSideEffect);
                setUpdateStatus(undefined);
            };
        }
        return cleanup;
    };

    const cartItemsCount = cart?.items?.reduce((count, i) => count + i?.product_count || 0, 0) || 0;
    const postponed = thread.visible_from && thread.visible_from > timestamp();
    const pickupLocation = thread?.pickup_destination;

    return (
        <Col padding="20px 0" background={themeColors[theme].secondaryBgColor1} minHeight="100vh">
            <Padding maxWidth={800} width="100%" margin="0 auto" flex="0 0 auto">
                <Navbar pageTitle={pageTitle} />
            </Padding>
            <Box
                maxWidth={800}
                minHeight={600}
                width="100%"
                margin="0 auto"
                flex="1 0 auto"
                paddingBottom={150}
            >
                <Padding>
                    {isHebrew() ? (
                        <Paragraph opacity={theme !== THEME.DARK && 0.3} lineHeight="1">
                            #{thread.trackingNumber}
                            {t('orders.orderNum')}
                        </Paragraph>
                    ) : (
                        <Paragraph opacity={theme !== THEME.DARK && 0.3} lineHeight="1">
                            {t('orders.orderNum')}
                            {thread.trackingNumber}
                        </Paragraph>
                    )}

                    <Heading>{getHeadline()}</Heading>
                    <Box fontSize={16} fontWeight={500} marginBottom={40} opacity={0.8}>
                        {pickupLocation ? (
                            <Row alignItems="center" marginBottom={5}>
                                <Icon name={theme === THEME.DARK ? 'storeWhite' : 'store'} />
                                <Inline marginLeft={5}>{pickupLocation.name}</Inline>
                            </Row>
                        ) : (
                            <Box>{user.name}</Box>
                        )}
                        <Box>
                            {pickupLocation
                                ? pickupLocation?.location.address
                                : thread?.location?.address}
                        </Box>
                    </Box>
                </Padding>
                <Col mediaQueries={mq} mFlexDirection="row">
                    <Box flex={1} mediaQueries={mq} sPadding="0 20px">
                        <StatusMap
                            status={thread.status}
                            closedTime={closedTime}
                            etaTime={eta}
                            delayTime={delayTime}
                            deliverBy={thread.deliver_by}
                            deliverFrom={thread.deliver_from}
                            deliveryWindow={thread.delivery_window}
                            delayedDeliverBy={thread.delayed_deliver_by}
                            delayedDeliverFrom={thread.delayed_deliver_from}
                            destination={thread.destination_id}
                            hub={thread.associated_hub}
                            avgEta={zone?.avg_eta}
                            placedTime={thread.placed_time}
                            visibleFrom={postponed}
                            pickupLocation={pickupLocation}
                            timeZone={thread.timezone}
                            readyForPickup={thread.pickup_ready_time}
                            isDeliveryOrder={thread.order_type === ORDER_TYPE.DELIVERY}
                            thread={thread}
                            storeClosed={!storeSettings.isOpen}
                        />
                    </Box>
                    <Padding flex={1} mediaQueries={mq} mMaxWidth="50%">
                        <Collapse
                            title={
                                <Box>
                                    <Title theme={theme} themeColors={themeColors}>
                                        {t('orderStatus.yourOrder')}
                                    </Title>
                                    {isHebrew() ? (
                                        <Subtitle theme={theme} themeColors={themeColors}>
                                            {thread?.paymentDetails?.paymentTransactionType ===
                                                SUPPORTED_TRANSACTION_TYPE.CASH && (
                                                <Box component="span">{`${change} ${t(
                                                    'orderStatus.change'
                                                )}`}</Box>
                                            )}
                                            <Box component="span">
                                                {' '}
                                                ,
                                                {`\u200E${priceToString(
                                                    isCancelled ? 0 : thread?.charges?.total,
                                                    currency
                                                )}`}{' '}
                                                :{t('orderStatus.total')}
                                            </Box>
                                            <Box component="span">
                                                ,{' '}
                                                {`\u200E${cartItemsCount}` +
                                                    (cartItemsCount === 1
                                                        ? `  :${t('orderStatus.item')} `
                                                        : ` :${t('orderStatus.items')} `)}
                                            </Box>
                                        </Subtitle>
                                    ) : (
                                        <Subtitle theme={theme} themeColors={themeColors}>
                                            <Box component="span">
                                                {cartItemsCount +
                                                    (cartItemsCount === 1
                                                        ? ` ${t('orderStatus.item')}`
                                                        : ` ${t('orderStatus.items')}`)}
                                            </Box>
                                            <Box component="span">
                                                , {t('orderStatus.total')}{' '}
                                                {priceToString(
                                                    isCancelled ? 0 : thread?.charges?.total,
                                                    currency
                                                )}
                                            </Box>
                                            {thread?.paymentDetails?.paymentTransactionType ===
                                                SUPPORTED_TRANSACTION_TYPE.CASH && (
                                                <Box component="span">{`, ${t(
                                                    'orderStatus.change'
                                                )} ${change}`}</Box>
                                            )}
                                        </Subtitle>
                                    )}
                                </Box>
                            }
                        >
                            <CartSummary
                                items={cart.items}
                                imageContainerFit={storeSettings.webstore_image_container}
                                itemsPrices={order_display_prices}
                            />
                            <Box
                                borderTop={`1px solid ${themeColors[theme].secondaryBorderColor2}`}
                            ></Box>

                            <CartTotal
                                charges={thread.charges}
                                paymentDetails={thread.paymentDetails}
                                paymentMethod={paymentMethod}
                                cancelled={isCancelled}
                                promoCodeId={thread.promoCode}
                                promoCode={cart.promo_code}
                                orderType={thread.order_type}
                                requireExactChange={storeSettings.require_exact_change}
                                pinTerminalFee={formatCentsPercentageValue(
                                    storeSettings.pin_terminal_fee,
                                    currency
                                )}
                                roundUpEnabled={storeSettings?.fees?.round_up_enabled}
                                roundUpReason={storeSettings?.fees?.round_up_reason}
                                alignRight
                            />
                            {/* //pin_terminal_fee */}
                        </Collapse>
                        {thread.status !== THREAD_STATUS.CLOSED &&
                            thread.status !== THREAD_STATUS.CANCELED && (
                                <Collapse
                                    title={
                                        <Box>
                                            <Title theme={theme} themeColors={themeColors}>
                                                {t('orderStatus.deliveryOptions')}
                                            </Title>
                                            <Subtitle
                                                maxWidth="100%"
                                                textOverflow="ellipsis"
                                                whiteSpace="nowrap"
                                                overflow="hidden"
                                                theme={theme}
                                                themeColors={themeColors}
                                            >
                                                {pickupLocation
                                                    ? t('orderStatus.pickupOrder')
                                                    : thread.deliveryType
                                                      ? t(`deliveryType.${thread?.deliveryType}`)
                                                      : ''}
                                                {thread?.note !== 'No special notes'
                                                    ? ', ' + thread?.note
                                                    : `, ${t('orderStatus.noNotes')}`}
                                            </Subtitle>
                                        </Box>
                                    }
                                >
                                    {storeSettings && !pickupLocation && (
                                        <DeliveryTypeSelect
                                            deliveryType={thread.deliveryType}
                                            onDeliveryTypeUpdate={onDeliveryInstructionsChange}
                                            disabled={thread.status > THREAD_STATUS.LOCKED}
                                            storeSettings={storeSettings}
                                            theme={theme}
                                            themeColors={themeColors}
                                        />
                                    )}

                                    <NoteInput
                                        onNoteChange={onDeliveryInstructionsChange}
                                        current={thread.note}
                                        disabled={thread.status > THREAD_STATUS.LOCKED}
                                        onFocus={() => {
                                            setSaveInstructionsVisible(true);
                                        }}
                                        onBlur={() => {
                                            setTimeout(() => {
                                                if (!instructionSaveEnabled) {
                                                    setSaveInstructionsVisible(false);
                                                }
                                            }, 100);
                                        }}
                                        pickupLocation={pickupLocation}
                                        t={t}
                                        theme={theme}
                                        themeColors={themeColors}
                                    />
                                    <Row justifyContent="flex-end" marginBottom={30}>
                                        {updateStatus ? (
                                            <Caption opacity={1} fontWeight={700}>
                                                {updateStatus}
                                            </Caption>
                                        ) : instructionsUpdated ? (
                                            <Spinner margin={0} />
                                        ) : (
                                            (saveInstructionsVisible || instructionSaveEnabled) && (
                                                <Button
                                                    padding="10px 30px"
                                                    opacity={!instructionSaveEnabled ? 0.3 : 1}
                                                    onClick={onInstructionsUpdate}
                                                    disabled={!instructionSaveEnabled}
                                                >
                                                    {t('orderStatus.updateOptions')}
                                                </Button>
                                            )
                                        )}
                                    </Row>
                                    <CancelOrder
                                        onOrderCancel={onCancel}
                                        onOrderDelay={onOrderDelay}
                                        supportedTransactionTypes={
                                            storeSettings.supportedTransactionTypes
                                        }
                                        cancelling={cancelling}
                                        postponing={postponing}
                                        eta={eta}
                                        postponed={postponed}
                                        forPickup={thread.order_type === ORDER_TYPE.PICKUP}
                                        cancellationReasons={storeSettings.cancel_reasons}
                                    />
                                </Collapse>
                            )}
                        <Box fontSize={12} opacity={0.7} paddingTop={30}>
                            {t('orderStatus.updateOrderMessage')}
                        </Box>
                    </Padding>
                </Col>
            </Box>
            <Footer flex="0 0 auto" narrow />
            {showAlert && (
                <Alert message={alertMessage} iconName={alertIcon} isFadingOut={fadeOutAlert} />
            )}
        </Col>
    );
}

function NoteInput({
    onNoteChange,
    current,
    onFocus,
    onBlur,
    disabled,
    pickupLocation,
    t,
    theme,
    themeColors
}) {
    return (
        <Box marginBottom={30}>
            <Title fontWeight={700} marginBottom={5} theme={theme} themeColors={themeColors}>
                {pickupLocation ? t('orderStatus.pickupNotes') : t('orderStatus.notes')}
            </Title>
            <Box fontSize={12} color={themeColors[theme].secondaryFontColor24} marginBottom={20}>
                {pickupLocation ? t('orderStatus.pickupNotesTxt') : t('orderStatus.notesTxt')}
            </Box>
            <Box
                component="textarea"
                maxWidth="100%"
                width="100%"
                height={160}
                background={themeColors[theme].greyBgColor1}
                color={themeColors[theme].blackFontColor}
                placeholderColor={
                    theme === THEME.DARK
                        ? themeColors[theme].greyfontColor2
                        : themeColors[theme].placeHolderColor2
                }
                borderRadius={8}
                border="none"
                padding="15px 20px"
                font="500 16px Heebo, sans-serif"
                letterSpacing="1px"
                resize="none"
                props={{
                    disabled,
                    placeholder: t('orderStatus.noNotes'),
                    defaultValue: current === 'No special notes' ? null : current,
                    onChange: e => {
                        onNoteChange({ note: e.target.value || t('orderStatus.noNotes') });
                    },
                    onFocus,
                    onBlur
                }}
            />
        </Box>
    );
}

function DeliveryTypeSelect({
    deliveryType,
    onDeliveryTypeUpdate,
    disabled,
    storeSettings,
    theme,
    themeColors
}) {
    const [type, setType] = useState(deliveryType);
    const onChange = e => {
        const value = e.target.value;
        setType(value);
        onDeliveryTypeUpdate({ type: value });
    };
    const { t } = useTranslation();
    return (
        <Col padding="20px 10px 30px">
            {storeSettings?.supported_delivery_options &&
                storeSettings.supported_delivery_options.map(deliveryOption => (
                    <Row component="label" cursor="pointer" marginBottom={25} key={deliveryOption}>
                        <RadioInput
                            name="deliveryType"
                            value={deliveryOption}
                            disabled={disabled}
                            checked={type === deliveryOption}
                            onChange={onChange}
                        />
                        <RadioLabel
                            checked={type === deliveryOption}
                            theme={theme}
                            themeColors={themeColors}
                        />
                        <Box />
                        {/* {DeliveryType[deliveryOption]} */}
                        {t(`deliveryType.${deliveryOption}`)}
                    </Row>
                ))}
        </Col>
    );
}

function RadioLabel({ checked, theme, themeColors }) {
    return (
        <Box
            width={22}
            height={22}
            borderRadius="50%"
            border={
                checked
                    ? `1px solid ${themeColors[theme].primaryBorderColor}`
                    : `1px solid ${themeColors[theme].secondaryBorderColor13}`
            }
            marginRight={30}
            background={checked ? `url(${selected}) center no-repeat` : 'transparent'}
        ></Box>
    );
}

function RadioInput({ checked, name, value, onChange, disabled }) {
    return (
        <Box
            component="input"
            display="none"
            props={{
                disabled,
                type: 'radio',
                name: name,
                checked: checked,
                value: value,
                onChange: onChange
            }}
        />
    );
}

function Wrapper(props) {
    const { children } = props;
    return (
        <Col padding={20} maxWidth={800} margin="0 auto" minHeight="100vh">
            <Box flex="1 0 auto" paddingBottom={150} minHeight={600}>
                {children}
            </Box>
            <Footer flex="0 0 auto" />
        </Col>
    );
}

function Padding(props) {
    const { children, ...rest } = props;
    return (
        <Box padding="0 20px" {...rest}>
            {children}
        </Box>
    );
}

function Title(props) {
    const { theme, themeColors, children, ...rest } = props;
    return (
        <Box color={themeColors[theme].primaryFontColor1} fontSize={18} fontWeight={500} {...rest}>
            {children}
        </Box>
    );
}

function Subtitle(props) {
    const { theme, themeColors, children, ...rest } = props;
    return (
        <Box
            opacity={0.5}
            color={themeColors[theme].primaryFontColor1}
            fontSize={12}
            fontWeight={500}
            {...rest}
        >
            {children}
        </Box>
    );
}
