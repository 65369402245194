import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate, useQueryParams } from '@patched/hookrouter';
import PaymentProviderType from 'constants/PaymentProvider';
import { Box, Row } from 'jsxstyle';
import Layout from 'layout/TitleAndSubtitlePageLayout';

import useCustomerData from 'hooks/useCustomerData';
import useSession from 'hooks/useSession';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';

import getPaymentMethods from 'api/getPaymentMethods';

import SaveButton from 'components/DeliveryOptionsModal/components/SaveButton';
import PaymentCredit from 'components/DeliveryOptionsModal/components/StripePaymentCredit';
import TranzilaPaymentCredit from 'components/DeliveryOptionsModal/components/TranzilaPaymentCredit';
import Head from 'components/HeadManager';
import Loading from 'components/Loading';
import StripeElementsWrapper from 'components/stripe/StripeElementsWrapper';

import { THEME } from '../api/constants';

export default function PaymentMethods({ pageTitle }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const session = useSession();
    const [params, setParams] = useQueryParams();
    const { data: storeSettings } = useStoreSettings();
    const { data: mappedData } = useCustomerData();
    const { user, paymentProviderSupplier } = mappedData || {};

    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(true);
    const paymentCreditRef = useRef();
    const [addCardEnabled, setAddCardEnabled] = useState(false);
    const [savingPaymentMethod, setSavingPaymentMethod] = useState(false);
    const [paymentOnFocus, setPaymentOnFocus] = useState(false);

    const standAlone = params.stand_alone;

    // TODO: this code can be reusable with the code inside the BuyNowModal
    useEffect(() => {
        async function _getPaymentMethods() {
            setLoadingPaymentMethod(true);
            const { cards } = await getPaymentMethods(session.organization_id);
            setLoadingPaymentMethod(false);
            if (cards && cards.length) {
                setPaymentMethod(cards[0]);
            }
        }

        if (session && user) {
            _getPaymentMethods();
        }
    }, [session, user]);

    const subtitle = () => {
        if (!paymentMethod) {
            return t('paymentMethods.addCreditCard');
        } else {
            return t('paymentMethods.chageCard');
        }
    };

    const onClose = () => {
        const prevPage = window.location.href;
        window.history.back();
        setTimeout(function () {
            if (window.location.href === prevPage) navigate('/cart');
        }, 100);
    };

    return (
        //   TODO: use some generic PageLayout component here
        // because a lot of pages looks the same so we can create a basic page
        // that can contain all common things like: Title, Subtitle, Close icon

        <Layout
            customerName={user?.name}
            title={loadingPaymentMethod ? '' : t('paymentMethods.title')}
            subtitle={loadingPaymentMethod ? '' : subtitle()}
            closeIconName={theme === THEME.DARK ? 'backWhite' : 'back'}
            onClose={onClose}
            hideFooter={standAlone}
        >
            <Head pageTitle={pageTitle} />
            {loadingPaymentMethod ? (
                <Loading />
            ) : (
                <StripeElementsWrapper>
                    <Box maxWidth={332}>
                        <Fragment>
                            {storeSettings?.payment_provider === PaymentProviderType.Stripe && (
                                <PaymentCredit
                                    ref={paymentCreditRef}
                                    paymentMethod={paymentMethod}
                                    cardNumberChanged={event => {
                                        setAddCardEnabled(event.complete);
                                    }}
                                    paymentMethodDeleted={() => {
                                        // when payment method has been deleted
                                        // we can set the payment method to null
                                        setPaymentMethod(null);
                                    }}
                                    onFocus={() => setPaymentOnFocus(true)}
                                    onBlur={() => setPaymentOnFocus(false)}
                                    canAddPaymentMethod={addCardEnabled}
                                    creditPaymentOnFocus={paymentOnFocus}
                                />
                            )}

                            {storeSettings?.payment_provider === PaymentProviderType.Tranzila && (
                                <TranzilaPaymentCredit
                                    ref={paymentCreditRef}
                                    paymentMethod={paymentMethod}
                                    onCardTypeChanged={isValid => {
                                        setAddCardEnabled(isValid);
                                    }}
                                    onCardDeleted={() => {
                                        setPaymentMethod(null);
                                    }}
                                    supplierId={paymentProviderSupplier}
                                    canAddPaymentMethod={addCardEnabled}
                                    onFocus={() => setPaymentOnFocus(true)}
                                    onBlur={() => setPaymentOnFocus(false)}
                                    creditPaymentOnFocus={paymentOnFocus}
                                />
                            )}
                        </Fragment>

                        {!paymentMethod && (
                            <Row justifyContent="flex-end">
                                <SaveButton
                                    onBlackBg
                                    disabled={!addCardEnabled}
                                    title={t('paymentMethods.addCard')}
                                    marginTop={20}
                                    loading={savingPaymentMethod}
                                    onClick={async () => {
                                        setSavingPaymentMethod(true);
                                        try {
                                            const paymentMethod =
                                                await paymentCreditRef.current.savePaymentMethod();
                                            setPaymentMethod(paymentMethod);
                                        } finally {
                                            setSavingPaymentMethod(false);
                                        }
                                    }}
                                ></SaveButton>
                            </Row>
                        )}
                    </Box>
                </StripeElementsWrapper>
            )}
        </Layout>
    );
}
