import AWS from 'aws-sdk';

const s3 = new AWS.S3();

export default class AWSDocumentProvider {
    constructor(options) {
        this.options = options;
    }

    create(bucket, fileName, file, contentType, ...rest) {
        return s3
            .putObject({
                Bucket: bucket,
                Key: fileName,
                Body: file,
                ContentType: contentType,
                ACL: rest?.acl ? rest.acl : 'private',
                CacheControl: 'no-cache'
            })
            .promise();
    }

    listObjects(Bucket) {
        return s3
            .listObjectsV2({
                Bucket
            })
            .promise();
    }

    async getObject(bucket, id, returnAsUrl = false) {
        const params = {
            Bucket: bucket,
            Key: id
        };

        const object = await s3.getObject(params).promise();

        if (returnAsUrl) {
            const url = await s3.getSignedUrlPromise('getObject', params);
            return {
                url,
                body: object.Body
            };
        }

        return object.Body;
    }
}
