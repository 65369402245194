import React from 'react';
import { Box } from 'jsxstyle';

export default function Body({ children, ...rest }) {
    return (
        <Box
            overflow="hidden"
            whiteSpace="nowrap"
            wordWrap="break-word"
            textOverflow="ellipsis"
            fontSize={16}
            {...rest}
            letterSpacing={1}
        >
            {children}
        </Box>
    );
}
