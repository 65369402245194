import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box, Inline } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';

import { formatDeliveryWindowText, isHebrew } from 'api/utils';

import Button from '../components/Button';
import Heading from '../components/Heading';
import Navbar from '../components/Navbar';
import P from '../components/Paragraph';
import Wrapper from '../components/Wrapper';

export default function SubmitSuccess({ pageTitle }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();
    const { data: mappedData } = useCustomerData();
    const { thread } = mappedData || {};
    const { data: storeSettings } = useStoreSettings();

    localStorage.removeItem('consentChecked');
    localStorage.removeItem('promoCode');
    const isPickup = thread?.pickup_destination;

    const windowText = thread?.delivery_window
        ? formatDeliveryWindowText(thread?.delivery_window, !storeSettings?.isOpen, t)
        : '';

    return (
        <Wrapper>
            <Navbar marginBottom={50} pageTitle={pageTitle} />
            {thread && (
                <>
                    <P>
                        {localStorage.getItem('language') === 'he-IL'
                            ? `\u200E#${thread.trackingNumber} ${t('submitSuccess.orderNum')}`
                            : `${t('submitSuccess.orderNum')}${thread.trackingNumber}`}
                    </P>
                    <Heading>
                        {t('submitSuccess.great')}
                        <span role="img" aria-label="ohh">
                            🎉
                        </span>
                    </Heading>

                    <Box
                        fontWeight={500}
                        fontSize={16}
                        color={themeColors[theme].secondaryFontColor12}
                        marginBottom={20}
                    >
                        {isPickup ? (
                            t('submitSuccess.pickupText')
                        ) : thread.deliver_by ? (
                            isHebrew() ? (
                                window.innerWidth < 440 ? (
                                    <Box>
                                        {t('submitSuccess.orderProcessed')}
                                        <br />
                                        {windowText}
                                    </Box>
                                ) : (
                                    `${windowText} \u200e${t('submitSuccess.orderProcessed')}`
                                )
                            ) : (
                                t('submitSuccess.orderProcessed', {
                                    shiftHours: windowText
                                })
                            )
                        ) : (
                            t('submitSuccess.receiveEta')
                        )}
                    </Box>

                    <Box
                        fontWeight={500}
                        fontSize={16}
                        color={themeColors[theme].secondaryFontColor12}
                        marginBottom={50}
                    >
                        {isPickup
                            ? t('submitSuccess.orderStatus2')
                            : t('submitSuccess.orderStatus')}
                        {!isPickup && (
                            <Inline
                                fontWeight={800}
                                color={themeColors[theme].secondaryFontColor25}
                            >
                                {' '}
                                {t('submitSuccess.updateDeliveryOptions')}
                            </Inline>
                        )}
                        {', '}
                        {t('submitSuccess.tapStatus')}
                    </Box>
                    <Button hoverWhite={isMobileOnly} props={{ onClick: () => navigate('/track') }}>
                        {t('submitSuccess.orderStatusBtn')}
                    </Button>
                </>
            )}
        </Wrapper>
    );
}
