import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function updateCartItems(orgId, threadId, items) {
    const payload = {
        organization_id: orgId,
        thread_id: threadId,
        items
    };

    return invokeLambda(Lambda.UpdateCartItems, payload);
}
