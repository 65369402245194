import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function getWindows(organization_id, zone_id) {
    const payload = {
        organization_id,
        zone_id
    };
    return invokeLambda(Lambda.GetWindows, payload);
}
