import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useTheme from 'hooks/useTheme';

import { countProductsInCart } from 'utils/cartUtils';
import { pushToDataLayer } from 'utils/dataLayer';

import { isThreadSubmitted } from '../api/utils';
import Icon from './Icon';

export default function CheckoutBar() {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();

    const { data: mappedData } = useCustomerData();
    const { thread, cart } = mappedData || {};

    const navigateToCart = () => {
        pushToDataLayer({ event: 'view_cart' });
        navigate('/cart');
    };
    const isSubmitted = thread && isThreadSubmitted(thread);
    const count = !isSubmitted && cart && cart.items && countProductsInCart(cart);

    return (
        <Row
            width={isMobileOnly ? '95%' : 355}
            minHeight={63}
            padding=" 12px 20px"
            boxShadow="0px 5px 12px 0 rgba(0,0,0, 0.25)"
            borderRadius={10}
            backgroundColor={themeColors[theme].tertiaryFontColor7}
            cursor="pointer"
            props={{ onClick: navigateToCart }}
            position="fixed"
            bottom={28}
            right={0}
            left={0}
            margin="0 auto"
            zIndex={10000}
            alignItems="center"
            justifyContent="space-between"
        >
            <Icon name="cartLight" />
            <Box
                fontSize={14}
                fontWeight={900}
                lineHeight={1.43}
                letterSpacing="1px"
                color={themeColors[theme].whitefontColor}
            >
                {t('catalogPage.checkout')}
            </Box>
            <Box>
                <Row background="rgba(0, 0, 0, 0.2)" width={36} height={36} borderRadius="50%">
                    <Box
                        fontSize={14}
                        fontWeight={900}
                        color={themeColors[theme].whitefontColor}
                        opacity={1}
                        margin="auto"
                    >
                        {count}
                    </Box>
                </Row>
            </Box>
        </Row>
    );
}
