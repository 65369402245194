import React from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'html-react-parser';

import useStoreSettings from '../hooks/useStoreSettings';

export default function HeadManager({ pageTitle }) {
    const { data: storeSettings } = useStoreSettings();
    const { meta_tags, name, description } = storeSettings || {};
    const title = name && pageTitle ? `${name} - ${pageTitle}` : '';

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            {/* adding OG metas in getWebsite
            <meta property="og:site_name" content={name} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" itemprop="image" content={small_logo_url} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} /> */}
            {meta_tags && meta_tags.length ? ReactHtmlParser(meta_tags) : null}
        </Helmet>
    );
}
