import React from 'react';
import { isMobile } from 'react-device-detect';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import warningIcon from 'assets/images/alert-circle.svg';
import errorIcon from 'assets/images/alert-tryangle.svg';
import rightArrow from 'assets/images/small-white-arrow.svg';

import Image from 'components/Image';
import { Body } from 'components/shared';

export const NoticeMessageType = {
    Info: 'Info',
    Warning: 'Warning',
    Err: 'Err',
    Custom: 'Custom'
};

export default function NoticeMessage({
    children,
    message,
    type = NoticeMessageType.Info,
    tappable = false,
    icon,
    inTransit = false,
    onClick,
    pickupOrder = false,
    ...rest
}) {
    const { theme, themeColors } = useTheme();

    const COLORS = {
        Info: themeColors[theme].infoNoticeBgColor,
        Warning: themeColors[theme].warningNoticeBgColor,
        Err: themeColors[theme].errNoticeBgColor,
        Custom: themeColors[theme].primaryBgColor2
    };

    const ICONS = {
        Warning: warningIcon,
        Err: errorIcon
    };

    const getIcon = () => {
        return icon ? icon : ICONS[type];
    };

    const _icon = getIcon();

    return (
        <Row
            padding={pickupOrder && isMobile ? '34px 4px' : '34px 30px'}
            borderRadius={10}
            position="relative"
            alignItems="center"
            cursor={tappable ? 'pointer' : 'default'}
            justifyContent="space-between"
            backgroundColor={COLORS[type]}
            color={themeColors[theme].primaryFontColor2}
            props={{
                onClick: onClick
            }}
            {...rest}
        >
            <Row alignItems="center">
                {_icon && (
                    <Image
                        zIndex={2}
                        src={_icon}
                        width={!pickupOrder && 25}
                        marginRight={pickupOrder ? 8 : 25}
                        marginTop={pickupOrder && 6}
                    ></Image>
                )}

                {children ? (
                    children
                ) : (
                    <Body lineHeight={1.25} whiteSpace="pre-wrap" fontWeight={700} fontSize={15}>
                        {message}
                        {tappable && isMobile && (
                            <Image marginLeft={5} verticalAlign={-2} src={rightArrow} />
                        )}
                    </Body>
                )}
            </Row>

            {tappable && !isMobile && <Image marginLeft={5} src={rightArrow} />}

            {inTransit && !pickupOrder && (
                <Box
                    position="absolute"
                    top={0}
                    left={41}
                    width={2}
                    zIndex={1}
                    height={52}
                    background={themeColors[theme].primaryBgColor2}
                />
            )}
        </Row>
    );
}
