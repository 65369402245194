import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Sticky from 'react-stickynode';
import { useQueryParams } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';

import usePickupLocations from 'hooks/usePickupLocations';
import usePopupManager from 'hooks/usePopupManager';
import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';
import { isEnglish, isHebrew } from 'api/utils';

import { useAppContext } from 'store';

import Filter from 'components/Filter';
import SearchLink from 'components/SearchLink';

import DeliveryAddressModal from '../components/Modals/DeliveryAddressModal';
import Icon from './Icon';
import ProductAvailabilityModal from './Modals/ProductAvailabilityModal';

export default function DeliveryInfo(props) {
    const {
        address,
        isInvalidZone,
        showStoreClosed,
        isSubmitted,
        organizationId,
        updateAddress,
        validateAddress,
        cart,
        deliveryDefaults,
        thread,
        timeZone,
        isAuth,
        supportPickup,
        supportsDelivery,
        updatePickupLocation,
        requiredAge,
        totalFiltersCount,
        setTotalFiltersCount,
        selectedSortType,
        setSelectedSortType,
        filterComponent,
        setFilterComponent,
        clearFilters,
        filtersApplied,
        setSelectedCategory,
        ...rest
    } = props;

    const { state, appContextActions } = useAppContext();
    const { data: destinations } = usePickupLocations();
    const [pickupLocations, setPickupLocations] = useState([]);
    const { theme, themeColors } = useTheme();

    useEffect(() => {
        const getPickUpLocations = async () => {
            if (destinations) {
                setPickupLocations(destinations);
                if (thread?.pickup_destination) {
                    const selectedPickupLocation = destinations.find(
                        location =>
                            location.destination_id === thread?.pickup_destination?.destination_id
                    );
                    updatePickupLocation(selectedPickupLocation);
                }
            }
        };

        getPickUpLocations();
    }, [destinations]);

    return (
        <Box mediaQueries={{ l: 'screen and (min-width: 1000px)' }} {...rest}>
            <AddressInput
                current={address || ''}
                disabled={isSubmitted}
                isInvalidZone={isInvalidZone}
                showStoreClosed={showStoreClosed}
                organizationId={organizationId}
                updateAddress={updateAddress}
                validateAddress={validateAddress}
                cart={cart}
                deliveryDefaults={deliveryDefaults}
                pickupLocations={pickupLocations}
                updatePickupLocation={updatePickupLocation}
                thread={thread}
                timeZone={timeZone}
                isAuth={isAuth}
                supportPickup={supportPickup}
                supportsDelivery={supportsDelivery}
                requiredAge={requiredAge}
                totalFiltersCount={totalFiltersCount}
                setTotalFiltersCount={setTotalFiltersCount}
                selectedSortType={selectedSortType}
                setSelectedSortType={setSelectedSortType}
                filterComponent={filterComponent}
                setFilterComponent={setFilterComponent}
                clearFilters={clearFilters}
                filtersApplied={filtersApplied}
                setSelectedCategory={setSelectedCategory}
                theme={theme}
                themeColors={themeColors}
            />
        </Box>
    );
}

const AddressInput = ({
    current,
    disabled,
    organizationId,
    updateAddress,
    validateAddress,
    cart,
    deliveryDefaults,
    pickupLocations,
    updatePickupLocation,
    thread,
    timeZone,
    isAuth,
    supportPickup,
    supportsDelivery,
    isInvalidZone,
    requiredAge,
    totalFiltersCount,
    setTotalFiltersCount,
    selectedSortType,
    setSelectedSortType,
    filterComponent,
    setFilterComponent,
    clearFilters,
    filtersApplied,
    setSelectedCategory,
    theme,
    themeColors
}) => {
    const { t } = useTranslation();
    const [isCartEmpty, setIsCartEmpty] = useState();

    const [showProductAvailabilityModal, setShowProductAvailabilityModal] = useState(false);

    const { state, appContextActions } = useAppContext();
    const { showDeliveryAddressModal, showAgeVerificationModal } = state;

    const [params] = useQueryParams();

    const { delayPopup } = usePopupManager();

    const ageVerified = localStorage.getItem('ageVerified');
    const isPickup = thread?.pickup_destination;
    const deliveryAddress = thread?.location?.address;

    useEffect(() => {
        if (cart) {
            setIsCartEmpty(!cart?.items?.length);
        }
    }, [cart?.items?.length]);

    const handleDeliveryToClick = async () => {
        if (isCartEmpty === false) {
            setShowProductAvailabilityModal(true);
        } else {
            appContextActions.deliveryAddressModal.open();
        }
    };

    useEffect(() => {
        isInvalidZone && handleDeliveryToClick();
    }, [isInvalidZone]);

    const handleDeclineChange = () => {
        setShowProductAvailabilityModal(false);
    };

    const handleConfirmChange = () => {
        setShowProductAvailabilityModal(false);
        appContextActions.deliveryAddressModal.open();
    };

    const addressBarText =
        thread?.pickup_destination?.location && supportPickup
            ? thread?.pickup_destination?.location.address
            : supportsDelivery && current
              ? current
              : supportsDelivery
                ? t('addressModal.enterAddress')
                : supportPickup
                  ? t('addressModal.choosePickup')
                  : '';

    return (
        <>
            <Sticky innerZ={1000} top={0} releasedClass="catalog-sticky">
                <Box
                    // className={
                    //     theme === THEME.DARK ? 'sticky-options-raw-dark' : 'sticky-options-raw'
                    // }

                    background={
                        theme === THEME.DARK
                            ? themeColors[theme].blackFontColor
                            : themeColors[theme].whiteBgColor2
                    }
                    padding="15px 15px 0px 15px"
                >
                    <Row
                        position="relative"
                        color={themeColors[theme].primaryFontColor1}
                        font="500 14px Heebo, Arial, sans-serif"
                        letterSpacing="1px"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                    >
                        <Filter
                            totalFiltersCount={totalFiltersCount}
                            setTotalFiltersCount={setTotalFiltersCount}
                            selectedSortType={selectedSortType}
                            setSelectedSortType={setSelectedSortType}
                            filterComponent={filterComponent}
                            setFilterComponent={setFilterComponent}
                            clearFilters={clearFilters}
                            filtersApplied={filtersApplied}
                            setSelectedCategory={setSelectedCategory}
                        />
                        <Box
                            maxWidth={isMobileOnly && 240}
                            cursor={!disabled && 'pointer'}
                            props={
                                !disabled && {
                                    onClick: handleDeliveryToClick
                                }
                            }
                        >
                            <OrderType
                                t={t}
                                isPickup={thread?.pickup_destination?.location}
                                supportPickup={supportPickup}
                                supportsDelivery={supportsDelivery}
                                theme={theme}
                                themeColors={themeColors}
                            />
                            <Box opacity={0.5} textAlign="center" fontSize={14}>
                                {addressBarText.length < 35 || !isMobileOnly
                                    ? addressBarText
                                    : `${addressBarText.slice(0, 28)}...`}
                            </Box>
                        </Box>
                        <SearchLink />
                    </Row>
                    <Box
                        marginTop={15}
                        borderBottom={`1px solid ${themeColors[theme].secondaryBorderColor9}`}
                    ></Box>
                </Box>
            </Sticky>
            {showDeliveryAddressModal && !showAgeVerificationModal && (
                <DeliveryAddressModal
                    showModal={showDeliveryAddressModal}
                    onClose={() => {
                        appContextActions.deliveryAddressModal.close();
                    }}
                    address={current}
                    updateAddress={updateAddress}
                    validateAddress={validateAddress}
                    organizationId={organizationId}
                    deliveryDefaults={deliveryDefaults}
                    pickupLocations={pickupLocations}
                    updatePickupLocation={updatePickupLocation}
                    currentPickupLocation={thread?.pickup_destination}
                    timeZone={timeZone}
                    isAuth={isAuth}
                    supportPickup={supportPickup}
                    supportsDelivery={supportsDelivery}
                    isInvalidZone={isInvalidZone}
                />
            )}
            {showProductAvailabilityModal && (
                <ProductAvailabilityModal
                    showModal={showProductAvailabilityModal}
                    onClose={() => {
                        setShowProductAvailabilityModal(false);
                    }}
                    onCancel={handleDeclineChange}
                    onConfirm={handleConfirmChange}
                />
            )}
            <Box padding="0 15px">
                {!thread?.pickup_destination?.location &&
                    !current &&
                    enterAddressTip(
                        isInvalidZone,
                        t,
                        theme,
                        themeColors,
                        supportPickup,
                        supportsDelivery
                    )}

                {isInvalidZone &&
                    !isPickup &&
                    enterAddressTip(
                        isInvalidZone,
                        t,
                        theme,
                        themeColors,
                        supportPickup,
                        supportsDelivery
                    )}
            </Box>
        </>
    );
};

const OrderType = ({ t, isPickup, supportPickup, supportsDelivery, theme, themeColors }) => {
    const orderIcon =
        (isPickup && supportPickup) || (supportPickup && !supportsDelivery)
            ? theme === THEME.DARK
                ? 'storeWhite'
                : 'store'
            : theme === THEME.DARK
              ? 'truckWhite'
              : 'truckBlack';
    const title =
        supportPickup && supportsDelivery
            ? t('addressBar.deliveryPickup')
            : supportsDelivery
              ? t('addressBar.deliverTo')
              : supportPickup
                ? t('addressBar.pickupOnly')
                : '';

    return (
        <Box
            fontSize={14}
            fontWeight="bold"
            letterSpacing={1}
            color={themeColors[theme].primaryFontColor1}
            display="flex"
            justifyContent="center"
        >
            {!isHebrew() && <Icon marginRight={5} name={orderIcon} />}
            {!isHebrew() && title}
            <Icon
                marginLeft={3}
                marginTop={theme === THEME.DARK ? 5 : 1}
                marginRight={isHebrew() && 3}
                width={theme === THEME.DARK ? 10 : 19}
                height={theme === THEME.DARK ? 10 : 19}
                name={theme === THEME.DARK ? 'arrowBottomLight' : 'arrowBottom'}
            />
            {isHebrew() && title}
            {isHebrew() && <Icon marginLeft={5} name={orderIcon} />}
        </Box>
    );
};

const enterAddressTip = (
    isInvalidZone,
    t,
    theme,
    themeColors,
    supportsPickup,
    supportsDelivery
) => (
    <Row
        marginTop={15}
        color={
            theme === THEME.DARK
                ? themeColors[theme].whitefontColor
                : themeColors[theme].tertiaryFontColor2
        }
        fontSize={12}
        fontWeight="normal"
        alignItems="center"
        marginBottom={15}
    >
        <Icon
            width={theme === THEME.DARK && 18}
            marginLeft={5}
            name={theme === THEME.DARK ? 'warningWhite' : 'warningGrey'}
            marginRight={5}
        />
        {supportsDelivery && isInvalidZone ? (
            <Box>
                {t('addressBar.invalideZoneTip')}
                <br />
                {isEnglish() && t('addressBar.invalideZoneTip2')}
            </Box>
        ) : supportsDelivery ? (
            t('addressBar.searchTip')
        ) : supportsPickup ? (
            t('addressBar.searchTipPickup')
        ) : (
            ''
        )}
    </Row>
);
