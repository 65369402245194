import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Col } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { ORDER_TYPE } from 'api/constants';

import { useAppContext } from 'store';

import CreditCardBody from 'components/CreditCardBody';
import Icon from 'components/Icon';
import NeedChange from 'components/NeedChange';
import { Body } from 'components/shared';

import { SUPPORTED_TRANSACTION_TYPE } from '../api/constants';
import { priceToString } from '../api/utils';

export default function CartTotal({
    charges,
    paymentDetails,
    paymentMethod,
    cancelled,
    promoCodeId,
    lessThenMin,
    promoCode,
    requireExactChange,
    productNotAvailable,
    pinTerminalFee,
    orderType,
    roundUpEnabled,
    roundUpReason,
    noBorder
}) {
    const { state } = useAppContext();
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    return (
        <Col
            borderBottom={!noBorder && `1px solid ${themeColors[theme].secondaryBorderColor9}`}
            paddingTop={30}
        >
            {charges ? (
                <>
                    <Row theme={theme} themeColors={themeColors}>
                        <Box flex={1}>
                            <Small theme={theme} themeColors={themeColors}>
                                {t('cart.total.subtotal')}
                            </Small>
                        </Box>
                        <Small theme={theme} themeColors={themeColors}>
                            {priceToString(charges.subtotal ? charges.subtotal : 0, state.currency)}
                        </Small>
                    </Row>

                    {charges.promocodeDiscount > 0 && (
                        <Row theme={theme} themeColors={themeColors}>
                            <Box flex={1} paddingLeft={5}>
                                <Small
                                    theme={theme}
                                    themeColors={themeColors}
                                    color={themeColors[theme].quinaryFontColor2}
                                >
                                    {promoCode?.referred_by || promoCode?.referred_from
                                        ? t('cart.total.referralPromo')
                                        : `${t('cart.total.promoCode')} ${promoCodeId}`}
                                </Small>
                            </Box>
                            <Small
                                theme={theme}
                                themeColors={themeColors}
                                color={themeColors[theme].quinaryFontColor2}
                            >
                                {priceToString(charges.promocodeDiscount, state.currency)}
                            </Small>
                        </Row>
                    )}
                    {orderType !== ORDER_TYPE.PICKUP && (
                        <Row theme={theme} themeColors={themeColors}>
                            <Box flex={1}>
                                <Small theme={theme} themeColors={themeColors}>
                                    {t('cart.total.delivery')}
                                </Small>
                            </Box>
                            <Small theme={theme} themeColors={themeColors}>
                                {charges.delivery && charges.delivery > 0
                                    ? priceToString(
                                          productNotAvailable && charges.subtotal === 0
                                              ? 0
                                              : charges.delivery,
                                          state.currency
                                      )
                                    : t('cart.total.free')}
                            </Small>
                        </Row>
                    )}

                    {charges.platformFee > 0 && (
                        <Row theme={theme} themeColors={themeColors}>
                            <Box flex={1}>
                                <Small theme={theme} themeColors={themeColors}>
                                    {charges.platformFeeDesp}
                                </Small>
                            </Box>
                            <Small theme={theme} themeColors={themeColors}>
                                {priceToString(charges.platformFee, state.currency)}
                            </Small>
                        </Row>
                    )}

                    <Row theme={theme} themeColors={themeColors}>
                        <Box flex={1}>
                            <Small theme={theme} themeColors={themeColors}>
                                {t('cart.total.taxAndFees')}
                            </Small>
                        </Box>
                        <Small theme={theme} themeColors={themeColors}>
                            {priceToString(
                                (productNotAvailable && charges.subtotal === 0) || !charges.fees
                                    ? 0
                                    : charges.fees,
                                state.currency
                            )}
                        </Small>
                    </Row>

                    {roundUpEnabled && charges.roundup > 0 && (
                        <Row theme={theme} themeColors={themeColors}>
                            <Box flex={1}>
                                <Small theme={theme} themeColors={themeColors}>
                                    {roundUpReason}
                                </Small>
                            </Box>
                            <Small theme={theme} themeColors={themeColors}>
                                {priceToString(charges.roundup, state.currency)}
                            </Small>
                        </Row>
                    )}

                    <Row
                        theme={theme}
                        themeColors={themeColors}
                        color={`${
                            lessThenMin
                                ? `${themeColors[theme].quaternaryFontColor1}`
                                : `${themeColors[theme].secondaryFontColor4}`
                        }`}
                    >
                        <Box flex={1}>
                            <Big>{t('cart.total.total')}</Big>
                        </Box>
                        <Big>
                            {priceToString(
                                cancelled ||
                                    (productNotAvailable && charges.subtotal === 0) ||
                                    !charges.total
                                    ? 0
                                    : charges.total,
                                state.currency
                            )}
                        </Big>
                    </Row>
                    <Row
                        theme={theme}
                        themeColors={themeColors}
                        noMargin={true}
                        paddingBottom={paymentDetails && 30}
                    >
                        {paymentDetails &&
                            {
                                [SUPPORTED_TRANSACTION_TYPE.CREDIT]: (
                                    <Box>
                                        <Body marginTop={30} fontWeight={500} marginBottom={10}>
                                            {t('cart.total.payWith')}
                                        </Body>
                                        <CreditCardBody
                                            last4={
                                                paymentDetails.paymentCardLast4
                                                    ? paymentDetails.paymentCardLast4
                                                    : paymentMethod
                                                      ? paymentMethod.last4
                                                      : ''
                                            }
                                            brand={
                                                paymentDetails.paymentCardBrand
                                                    ? paymentDetails.paymentCardBrand
                                                    : paymentMethod
                                                      ? paymentMethod.brand
                                                      : ''
                                            }
                                        />
                                    </Box>
                                ),
                                [SUPPORTED_TRANSACTION_TYPE.CASH]: (
                                    <Box width="100%" marginTop={15}>
                                        {!cancelled && requireExactChange && (
                                            <NeedChange
                                                marginTop={20}
                                                pinTerminalFee={pinTerminalFee}
                                                alignRight
                                            />
                                        )}
                                    </Box>
                                )
                            }[paymentDetails.paymentTransactionType]}
                    </Row>
                </>
            ) : null}
        </Col>
    );
}

function Row({ children, noMargin, theme, themeColors, ...rest }) {
    return (
        <Box
            display="flex"
            color={themeColors[theme].primaryFontColor1}
            marginBottom={!noMargin && 12}
            {...rest}
        >
            {children}
        </Box>
    );
}

function Big({ children, ...rest }) {
    return (
        <Box opacity={1} fontSize={16} fontWeight="bold" {...rest}>
            {children}
        </Box>
    );
}

function Small({ children, theme, themeColors, ...rest }) {
    return (
        <Box
            color={themeColors[theme].secondaryFontColor2}
            fontSize={12}
            fontWeight="bold"
            {...rest}
        >
            {children}
        </Box>
    );
}
