import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Inline } from 'jsxstyle';
import phoneParser from 'libphonenumber-js';

import useCustomerData from 'hooks/useCustomerData';

import { useStore } from 'store';

import { isHebrew } from '../api/utils';
import Heading from '../components/Heading';
import Navbar from '../components/Navbar';
import Spinner from '../components/Spinner';
import Wrapper from '../components/Wrapper';
import useStoreSettings from '../hooks/useStoreSettings';
import useTheme from '../hooks/useTheme';

export default function DirectToAgent({ pageTitle }) {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();
    const { data: mappedData } = useCustomerData();
    const { data: storeSettings } = useStoreSettings();
    const { user, thread } = mappedData || {};

    const customerSupportPhone = storeSettings?.customer_support_details?.phone;

    const callLink = `tel:${customerSupportPhone}`;
    const large = 'screen and (min-width: 768px)';
    const blockedUser = (user && user.isBlocked) || thread?.is_flagged;
    const title = blockedUser ? t('cart.directToAgent.hi') : t('cart.directToAgent.bigSpender');
    const reason = blockedUser
        ? t('cart.directToAgent.verified')
        : t('cart.directToAgent.verified2');

    if (!user) {
        return (
            <Wrapper>
                <Navbar pageTitle={pageTitle} />
                <Spinner margin="0 20px 0 0" display="inline-block" verticalAlign="-4px" />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Navbar pageTitle={pageTitle} />
            <Heading marginTop={70}>
                {title}
                {!blockedUser && (
                    <span role="img" aria-label="ohh">
                        💰
                    </span>
                )}
            </Heading>
            <Box
                fontWeight={500}
                fontSize={16}
                color={themeColors[theme].secondaryFontColor12}
                marginBottom={40}
            >
                {reason}
                {/* <Box> */} {t('cart.directToAgent.textTo')}{' '}
                <Inline whiteSpace="nowrap">
                    {customerSupportPhone && isHebrew()
                        ? customerSupportPhone.replace(/\+/g, '') // '+' and phone formatting is causing line break issues in hebrew
                        : (customerSupportPhone &&
                              phoneParser(customerSupportPhone)?.formatInternational()) ||
                          customerSupportPhone}
                </Inline>{' '}
                {t('cart.directToAgent.placeOrder')}
                {/* </Box> */}
            </Box>

            <Box
                component="a"
                mediaQueries={{ large }}
                display="block"
                largeDisplay="none"
                padding={15}
                width={260}
                border={`1px solid ${themeColors[theme].primaryBorderColor}`}
                color={themeColors[theme].primaryFontColor2}
                backgroundColor={themeColors[theme].primaryBgColor2}
                borderRadius={30}
                fontSize={16}
                fontWeight={500}
                letterSpacing={1}
                textAlign="center"
                textDecoration="none"
                textTransform="none"
                props={{
                    href: callLink,
                    target: '_blank'
                }}
            >
                {t('cart.directToAgent.textAgent')}
            </Box>
        </Wrapper>
    );
}
