import React from 'react';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

export default function Divider({ height = 1, light, ...rest }) {
    const { theme, themeColors } = useTheme();

    return (
        <Box
            component="div"
            height={height}
            backgroundColor={
                light ? themeColors[theme].secondaryBgColor8 : themeColors[theme].secondaryBgColor9
            }
            {...rest}
        />
    );
}
