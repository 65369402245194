import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';

import NoticeMessage, { NoticeMessageType } from 'components/NoticeMessage';

import store from './../assets/images/store2.svg';
import van from './../assets/images/van-icon.svg';

export default function Notice({ pickupOrder, readyForPickup, ...rest }) {
    const { t } = useTranslation();
    return (
        <NoticeMessage
            message={`${
                pickupOrder
                    ? readyForPickup
                        ? t('message.readyForPickup')
                        : t('message.preparingOrder')
                    : t('message.orderOnWay')
            }\n${t('message.orderStatus')}`}
            type={NoticeMessageType.Info}
            icon={pickupOrder ? store : van}
            tappable={true}
            inTransit={true}
            pickupOrder={pickupOrder}
            {...rest}
            onClick={() => navigate('/track')}
        />
    );
}
