import React, { forwardRef, Fragment, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import PhoneInputField from 'react-phone-number-input';
import { navigate, useQueryParams } from '@patched/hookrouter';
import { Box, Inline, Row } from 'jsxstyle';
import phoneParser, { isValidPhoneNumber } from 'libphonenumber-js';

import useCustomerData from 'hooks/useCustomerData';

import { Page, THEME } from 'api/constants';

import { clearCachedPromoCode, isHebrew, validateInput } from '../api/utils';
import Button from '../components/Button';
import Heading from '../components/Heading';
import Head from '../components/HeadManager';
import Icon from '../components/Icon';
import Loading from '../components/Loading';
import Logo from '../components/Logo';
import P from '../components/Paragraph';
import Spinner from '../components/Spinner';
import TextInput from '../components/TextInput';
import Wrapper from '../components/Wrapper';
import useStoreSettings from '../hooks/useStoreSettings';
import useTheme from '../hooks/useTheme';

import 'react-phone-number-input/style.css';

export default function SignUp({ pageTitle }) {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();
    const { data: mappedData, store } = useCustomerData();

    const { cart, user } = mappedData || {};

    const [userData, setUserData] = useState(user);

    const { data: storeSettings } = useStoreSettings();
    const LOGIN = t('auth.logIn');
    const GO = t('auth.getCode');
    const VERIFICATION = t('auth.codeVerification');
    const PROFILE = t('auth.settingProfile');
    const [params] = useQueryParams();
    const previousPage = params.from;
    const [step, setStep] = useState(VERIFICATION);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [sentAgain, setSentAgain] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [customerName, setCustomerName] = useState(null);
    const [customerEmail, setCustomerEmail] = useState(null);
    const [validCustomerData, setValidCustomerData] = useState(false);
    const [wrongCode, setWrongCode] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (user) {
            setUserData(user);
        }
    }, [user]);

    useEffect(() => {
        if (userData && userData.authenticated) {
            setStep(PROFILE);
        } else if (userData) {
            if (previousPage === Page.Cart) {
                setStep(GO);
            } else {
                setStep(LOGIN);
            }
        }
    }, [previousPage, userData?.authenticated]);

    useEffect(() => {
        if (user && user.email && user.name) {
            setValidCustomerData(true);
        }
    }, [user]);

    if (!user || !storeSettings) {
        return (
            <Wrapper>
                <Loading />
            </Wrapper>
        );
    }

    const onPhoneNumberChange = (number, isValid) => {
        setPhoneNumber(number);
    };

    const onCodeFill = async code => {
        setWrongCode(false);
        setError(true);
        if (code.length === 5) {
            setProcessing(true);
            const response = await store.authenticate(code);
            const { customer, error } = response;
            const handoffId = customer && customer.handoff_id;
            if (error && error === 'WrongCode') {
                setProcessing(false);
                setWrongCode(true);
            } else if (customer) {
                const ref = localStorage.getItem('promoCode');
                const loginPage = `/login?hid=${handoffId}`;
                const cartPage = `/cart?hid=${handoffId}`;
                const catalogPage = `/?hid=${handoffId}${ref ? '&ref=' + ref : ''}`;
                const referPage = `/refer?hid=${handoffId}`;
                const refLink = `/?hid=${handoffId}&ref=${ref}`;
                const eligibleProductPage = `/?hid=${handoffId}&promoRelay=true`;
                const nextPage =
                    !customer.full_name ||
                    !customer.email ||
                    (storeSettings?.requires_identity_verification && !customer.is_compliant)
                        ? loginPage
                        : cart && cart.items && cart.items.length && previousPage !== Page.Refer
                          ? cartPage
                          : previousPage === Page.Refer
                            ? referPage
                            : previousPage === Page.RefLink
                              ? refLink
                              : previousPage === Page.PromoRelay
                                ? eligibleProductPage
                                : catalogPage;
                localStorage.removeItem(user.organizationId);
                window.location.replace(nextPage);
            } else {
                setProcessing(false);
                setError(true);
            }
        }
    };

    const onCustomerDataChange = (name, email, isValid) => {
        setCustomerName(name.trim().replace(/\s\s/g, ' '));
        setCustomerEmail(email);
        setValidCustomerData(isValid);
    };

    const verifyCustomer = async () => {
        if (processing) return;
        await sendCodeToCustomer();
        setStep(VERIFICATION);
    };

    const sendCodeAgain = async () => {
        await sendCodeToCustomer();
        setSentAgain(true);
    };

    const sendCodeToCustomer = async () => {
        if (!phoneNumber) {
            return;
        }
        const formattedPhoneNumber = phoneParser(phoneNumber)
            .formatInternational()
            .replace(/\D/g, '');

        setProcessing(true);
        await store.sendVerificationCode(formattedPhoneNumber);
        setProcessing(false);
    };

    const onClose = () => {
        if (params.from === 'promoRelay') {
            clearCachedPromoCode();
        }
        navigate('/');
    };

    const onIdVerify = async () => {
        await store.updateCustomerProfileData(user.username.trim(), customerName, customerEmail);
        localStorage.setItem('startedVerifyFromWeb', true);
        navigate('/compliance');
    };

    const onSignUp = async () => {
        await store.updateCustomerProfileData(user.username, customerName, customerEmail);
        const prevPage = window.location.href;
        window.history.back();
        setTimeout(() => {
            if (window.location.href === prevPage) navigate('/');
        }, 100);
    };

    const title = {
        [LOGIN]: t('auth.loginSignup'),
        [GO]: t('auth.almostThere'),
        [VERIFICATION]: t('auth.verification'),
        [PROFILE]: t('auth.profile')
    };

    const description = {
        [LOGIN]: t('auth.logInDesp'),
        [GO]: t('auth.goDesp'),
        [VERIFICATION]: isHebrew() ? (
            <Box>
                {t('auth.verificationDesp')}
                <Box>
                    {phoneNumber && phoneNumber.length
                        ? phoneParser(phoneNumber)?.formatInternational()
                        : ''}
                </Box>
            </Box>
        ) : (
            t('auth.verificationDesp', {
                phoneNumber:
                    phoneNumber && phoneNumber.length
                        ? phoneParser(phoneNumber)?.formatInternational()
                        : ''
            })
        ),
        [PROFILE]: t('auth.profileDesp')
    };

    const forms = {
        [LOGIN]: (
            <PhoneInput
                onChange={onPhoneNumberChange}
                operatingCountry={storeSettings.operating_country}
                theme={theme}
                themeColors={themeColors}
            />
        ),
        [GO]: (
            <PhoneInput
                onChange={onPhoneNumberChange}
                operatingCountry={storeSettings.operating_country}
                theme={theme}
                themeColors={themeColors}
            />
        ),
        [VERIFICATION]: (
            <Fragment>
                <CodeInput
                    onCodeFill={onCodeFill}
                    processing={processing}
                    sendCode={sendCodeAgain}
                    sentAgain={sentAgain}
                    wrongCode={wrongCode}
                    error={error}
                    onBack={() => setStep(LOGIN)}
                    theme={theme}
                    themeColors={themeColors}
                />
            </Fragment>
        ),
        [PROFILE]: (
            <ProfileForm
                onUpdate={onCustomerDataChange}
                name={user.name}
                savedEmail={user.email}
                theme={theme}
                themeColors={themeColors}
            />
        )
    };

    const disableButton =
        phoneNumber?.length > 0 && !processing ? !isValidPhoneNumber(phoneNumber) : true;

    return (
        <Wrapper>
            <Head pageTitle={pageTitle} />
            <Box maxWidth={400}>
                <Row
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom={50}
                    color={themeColors[theme].primaryFontColor1}
                >
                    <Logo />
                    <Close onClose={onClose} />
                </Row>
                <Heading>{title[step]}</Heading>
                <Box color={themeColors[theme].secondaryFontColor2}>
                    <Box fontWeight={500} fontSize={18} marginBottom={10}>
                        {description[step]}
                    </Box>
                    {forms[step]}

                    {step === LOGIN && (
                        <>
                            <Row alignItems="center" justifyContent="flex-end">
                                <Button disabled={disableButton} onClick={verifyCustomer}>
                                    {processing ? t('auth.sendingCode') : t('auth.getCode')}
                                </Button>
                            </Row>
                        </>
                    )}
                    {step === GO && (
                        <>
                            <Row alignItems="center" justifyContent="flex-end">
                                <Button disabled={disableButton} onClick={verifyCustomer}>
                                    {processing ? t('auth.sendingCode') : GO}
                                </Button>
                            </Row>
                        </>
                    )}
                    {step === PROFILE && (
                        <Row justifyContent="flex-end">
                            <Button
                                hoverWhite={isMobileOnly}
                                noHover
                                disabled={!validCustomerData}
                                props={{
                                    onClick: storeSettings?.requires_identity_verification
                                        ? onIdVerify
                                        : onSignUp,
                                    disabled: !validCustomerData
                                }}
                            >
                                {storeSettings?.requires_identity_verification
                                    ? t('auth.idVerify')
                                    : t('auth.signUp')}
                                <Icon
                                    name="arrowRightWhite"
                                    marginLeft={35}
                                    verticalAlign="middle"
                                />
                            </Button>
                        </Row>
                    )}

                    <TermsNote
                        links={(storeSettings || {}).webstore_footer}
                        theme={theme}
                        themeColors={themeColors}
                    />
                </Box>
            </Box>
        </Wrapper>
    );
}

const Close = ({ onClose }) => {
    const { t } = useTranslation();
    return (
        <Box
            fontSize={17}
            fontWeight={500}
            cursor="pointer"
            padding="20px 10px"
            props={{ onClick: onClose }}
        >
            {t('auth.close')}
        </Box>
    );
};

const TermsNote = ({ links, theme, themeColors }) => {
    const { t } = useTranslation();
    return (
        <Box
            maxWidth={270}
            fontSize={13}
            fontWeight={500}
            lineHeight={1.54}
            letterSpacing="1px"
            marginTop={100}
            textAlign="center"
            margin="0 auto"
        >
            {t('auth.signUpTxt')}{' '}
            <Link goTo={links.terms_privacy_link} theme={theme} themeColors={themeColors}>
                {t('auth.termsAndPolicy')}
            </Link>
        </Box>
    );
};

const PhoneInput = ({ onChange, operatingCountry, theme, themeColors }) => {
    const [showError, setShowError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('US');
    const { t } = useTranslation();
    const onPhoneChange = value => {
        setPhoneNumber(value);
        if (showError && isValidPhoneNumber(value)) {
            setShowError(false);
        }
        onChange(value);
    };

    const onPhoneInputBlur = () => {
        const isValid = phoneNumber ? isValidPhoneNumber(phoneNumber) : true;
        setShowError(!isValid);
        onChange(phoneNumber, isValid);
    };

    return (
        <>
            <Row>
                <PhoneInputField
                    name="phoneNumber"
                    placeholder={t('auth.phoneNumber')}
                    value={phoneNumber}
                    country={countryCode}
                    defaultCountry={operatingCountry ?? 'US'}
                    withCountryCallingCode={true}
                    onChange={onPhoneChange}
                    international={true}
                    onBlur={onPhoneInputBlur}
                    autoFocus={true}
                    onCountryChange={code => {
                        setCountryCode(code);
                    }}
                    style={{
                        backgroundColor: themeColors?.[theme]?.inputBgColor,
                        paddingLeft: 15,
                        borderRadius: 8,
                        width: '100%',
                        marginBottom: 5,
                        color: themeColors?.[theme]?.inputTextColor
                    }}
                    inputComponent={forwardRef((props, ref) => {
                        return (
                            <Input
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                value={props.value}
                                placeholder={props.placeholder}
                                inputRef={ref}
                                autoFocus={props.autoFocus}
                                backgroundColor="transparent"
                                borderRadius={0}
                                type="tel"
                                theme={theme}
                                themeColors={themeColors}
                            />
                        );
                    })}
                />
            </Row>
            {showError ? (
                <InputError marginBottom={35} theme={theme} themeColors={themeColors}>
                    {t('auth.wrongNumber')}
                </InputError>
            ) : (
                <P
                    color={themeColors[theme].secondaryFontColor11}
                    marginBottom={35}
                    lineHeight={1.2}
                >
                    {t('auth.sendSms')}
                </P>
            )}
        </>
    );
};

const CodeInput = ({
    onCodeFill,
    sentAgain,
    sendCode,
    processing,
    wrongCode,
    onBack,
    error,
    theme,
    themeColors
}) => {
    const [code, setCode] = useState('');
    const { t } = useTranslation();
    if (processing) {
        return <Spinner margin={0} white={theme === THEME.DARK} />;
    }
    const onCodeChange = value => {
        if (validateInput('verificationCode', value)) {
            setCode(value);
            if (value.length === 5) {
                onCodeFill(value);
            }
        }
    };
    return (
        <>
            <Input
                value={code}
                onChange={onCodeChange}
                placeholder={t('auth.code')}
                autoFocus={true}
                autoComplete="one-time-code"
                theme={theme}
                themeColors={themeColors}
            />
            {wrongCode || error ? (
                <InputError theme={theme} themeColors={themeColors}>
                    {wrongCode ? t('auth.wrongCode') : t('compliance.error')}
                </InputError>
            ) : null}
            <Box
                cursor="pointer"
                marginTop={24}
                display="flex"
                props={{
                    onClick: () => {
                        onBack();
                    }
                }}
                maxWidth={80}
                style={{
                    cursor: 'pointer'
                }}
            >
                <Icon name={theme === THEME.DARK ? 'backWhite' : 'back'} />
                <Box
                    fontSize={14}
                    marginLeft={10}
                    fontWeight="bold"
                    color={themeColors[theme].secondaryFontColor13}
                    flexShrink={1}
                >
                    {t('auth.back')}
                </Box>
            </Box>
            <Box fontSize={14} letterSpacing="1px" fontWeight={800} marginTop={40}>
                {sentAgain ? (
                    t('auth.sendAgain')
                ) : (
                    <Inline
                        cursor="pointer"
                        props={{
                            onClick: () => {
                                sendCode();
                            }
                        }}
                    >
                        {t('auth.noCode')}
                    </Inline>
                )}
            </Box>
        </>
    );
};

const ProfileForm = ({ onUpdate, name, savedEmail, theme, themeColors }) => {
    const [firstName, setFirstName] = useState(name ? name.split(' ')[0] : '');
    const [lastName, setLastName] = useState(name ? name.split(' ')[1] : '');
    const [email, setEmail] = useState(savedEmail || '');
    const [wrongName, setWrongName] = useState(null);
    const [wrongEmail, setWrongEmail] = useState(null);
    const [fieldChanged, setFieldChanged] = useState({
        firstName: false,
        lastName: false,
        email: false
    });
    const { t } = useTranslation();

    useEffect(() => {
        const fullName = `${firstName?.trim() || ''} ${lastName?.trim() || ''}`;

        const nameValid = validateInput('name', fullName) && firstName?.length && lastName?.length;
        const emailValid = validateInput('email', email?.trim());
        const allValid = emailValid && nameValid;
        if (emailValid) {
            setWrongEmail(false);
        }
        setWrongName(!nameValid && fieldChanged.firstName && fieldChanged.lastName);
        onUpdate(fullName, email.trim(), allValid);
    }, [firstName, lastName, email, onUpdate, name, fieldChanged]);

    const onEmailBlur = () => {
        const emailValid = validateInput('email', email?.trim());
        setWrongEmail(!emailValid && fieldChanged.email);
    };

    return (
        <>
            <Row marginBottom={10}>
                <Input
                    onChange={value => {
                        setFirstName(value);
                        setFieldChanged(prev => {
                            prev.firstName = true;
                            return prev;
                        });
                    }}
                    value={firstName}
                    placeholder={t('auth.fName')}
                    marginRight={10}
                    autoFocus={true}
                    maxWidth={195}
                    theme={theme}
                    themeColors={themeColors}
                />
                <Input
                    onChange={value => {
                        setLastName(value);
                        setFieldChanged(prev => {
                            prev.lastName = true;
                            return prev;
                        });
                    }}
                    value={lastName}
                    placeholder={t('auth.lName')}
                    maxWidth={195}
                    theme={theme}
                    themeColors={themeColors}
                />
            </Row>
            {wrongName && (
                <InputError theme={theme} themeColors={themeColors}>
                    {t('auth.wrongName')}
                </InputError>
            )}
            <Input
                onChange={value => {
                    setEmail(value);
                    setFieldChanged(prev => {
                        prev.email = true;
                        return prev;
                    });
                }}
                onBlur={onEmailBlur}
                value={email}
                placeholder={t('auth.emailPlaceholder')}
                theme={theme}
                themeColors={themeColors}
            />
            {wrongEmail && (
                <InputError theme={theme} themeColors={themeColors}>
                    {t('auth.wrongEmail')}
                </InputError>
            )}
            <P color={themeColors[theme].secondaryFontColor11} marginBottom={35} lineHeight={1.2}>
                {t('auth.emailDesp')}
            </P>
        </>
    );
};

const Input = (
    {
        autoFocus,
        placeholder,
        onChange,
        value,
        onBlur,
        onFocus,
        inputRef,
        theme,
        themeColors,
        backgroundColor = themeColors[theme].secondaryBgColor,
        borderRadius = 8,
        ...rest
    },
    ref
) => {
    return (
        <TextInput
            placeholder={placeholder}
            background={backgroundColor}
            borderRadius={borderRadius}
            width="100%"
            border="none"
            paddingTop={20}
            paddingBottom={20}
            paddingLeft={14}
            fontSize={18}
            fontWeight={500}
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            autoFocus={autoFocus}
            {...rest}
            ref={inputRef}
        />
    );
};

const Link = ({ goTo, theme, themeColors, children }) => {
    return (
        <Box
            component="a"
            textDecoration="underline"
            cursor="pointer"
            color={themeColors[theme].secondaryFontColor2}
            hoverColor={themeColors[theme].secondaryFontColor2}
            props={{ href: goTo, target: '_blank' }}
        >
            {children}
        </Box>
    );
};

function InputError({ theme, themeColors, children, ...rest }) {
    return (
        <Box
            color={themeColors[theme].quaternaryBorderColor}
            fontSize={12}
            paddingTop={3}
            paddingLeft={3}
            {...rest}
        >
            {children}
        </Box>
    );
}
