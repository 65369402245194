import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Col, Inline } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { useAppContext } from 'store';

import { isHebrew, priceToString } from '../api/utils';

export default function Price({
    price,
    promotionPrice,
    small,
    productPage,
    displayPrices,
    flexCenter,
    isWeightProduct,
    weightValue,
    weightUnit,
    ...rest
}) {
    const { t } = useTranslation();
    const { state } = useAppContext();
    const { theme, themeColors } = useTheme();

    const WeighLabel = () => (
        <Inline
            opacity={0.5}
            color={themeColors[theme].secondaryFontColor5}
            fontSize={12}
            fontWeight="bold"
        >
            {isWeightProduct ? `/${weightValue}${weightUnit.toLowerCase()}` : ''}
        </Inline>
    );

    return (
        <Col
            justifyContent={flexCenter ? 'center' : 'flex-end'}
            fontWeight={900}
            fontSize={small ? '16px' : '30px'}
            color={
                productPage
                    ? themeColors[theme].primaryFontColor1
                    : themeColors[theme].blackFontColor
            }
            {...rest}
        >
            {price != null && promotionPrice && promotionPrice > 0 ? (
                <Box
                    fontSize={small ? '12px' : '16px'}
                    color={themeColors[theme].quaternaryFontColor4}
                    textDecoration="line-through"
                    marginBottom={2}
                >
                    {`${priceToString(price, state.currency)}`}
                    <WeighLabel />
                </Box>
            ) : displayPrices?.show_original ? (
                <Box
                    fontSize={small ? '12px' : '16px'}
                    color={themeColors[theme].quaternaryFontColor4}
                    textDecoration="line-through"
                    marginBottom={2}
                >
                    {displayPrices?.original}
                </Box>
            ) : null}

            {displayPrices ? (
                displayPrices.current.map(({ price_string }) => {
                    let priceString = '';
                    const isFree = price_string.includes('Free');

                    if (isHebrew()) {
                        if (isFree) {
                            priceString = price_string.replace(
                                'for Free',
                                `\u200e${t('cart.item.forFree')}`
                            );
                        } else if (price_string.includes('for')) {
                            priceString = price_string.replace('for ', `${t('cart.item.for')}`);
                        } else if (price_string.includes('Free')) {
                            priceString = price_string.replace(
                                'Free',
                                `\u200e${t('cart.item.free')}`
                            );
                        } else {
                            priceString = price_string;
                        }
                    } else {
                        priceString = price_string;
                    }
                    return (
                        <Box key={price_string}>
                            {priceString}
                            {!isFree ? <WeighLabel /> : null}
                        </Box>
                    );
                })
            ) : price != null ? (
                <Box>
                    {`${priceToString(promotionPrice || price, state.currency)}`}
                    <WeighLabel />
                </Box>
            ) : null}
        </Col>
    );
}
