import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/min/moment-with-locales';

import { nextOpenTimeText } from 'api/utils';

import NoticeMessage, { NoticeMessageType } from 'components/NoticeMessage';

export default function ClosedNotice({ day, time, ...rest }) {
    const { t } = useTranslation();
    const dayTranslated = t(`footer.${day}`);
    const momentObj = moment(time, 'HH:mm A');
    const hour =
        momentObj.minutes() == 0
            ? `\u200E${momentObj.format('hA')}`
            : `\u200E${momentObj.format('h:mmA')}`;
    return (
        <NoticeMessage
            type={NoticeMessageType.Warning}
            message={nextOpenTimeText(day, time, t)}
            {...rest}
        />
    );
}
