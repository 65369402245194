import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import loadImage from 'blueimp-load-image';
import { Box, Row } from 'jsxstyle';
import phoneParser from 'libphonenumber-js';

import useCustomerData from 'hooks/useCustomerData';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';

import { SUPPORTED_TRANSACTION_TYPE, THEME } from 'api/constants';
import { priceToString } from 'api/utils';

import { useAppContext } from 'store';

import Icon from 'components/Icon';
import Input from 'components/ImageInput';
import Loading from 'components/Loading';
import Navbar from 'components/Navbar';
import P from 'components/Paragraph';
import Spinner from 'components/Spinner';
import Wrapper from 'components/Wrapper';

export default function Account({ pageTitle }) {
    const { theme, themeColors } = useTheme();

    const { data: mappedData, store } = useCustomerData();
    const { state } = useAppContext();

    const { user } = mappedData || {};

    const [loadingPicture, setLoadingPicture] = useState(false);
    const { data: storeSettings } = useStoreSettings();

    const [menuItems, setMenuItems] = useState([]);

    const { t } = useTranslation();
    useEffect(() => {
        if (storeSettings) {
            // build menu items array
            const menuItems = [
                {
                    title: t('account.yourOrders'),
                    link: '/orders',
                    icon: theme === THEME.DARK ? 'cartLight' : 'cart'
                },
                {
                    title: t('account.editAccount'),
                    link: '/edit-account',
                    icon: theme === THEME.DARK ? 'userWhite' : 'user'
                }
            ];

            if (storeSettings.referral_program && storeSettings.supports_referral) {
                const referringCustomerReward = storeSettings?.referral_program
                    .referring_customer_reward.cents
                    ? priceToString(
                          storeSettings.referral_program.referring_customer_reward.cents,
                          state.currency
                      ).slice(0, -3)
                    : `${storeSettings.referral_program.referring_customer_reward.percentage}%`;

                menuItems.push({
                    title: (
                        <Box color={themeColors[theme].quaternaryFontColor2}>
                            {t('account.referral')}
                        </Box>
                    ),
                    link: '/refer',
                    icon: 'referralReward',
                    referringCustomerReward: referringCustomerReward
                });
            }

            if (
                storeSettings.supportedTransactionTypes.includes(SUPPORTED_TRANSACTION_TYPE.CREDIT)
            ) {
                menuItems.unshift({
                    title: t('account.paymentMethod'),
                    link: '/payment-methods',
                    icon: theme === THEME.DARK ? 'paymentCardWhite' : 'paymentCard'
                });
            }

            menuItems.push({
                title: t('account.continueShop'),
                link: '/',
                icon: theme === THEME.DARK ? 'shoppingCartWhite' : 'shoppingCart'
            });

            setMenuItems(menuItems);
        }
    }, [storeSettings]);

    if (user && !user.authenticated) {
        navigate('/', false, {});
    }

    const isLoading = !user;

    const onChange = file => {
        setLoadingPicture(true);
        resizeImage(file).then(file => {
            const bucket = `${storeSettings.buckets.organization_resources}/${storeSettings.organization_id}/customer_profiles`;
            store.uploadPicture(file, bucket).then(() => {
                setLoadingPicture(false);
            });
        });
    };

    if (isLoading) {
        return (
            <Wrapper white>
                <Navbar concise pageTitle={pageTitle} />
                <Loading />
            </Wrapper>
        );
    }

    const editAccount = () => {
        navigate(`/edit-account`);
    };

    const goShopping = () => {
        navigate(`/`);
    };

    const phoneNumber = getPhoneNumber(user);
    const memberSince = user && user.createdTime ? getFormatedDate(user.createdTime) : null;

    const m = 'screen and (min-width: 550px)';

    const onLogout = async () => {
        await store.logout();
        window.location.reload();
    };

    return (
        <Wrapper white noPadding>
            <Box padding={20}>
                <Navbar onLogout={onLogout} pageTitle={pageTitle} />
                <ImageUploader
                    onChange={onChange}
                    picture={user.picture}
                    loading={loadingPicture}
                    theme={theme}
                    themeColors={themeColors}
                />
                <Box
                    color={themeColors[theme].primaryFontColor1}
                    fontSize={30}
                    fontWeight={900}
                    marginTop={30}
                >
                    {user.name}
                </Box>
                {user.createdTime && (
                    <P color={themeColors[theme].secondaryFontColor11} marginBottom={15}>
                        {t('account.memberSince')} {memberSince}
                    </P>
                )}
                <Box
                    fontSize={18}
                    fontWeight={500}
                    color={themeColors[theme].secondaryFontColor2}
                    marginBottom={75}
                >
                    {user.email}
                    <Box>{phoneNumber}</Box>
                </Box>
            </Box>
            <Box mediaQueries={{ m }} mPaddingLeft={20} maxWidth={550}>
                {menuItems.map(item => {
                    return (
                        <Link
                            icon={item.icon}
                            text={item.title}
                            action={() => {
                                navigate(item.link, false, {});
                            }}
                            key={item.link}
                            referralReward={item.referringCustomerReward}
                            theme={theme}
                            themeColors={themeColors}
                        />
                    );
                })}
            </Box>
        </Wrapper>
    );
}

const ImageUploader = ({ onChange, picture, loading, theme, themeColors }) => {
    const { t } = useTranslation();
    return (
        <Row alignItems="center" paddingTop={25}>
            <Box
                cursor="pointer"
                component="label"
                width={92}
                height={92}
                background={
                    picture
                        ? `url(${picture}) center no-repeat`
                        : themeColors[theme].profileImgBgColor
                }
                backgroundSize="cover"
                borderRadius="50%"
                display="block"
                position="relative"
                marginRight={20}
            >
                <Input
                    onChange={e => {
                        onChange(e.target.files[0]);
                    }}
                />
                <Box position="absolute" bottom={0} right={0}>
                    <Icon name="camera" />
                </Box>
            </Box>
            {loading && <Spinner margin="0 7px 0" />}
            <P maxWidth={230} lineHeight={1} color={themeColors[theme].secondaryFontColor11}>
                {loading ? t('account.loading') : picture ? '' : t('account.photoTxt')}
            </P>
        </Row>
    );
};

const Link = ({ icon, text, action, referralReward, theme, themeColors }) => {
    return (
        <Row
            padding="25px 20px"
            fontSize={18}
            fontWeight={500}
            borderBottom={`1px solid ${themeColors[theme].secondaryBorderColor4}`}
            color={themeColors[theme].primaryFontColor1}
            cursor="pointer"
            props={{
                onClick: action
            }}
        >
            <Icon name={icon} width={22} marginRight={20} />
            <Box flex="1">{text}</Box>
            {referralReward && (
                <Box
                    fontSize={14}
                    marginRight={14}
                    marginTop={3}
                    color={themeColors[theme].secondaryFontColor21}
                >
                    {referralReward}
                </Box>
            )}
            <Icon name={theme === THEME.DARK ? 'arrowRightWhite2' : 'arrowRight'} />
        </Row>
    );
};

const resizeImage = image => {
    return new Promise(resolve => {
        const MAX_IMAGE_WIDTH = 250;
        const MAX_IMAGE_HEIGHT = 250;
        const resizeScale = 0.9;
        const uploadParams = {
            maxWidth: MAX_IMAGE_WIDTH,
            maxHeight: MAX_IMAGE_HEIGHT,
            canvas: true,
            noRevoke: true,
            orientation: true
        };
        loadImage(
            image,
            canvas => {
                canvas.toBlob(
                    picture => {
                        resolve(picture);
                    },
                    'image/jpeg',
                    resizeScale
                );
            },
            uploadParams
        );
    });
};

const getPhoneNumber = user => {
    return user && user.username && phoneParser(`+${user.username}`).formatInternational();
};

const getFormatedDate = date => {
    if (date) {
        const time = new Date(date * 1000);
        const year = time.getFullYear();
        const month = new Intl.DateTimeFormat(localStorage.getItem('language'), {
            month: 'short'
        }).format(time);
        return `${month} ${year}`;
    }
    return null;
};
