import find from 'lodash.find';
import useSWR from 'swr';

import { getCachedDeliveryModel, isSuperFastOrder } from 'api/utils';

import api from '../api';
import useStoreSettings from '../hooks/useStoreSettings';
import useCustomerData from './useCustomerData';

export default function useCart(threadId) {
    const { data: mappedData, mutate: revalidateCustomer } = useCustomerData();
    const { data: storeSettings } = useStoreSettings();
    const { user, thread } = mappedData || {};

    const kit_id = thread?.menu_kit || sessionStorage.getItem('menu_kit') || null;
    const selectedDeliveryModel = getCachedDeliveryModel();
    const thread_id = threadId || user?.threadId;

    const isOndemandOrder = selectedDeliveryModel ? isSuperFastOrder(selectedDeliveryModel) : false;

    const key = () => [`customerCart`, user?.threadId, thread_id, isOndemandOrder];

    const fetch = (key, threadId) => {
        if (!user) {
            return null;
        } else {
            return api.getCart(
                user,
                storeSettings.fees,
                storeSettings.includeTaxes,
                storeSettings.defaultTaxType,
                thread_id,
                true,
                storeSettings.isOpen,
                thread.zoneId,
                thread.associated_hub,
                kit_id,
                isOndemandOrder
            );
        }
    };

    const options = {
        dedupingInterval: 10000,
        focusThrottleInterval: 10000,
        errorRetryInterval: 1000,
        errorRetryCount: 3
    };
    const { data, error, mutate, isValidating } = useSWR(key, fetch, options);

    function addItem(id, qty, product) {
        return api.addToCart(user, id, qty, product).then(async response => {
            if (response.revalidateCustomer) {
                await revalidateCustomer();
            }
            await mutate(data);
        });
    }

    function removeItem(id) {
        return api.removeFromCart(user, id).then(async response => {
            if (response.revalidateCustomer) {
                await revalidateCustomer();
            }
            await mutate(data);
        });
    }

    function setQuantity(id, qty, productId) {
        return api.setCartQuantity(user, id, qty, productId).then(async () => {
            await mutate(data);
        });
    }

    async function updateCartItems(orgId, threadId, items) {
        return await api.updateCartItems(orgId, threadId, items);
    }

    async function setProductQuantity(product, qty) {
        const item = find(data.items, item => item.product.product_id === product.product_id);
        if (!item) {
            await addItem(product.product_id, qty, product);
        } else if (qty === 0) {
            await removeItem(item.item_id);
        } else {
            await setQuantity(item.item_id, qty, product.product_id);
        }
    }

    function sendOrderPlacedMessage() {
        return api.sendMessageOrderPlaced(user);
    }

    function checkItemsAvailability(items) {
        const zoneId = (thread || {}).zoneId;
        const associated_hub = (thread || {}).associated_hub;
        return api.checkItemsAvailability();
    }

    return {
        data,
        error,
        mutate,
        addItem,
        removeItem,
        setQuantity,
        setProductQuantity,
        sendOrderPlacedMessage,
        checkItemsAvailability,
        updateCartItems,
        isValidating
    };
}
