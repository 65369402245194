import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Box, Col } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Button from '../components/Button';
import Spinner from '../components/Spinner';

export default function CartActions(props) {
    const {
        onContinue,
        onSubmit,
        submitting,
        loading,
        lessThenMin,
        disabled = false,
        isAuth,
        consentRequired
    } = props;
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const purchaseDisabled =
        loading || submitting || lessThenMin || disabled || (isAuth && consentRequired);

    const sm = 'screen and (max-width: 450px)';

    return (
        <Col
            padding="35px 20px 20px"
            mediaQueries={{ sm }}
            smPadding="35px 5px 20px"
            alignItems="center"
            // background={themeColors[theme].secondaryBgColor12}
            borderTop={`1px solid ${themeColors[theme].secondaryBorderColor9}`}
        >
            {submitting ? (
                <Spinner />
            ) : (
                <Col alignItems="center" width="100%">
                    <Button
                        // white={theme === THEME.DARK}
                        width={260}
                        disabled={purchaseDisabled}
                        props={{
                            onClick: () => {
                                onSubmit();
                            },
                            disabled: purchaseDisabled
                        }}
                        hoverWhite={isMobileOnly}
                    >
                        {isAuth ? t('cart.buyNow') : t('cart.loginBuy')}
                    </Button>

                    <Box
                        fontSize={14}
                        padding="15px 0"
                        fontWeight="bold"
                        color={themeColors[theme].primaryFontColor1}
                        cursor="pointer"
                        opacity={0.7}
                        hoverOpacity={1}
                        marginTop={15}
                        props={{ onClick: onContinue }}
                    >
                        {t('cart.continueShop')}
                    </Box>
                </Col>
            )}
        </Col>
    );
}
