import React from 'react';
import { useTranslation } from 'react-i18next';
import LinesEllipsis from 'react-lines-ellipsis';
import { navigate, usePath } from '@patched/hookrouter';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { isHebrew } from 'api/utils';

import { useAppContext } from 'store';

import { isWeightProduct, priceToString } from '../api/utils';
import placeholder from './../assets/images/place-holder.svg';
import Image from './Image';

export default function CartSummary({ items, itemsPrices, imageContainerFit = 'cover' }) {
    const path = usePath();
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();
    const onClick = id => {
        sessionStorage.setItem('previousPage', JSON.stringify({ path }));
        navigate(`/p/${id}`);
    };
    const { state } = useAppContext();
    const getPromocodePrices = promocodePriceString => {
        let priceString = '';

        const isFree = promocodePriceString.includes('Free');

        if (isHebrew()) {
            if (isFree) {
                priceString = promocodePriceString.replace(
                    'for Free',
                    `\u200e${t('cart.item.forFree')}`
                );
            } else if (promocodePriceString.includes('for')) {
                priceString = promocodePriceString.replace('for ', `${t('cart.item.for')}`);
            } else if (promocodePriceString.includes('Free')) {
                priceString = promocodePriceString.replace('Free', `\u200e${t('cart.item.free')}`);
            } else {
                priceString = promocodePriceString;
            }
        } else {
            priceString = promocodePriceString;
        }
        return <Box key={promocodePriceString}>{priceString}</Box>;
    };

    return (
        <Box>
            {items.map((item, index) => {
                const { product, total_item_weight, promocode_count, product_count } = item;

                const itemDisplayPrices = itemsPrices.find(
                    i => i.item_id === item.item_id
                )?.display_prices;
                console.log('prices', itemDisplayPrices);

                const { image_url, name, sku, weight_value, weight_unit } = product;

                const showWeight = isWeightProduct(product);

                // const itemHasPromotionDiscount =
                // itemDisplayPrices.original_prices != null && itemDisplayPrices.promotion_prices;

                return (
                    <Row
                        justifyContent="space-between"
                        alignItems="center"
                        marginBottom={20}
                        cursor="pointer"
                        key={index}
                        props={{ onClick: () => onClick(sku) }}
                    >
                        <Box width="100%" maxWidth={50} height={50} marginRight={20}>
                            <Image
                                src={image_url ? image_url : placeholder}
                                alt={name}
                                width="100%"
                                height={50}
                                borderRadius={8}
                                objectFit={imageContainerFit}
                            ></Image>
                        </Box>
                        <Row
                            justifyContent="space-between"
                            width="100%"
                            color={themeColors[theme].secondaryFontColor19}
                            fontSize={14}
                            fontWeight={700}
                            lineHeight={1}
                            flex={2}
                        >
                            <Box
                                color={themeColors[theme].primaryFontColor1}
                                marginBottom={3}
                                width="100%"
                            >
                                <LinesEllipsis
                                    text={name}
                                    maxLine={1}
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                />
                            </Box>
                            <Box textAlign="right" minWidth={110}>
                                {/* <Box color={themeColors[theme].primaryFontColor1}>
                                    {priceToString(subtotal, state.currency)}
                                </Box> */}

                                <Box>
                                    {/* Red price crossed through */}
                                    {/* {itemHasPromotionDiscount ? (
                                        <Box
                                            color={themeColors[theme].quaternaryFontColor4}
                                            textDecoration="line-through"
                                            marginBottom={2}
                                        >
                                            {
                                                itemDisplayPrices.original_prices
                                                    ?.price_string_per_unit
                                            }
                                        </Box>
                                    ) : null} */}
                                    {/* End Red price crossed through */}

                                    {itemDisplayPrices.promocode_prices ? (
                                        // Promocode price for DISCOUNTED_PRODUCT or BUY_1_GET_1 or BUY_1_GET_SAME promocode discount type
                                        <Box
                                            color={
                                                itemDisplayPrices.regular_prices
                                                    ? null
                                                    : themeColors[theme].primaryFontColor1
                                            }
                                        >
                                            {/* promocode price  */}
                                            {getPromocodePrices(
                                                itemDisplayPrices.promocode_prices.price_string
                                            )}
                                            <Box color={themeColors[theme].primaryFontColor1}>
                                                {/* regular price, if not all qty discounted */}
                                                {itemDisplayPrices.regular_prices
                                                    ? getPromocodePrices(
                                                          itemDisplayPrices.regular_prices
                                                              .price_string_per_unit
                                                      )
                                                    : null}
                                            </Box>
                                        </Box>
                                    ) : // end Promocode price for DISCOUNTED_PRODUCT or BUY_1_GET_1 or BUY_1_GET_SAME promocode discount type
                                    itemDisplayPrices.regular_prices ? (
                                        <Box color={themeColors[theme].primaryFontColor1}>
                                            {
                                                itemDisplayPrices.regular_prices
                                                    ?.price_string_per_unit
                                            }
                                            {showWeight ? `/${weight_value}${weight_unit}` : ''}
                                        </Box>
                                    ) : null}
                                    {/* {showWeight
                                        ? `${priceToString(price, state.currency)}/${
                                              weight_value > 1 ? weight_value : ''
                                          }${weight_unit}`
                                        : `${t('cart.item.quantity')}: ${product_count}`} */}
                                    <Box marginBottom={2}>
                                        {showWeight
                                            ? total_item_weight + weight_unit
                                            : 'Qty: ' + product_count}
                                        {product_count > 1 ? `/ ${itemDisplayPrices.total}` : ''}
                                    </Box>
                                </Box>
                            </Box>
                        </Row>
                    </Row>
                );
            })}
        </Box>
    );
}
