import { updateThread } from '../utils/cacheDataManager';
import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function updateThreadDeliverBy(
    isAuth,
    thread_id,
    deliver_by = null,
    deliver_from = null,
    organization_id
) {
    if (isAuth) {
        const payload = {
            thread_id,
            deliver_by,
            deliver_from
        };
        return invokeLambda(Lambda.UpdateThread, payload);
    } else {
        return updateThread(organization_id, deliver_by);
    }
}
