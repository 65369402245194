import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function getAvailableProductsCount({
    organization_id,
    zone_id,
    associated_hub,
    isOndemandOrder,
    kit_id,
    storeOpen
}) {
    if (!organization_id || (isOndemandOrder && !zone_id) || !associated_hub) {
        return null;
    }
    const payload = {
        organization_id,
        zone_id,
        associated_hub,
        isOndemandOrder,
        kit_id,
        storeOpen
    };

    const { count } = await invokeLambda(Lambda.GetAvailableProductsCount, payload);

    return count;
}
