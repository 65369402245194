import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function getCategorizedCatalog({
    organization_id,
    zone_id,
    redirected_from_zone,
    category_size,
    category_ids,
    isOpen,
    kit_id,
    thread_id,
    useHubStock,
    associated_hub
}) {
    try {
        if (!category_ids) {
            return null;
        }
        const payload = {
            organization_id,
            zone_id,
            redirected_from_zone,
            category_size,
            category_ids,
            isOpen,
            kit_id,
            useHubStock,
            associated_hub,
            sort_type: JSON.parse(sessionStorage.getItem('selectedSortType')) || null
        };

        const response = await invokeLambda(Lambda.getCategorizedCatalog, payload);

        if (response.kit_id) {
            sessionStorage.setItem('menu_kit', response.kit_id);
        } else if (kit_id) {
            sessionStorage.removeItem('menu_kit');
        }

        if (thread_id && response.kit_id != kit_id) {
            const payload = {
                thread_id,
                menu_kit: response.kit_id || null
            };

            await invokeLambda(Lambda.UpdateThread, payload);
        }
        return {
            categories_map: response.categorizedProducts,
            count: response.count,
            nextShiftEndTime: response.nextShiftEndTime,
            nextShiftHours: response.nextShiftHours
        };
    } catch (e) {
        console.log('catch e', e);
    }
}
