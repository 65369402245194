import React from 'react';
import { Box } from 'jsxstyle';

import addBlueIcon from 'assets/images/add-blue.svg';
import addIcon from 'assets/images/add-icon.svg';
import redAlertIcon from 'assets/images/alert-red.svg';
import arrowBlueIcon from 'assets/images/arrow-blue.svg';
import arrowDownIcon from 'assets/images/arrow-down.svg';
import arrowDownWhiteIcon from 'assets/images/arrow-down-white.svg';
import cartCheckboxIcon from 'assets/images/cart-checkbox.svg';
import cashIcon from 'assets/images/change-icon.svg';
import cashWhiteIcon from 'assets/images/change-white.svg';
import checkboxIcon from 'assets/images/checkbox-circle-checked.svg';
import circleXRedIcon from 'assets/images/circle-x-red.svg';
import closeAlertIcon from 'assets/images/close-alert-button.svg';
import cookiesIcon from 'assets/images/cookies.svg';
import copyCodeIcon from 'assets/images/copy-icon.svg';
import copyCodeWhiteIcon from 'assets/images/copy-white.svg';
import deliverIcon from 'assets/images/deliver-icon.svg';
import deliverRedIcon from 'assets/images/deliver-icon-red.svg';
import deliveryTypeIcon from 'assets/images/delivery-type.svg';
import facebookIcon from 'assets/images/facebook-icon.svg';
import facebookWhiteIcon from 'assets/images/facebook-white.svg';
import filtersGreyIcon from 'assets/images/filters-grey.svg';
import filtersIcon from 'assets/images/filters-icon.svg';
import homeIcon from 'assets/images/home-icon.svg';
import homeLightIcon from 'assets/images/home-transparent.svg';
import scLargeIcon from 'assets/images/large-sc-icon.svg';
import scLargeDarkIcon from 'assets/images/large-sc-icon-grey.svg';
import sfLargeIcon from 'assets/images/large-sf-icon.svg';
import sfLargeRedIcon from 'assets/images/large-sf-icon-red.svg';
import mailIcon from 'assets/images/mail-icon.svg';
import mailWhiteIcon from 'assets/images/mail-white.svg';
import cartRemoveIcon from 'assets/images/minus.svg';
import cartAddIcon from 'assets/images/plus1.svg';
import cartAddWhiteIcon from 'assets/images/plus1-white.svg';
import promoBlueIcon from 'assets/images/promo-blue.svg';
import promotionIcon from 'assets/images/promotion.svg';
import shareIcon from 'assets/images/refer-friend-illustration.svg';
import referralRewardIcon from 'assets/images/referal-side-menu-icon.svg';
import scAlertIconIcon from 'assets/images/sc-alert-icon.svg';
import scheduledIcon from 'assets/images/sc-cart-icon.svg';
import scheduledCheckboxIcon from 'assets/images/sc-checked.svg';
import scGreyIcon from 'assets/images/sc-icon-grey.svg';
import scheduledToggleIcon from 'assets/images/sc-icon-on-toggle.svg';
import scWhiteIcon from 'assets/images/sc-icon-white.svg';
import sfAlertIconIcon from 'assets/images/sf-alert-icon.svg';
import sfGreyIcon from 'assets/images/sf-cart-grey.svg';
import superFastIcon from 'assets/images/sf-cart-icon.svg';
import superFastCheckboxIcon from 'assets/images/sf-checked.svg';
import superFastToggleIcon from 'assets/images/sf-icon-on-toggle.svg';
import shareBtnIcon from 'assets/images/share-btn.svg';
import shareBtnWhiteIcon from 'assets/images/share-btn-white.svg';
import smallAlertWarnIcon from 'assets/images/small-alert-warning.svg';
import smsIcon from 'assets/images/sms-icon.svg';
import smsWhiteIcon from 'assets/images/sms-white.svg';
import storeIcon from 'assets/images/store.svg';
import storeRedIcon from 'assets/images/store-red.svg';
import storeWhiteIcon from 'assets/images/store-white.svg';
import truckIcon from 'assets/images/truck.svg';
import truckBlackIcon from 'assets/images/truck-black.svg';
import truckWhiteIcon from 'assets/images/truck-white.svg';
import twitterIcon from 'assets/images/twitter-icon.svg';
import twitterWhiteIcon from 'assets/images/twitter-white.svg';
import vanIcon from 'assets/images/van-icon.svg';
import warningGreyIcon from 'assets/images/warning-grey.svg';
import warningWhiteIcon from 'assets/images/warning-white.svg';
import whatsappIcon from 'assets/images/whats-app-icon.svg';
import whatsappWhiteIcon from 'assets/images/whatsApp-white.svg';
import whiteCheckIcon from 'assets/images/white-round-check.svg';
import whiteClearIcon from 'assets/images/white-round-clear.svg';

import arrowBottomIcon from '../assets/images/arrow-bottom.svg';
import arrowBottomLightIcon from '../assets/images/arrow-bottom-light.svg';
import backIcon from '../assets/images/arrow-left.svg';
import backWhiteIcon from '../assets/images/arrow-left-white.svg';
import arrowRightWhiteIcon from '../assets/images/arrow-right.png';
import arrowRightWhite2Icon from '../assets/images/arrow-right-white.svg';
import closeIcon from '../assets/images/black-close-icon.svg';
import cameraIcon from '../assets/images/camera.svg';
import cartIcon from '../assets/images/cart-empty.svg';
import cartLightIcon from '../assets/images/cart-transparent.svg';
import clearIcon from '../assets/images/clear-input.svg';
import clearDarkIcon from '../assets/images/clear-input-dark.svg';
import clearSearchIcon from '../assets/images/clear-input-grey.svg';
import clearChangeIcon from '../assets/images/clear-input-lightGrey.svg';
import closeLightIcon from '../assets/images/close-white.svg';
import paymentCardIcon from '../assets/images/credit-card.svg';
import emptyCartIcon from '../assets/images/empty-cart.svg';
import arrowRightIcon from '../assets/images/list-arrow.svg';
import mapPinIcon from '../assets/images/map-pin.svg';
import minusIcon from '../assets/images/minus.svg';
import minusWhiteIcon from '../assets/images/minus-white.svg';
import paymentCardWhiteIcon from '../assets/images/payment-white.svg';
import plusIcon from '../assets/images/plus.svg';
import plusWhiteIcon from '../assets/images/plus-white.svg';
import promocodeLabelIcon from '../assets/images/promocode-label.svg';
import searchGreyIcon from '../assets/images/search-grey.svg';
import searchIcon from '../assets/images/search-icon.svg';
import searchLightIcon from '../assets/images/search-transparent.svg';
import shoppingCartIcon from '../assets/images/shopping-cart.svg';
import shoppingCartWhiteIcon from '../assets/images/shopping-white.svg';
import alertSmIcon from '../assets/images/small-alert.svg';
import trashIcon from '../assets/images/trash-icon.svg';
import trashWhiteIcon from '../assets/images/trash-white.svg';
import userIcon from '../assets/images/user.svg';
import userPlaceholderIcon from '../assets/images/user-placeholder.svg';
import userWhiteIcon from '../assets/images/user-white.svg';
import warningIcon from '../assets/images/warning.svg';

const images = {
    search: searchIcon,
    searchLight: searchLightIcon,
    searchGrey: searchGreyIcon,
    cart: cartIcon,
    cartLight: cartLightIcon,
    close: closeIcon,
    closeLight: closeLightIcon,
    plus: plusIcon,
    minus: minusIcon,
    plusWhite: plusWhiteIcon,
    minusWhite: minusWhiteIcon,
    trash: trashIcon,
    trashWhite: trashWhiteIcon,
    back: backIcon,
    backWhite: backWhiteIcon,
    arrowBottom: arrowBottomIcon,
    arrowBottomLight: arrowBottomLightIcon,
    clear: clearIcon,
    clearDark: clearDarkIcon,
    clearSearch: clearSearchIcon,
    clearChange: clearChangeIcon,
    emptyCart: emptyCartIcon,
    mapPin: mapPinIcon,
    alertSm: alertSmIcon,
    smallAlertWarn: smallAlertWarnIcon,
    userPlaceholder: userPlaceholderIcon,
    camera: cameraIcon,
    user: userIcon,
    userWhite: userWhiteIcon,
    shoppingCart: shoppingCartIcon,
    shoppingCartWhite: shoppingCartWhiteIcon,
    arrowRight: arrowRightIcon,
    paymentCard: paymentCardIcon,
    paymentCardWhite: paymentCardWhiteIcon,
    arrowRightWhite: arrowRightWhiteIcon,
    arrowRightWhite2: arrowRightWhite2Icon,
    warning: warningIcon,
    van: vanIcon,
    cash: cashIcon,
    cashWhite: cashWhiteIcon,
    add: addIcon,
    checkbox: checkboxIcon,
    circleXRed: circleXRedIcon,
    cartAdd: cartAddIcon,
    cartAddWhite: cartAddWhiteIcon,
    cartRemove: cartRemoveIcon,
    cartCheckbox: cartCheckboxIcon,
    scheduled: scheduledIcon,
    scheduledCheckbox: scheduledCheckboxIcon,
    superFast: superFastIcon,
    superFastCheckbox: superFastCheckboxIcon,
    superFastToggle: superFastToggleIcon,
    scheduledToggle: scheduledToggleIcon,
    closeAlert: closeAlertIcon,
    scAlertIcon: scAlertIconIcon,
    sfAlertIcon: sfAlertIconIcon,
    scLarge: scLargeIcon,
    sfLarge: sfLargeIcon,
    sfGrey: sfGreyIcon,
    sfLargeRed: sfLargeRedIcon,
    scLargeDark: scLargeDarkIcon,
    scWhite: scWhiteIcon,
    scGrey: scGreyIcon,
    copyCode: copyCodeIcon,
    copyCodeWhite: copyCodeWhiteIcon,
    facebook: facebookIcon,
    facebookWhite: facebookWhiteIcon,
    twitter: twitterIcon,
    twitterWhite: twitterWhiteIcon,
    whatsapp: whatsappIcon,
    whatsappWhite: whatsappWhiteIcon,
    mail: mailIcon,
    mailWhite: mailWhiteIcon,
    sms: smsIcon,
    smsWhite: smsWhiteIcon,
    referFriendBg: shareIcon,
    share: shareIcon,
    shareBtn: shareBtnIcon,
    shareBtnWhite: shareBtnWhiteIcon,
    whiteCheck: whiteCheckIcon,
    whiteClear: whiteClearIcon,
    referralReward: referralRewardIcon,
    home: homeIcon,
    homeLight: homeLightIcon,
    redAlert: redAlertIcon,
    truck: truckIcon,
    truckBlack: truckBlackIcon,
    truckWhite: truckWhiteIcon,
    deliver: deliverIcon,
    deliverRed: deliverRedIcon,
    arrowDown: arrowDownIcon,
    arrowDownWhite: arrowDownWhiteIcon,
    cookies: cookiesIcon,
    store: storeIcon,
    storeWhite: storeWhiteIcon,
    storeRed: storeRedIcon,
    warningGrey: warningGreyIcon,
    warningWhite: warningWhiteIcon,
    addBlue: addBlueIcon,
    promotion: promotionIcon,
    promoBlue: promoBlueIcon,
    arrowBlue: arrowBlueIcon,
    filters: filtersIcon,
    filtersGrey: filtersGreyIcon,
    deliveryType: deliveryTypeIcon,
    promocodeLabel: promocodeLabelIcon
};

export default function Icon({ name, onClick, ...rest }) {
    const src = images[name];

    return (
        <Box
            component="img"
            props={{
                src,
                alt: name,
                onClick
            }}
            {...rest}
        />
    );
}
