import { useEffect, useState } from 'react';

import useStoreSettings from './useStoreSettings';

const usePopupManager = () => {
    const { data: storeSettings } = useStoreSettings();

    const delaySeconds = storeSettings?.popup_delay || 3000;

    const delayPopup = cb => {
        setTimeout(() => cb(), delaySeconds);
    };

    return {
        delayPopup,
        delaySeconds
    };
};

export default usePopupManager;
