import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

export default function Chip({ onClick, selected, title, chipKey, ...rest }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    return (
        <Box
            key={chipKey}
            cursor="pointer"
            borderBottom={selected && `2px solid ${themeColors[theme].categoryBarBorderColor}`}
            paddingBottom={11}
            color={themeColors[theme].primaryFontColor1}
            fontSize={14}
            fontWeight={500}
            lineHeight={1.43}
            letterSpacing={1}
            display="inline-flex"
            props={{
                onClick: () => {
                    onClick(chipKey);
                }
            }}
            {...rest}
        >
            {title.length > 20 ? `${title.slice(0, 20)}...` : title}
        </Box>
    );
}
