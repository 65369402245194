import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from 'jsxstyle';

import Heading from '../components/Heading';
import Navbar from '../components/Navbar';
import Wrapper from '../components/Wrapper';

export default function CartSubmitted({ pageTitle }) {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <Navbar pageTitle={pageTitle} />
            <Heading>{t('cart.allSet')}</Heading>
            <Box component="p">{t('cart.receiveTxt')}</Box>
        </Wrapper>
    );
}
