import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function sendMessageOrderPlaced(user) {
    const payload = {
        messageKey: 'orderCompleted',
        handoff_id: user.handoffId,
        thread_id: user.threadId,
        organization_id: user.organizationId,
        username: user.username
    };

    return invokeLambda(Lambda.SendMessageWithKey, payload);
}
