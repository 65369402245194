import { setCartItemQuantity } from '../utils/cacheDataManager';
import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function setCartQuantity(user, item_id, quantity, productId) {
    if (user.authenticated) {
        const payload = {
            item_id,
            organization_id: user.organizationId,
            number: parseInt(quantity),
            user_id: user.id,
            product_id: productId
        };

        return invokeLambda(Lambda.SetCartQuantity, payload);
    } else {
        return setCartItemQuantity(item_id, parseInt(quantity), user.organizationId);
    }
}
