import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import loadImage from 'blueimp-load-image';
import { Box, Inline } from 'jsxstyle';
import phoneParser from 'libphonenumber-js';

import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';
import DocumentProviderFactory from 'api/documentManager/documentProviderFactory';
import DocumentProviderType from 'api/documentManager/documentProviderType';
import { isHebrew } from 'api/utils';

import api from '../api';
import Button from '../components/Button';
import Heading from '../components/Heading';
import Input from '../components/ImageInput';
import Navbar from '../components/Navbar';
import Loader from '../components/Spinner';
import Wrapper from '../components/Wrapper';
import useCustomerData from '../hooks/useCustomerData';
import useStoreSettings from '../hooks/useStoreSettings';
import alert from './../assets/images/alert.svg';
import nextIcon from './../assets/images/arrow-right.svg';
import success from './../assets/images/check-circle.svg';
import fail from './../assets/images/delete-button.svg';
import backIcon from './../assets/images/id-back.svg';
import frontIcon from './../assets/images/id-front.svg';
import selfieIcon from './../assets/images/id-selfie.svg';
import nextIconBlack from './../assets/images/right-arrow-black.svg';
import spinner from './../assets/images/spinner.svg';
import tickIcon from './../assets/images/tick.svg';
import tickIconBlack from './../assets/images/tick-black.svg';

const documentProvider = DocumentProviderFactory.create(DocumentProviderType.LAZZY);

export default function Compliance({ pageTitle }) {
    const { theme, themeColors } = useTheme();

    const { t } = useTranslation();
    const { data: mappedData } = useCustomerData();
    const { user } = mappedData || {};

    const { data: storeSettings } = useStoreSettings();

    if (user && !user.authenticated) {
        navigate('/login');
    }

    const FRONT = 'FRONT';
    const BACK = 'BACK';
    const SELFIE = 'SELFIE';
    const DONE = 'DONE';

    const imageKeys = {
        [FRONT]: 'id-front.jpg',
        [BACK]: 'id-back.jpg',
        [SELFIE]: 'holding.jpg'
    };
    const MAX_IMAGE_WIDTH = 700;
    const MAX_IMAGE_HEIGHT = 500;

    const [step, setStep] = useState(FRONT);
    const [images, setImages] = useState({});
    const [files, setFiles] = useState({});
    const [error, setError] = useState();
    const [verified, setVerified] = useState(null);

    const requireIdWhileDelivery =
        storeSettings?.requires_identity_verification &&
        storeSettings?.is_identity_photo_visible_to_drivers;

    const upload = (key, file) =>
        documentProvider.putObject(
            key,
            file,
            'verification_images',
            'jpg',
            user?.id,
            storeSettings?.organization_id
        );
    const onVerify = async () => {
        const response = await api.verifyCompliance(user);
        setVerified(response?.is_verified);
    };

    const uploadFile = async (key, file) => {
        let resizeScale = 0.8;
        try {
            const uploadParams = {
                maxWidth: MAX_IMAGE_WIDTH,
                maxHeight: MAX_IMAGE_HEIGHT,
                canvas: true,
                noRevoke: true,
                orientation: true
            };
            loadImage(
                file,
                canvas => {
                    canvas.toBlob(
                        async data => {
                            // data - it's shrinked image
                            if (data.size > 100000) {
                                //if image size still more than max size 100kb, will shrink image again with decreased resize scale
                                //if size more than 110 kb will shrink with quality 0.6 otherwise with quality 0.7
                                resizeScale =
                                    data.size > 110000 ? resizeScale - 0.2 : resizeScale - 0.1;
                                loadImage(
                                    file,
                                    canvas => {
                                        canvas.toBlob(
                                            async data => {
                                                await onFileShrink(key, data);
                                            },
                                            'image/jpeg',
                                            resizeScale
                                        );
                                    },
                                    uploadParams
                                );
                            } else {
                                await onFileShrink(key, data);
                            }
                        },
                        'image/jpeg',
                        resizeScale
                    );
                },
                uploadParams
            );

            const onFileShrink = async (key, data) => {
                const imageUrl = await upload(key, data);

                setImages({
                    ...images,
                    [key]: imageUrl
                });
                setFiles({
                    ...files,
                    [key]: URL.createObjectURL(data)
                });
            };
        } catch (err) {
            setError(err);
        }
    };

    const verify = () => {
        if (step === DONE) {
            onVerify();
        }
    };

    useEffect(verify, [step]);

    const nextStep = () => {
        let newStep;
        if (step === FRONT) newStep = BACK;
        if (step === BACK) newStep = SELFIE;
        if (step === SELFIE) newStep = DONE;
        setStep(newStep);
    };

    const renderStep = () => {
        if (step === DONE) {
            return (
                <UploadComplete
                    verified={verified}
                    customerSupportPhone={storeSettings?.customer_support_details?.phone}
                    t={t}
                    theme={theme}
                    themeColors={themeColors}
                />
            );
        }

        const labels = {
            [FRONT]: t('compliance.front'),
            [BACK]: t('compliance.back'),
            [SELFIE]: t('compliance.selfie')
        };

        const icons = {
            [FRONT]: frontIcon,
            [BACK]: backIcon,
            [SELFIE]: selfieIcon
        };

        let label = labels[step];
        if (error) label = t('compliance.error');

        let icon = icons[step];
        if (error) icon = alert;

        const key = imageKeys[step];
        const image = files[key];

        const uploader = (
            <ImageInput
                label={label}
                error={error}
                icon={icon}
                onChange={file => uploadFile(key, file)}
                image={image}
                t={t}
                theme={theme}
                themeColors={themeColors}
            />
        );

        if (step === FRONT) {
            return (
                <UploadFront
                    requireIdWhileDelivery={requireIdWhileDelivery}
                    t={t}
                    isHebrew={isHebrew}
                    theme={theme}
                    themeColors={themeColors}
                >
                    {uploader}
                    <NextButton
                        disabled={!image}
                        onClick={nextStep}
                        t={t}
                        icon={theme === THEME.DARK ? nextIconBlack : nextIcon}
                    />
                </UploadFront>
            );
        }

        if (step === BACK) {
            return (
                <UploadBack t={t} isHebrew={isHebrew} theme={theme} themeColors={themeColors}>
                    {uploader}
                    <NextButton
                        disabled={!image}
                        onClick={nextStep}
                        t={t}
                        icon={theme === THEME.DARK ? nextIconBlack : nextIcon}
                    />
                </UploadBack>
            );
        }

        if (step === SELFIE) {
            return (
                <UploadSelfie t={t} isHebrew={isHebrew} theme={theme} themeColors={themeColors}>
                    {uploader}
                    <NextButton
                        icon={theme === THEME.DARK ? tickIconBlack : tickIcon}
                        text={t('compliance.finish')}
                        disabled={!image}
                        onClick={nextStep}
                        t={t}
                    />
                </UploadSelfie>
            );
        }
    };

    const isLoading = !user || !storeSettings;
    if (isLoading) {
        return (
            <Wrapper>
                <Box>{t('compliance.loading')}</Box>
            </Wrapper>
        );
    }
    const headingIcon = verified ? success : verified !== null ? fail : null;

    return (
        <Wrapper>
            <Navbar pageTitle={pageTitle} />
            <Heading>
                {t('compliance.title')}
                {headingIcon && (
                    <Box
                        component="img"
                        maxWidth={22}
                        maxHeight={22}
                        marginLeft={7}
                        verticalAlign="middle"
                        props={{ src: headingIcon }}
                    />
                )}{' '}
            </Heading>
            <Box
                fontSize={18}
                fontWeight={500}
                lineHeight="1.11"
                letterSpacing={1}
                color={themeColors[theme].secondaryFontColor19}
                maxWidth={470}
            >
                {renderStep()}
            </Box>
        </Wrapper>
    );
}

const UploadFront = ({ requireIdWhileDelivery, children, t, isHebrew, theme, themeColors }) => (
    <Box>
        {isHebrew() ? (
            <Box>
                {t('compliance.uploadId')}
                <br />
                {requireIdWhileDelivery ? t('compliance.requireId') : ''}
            </Box>
        ) : (
            <Box>
                {t('compliance.uploadId')} {requireIdWhileDelivery ? t('compliance.requireId') : ''}
            </Box>
        )}

        {isHebrew() ? (
            <Box margin="20px 0">{t('compliance.startFront')}</Box>
        ) : (
            <Box margin="20px 0">
                <Inline fontWeight={900} color={themeColors[theme].primaryFontColor1}>
                    {t('compliance.startFront')}
                </Inline>{' '}
                {t('compliance.startFront2')}
            </Box>
        )}

        <Box marginBottom="10px">{t('compliance.clearPhoto')}</Box>
        {children}
    </Box>
);

const UploadBack = ({ children, t, isHebrew, theme, themeColors }) => (
    <Box>
        {isHebrew() ? (
            <Box marginBottom={40}>
                <Inline fontWeight={900} color={themeColors[theme].primaryFontColor1}>
                    {t('compliance.uploadBack2')}
                </Inline>
                {`\u200E`} {t('compliance.uploadBack')}
                <Box>{t('compliance.uploadBack3')}</Box>
            </Box>
        ) : (
            <Box marginBottom={40}>
                {t('compliance.uploadBack')}{' '}
                <Inline fontWeight={900} color={themeColors[theme].primaryFontColor1}>
                    {t('compliance.uploadBack2')}
                </Inline>
                . {t('compliance.uploadBack3')}
            </Box>
        )}

        {children}
    </Box>
);

const UploadSelfie = ({ children, t, isHebrew, theme, themeColors }) => (
    <Box>
        {isHebrew() ? (
            <Box marginBottom={40}>{t('compliance.uploadSelfie')}</Box>
        ) : (
            <Box marginBottom={40}>
                {t('compliance.uploadSelfie')}{' '}
                <Inline fontWeight={900} color={themeColors[theme].primaryFontColor1}>
                    {t('compliance.uploadSelfie2')}
                </Inline>
            </Box>
        )}
        <Box marginBottom={40}>{t('compliance.uploadSelfie3')}</Box>
        {children}
    </Box>
);

const UploadComplete = ({ verified, customerSupportPhone, theme, themeColors }) => {
    const { t } = useTranslation();
    const tryAgain = window.location.reload.bind(window.location);
    const { data: mappedData } = useCustomerData();
    const { cart } = mappedData || {};

    const chatLink = customerSupportPhone?.includes('whatsapp')
        ? `https://wa.me/${customerSupportPhone?.replace('whatsapp:+', '')}`
        : `tel:${customerSupportPhone}`;

    const startedFromWeb = localStorage.getItem('startedVerifyFromWeb');
    return (
        <Box>
            {verified ? (
                <Box>
                    <Box>{t('compliance.uploadComplete')}</Box>

                    {startedFromWeb ? (
                        cart && cart.items && cart.items.length ? (
                            <Button
                                marginTop={50}
                                minWidth={250}
                                props={{ onClick: () => navigate('/cart') }}
                            >
                                {t('compliance.placeOrder')}
                            </Button>
                        ) : (
                            <Button marginTop={50} props={{ onClick: () => navigate('/') }}>
                                {t('compliance.browseCatalog')}
                            </Button>
                        )
                    ) : (
                        customerSupportPhone && (
                            <Box
                                component="a"
                                display="block"
                                textDecoration="underline"
                                color={themeColors[theme].secondaryFontColor19}
                                props={{ href: chatLink }}
                            >
                                {t('compliance.backToChat')}
                            </Box>
                        )
                    )}
                </Box>
            ) : verified === false ? (
                <Box fontSize={15} maxWidth={600}>
                    <Box fontSize={18} marginBottom={20}>
                        {t('compliance.verifyError')}
                    </Box>
                    <Box marginBottom={15}>{t('compliance.verifyErrorDesp')}</Box>
                    <Box>
                        {t('compliance.failTwice')}{' '}
                        <Inline
                            component="a"
                            color={themeColors[theme].secondaryFontColor22}
                            whiteSpace="nowrap"
                            props={{ href: chatLink }}
                        >
                            {customerSupportPhone &&
                                (phoneParser(`+${customerSupportPhone}`)?.formatInternational() ||
                                    customerSupportPhone)}
                        </Inline>
                    </Box>
                    <Button marginTop={50} minWidth={280} props={{ onClick: tryAgain }}>
                        {t('compliance.tryAgain')}
                    </Button>
                </Box>
            ) : (
                <Loader white={theme === THEME.DARK} />
            )}
        </Box>
    );
};

const LargeImage = ({ src, alt, theme, themeColors }) => (
    <Box
        component="img"
        maxWidth={335}
        height={220}
        margin="0 25px 20px 0"
        borderRadius={10}
        width="100%"
        border={`2px solid ${themeColors[theme].primaryBorderColor1}`}
        background={themeColors[theme].primaryBgColor1}
        objectFit="contain"
        objectPosition="center"
        props={{ src, alt }}
    />
);

const ImageInput = ({ image, label, icon, onChange, error, t, theme, themeColors }) => {
    const [loading, setLoading] = useState(false);
    const [stateLabel, setStateLabel] = useState(label);
    const [stateIcon, setStateIcon] = useState(icon);
    const [stateImage, setStateImage] = useState(image);
    const [stateAnimation, setStateAnimation] = useState(null);
    const [statePadding, setStatePadding] = useState(0);
    const [actionLabel, setActionLabel] = useState(t('compliance.tapHere'));

    const onFileChoose = async file => {
        setLoading(true);
        await onChange(file);
        setLoading(false);
    };

    useEffect(() => {
        if (loading) {
            setStateIcon(spinner);
            setStateImage(null);
            setActionLabel('');
            setStateLabel(t('compliance.uploadPhoto'));
            setStatePadding('75px');
            setTimeout(() => {
                setStateAnimation('spin 1s linear infinite');
            }, 10);
        }
        if (!loading && image) {
            setStateImage(image);
        }
        if (error) {
            setStatePadding('75px');
        }
    }, [icon, label, error, image, loading]);
    return (
        <Box cursor="pointer" component="label">
            <Box
                component="img"
                display="none"
                props={{
                    src: spinner,
                    alt: ''
                }}
            />
            {stateImage && !loading ? (
                <LargeImage src={stateImage} theme={theme} themeColors={themeColors} />
            ) : (
                <Box
                    background={themeColors[theme].primaryBgColor1}
                    padding="0 45px 0"
                    paddingTop={statePadding}
                    marginBottom={20}
                    minHeight={220}
                    maxWidth={335}
                    borderRadius={10}
                    boxShadow={`0 18px 33px 0 ${themeColors[theme].boxShadowColor3}`}
                    textAlign="center"
                >
                    <Box
                        component="img"
                        animation={stateAnimation}
                        props={{
                            src: stateIcon,
                            alt: t('compliance.uploadIcon')
                        }}
                    />
                    <Box
                        fontSize={16}
                        fontWeight={500}
                        lineHeight="1.25"
                        letterSpacing={1}
                        color={themeColors[theme].primarysecondaryFontColor19FontColor1}
                    >
                        {!loading && !error && (
                            <Box fontWeight={700} color={themeColors[theme].primaryFontColor1}>
                                {actionLabel}
                            </Box>
                        )}
                        {stateLabel}
                    </Box>
                </Box>
            )}

            <Input
                onChange={e => {
                    setLoading(true);
                    onFileChoose(e.target.files[0]);
                }}
            />
        </Box>
    );
};

const NextButton = ({ icon, text, disabled, onClick, t }) => (
    <Box maxWidth={335} textAlign="right">
        <Button
            noHover
            disabled={disabled}
            props={{
                disabled: disabled,
                onClick: onClick
            }}
        >
            {text || t('compliance.next')}
            <Box
                component="img"
                marginLeft={80}
                maxWidth={16}
                props={{
                    src: icon || nextIcon
                }}
            />
        </Button>
    </Box>
);
