import React, { useEffect, useState } from 'react';
import { isAndroid, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { navigate } from '@patched/hookrouter';
import { Box, Col, Inline } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';

import { useAppContext } from 'store';

import Button from '../../components/Button';

function AgeVerificationModal({ showModal, storeDescription, minAge }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();
    const { appContextActions } = useAppContext();

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [deviceHeight, setDeviceHeight] = useState(
        isAndroid ? window.screen.height : window.innerHeight
    );

    useEffect(() => {
        window.addEventListener('resize', () => {
            setDeviceWidth(window.innerWidth);
            setDeviceHeight(isAndroid ? window.screen.height : window.innerHeight);
        });
    }, []);

    const modalStyle = () => {
        const isSmallScreen = isMobileOnly || deviceWidth <= 480 || deviceHeight <= 480;
        if (isSmallScreen) {
            return {
                content: {
                    position: 'absolute',
                    top: `${deviceWidth < 330 ? '10%' : deviceHeight <= 480 ? '10%' : 'auto'}`,
                    left: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    right: `${deviceHeight <= 480 ? '5%' : '0'}`,
                    bottom: '0',
                    backgroundColor: themeColors[theme].popupBgColor1,
                    border: 'none',
                    padding: '60px 30px',
                    borderRadius: '20px 20px 0 0',
                    maxHeight: '80vh',
                    color: themeColors[theme].blackFontColor
                }
            };
        } else {
            return {
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    padding: '40px 60px',
                    borderRadius: '20px',
                    height: '371px',
                    width: '652px',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    overflow: 'hidden',
                    backgroundColor: themeColors[theme].popupBgColor1,
                    color: themeColors[theme].blackFontColor
                }
            };
        }
    };

    const modalStyles = modalStyle();

    return (
        <Box>
            <Modal
                style={modalStyles}
                isOpen={showModal}
                overlayClassName="buy-now-modal-overlay"
                shouldCloseOnOverlayClick={false}
                closeTimeoutMS={500}
            >
                <Box textAlign="center">
                    <Box fontSize={30} fontWeight={900} letterSpacing={1} marginBottom={10}>
                        {t('ageConfirmation.isAge', { minAge: minAge })}
                    </Box>
                    <Box
                        fontWeight={700}
                        fontSize={18}
                        letterSpacing={1}
                        color={themeColors[theme].popupFontColor1}
                        marginBottom={40}
                    >
                        <Box marginBottom={10}>
                            {t('ageConfirmation.desp')}{' '}
                            <Inline color={themeColors[theme].secondaryFontColor6}>
                                {t('ageConfirmation.overAge', { minAge: minAge })}
                            </Inline>
                        </Box>
                        <Box fontSize={14} fontWeight={400}>
                            {storeDescription}
                        </Box>
                    </Box>
                    <Col alignItems="center">
                        <Button
                            white={theme === THEME.DARK}
                            width={isMobileOnly ? ' 100%' : '280px'}
                            marginBottom="20px"
                            backgroundSize={15}
                            noHover
                            hoverWhite={isMobileOnly}
                            props={{
                                onClick: () => {
                                    appContextActions.ageVerificationModal.close();
                                    localStorage.setItem('ageVerified', true);
                                    navigate('/');
                                }
                            }}
                        >
                            {t('ageConfirmation.yes')}
                        </Button>
                        <Button
                            white={theme === THEME.DARK}
                            width={isMobileOnly ? ' 100%' : '280px'}
                            noHover
                            hoverWhite={isMobileOnly}
                            props={{
                                onClick: () => {
                                    appContextActions.ageVerificationModal.close();
                                    window.location = 'https://www.nick.com/';
                                }
                            }}
                        >
                            {t('ageConfirmation.exit')}
                        </Button>
                    </Col>
                </Box>
            </Modal>
        </Box>
    );
}

export default AgeVerificationModal;
