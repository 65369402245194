import React, { useEffect, useState } from 'react';
import { navigate, useQueryParams } from '@patched/hookrouter';
import { ScrollingCarousel } from '@trendyol-js/react-carousel';
import { Box } from 'jsxstyle';

import useCategorizedCatalog from 'hooks/useCategorizedCatalog';
import useTheme from 'hooks/useTheme';

import { allProductsCategory, home, THEME } from 'api/constants';
import { isOverflowing } from 'api/utils';

import { useAppContext } from 'store';

import Chip from 'components/Chip';
import Icon from 'components/Icon';

export default function CategoryCarousel({
    onCategorySelected,
    categoriesList,
    selectedCategory,
    setSelectedCategory
}) {
    const { theme, themeColors } = useTheme();

    const [params, setParams] = useQueryParams();
    const {
        state: { currentDeliveryModel }
    } = useAppContext();

    const sortType = sessionStorage.getItem('selectedSortType');
    const { data: categoriesCatalog } = useCategorizedCatalog(
        currentDeliveryModel,
        10,
        params,
        sortType
    );

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        function buildCategories() {
            let filteredList = [];
            if (categoriesCatalog?.categories_map) {
                const categoriesMap = categoriesCatalog.categories_map;

                for (const category in categoriesMap) {
                    if (categoriesMap[category].products.length) {
                        categoriesMap[category].categoryItem = categoriesList.find(
                            c => c.category_id === category
                        );
                        filteredList.push(categoriesMap[category].categoryItem);
                    }
                }

                const allCategories = [];

                filteredList.forEach(category => {
                    category.webstore_visible && allCategories.push(category);
                });

                allCategories.push(allProductsCategory);
                setCategories(allCategories);
            }
        }

        buildCategories();
    }, [categoriesList, categoriesCatalog]);

    useEffect(() => {
        if (params && params.category && categoriesList) {
            const currentCategory = categoriesList.find(cat => cat.category_id === params.category);
            if (currentCategory && currentCategory.name) {
                setSelectedCategory(currentCategory.name);
            } else {
                setSelectedCategory(allProductsCategory.name);
            }
        }
    }, [params, categoriesList]);

    const categoryItemMargin = 20;

    useEffect(() => {
        function scrollCategoryNavigation() {
            if (categories.length > 1 && params?.category) {
                let bar = document.querySelector('.horizontal-scrolling');
                let categoryElms = document.getElementsByClassName('categoryElm');

                const categoryIndex = categories.findIndex(
                    cate => cate.category_id === params.category
                );
                let curCategoryWidth = 0;
                for (let i = 0; i <= categoryIndex; i++) {
                    curCategoryWidth += categoryElms[i].offsetWidth + categoryItemMargin;
                }

                if (isOverflowing(bar) && params?.category && curCategoryWidth > bar.offsetWidth) {
                    bar.scrollLeft =
                        curCategoryWidth -
                        document.getElementsByClassName('categoryElm')[categoryIndex].offsetWidth -
                        categoryItemMargin -
                        10;
                }
            }
        }
        scrollCategoryNavigation();
    }, [categories]);

    return (
        <Box>
            <Box
                margin="0 15px"
                // padding="10px 20px 0"
                borderBottom={`1px solid ${themeColors[theme].secondaryBorderColor9}`}
                className="category-carousel"
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                paddingTop={15}
            >
                <Box
                    padding="0 20px"
                    marginRight="10px"
                    paddingBottom={10}
                    cursor="pointer"
                    props={{
                        onClick: () => {
                            setSelectedCategory(home);
                            onCategorySelected(home);
                            navigate('/');
                            setParams({}, true);
                        }
                    }}
                    className="category-carousel-home"
                    borderBottom={
                        selectedCategory === home &&
                        `2px solid ${themeColors[theme].categoryBarBorderColor}`
                    }
                    display="flex"
                    justifyContent="center"
                >
                    {theme === THEME.DARK ? <Icon name="homeLight" /> : <Icon name="home" />}
                </Box>

                <Box className="horizontal-scrolling" flex={2}>
                    <ScrollingCarousel>
                        {categories.map(category => {
                            return (
                                <Chip
                                    onClick={() => {
                                        setSelectedCategory(category.name);
                                        onCategorySelected(category);
                                    }}
                                    chipKey={category.name}
                                    key={category.name}
                                    selected={selectedCategory === category.name}
                                    title={category.name}
                                    marginRight={categoryItemMargin}
                                    className="categoryElm"
                                ></Chip>
                            );
                        })}
                    </ScrollingCarousel>
                </Box>
            </Box>
        </Box>
    );
}
