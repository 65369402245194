import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box, Inline, Row } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useTheme from 'hooks/useTheme';

import { useAppContext } from 'store';

import Text from 'components/shared/FormattedText';

import { THEME } from '../api/constants';
import {
    formatTime,
    getOperationHourText,
    isHebrew,
    isStoreOpen,
    priceToString
} from '../api/utils';
import clock from '../assets/images/clock.svg';
import clockBlack from '../assets/images/clock-black.svg';
import securedBlack from '../assets/images/secure-black.svg';
import securedIcon from '../assets/images/secure-icon.svg';
import useStoreSettings from '../hooks/useStoreSettings';
import Collapse from './Collapse';

function Footer({ narrow, ...rest }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const split = 'screen and (max-width: 1000px)';

    const { data: storeSettings } = useStoreSettings();
    const { data: mappedData } = useCustomerData();
    const { zone, thread } = mappedData || {};
    const { state, appContextActions } = useAppContext();

    const { currency } = state;

    useEffect(() => {
        if (storeSettings && !currency) {
            appContextActions.currency.set(storeSettings.default_currency);
        }
    }, [currency, storeSettings]);

    if (!storeSettings) return null;
    const {
        fees,
        default_license,
        isOpen,
        whenCloses,
        availability,
        webstore_footer,
        customer_footer
    } = storeSettings;

    const order_min = fees.order_min && fees.order_min > 0 ? fees.order_min : null;
    const delivery_fee = fees.delivery_fee && fees.delivery_fee > 0 ? fees.delivery_fee : null;
    const delivery_fee_waiver_min =
        fees.delivery_fee_waiver_min && fees.delivery_fee_waiver_min > 0
            ? fees.delivery_fee_waiver_min
            : null;
    const license = zone && zone.license ? zone.license : default_license;

    const pickupLocation = thread?.pickup_destination;

    const StoreInfo = () => {
        return (
            <Box minHeight={25}>
                {delivery_fee && !pickupLocation && (
                    <Box>
                        {t('footer.freeDelivery', {
                            limitCost: priceToString(delivery_fee_waiver_min, currency)
                        })}{' '}
                    </Box>
                )}
                <Box>
                    {order_min && `${t('footer.minOrder')} ${priceToString(order_min, currency)}`}
                </Box>
                {pickupLocation && (
                    <Box>
                        {!isHebrew() && <Inline fontWeight={700}> {pickupLocation.name} </Inline>}
                        {t('footer.openHours')}
                        {isHebrew() && <Inline fontWeight={700}> {pickupLocation.name} </Inline>}
                    </Box>
                )}

                {pickupLocation ? (
                    <Box
                        fontSize={12}
                        fontWeight={500}
                        color={
                            isStoreOpen(pickupLocation.availability, storeSettings?.timeZone)
                                ? themeColors[theme].quinaryFontColor1
                                : themeColors[theme].secondaryFontColor1
                        }
                    >
                        {getOperationHourText(
                            pickupLocation.availability,
                            storeSettings?.timeZone,
                            t
                        )}
                    </Box>
                ) : isOpen ? (
                    whenCloses ? (
                        <Box color={themeColors[theme].quinaryFontColor1}>
                            {isHebrew()
                                ? `${whenCloses} ${t('footer.openUntil')}`
                                : `${t('footer.openUntil')} ${whenCloses}`}
                        </Box>
                    ) : (
                        <Box color={themeColors[theme].quinaryFontColor1}>
                            {t('footer.open247')}
                        </Box>
                    )
                ) : (
                    <Box color={themeColors[theme].quaternaryFontColor2}>{t('footer.closed')}</Box>
                )}
            </Box>
        );
    };
    return (
        <Box
            background={themeColors[theme].whiteBgColor}
            fontSize={12}
            color={themeColors[theme].secondaryFontColor16}
            props={{ ...rest, id: 'footer' }}
            borderTop={`1px solid ${themeColors[theme].secondaryBorderColor11}`}
        >
            <Box
                mediaQueries={{ split }}
                splitMaxWidth="100%"
                maxWidth={narrow ? 800 : 1000}
                margin="0 auto"
                padding="40px 20px 30px"
            >
                <Box
                    maxWidth={380}
                    paddingLeft={30}
                    marginBottom={30}
                    background={`url(${theme === THEME.DARK ? clockBlack : clock}
) left top 4px no-repeat`}
                >
                    {whenCloses || !isOpen ? (
                        <Collapse noBorder noPadding grey title={<StoreInfo />}>
                            <Box margin="10px 0">
                                {pickupLocation
                                    ? renderAvailability(pickupLocation.availability, t)
                                    : renderAvailability(availability, t)}
                            </Box>
                        </Collapse>
                    ) : (
                        <StoreInfo />
                    )}
                </Box>
                {customer_footer && (
                    <P icon={theme === THEME.DARK ? securedBlack : securedIcon}>
                        <Text>{customer_footer}</Text>
                    </P>
                )}

                {license && (
                    <P>
                        {t('footer.lic')} {license}
                    </P>
                )}

                <P marginBottom={0} lineHeight={1} minHeight={0}>
                    ©
                    <Inline
                        borderBottom="1px solid transparent"
                        hoverBorderBottom={`1px solid ${themeColors[theme].secondaryBorderColor12}`}
                        cursor="pointer"
                        props={{ onClick: () => navigate('/', false, {}) }}
                    >
                        {storeSettings.organization_name}
                    </Inline>
                    , {t('footer.allRights')}.
                </P>
                <P>
                    <Link theme={theme} themeColors={themeColors} link={webstore_footer?.blog_link}>
                        {t('footer.blog')}
                    </Link>
                    <Inline color={themeColors[theme].tertiaryFontColor4}> {' | '}</Inline>
                    <Link theme={theme} themeColors={themeColors} link={webstore_footer?.faq_link}>
                        {t('footer.FAQ')}
                    </Link>
                    <Inline color={themeColors[theme].tertiaryFontColor4}> {' | '}</Inline>
                    <Link
                        theme={theme}
                        themeColors={themeColors}
                        link={webstore_footer.terms_privacy_link}
                    >
                        {t('footer.termsAndPolicy')}
                    </Link>
                </P>
            </Box>
        </Box>
    );
}
export default React.memo(Footer);

function Link({ link, children, theme, themeColors }) {
    return (
        <Box
            component="a"
            color={themeColors[theme].tertiaryFontColor5}
            hoverColor={themeColors[theme].tertiaryFontColor6}
            textDecoration="none"
            whiteSpace="nowrap"
            props={{ href: link, target: '_blank' }}
        >
            {children}
        </Box>
    );
}

function P({ icon, children, ...rest }) {
    return (
        <Box
            background={icon ? `url(${icon}) left top 4px no-repeat` : 'none'}
            marginBottom={18}
            minHeight={28}
            padding="0 5px 5px 30px"
            letterSpacing="1px"
            {...rest}
        >
            {children}
        </Box>
    );
}

function renderAvailability(availability, t) {
    return Object.values(availability).map((el, i) => (
        <Row maxWidth={250} justifyContent="space-between" key={i}>
            <span>{t(`footer.${el.day}`)}</span>
            <span>
                {el.open_hour === -1 && el.close_hour === -1
                    ? t('footer.closed')
                    : el.open_hour === '0000' && el.close_hour === '2400'
                      ? t('footer.open24')
                      : `${formatTime(el.open_hour)} - ${formatTime(el.close_hour)}`}
            </span>
        </Row>
    ));
}
