import React from 'react';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';

function FormattedText({ children: text }) {
    const components = [];
    const lines = text.split(/\n|\r/);

    lines.forEach((part, j) => {
        if (!part) return;
        components.push(part);
        if (j < lines.length - 1) {
            components.push(<br />);
        }
    });

    return <Linkify>{components}</Linkify>;
}

FormattedText.propTypes = {
    children: PropTypes.string
};

export default FormattedText;
