import AWS from 'aws-sdk';

import log from '../utils/log';
import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function createSession(handoff) {
    const {
        handoff_id,
        user_cognito_id: user_id,
        app_client_id,
        cognito_identity_pool_id,
        session_token,
        login,
        user_cognito_id,
        organization_id
    } = handoff;

    const isInvalid =
        !app_client_id ||
        !cognito_identity_pool_id ||
        !session_token ||
        !login ||
        !user_cognito_id ||
        isExpired(handoff);

    if (isInvalid) {
        throw Error('Handoff is not valid');
    }

    // cognito will fail with 'missing credentials in config' unless we clear the cached IdentityId
    // instead of re-auth each time we should instead first check if auth is valid
    // additionally we should check if it needs renewed (1hr?)
    window.localStorage.removeItem(`aws.cognito.identity-id.${cognito_identity_pool_id}`);

    AWS.config.update({
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: cognito_identity_pool_id
        }),
        region: 'us-east-1'
    });

    const idProvider = new AWS.CognitoIdentityServiceProvider(cognito_identity_pool_id);

    log(`Authenticating session...`);

    try {
        const { AuthenticationResult } = await idProvider
            .initiateAuth({
                AuthFlow: 'REFRESH_TOKEN',
                ClientId: app_client_id,
                AuthParameters: { REFRESH_TOKEN: session_token }
            })
            .promise();
        log(`Authenticating result...`, AuthenticationResult);

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: cognito_identity_pool_id,
                IdentityId: user_cognito_id,
                Logins: { [login]: AuthenticationResult.IdToken }
            }),
            region: 'us-east-1'
        });

        log(`Authenticated!`, AuthenticationResult);
        return { userId: user_cognito_id, organization_id, authenticated: true };
    } catch (e) {
        log('catch error while authenticate: ', e.message, 'code: ', e.code);

        if (e.code === 'NotAuthorizedException') {
            //refresh token probably expired or revoked, need to delete handoff with session token
            //so user will be able to login next time

            console.log('got error NotAuthorizedException');
            localStorage.removeItem('handoff');

            const params = { handoff_id, user_id, organization_id };

            await invokeLambda(Lambda.DeleteHandoff, params);
        }
    }
}

function isExpired(handoff) {
    const now = Math.floor(Date.now() / 1000);
    const limit = 4 * 60 * 60; // 4 hours
    return now - handoff.last_update_time > limit;
}
