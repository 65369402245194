import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function deleteCustomer(username, organization_id) {
    const payload = {
        username,
        organization_id
    };
    return invokeLambda(Lambda.deleteCustomer, payload);
}
