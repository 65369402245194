import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import LinesEllipsis from 'react-lines-ellipsis';
import { navigate } from '@patched/hookrouter';
import { ScrollingCarousel } from '@trendyol-js/react-carousel';
import { Box, Col, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { CATEGORY_TYPES, THEME } from 'api/constants';
import { isHebrew } from 'api/utils';

import rightArrowLight from 'assets/images/right-arrow-light.svg';
import rightArrow from 'assets/images/small-black-arrow.svg';

import Image from 'components/Image';
import CampaignModal from 'components/Modals/CampaignModal';
import ProductCell, { ProductCellMode } from 'components/ProductCell';
import { Headline, Subtitle } from 'components/shared';

import '@brainhubeu/react-carousel/lib/style.css';

export default function CategoryRow({
    onCategoryClick,
    categoryItem,
    products,
    promotions,
    queryParams,
    itemsCount,
    storeSettings,
    thread,
    isOrderInTransit,
    userIsAuth,
    applyPromoCode,
    showPromoAppliedAlert,
    supportsDelivery,
    supportsPickup
}) {
    const [showCampaignModal, setShowCampaignModal] = useState(false);
    const [currentPromotion, setCurrentPromotion] = useState(false);

    const onProductClick = product => {
        sessionStorage.setItem('previousPage', JSON.stringify({ path: window.location.href }));
        navigate(`/p/${product.sku}`, false, queryParams);
    };

    const isPromotionType = categoryItem.type === CATEGORY_TYPES.PROMOTIONS_CATEGORY;

    const onPromotionClick = promotion => {
        setCurrentPromotion(promotion);
        setShowCampaignModal(true);
    };
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();
    return (
        <Col>
            <Box paddingLeft={20} paddingRight={20}>
                <Row
                    cursor={isPromotionType ? 'default' : 'pointer'}
                    props={{
                        onClick: isPromotionType ? null : onCategoryClick
                    }}
                >
                    <Headline color={themeColors[theme].primaryFontColor1}>
                        {categoryItem.name}
                    </Headline>
                    {!isPromotionType && (
                        <Image
                            marginLeft={20}
                            src={theme === THEME.DARK ? rightArrowLight : rightArrow}
                            color={themeColors[theme].primaryFontColor1}
                        />
                    )}
                </Row>
                <RowSubtitle smMargin theme={theme} themeColors={themeColors}>
                    {categoryItem.description}
                </RowSubtitle>
            </Box>

            <Box
                paddingLeft={!isMobileOnly && 20}
                className={`slider-wrapper ${isPromotionType && 'slider-wrapper-promotions'}`}
            >
                <ScrollingCarousel>
                    {isPromotionType
                        ? promotions.map(promotion => {
                              return (
                                  <CategoryCell
                                      key={promotion.promo_code}
                                      onPromotionClick={onPromotionClick}
                                      promotion={promotion}
                                  />
                              );
                          })
                        : products.map((product, index) => (
                              <Box
                                  width={185}
                                  display="inline-flex"
                                  paddingRight={5}
                                  paddingTop={20}
                                  paddingBottom={10}
                                  marginBottom={40}
                                  key={`product_${index}`}
                              >
                                  <ProductCell
                                      onClick={event => {
                                          event.stopPropagation();
                                          onProductClick(product);
                                      }}
                                      boxShadow={`0 1px 10px 0 ${themeColors[theme].boxShadowColor}`}
                                      border="none"
                                      product={product}
                                      borderRadius={10}
                                      backgroundColor={themeColors[theme].productCellBgColor}
                                      mode={ProductCellMode.Category}
                                      priceIncludesTax={storeSettings?.display_price_includes_tax}
                                      isOrderInTransit={isOrderInTransit}
                                      imageContainerFit={storeSettings.webstore_image_container}
                                      noOrderType={
                                          !thread?.pickup_destination && !thread?.location?.address
                                      }
                                  />
                              </Box>
                          ))}
                    {products?.length && itemsCount > products?.length && (
                        <Box
                            padding="20px 0px 40px 20px"
                            height="100%"
                            display="inline-flex"
                            cursor="pointer"
                        >
                            <Col
                                borderRadius={10}
                                marginRight={20}
                                height="100%"
                                justifyContent="center"
                                padding={30}
                                fontWeight={900}
                                props={{
                                    onClick: onCategoryClick
                                }}
                            >
                                <Box fontSize={18}>
                                    {t('categories.seeAll')}{' '}
                                    <Image
                                        marginLeft={20}
                                        src={theme === THEME.DARK ? rightArrowLight : rightArrow}
                                        color={themeColors[theme].primaryFontColor1}
                                    />
                                </Box>
                                <Box fontSize={11} color={themeColors[theme].secondaryFontColor10}>
                                    {isHebrew()
                                        ? `${
                                              itemsCount - products.length === 1
                                                  ? t('categories.moreProduct')
                                                  : t('categories.moreProducts')
                                          } \u200e${itemsCount - products.length}`
                                        : `${itemsCount - products.length} ${
                                              itemsCount - products.length === 1
                                                  ? t('categories.moreProduct')
                                                  : t('categories.moreProducts')
                                          }`}
                                </Box>
                            </Col>
                        </Box>
                    )}
                </ScrollingCarousel>
            </Box>
            {showCampaignModal && (
                <CampaignModal
                    showModal={true}
                    onClose={() => {
                        setShowCampaignModal(false);
                    }}
                    setShowCampaignModal={setShowCampaignModal}
                    promotionRelay={currentPromotion}
                    isAuth={userIsAuth}
                    applyPromoCode={applyPromoCode}
                    showPromoAppliedAlert={showPromoAppliedAlert}
                    modalTitle={t('promotionsCategory.applyAction')}
                    modalBackground={currentPromotion.image_url}
                    showClose={false}
                    isOrderInTransit={isOrderInTransit}
                />
            )}
        </Col>
    );
}

const RowSubtitle = ({ children, smMargin, theme, themeColors }) => (
    <Subtitle
        color={themeColors[theme].secondaryFontColor9}
        // color={'blue'}
        marginTop={10}
        marginBottom={smMargin ? 10 : 30}
        fontWeight={500}
        opacity={1}
    >
        {children}
    </Subtitle>
);

const CategoryCell = ({ promotion, onPromotionClick }) => {
    const { theme, themeColors } = useTheme();
    return (
        <Box
            width={310}
            height={260}
            cursor="pointer"
            margin="0 5px 40px 0"
            key={promotion?.promo_code}
            background={themeColors[theme].productCellBgColor}
            padding={7}
            borderRadius={10}
            boxShadow={`0 1px 10px 0 ${themeColors[theme].boxShadowColor}`}
            props={{
                onClick: event => {
                    event.preventDefault();
                    event.stopPropagation();
                    onPromotionClick(promotion);
                }
            }}
        >
            <Image
                height={170}
                minHeight={170}
                objectFit="cover"
                width="100%"
                src={promotion.image_url}
                alt={promotion.message}
                borderRadius={8}
            />
            <Subtitle
                opacity={0.7}
                fontWeight={500}
                minHeight={60}
                height={60}
                lineHeight={1.2}
                width="100%"
                padding="10px 5px 20px"
                color={themeColors[theme].blackFontColor}
            >
                <LinesEllipsis text={promotion.message} maxLine={3} ellipsis="..." />
            </Subtitle>
        </Box>
    );
};
