import log from '../utils/log';
import { Endpoint } from './constants';

export default async function getHandoff(id) {
    if (!id) throw new Error('Missing handoff ID');
    log(`Fetching handoff with id ${id}`);
    const data = await fetch(`${Endpoint.Handoff}${id}`);
    const handoff = await data.json();
    log(`Handoff fetched`, handoff);
    return handoff;
}
