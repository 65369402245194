import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import RadioButton from '../../shared/RadioButton';

export default function Delivery({ deliveryType, onChange, supportedDeliveryOptions, ...rest }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    const deliveryTypes = supportedDeliveryOptions.map(item => {
        return {
            label: t(`deliveryType.${item}`),
            value: item
        };
    });

    return (
        <Col justifyContent="flex-end" background={themeColors[theme].primaryBgColor4} {...rest}>
            {deliveryTypes.map(item => {
                return (
                    <RadioButton
                        key={item.value}
                        onChange={onChange}
                        checked={item.value === deliveryType}
                        value={item.value}
                        marginBottom={10}
                    >
                        {item.label}
                    </RadioButton>
                );
            })}
        </Col>
    );
}
