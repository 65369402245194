import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box, Col, Row } from 'jsxstyle';
import Layout from 'layout/TitleAndSubtitlePageLayout';
import moment from 'moment/min/moment-with-locales';

import useCustomerData from 'hooks/useCustomerData';
import useOrders from 'hooks/useOrders';
import useTheme from 'hooks/useTheme';

import Button from 'components/Button';
import Head from 'components/HeadManager';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { Body, Caption } from 'components/shared';
import Spinner from 'components/Spinner';
import TextLink from 'components/TextLink';

import { THEME, THREAD_STATUS } from '../api/constants';

export default function Orders({ pageTitle }) {
    const { theme, themeColors } = useTheme();
    const [startKey, setStartKey] = useState(null);
    const ordersHistory = useOrders();
    const { data: mappedData } = useCustomerData();
    const { thread } = mappedData || {};
    const [userOrders, setUserOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMoreResults, setLoadingMoreResults] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (ordersHistory && ordersHistory.data && thread) {
            const { startKey, orders } = ordersHistory.data;
            setStartKey(startKey);
            const _orders = [];
            // if there is an on going order we need to show it at first place
            if (thread.status === THREAD_STATUS.LOCKED) {
                _orders.push(thread);
            }
            _orders.push(...orders);
            setUserOrders(_orders);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ordersHistory.data, thread]);

    const getOrderTitle = order => {
        const arrival = new Date();
        arrival.setSeconds(arrival.getSeconds() + (order.eta || 0));
        const locale = localStorage.getItem('language').slice(0, 2);
        switch (order.status) {
            case THREAD_STATUS.LOCKED:
                return order.eta && arrival
                    ? `${t('orders.arriving')} ${moment(arrival).locale(locale).format('hh:mm A')}`
                    : t('orders.processing');
            case THREAD_STATUS.CANCELED:
                return `${t('orders.cancelled')} ${moment
                    .unix(order.closedTime)
                    .locale(locale)
                    .format('MMM Do')}`;
            default:
                return `${
                    order.pickup_destination ? t('orders.pickedup') : t('orders.delivered')
                } ${moment.unix(order.closedTime).locale(locale).format('MMM Do')}`;
        }
    };

    return (
        <Layout
            title={t('account.yourOrders')}
            closeIconName={theme === THEME.DARK ? 'backWhite' : 'back'}
            onClose={() => navigate('/account')}
        >
            <Head pageTitle={pageTitle} />
            {loading ? (
                <Loading title={t('account.loadingOrder')} />
            ) : userOrders && userOrders.length ? (
                <Box maxWidth={550}>
                    {userOrders.length
                        ? userOrders.map(order => {
                              return (
                                  <Box
                                      key={order?.thread_id}
                                      props={{
                                          onClick: () => {
                                              const { id, status } = order;

                                              // if order is in transit then we can use active thread implemenation
                                              if (status === THREAD_STATUS.LOCKED) {
                                                  navigate('/track');
                                              } else {
                                                  // navigate to specific order page
                                                  navigate(`/order/${id}`);
                                              }
                                          }
                                      }}
                                      color={themeColors[theme].primaryFontColor1}
                                  >
                                      <Row
                                          height={70}
                                          paddingLeft={20}
                                          paddingRight={20}
                                          alignItems="center"
                                          cursor="pointer"
                                          justifyContent="space-between"
                                          borderBottom={`1px solid ${themeColors[theme].secondaryBorderColor4}`}
                                      >
                                          <Row>
                                              {order.status === THREAD_STATUS.LOCKED && (
                                                  <Col
                                                      width={46}
                                                      height={46}
                                                      backgroundColor={
                                                          themeColors[theme].tertiaryBgColor2
                                                      }
                                                      marginRight={16}
                                                      borderRadius={23}
                                                      justifyContent="center"
                                                      alignItems="center"
                                                  >
                                                      <Icon name="van" width={26} height={26} />
                                                  </Col>
                                              )}

                                              <Col>
                                                  <Body
                                                      fontWeight={500}
                                                      fontSize={18}
                                                      marginBottom={5}
                                                  >
                                                      {getOrderTitle(order)}
                                                  </Body>
                                                  <Caption fontWeight={500}>
                                                      {order.status === THREAD_STATUS.LOCKED
                                                          ? t('orders.trackingTxt')
                                                          : `${t('orders.at')} ${moment
                                                                .unix(order.closedTime)
                                                                .format('LT')} • ${
                                                                order.pickup_destination
                                                                    ? order.pickup_destination.name
                                                                    : t(
                                                                          `deliveryType.${order.deliveryType}`
                                                                      )
                                                            }`}
                                                  </Caption>
                                              </Col>
                                          </Row>
                                          <Icon
                                              name={
                                                  theme === THEME.DARK
                                                      ? 'arrowRightWhite2'
                                                      : 'arrowRight'
                                              }
                                          />
                                      </Row>
                                  </Box>
                              );
                          })
                        : null}
                    {startKey && (
                        <Row justifyContent="center" alignItems="center" marginTop={60}>
                            {loadingMoreResults ? (
                                <Spinner margin={0} />
                            ) : (
                                <Button
                                    noHover={true}
                                    boxShadow="none"
                                    border="none"
                                    color={themeColors[theme].primaryFontColor1}
                                    backgroundColor={themeColors[theme].secondaryBgColor4}
                                    fontSize={12}
                                    height={32}
                                    padding="9px 0"
                                    onClick={async () => {
                                        try {
                                            setLoadingMoreResults(true);
                                            const response =
                                                await ordersHistory.getNextItems(startKey);
                                            setStartKey(response.startKey || null);
                                            const currentOrders = [...userOrders];
                                            response.orders.forEach(order => {
                                                currentOrders.push(order);
                                            });
                                            setUserOrders(currentOrders);
                                        } finally {
                                            setLoadingMoreResults(false);
                                        }
                                    }}
                                >
                                    {t('orders.showMore')}
                                </Button>
                            )}
                        </Row>
                    )}
                </Box>
            ) : (
                <Box>
                    <Caption fontWeight={500} opacity={0.5} marginBottom={28}>
                        {t('account.noOrder')}
                    </Caption>
                    <TextLink
                        fontSize={12}
                        goto={() => {
                            navigate('/');
                        }}
                        font-fontWeight={500}
                    >
                        {t('account.browseCatalog')}
                    </TextLink>
                </Box>
            )}
        </Layout>
    );
}
