import React from 'react';
import { Box } from 'jsxstyle';

export default function Image(props) {
    const { src, alt, onClick, onLoad, ...rest } = props;
    return (
        <Box
            component="img"
            props={{
                src,
                alt,
                onClick,
                onLoad
            }}
            {...rest}
        />
    );
}
