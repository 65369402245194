import React, { useEffect } from 'react';
import { usePath, useQueryParams, useRoutes } from '@patched/hookrouter';

import useStoreSettings from 'hooks/useStoreSettings';

import { AppContextProvider } from 'store';

import { trackPixel } from 'utils/trackingPixels';

import Session from '../providers/Session';
import Theme from '../providers/ThemeProvider';
import Account from './Account';
import Cart from './Cart';
import CartSubmitted from './CartSubmitted';
import Categories from './Categories';
import Compliance from './Compliance';
import DeleteAccount from './DeleteAccount';
import DirectToAgent from './DirectToAgent';
import EditAccount from './EditAccount';
import ErrorBoundary from './ErrorBoundary';
import ErrorPage from './ErrorPage';
import Login from './Login';
import NotFound from './NotFound';
import Order from './Order';
import Orders from './Orders';
import OrderStatus from './OrderStatus';
import PaymentMethods from './PaymentMethods';
import Product from './Product';
import ReferFriend from './ReferFriend';
import Search from './Search';
import SendDocument from './SendDocument';
import SubmitFailed from './SubmitFailed';
import SubmitSuccess from './SubmitSuccess';
import WeClosed from './WeClosed';

const routes = {
    '/catalog': () => <Categories pageTitle="Categories" />,
    '/': () => <Categories pageTitle="Categories" />,
    '/p/:id': params => <Product id={params.id} />,
    '/cart': () => <Cart pageTitle="Cart" />,
    '/search': () => <Search pageTitle="Catalog search" />,
    '/compliance': () => <Compliance pageTitle="ID verification" />,
    '/order/:id': params => <Order id={params.id} pageTitle="Past order" />,
    '/track': () => <OrderStatus pageTitle="Track you order" />,
    '/orders': () => <Orders pageTitle="Your orders" />,
    '/submitted': () => <CartSubmitted pageTitle="Order placed" />,
    '/account': () => <Account pageTitle="Your account" />,
    '/edit-account': () => <EditAccount pageTitle="Edit profile" />,
    '/delete-account': () => <DeleteAccount pageTitle="Delete account" />,
    '/login': () => <Login pageTitle="Login / Sign up" />,
    '/submit-failed': () => <SubmitFailed pageTitle="Order failed" />,
    '/order-placed': () => <SubmitSuccess pageTitle="Order placed successfully" />,
    '/we-closed': () => <WeClosed pageTitle="We are currently closed" />,
    '/direct-to-agent': () => <DirectToAgent pageTitle="Connect with agent" />,
    '/payment-methods': () => <PaymentMethods pageTitle="Payment methods" />,
    '/documents': () => <SendDocument pageTitle="Document required" />,
    '/refer': () => <ReferFriend pageTitle="Refer a friend" />,
    '/notfound': () => <NotFound pageTitle="Page not found" />
};

/**
 * RedirectProvider - performs any necessary redirects before rendering the application
 *
 * NOTE: RedirectProvider will render it's own children.
 *
 * USAGE: For example we always redirect to the organziation's store domain; if a user would
 * navigate to a lazzy subdomain (kushagram.iiitech.io for example) while a custom domain is active
 * they would be redirected to the current custom domain (store.blaze.delivery for example).
 *
 * @date 2021-06-16
 * @param {any} storeDomain - inserted into header via getWebSite
 */
function RedirectProvider({ storeDomain, children }) {
    const { data: storeSettings } = useStoreSettings();
    const [params] = useQueryParams();
    params.ref && localStorage.setItem('promoCode', params.ref);
    params.aid && localStorage.setItem('aid', params.aid);
    params.cid && localStorage.setItem('cid', params.cid);
    useEffect(() => {
        // if current domain does not match the default store domain,
        // redirect to the organization's store domain
        if (
            storeSettings &&
            storeSettings.store_domain &&
            storeSettings.store_domain !== storeDomain
        ) {
            window.location.replace(`https://${storeSettings.store_domain}/`);
        }

        // track pixels
        if (storeSettings?.pixel_integrations_config) {
            const { advertiser_id, events, share_with_meta, token } =
                storeSettings.pixel_integrations_config;

            if (events?.site_wide?.pixel_id) {
                trackPixel({
                    isMeta: share_with_meta,
                    advertiser_id,
                    pixel_id: events.site_wide.pixel_id,
                    token
                });
            }
        }
    }, [storeSettings, storeDomain]);
    return children;
}

export default function Router({
    storeDomain,
    countryCode,
    identityPool,
    organizationId,
    botPhoneNumber,
    storeDescription,
    storeTheme
}) {
    const cachedSupportPhoneNumber = localStorage.getItem('customerSupportPhone');

    const route = useRoutes(routes) || <NotFound />;
    const path = usePath();

    // scroll to top on route changes
    function scrollToTop() {
        if (path !== '/') {
            window.scrollTo(0, 0);
        }
    }

    useEffect(scrollToTop, [route]);

    return (
        <AppContextProvider>
            <Theme storeTheme={storeTheme}>
                <ErrorBoundary>
                    {organizationId ? (
                        <Session
                            storeDomain={storeDomain}
                            countryCode={countryCode}
                            botNumber={cachedSupportPhoneNumber || botPhoneNumber}
                            storeDescription={storeDescription}
                            identityPool={identityPool}
                            organizationId={organizationId}
                        >
                            <RedirectProvider storeDomain={storeDomain}>{route}</RedirectProvider>
                        </Session>
                    ) : (
                        <ErrorPage pageTitle="Something went wrong" />
                    )}
                </ErrorBoundary>
            </Theme>
        </AppContextProvider>
    );
}
