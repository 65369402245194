import React from 'react';
import { Box, Row } from 'jsxstyle';

import { THEME } from 'api/constants';

import Icon from './Icon';

export default function CloseBar({
    onClose,
    right,
    theme,
    text,
    closeIconName = `${theme === THEME.DARK ? 'closeLight' : 'close'}`
}) {
    return (
        <Row>
            <Box padding="10px 30px 15px 0" flex={0} cursor="pointer" props={{ onClick: onClose }}>
                {text ? (
                    <Box fontSize={16} fontWeight={700} minWidth={150}>
                        {text}
                    </Box>
                ) : (
                    <Icon name={closeIconName} />
                )}
            </Box>
            <Box display="flex" flex={1} justifyContent="flex-end">
                {right}
            </Box>
        </Row>
    );
}
