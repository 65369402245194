import { Lambda } from './../../constants';
import invokeLambda from './../../invokeLambda';

export default class LazzyDocumentProvider {
    constructor(options) {
        this.options = options;
    }

    async putObject(key, file, folder = null, type, userId, organization_id) {
        let filePath;

        if (folder) {
            filePath = `${folder}/${key}`;
        } else {
            filePath = key;
        }
        const { url, errorMessage } = await invokeLambda(Lambda.getSignedUrl, {
            operation: 'putObject',
            customer_user_id: userId,
            file_path: filePath,
            organization_id
        });

        if (url) {
            const response = await fetch(url, {
                method: 'PUT',
                body: file
            });

            if (response.ok) {
                return {
                    success: true,
                    name: key,
                    url,
                    type
                };
            }
        } else if (errorMessage) {
            return {
                success: false,
                errorMessage
            };
        }
    }

    async getObject(key, userId, organization_id) {
        //TODO Lambda.getSignedUrl don't support getObject operation
        //cant be used for this
        return invokeLambda(Lambda.getSignedUrl, {
            operation: 'getObject',
            customer_user_id: userId,
            file_path: `verification_images/${key}`,
            organization_id
        });
    }
}
