import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function getProduct({
    organization_id,
    product_id,
    isOpen,
    zone_id,
    redirected_from_zone,
    menu_kit_id,
    useHubStock,
    thread_id,
    associated_hub
}) {
    // if (!associated_hub && !zone_id) return null;
    const payload = {
        product_id,
        organization_id,
        isOpen,
        kit_id: menu_kit_id,
        zone_id,
        redirected_from_zone,
        useHubStock,
        associated_hub
    };

    const { results, menu_kit } = await invokeLambda(Lambda.GetCatalogFromInventory, payload);

    if (menu_kit) {
        sessionStorage.setItem('menu_kit', menu_kit);
    } else if (menu_kit_id) {
        sessionStorage.removeItem('menu_kit');
    }
    if (thread_id && menu_kit_id !== menu_kit) {
        const payload = {
            thread_id,
            menu_kit: menu_kit || null
        };

        await invokeLambda(Lambda.UpdateThread, payload);
    }
    return results[0];
}
