import React from 'react';
import { Box, Inline, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

function TextInput(props, ref) {
    const {
        value,
        onChange,
        onKeyPress,
        autoFocus,
        placeholder,
        onBlur,
        onFocus,
        type = 'text',
        autocomplete,
        ...rest
    } = props;
    const { theme, themeColors } = useTheme();

    return (
        <Box
            component="input"
            outline="none"
            placeholderColor={themeColors[theme].secondaryFontColor3}
            backgroundColor={themeColors[theme].primaryBgColor1}
            color={themeColors[theme].primaryFontColor1}
            props={{
                ref,
                autocomplete,
                type,
                value,
                autoFocus,
                placeholder,
                onChange: e => onChange(e.target.value),
                onKeyPress,
                onBlur,
                onFocus
            }}
            {...rest}
        />
    );
}

export default React.forwardRef(TextInput);
