import AWS from 'aws-sdk';

import { clearCache } from 'api/utils';

import log from '../utils/log';

export default async function invokeLambda(name, payload) {
    try {
        // log(`[API] Invoking ${name}...`);
        const lambda = new AWS.Lambda();
        const response = await lambda
            .invoke({
                FunctionName: name,
                InvocationType: 'RequestResponse',
                Payload: JSON.stringify(payload)
            })
            .promise();
        const data = JSON.parse(response.Payload);
        // log(`[API] ${name} response`, data);
        return data;
    } catch (e) {
        if (e.code === 'CredentialsError') {
            clearCache();
            window.location.replace('/');
        }
        console.log('Error in invoke lambda', e);
        console.log('Error code', e.code);
    }
}
