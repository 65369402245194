import { isScheduledOrder } from 'api/utils';

import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function checkItemsAvailability({
    cart,
    isOpen,
    zoneId,
    redirected_from_zone,
    associated_hub,
    kit_id,
    organization_id,
    current_delivery_model,
    promo_code,
    user_id,
    return_payment_details = false,
    is_pickup = false,
    use_free_delivery,
    payment_type
}) {
    try {
        if (!cart) return null;

        if (!cart.length) return [];

        const useHubStock = isScheduledOrder(current_delivery_model);

        const availabilityData = await invokeLambda(Lambda.checkCartAvailability, {
            cart,
            isOpen,
            zoneId,
            redirected_from_zone,
            associated_hub,
            organization_id,
            kit_id,
            useHubStock,
            promo_code,
            user_id,
            current_delivery_model,
            return_payment_details,
            is_pickup,
            use_free_delivery,
            payment_type
        });

        return {
            ...availabilityData,
            charges: {
                subtotal: availabilityData.payment_details?.subtotal_after_discount,
                fees: availabilityData.payment_details?.total_taxes,
                delivery: availabilityData.payment_details?.delivery_rate,
                total: availabilityData.payment_details?.total_charge_in_cents,
                promocodeDiscount: availabilityData.payment_details?.promocode_discount,
                roundup: availabilityData.payment_details?.round_up_addition,
                platformFee: availabilityData.payment_details?.platform_fee_total,
                platformFeeDesp: availabilityData.payment_details?.platform_fee_description
            }
        };
    } catch (e) {
        console.log('error', e);
    }
}
