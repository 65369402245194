import DeliveryModel from 'constants/DeliveryModel';
import moment from 'moment/min/moment-with-locales';

import {
    ORDER_REQUIREMENTS,
    PROMOCODEKEY,
    SHOW_STATE,
    THREAD_STATUS,
    WindowSubType
} from './constants';

/**
 * Formats phone number to (XXX) XXX XXXX
 *
 * @param {string} phoneNumber - phone number or customer username with optional "+" (+XXXXXXXXXX)
 */
export function formatPhoneNumber(phoneNumber) {
    return phoneNumber
        ? phoneNumber.replace('+', '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '($2) $3 $4')
        : '';
}

export function validateInput(type, input) {
    const patterns = {
        phoneNumber: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{2,5})$/,
        countryCode: /^\+(\d{0,3})$/,
        verificationCode: /^(\d{0,5})$/,
        email: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
        // name: /^\s*([A-Za-z]{1,}([.,] |[-']| ))+[A-Za-z]+\.?\s*$/
        name: /^\s*([\w\W]{1,}([.,] |[-']| ))+[\w\W]+\.?\s*$/,
        promoCode: /^[a-zA-Z0-9]*$/
    };

    return patterns[type].test(input);
}

/**
 * returns formatted timestamp for current time
 *
 * @returns {number}
 */
export function timestamp() {
    return Math.floor(Date.now() / 1000);
}

/**
 * Check if value is a safe integer, if no throws error
 *
 * @param {any} value to check
 * @returns {any} returns number if value is safe int, otherwise throwing error
 */
export const safeInt = value => {
    const number = Number(value);
    if (Number.MIN_SAFE_INTEGER < number && number < Number.MAX_SAFE_INTEGER) {
        return number;
    } else {
        throw new Error('The value is not safe integer');
    }
};

/**
 * shuffleArray - sorts an array differently each time
 * uses Durstenfeld implementation of Fisher-Yates
 *
 * from https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 *
 * @date 2020-10-23
 * @param {any} array
 * @returns {any}
 */
export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

/**
 * This checks if customer order meets organization order
 * requirements
 *
 * @param {number} subtotal - cart subtotal
 * @param {object} fees - organization fees
 * @returns {(0|1)} 0 - ORDER_MEETS_REQUIREMENTS, 1 - ORDER_LESS_MIN_ORDER_REQUIREMENT
 */
export function checkOrderMeetsRequirements(subtotal, fees) {
    if (fees.order_min && subtotal < fees.order_min) {
        return ORDER_REQUIREMENTS.LESS_MIN_ORDER;
    }

    return ORDER_REQUIREMENTS.MEETS;
}

/**
 * check if thread submitted
 * @param {object} thread
 */
export function threadSubmitted(thread) {
    if (!thread) return false;
    // Pending is the only status where a customer can modify an order
    return thread.thread_status !== THREAD_STATUS.PENDING;
}

/**
 * check if customer cart full
 * @param {object} cart
 */
export function cartFull(cart) {
    return cart && cart.length;
}

/**
 * check if customer cart empty
 * @param {object} cart
 */
export function cartEmpty(cart) {
    return !cartFull(cart);
}

/**
 * calculate eta in minutes
 * @param {object} eta
 */
export function etaInMinutes(eta) {
    if (!eta) return null;
    const now = new Date();
    const lastUpdate = eta.last_update_time;
    const timeSinceUpdate = +now / 1000 - lastUpdate;
    const roundedEta = roundToPrecision((eta - timeSinceUpdate) / 60, 5);
    return roundedEta;
}

/**
 * round number to precision
 * @param {number} x
 * @param {number} precision
 */
export function roundToPrecision(x, precision) {
    var y = +x + (precision === undefined ? 0.5 : precision / 2);
    return y - (y % (precision === undefined ? 1 : +precision));
}

/**
 * count cart total
 * @param {number} x
 * @param {number} precision
 */
export function cartTotal(items) {
    const subtotal = x => x.product_count * x.product.total_price;
    const total = items.reduce((x, y) => x + subtotal(y), 0);
    return total;
}

/**
 * Formats price in number format to dollar string format
 *
 * @param {number} cents
 */
export function priceToString(cents, currency, countryCode) {
    if (!currency) {
        currency = 'USD';
    }

    if (!countryCode) {
        countryCode = 'en-US';
    }
    const formatted = new Intl.NumberFormat(countryCode, {
        style: 'currency',
        currency
    }).format(cents / 100);

    return formatted;
}

export function priceToStringRound(cents, currency, countryCode) {
    return priceToString(cents, currency, countryCode).replace('.00', '');
}

/**
 * checks if store open
 *
 * @param {object} schedule
 * @param {*} timezone - store timezone
 * @returns {boolean}
 */
export function isStoreOpen(schedule, timezone) {
    const now = getAdjustedTime(timezone);
    const day = now.getDay();
    const time = timeToNumber(now);
    const nightMorning = 0;
    const nightMidnight = 2400;

    if (!Object.keys(schedule).length) {
        //store opened always (24/7)
        return true;
    }

    if (
        !schedule[day].open_hour ||
        schedule[day].open_hour === -1 ||
        !schedule[day].close_hour ||
        schedule[day].close_hour === -1
    ) {
        return false;
    }

    let openTimeToday = +schedule[day].open_hour;
    let closeTimeToday = +schedule[day].close_hour;

    // if openTimeToday > closeTimeToday it means store will be closed after midnight
    let isOpen =
        openTimeToday > closeTimeToday
            ? isInRange(time, openTimeToday, nightMidnight)
            : isInRange(time, openTimeToday, closeTimeToday);

    if (!isOpen) {
        //better check previous day
        const yesterday = schedule[day - 1 === -1 ? 6 : day - 1];

        const openTimeYesterday = yesterday ? +yesterday.open_hour : null;
        const closeTimeYesterday = yesterday ? +yesterday.close_hour : null;
        if (yesterday && openTimeYesterday > closeTimeYesterday) {
            openTimeToday = nightMorning;
            closeTimeToday = closeTimeYesterday;
            isOpen = isInRange(time, openTimeToday, closeTimeToday);
        }
    }

    return isOpen;
}

/**
 * returns number key of the next day
 *
 * @param {number} day
 * @returns {number} next day
 */
export function getNextDay(day) {
    return +day < 6 ? +day + 1 : 0;
}

/**
 * convert time to string format
 *
 * @param {number} time
 * @returns {string} time, ex. "10:30pm"
 */
export function formatTime(time) {
    const t = +time;
    const timeInAmPm =
        t === 2400 || t === 0
            ? '1200am'
            : t < 60
              ? `12${t < 10 ? '0' + t : t}am`
              : t > 1259
                ? `${t - 1200}pm`
                : t < 1200
                  ? `${t}am`
                  : `${t}pm`;

    const timeRegExp = /(\d{2})(\d{2})/;
    const smallTimeRegExp = /(\d{1})(\d{2})/;
    if (timeRegExp.test(timeInAmPm)) {
        return timeInAmPm.replace(timeRegExp, '$1:$2').replace(/^0/, '');
    }
    return timeInAmPm.replace(smallTimeRegExp, '$1:$2').replace(/^0/, '');
}

/**
 * return formatted day name
 *
 * @param {number} day
 * @param {number} dayToday
 * @param {object} daySchedule
 * @returns {string} day
 */
export function formatDay(day, dayToday, daySchedule) {
    if (day === dayToday) return 'Today';
    if (day === getNextDay(dayToday)) return 'Tomorrow';
    return daySchedule.day;
}

/**
 * return day and time when store will be open next time
 *
 * @param {object} availability
 * @param {Date} date
 * @returns {object: {day, time}}
 */
export function whenStoreOpen(availability, timezone) {
    const now = getAdjustedTime(timezone);
    let day = now.getDay();
    const dayToday = day;

    if (!Object.keys(availability).length) {
        //store opened always (24/7)
        return { day: dayToday, time: '0000' };
    }

    const time = timeToNumber(now);
    const openTimeToday =
        availability[dayToday].open_hour && availability[dayToday].open_hour !== -1
            ? +availability[dayToday].open_hour
            : null;
    let dayOpen, timeOpen, timeClose;

    for (let i = 0; i < 7; i++) {
        if (availability[day].open_hour !== -1) {
            if (day === dayToday) {
                if (openTimeToday != null && openTimeToday > time) {
                    dayOpen = day;
                    break;
                }
            } else {
                dayOpen = day;
                break;
            }
        }
        day = getNextDay(day);
    }

    timeOpen = formatTime(availability[day].open_hour);
    timeClose = formatTime(availability[day].close_hour);

    dayOpen = formatDay(dayOpen, dayToday, availability[day]);
    return { day: dayOpen, time: timeOpen, timeClose };
}

/**
 * returns time in 4 numbers format, example 1030 - 10 h 30 min
 * @param {date} date
 * @returns {number} time
 */
export function timeToNumber(date) {
    const hours = date.getHours();
    const min = date.getMinutes();
    // need to add "0" if minutes less than 10 for proper calculation
    return +`${hours}${min < 10 ? '0' + min : min}`;
}

/**
 * checks if num in range of min and max
 *
 * @param {number} num
 * @param {number} min
 * @param {number} max
 * @returns {boolean}
 */
export function isInRange(num, min, max) {
    return num >= min && num < max;
}

/**
 * calculate when store will be closed based on the
 * company availability and given date object
 * @param {object} availability - organization availability
 * @param {object} date - now date
 * @returns {string} time when store closes
 */
export function whenStoreCloses(availability, date) {
    if (!Object.keys(availability).length) {
        //store opened always (24/7)
        return null;
    }

    const day = date.getDay();
    let closeTimeToday = +availability[day].close_hour;
    const openTimeToday = +availability[day].open_hour;

    //if store was open from yesterday need to check
    //yesterday availability and get closed time from that day
    const timeNow = timeToNumber(date);
    if (timeNow < openTimeToday) {
        const yesterday = availability[day - 1 === -1 ? 6 : day - 1];
        closeTimeToday = +yesterday.close_hour;
    }
    return formatTime(closeTimeToday);
}

/**
 * Check if order weight exceeds rules for
 * flower and nonflower products
 *
 * @param {array} cart customer cart
 * @returns {boolean} return true if need agent for verification
 */
export function requireAgentVerification(cart) {
    const FLOWER_TYPE = 'Flower';
    const FLOWER_MAX_WEIGHT = 28.5;
    const NONFLOWER_MAX_WEIGHT = 8;
    let flowerTotalWeight = 0;
    let nonflowerTotalWeight = 0;
    cart.forEach(item => {
        const product = item.product;
        const count = item.product_count;
        if (product.weight) {
            if (product.weight_type && product.weight_type === FLOWER_TYPE) {
                flowerTotalWeight += parseFloat(product.weight) * count;
            } else {
                nonflowerTotalWeight += parseFloat(product.weight) * count;
            }
        }
    });
    return flowerTotalWeight > FLOWER_MAX_WEIGHT || nonflowerTotalWeight > NONFLOWER_MAX_WEIGHT;
}

export function getTimeOpenToday(availability, timezone) {
    const now = getAdjustedTime(timezone);
    const day = now.getDay();

    const openHour =
        availability && availability[day] && availability[day].open_hour !== -1
            ? availability[day].open_hour
            : '0000';

    now.setHours(+openHour.substr(0, 2), +openHour.substr(2));
    return Math.floor(now / 1000);
}

export function getAdjustedTime(timezone) {
    const adjustedTime = new Date().toLocaleString('en-US', {
        timeZone: timezone
    });
    return new Date(adjustedTime);
}

/**
 * checks if thread locked
 *
 * @param {object} thread
 * @returns {boolean} is thread locked
 */
export function isThreadLocked(thread) {
    return thread && thread.status !== THREAD_STATUS.PENDING;
}

/**
 * checks if thread status is processing
 *
 * @param {object} thread
 * @returns {boolean} is processing
 */
export function isThreadProcessing(thread) {
    return thread && thread.status === THREAD_STATUS.OPEN;
}

/**
 * checks if thread status is in transit
 *
 * @param {object} thread
 * @returns {boolean} is in transit
 */
export function isThreadInTransit(thread) {
    return thread && thread.status === THREAD_STATUS.LOCKED;
}

export function isThreadDelivered(thread) {
    return thread && thread.status === THREAD_STATUS.CLOSED;
}

export function isThreadCancelled(thread) {
    return thread && thread.status === THREAD_STATUS.CANCELED;
}

export function isThreadClosed(thread) {
    return thread && (isThreadCancelled(thread) || isThreadDelivered(thread));
}

export function isThreadSubmitted(thread) {
    return (
        thread && (thread.status === THREAD_STATUS.OPEN || thread.status === THREAD_STATUS.LOCKED)
    );
}

/**
 * gets customer name
 *
 * @param {object} customer
 * @returns {string} name
 */
export function getFullName(customer) {
    return customer.settings && customer.settings.full_name;
}
/**
 * gets customer email
 *
 * @param {object} customer
 * @returns {string} email
 */
export function getEmail(customer) {
    return customer.settings && customer.settings.email;
}

/**
 * gets customer address
 *
 * @param {object} customer
 * @returns {string} address
 */
export function getFormattedAddress(customer) {
    return customer.location && customer.location.address;
}

/**
 * checks if cart have items
 *
 * @param {Array} cart
 * @returns {boolean}
 */
export function isCartFull(cart) {
    return cart && cart.length;
}

/**
 * Checks if number is integer
 *
 * @param {number} value - some value
 * @returns {boolean} is integer value
 */
export function isInteger(value) {
    return value && typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

/**
 * looks for an item in cart, and if there is return item quantity
 *
 * @param {object} product
 * @param {cart} cart
 * @returns {number} quantity
 */
export function numberInCart(product, cart) {
    if (!cart) return 0;
    const item = cart.find(x => x.product.product_id === product.product_id);
    if (!item) return 0;
    return item.product_count;
}

/**
 * looks for an item in cart, and if there is return item quantity
 *
 * @param {object} product
 * @param {cart} cart
 * @returns {number} quantity
 */
export function getScheduledETA(estimatedEta, averageEta) {
    if (estimatedEta === 'No ETA') {
        return '';
    } else if (estimatedEta === 'Same day') {
        return 'same day';
    } else {
        const estimatedEtaMin = parseInt(estimatedEta);
        const averageEtaMin = Math.floor(averageEta / 60);
        const maxEta = Math.max(estimatedEtaMin, averageEtaMin);
        return `${maxEta} min`;
    }
}

export function adjustForTimezone(timestamp) {
    const date = new Date(timestamp * 1000);
    const timeOffsetInMS = date.getTimezoneOffset() * 60 * 1000;
    date.setTime(date.getTime() + timeOffsetInMS);

    return Math.floor(date / 1);
}

export const isHebrew = () => localStorage.getItem('language') === 'he-IL';
export const isEnglish = () => localStorage.getItem('language') === 'en-US';
export const isRussian = localStorage.getItem('language') === 'ru-RU';
export const locale = localStorage.getItem('language')?.slice(0, 2);

export function formatFutureDate(timestamp, t, isShort) {
    const locale = localStorage.getItem('language')?.slice(0, 2);
    const momentObj = moment(timestamp * 1000);

    return ` ${
        momentObj.minutes() == 0
            ? `\u200E${momentObj.format('hA')}`
            : `\u200E${momentObj.format('h:mmA')}`
    } 
    ${
        !isHebrew()
            ? momentObj.locale(locale).calendar(null, {
                  sameDay: `[${t('stickyBar.today')}]`,
                  nextDay: `[${t('stickyBar.tomorrow')}]`,
                  nextWeek: `ddd${!isShort ? 'd' : ''}, MMM D`,
                  sameElse: `ddd${!isShort ? 'd' : ''}, MMM D`
              })
            : `בין \u200E${momentObj.locale(locale).calendar(null, {
                  sameDay: `[${t('stickyBar.today')}]`,
                  nextDay: `[${t('stickyBar.tomorrow')}]`,
                  nextWeek: `ddd${!isShort ? 'd' : ''}\u200E`,
                  sameElse: 'MMMM D'
              })}`
    }`;
}

export function formatDateTimeOnly(timestamp, t) {
    const momentObj = moment(timestamp * 1000);
    const hour =
        momentObj.minutes() == 0
            ? `\u200E${momentObj.format('hA')}`
            : `\u200E${momentObj.format('h:mmA')}`;
    return hour;
}

export function formatShiftHours(start, end, t) {
    return start && end
        ? ` ${formatDateTimeOnly(start, t).toUpperCase()} - ${formatFutureDate(end, t)}`
        : '';
}

export function shortDateFormat(timestamp, t) {
    return ` ${moment(timestamp * 1000)
        .locale(locale)
        .format('MMM DD, yyyy')}`;
}

export function DateFormatWithWeek(timestamp, t) {
    const locale = localStorage.getItem('language')?.slice(0, 2);
    return ` ${moment(timestamp * 1000)
        .locale(locale)
        .format('dddd MMM DD, yyyy')}`;
}

export function formatNextDay(timestamp, t) {
    const locale = localStorage.getItem('language')?.slice(0, 2);
    const momentObj = moment(timestamp * 1000);

    const isTodayTomorrow =
        momentObj.isSame(new Date(), 'day') || momentObj.isSame(moment().add(1, 'day'), 'day');

    const nextDayText = isTodayTomorrow
        ? t(`scheduledModal.nextday`)
        : isHebrew()
          ? `${t(`scheduledModal.delivery2`)} ${momentObj.locale(locale).calendar(null, {
                nextWeek: 'dddd'
            })}`
          : `${momentObj.locale(locale).calendar(null, {
                nextWeek: 'dddd'
            })} ${t(`scheduledModal.delivery`)}`;

    return nextDayText;
}

export function formatDeliveryWindowText(window, storeClosed = false, t, isShort) {
    return window.window_subtype !== WindowSubType.range
        ? window.window_subtype === WindowSubType.nextday
            ? formatNextDay(window.deliver_by, t)
            : window.window_subtype === WindowSubType.asap && storeClosed
              ? t(`scheduledModal.asapclosed`)
              : t(`scheduledModal.${window.window_subtype}`)
        : ` ${formatDateTimeOnly(window.deliver_from, t).toUpperCase()} - ${formatFutureDate(
              window.deliver_by,
              t,
              isShort
          )}`;
}

export function supportsOnlySuperfastMenu(supportsOndemandMenu, supportsScheduledMenu) {
    return supportsOndemandMenu && !supportsScheduledMenu;
}
export function supportsOnlyScheduledMenu(supportsOndemandMenu, supportsScheduledMenu) {
    return supportsScheduledMenu && !supportsOndemandMenu;
}
export function supportsBothMenus(supportsOndemandMenu, supportsScheduledMenu) {
    return supportsScheduledMenu && supportsOndemandMenu;
}

export function cacheDeliveryModel(deliveryModel) {
    localStorage.setItem(`selectedDeliveryModel`, deliveryModel);
}

export function getCachedDeliveryModel() {
    return localStorage.getItem(`selectedDeliveryModel`);
}

export function clearCachedDeliveryModel() {
    localStorage.removeItem('selectedDeliveryModel');
}

export function cacheHidePromoPopup(promoId) {
    sessionStorage.setItem(`hidePromoPopup`, true);
}

export function getHidePromoPopup(promoId) {
    return sessionStorage.getItem(`hidePromoPopup`);
}

export function cacheHidePromotionBar(promoId) {
    sessionStorage.setItem(`hidePromotionBar-${promoId}`, true);
}

export function getHidePromotionBar(promoId) {
    return sessionStorage.getItem(`hidePromotionBar-${promoId}`);
}

export function clearHidePromotions() {
    Object.keys(sessionStorage).forEach(key => {
        if (key.includes('hidePromotionBar-') || key.includes('hidePromoPopup-')) {
            sessionStorage.removeItem(key);
        }
    });
}

export function clearHidePromotionBar() {
    Object.keys(sessionStorage).forEach(key => {
        if (key.includes('hidePromotionBar-')) {
            sessionStorage.removeItem(key);
        }
    });
    sessionStorage.setItem('hidePromotionPopups', true);
}

export function cachePromoCode(promoCode) {
    localStorage.setItem(PROMOCODEKEY, promoCode);
}

export function getCachedPromoCode() {
    return localStorage.getItem(PROMOCODEKEY);
}

export function clearCachedPromoCode() {
    localStorage.removeItem(PROMOCODEKEY);
}

export function cachePromoLink(url) {
    if (url) {
        localStorage.setItem('promoLink', url);
    }
}

export function getCachedPromoLink() {
    return localStorage.getItem('promoLink');
}

export function clearCachedPromoLink() {
    localStorage.removeItem('promoLink');
}

export function cacheDoNotAutoApplyPromo() {
    sessionStorage.setItem(`doNotAutoApply`, true);
}

export function getDoNotAutoApplyPromo() {
    return sessionStorage.getItem(`doNotAutoApply`);
}

export function removeDoNotAutoApplyPromo() {
    return sessionStorage.removeItem(`doNotAutoApply`);
}

export function cachePromoMessage(message) {
    localStorage.setItem('promoMessage', message);
}

export function getCachedPromoMessage() {
    return localStorage.getItem('promoMessage');
}

export function getCurrentDeliveryModel(
    thread,
    isStoreOpen,
    supportsOndemandMenu,
    supportsScheduledMenu
) {
    if (
        thread != null &&
        isStoreOpen != null &&
        supportsOndemandMenu != null &&
        supportsScheduledMenu != null
    ) {
        let deliveryModel = null;

        if (supportsBothMenus(supportsOndemandMenu, supportsScheduledMenu)) {
            const cachedDeliveryModel = getCachedDeliveryModel();
            const threadDeliveryModel = getThreadDeliveryModel(
                thread,
                supportsOndemandMenu,
                supportsScheduledMenu
            );

            deliveryModel =
                threadDeliveryModel === DeliveryModel.Scheduled ||
                cachedDeliveryModel === DeliveryModel.Scheduled
                    ? DeliveryModel.Scheduled
                    : cachedDeliveryModel ||
                      getZonePreferableDeliveryModel(
                          supportsOndemandMenu,
                          supportsScheduledMenu,
                          isStoreOpen
                      );
        } else {
            deliveryModel = supportsOndemandMenu ? DeliveryModel.OnDemand : DeliveryModel.Scheduled;
        }

        return deliveryModel;
    }
}

export function getThreadDeliveryModel(thread, supportsOndemandMenu, supportsScheduledMenu) {
    if (!supportsOndemandMenu && !supportsScheduledMenu) return null;
    if (supportsBothMenus(supportsOndemandMenu, supportsScheduledMenu)) {
        return thread?.deliver_by || thread?.delivery_window?.deliver_by
            ? DeliveryModel.Scheduled
            : DeliveryModel.OnDemand;
    } else {
        return supportsOndemandMenu ? DeliveryModel.OnDemand : DeliveryModel.Scheduled;
    }
}

export function getZonePreferableDeliveryModel(
    supportsOndemandMenu,
    supportsScheduledMenu,
    isStoreOpen
) {
    if (supportsOndemandMenu == null || supportsScheduledMenu == null) return null;

    if (supportsOnlySuperfastMenu(supportsOndemandMenu, supportsScheduledMenu)) {
        return DeliveryModel.OnDemand;
    } else if (supportsOnlyScheduledMenu(supportsOndemandMenu, supportsScheduledMenu)) {
        return DeliveryModel.Scheduled;
    } else {
        return isStoreOpen ? DeliveryModel.OnDemand : DeliveryModel.Scheduled;
    }
}

export function getAnotherDeliveryModel(current) {
    return current === DeliveryModel.Scheduled ? DeliveryModel.OnDemand : DeliveryModel.Scheduled;
}

export function isSuperFastOrder(deliveryModel) {
    return deliveryModel === DeliveryModel.OnDemand;
}

export function isScheduledOrder(deliveryModel) {
    return !isSuperFastOrder(deliveryModel);
}

export function deliveryModelSupportedInZone(
    deliveryModel,
    supportsScheduledMenu,
    supportsOndemandMenu
) {
    if (
        deliveryModel &&
        ((deliveryModel === DeliveryModel.Scheduled && supportsScheduledMenu) ||
            (deliveryModel === DeliveryModel.OnDemand && supportsOndemandMenu))
    ) {
        return true;
    }

    return false;
}

export function scrollToTop() {
    window.scrollTo(0, 0);
}

export function cacheSelectedWindow(window) {
    sessionStorage.setItem(`selectedWindow`, JSON.stringify(window));
}

export function getCachedSelectedWindow() {
    return JSON.parse(sessionStorage.getItem(`selectedWindow`));
}

export function clearCachedSelectedWindow() {
    sessionStorage.removeItem('selectedWindow');
}

function getDistanceBetweenDays(day1, day2) {
    return (day2 - day1 + 7) % 7;
}

export const whenStoreOpenNextDay = (availability, timezone, now = null) => {
    let day = now.getDay();

    const dayToday = day;
    let dayOpen;

    for (let i = 0; i < 7; i++) {
        if (availability[day]?.open_hour !== -1) {
            if (day !== dayToday) {
                dayOpen = day;
                break;
            }
        }
        day = getNextDay(day);
    }

    return {
        dayOpen,
        dayToday,
        time: availability[day].open_hour,
        closeTime: availability[day].close_hour
    };
};

function setTime(date, time) {
    date.setHours(Number(time.substring(0, 2)));
    date.setMinutes(Number(time.substring(2)));
    date.setSeconds(0, 0);
    return date;
}

export const getNextDayOpenTimes = (availability, timezone, date) => {
    try {
        const {
            dayOpen,
            dayToday,
            time: timeOpen,
            closeTime
        } = whenStoreOpenNextDay(availability, timezone, date);

        const distance = getDistanceBetweenDays(dayToday, dayOpen);

        date.setDate(date.getDate() + distance);

        const start = Math.floor(setTime(date, timeOpen) / 1000);
        const end = Math.floor(setTime(date, closeTime) / 1000);

        return { start, end };
    } catch (e) {
        return {};
    }
};

export function getDefaultNextDayWindow(orgId, availability, timezone) {
    const now = new Date();

    const { start, end } = getNextDayOpenTimes(availability, timezone, now);

    return {
        window_id: 'nextday',
        buffer_time: 0,
        description: '',
        is_enabled: true,
        organization_id: orgId,
        window_subtype: 'nextday',
        window_type: 'delivery',
        deliver_by: end,
        deliver_from: start
    };
}

export function isOverflowing(element) {
    return element.scrollWidth > element.offsetWidth;
}

export function clearCache() {
    const location = localStorage.getItem('geolocation');
    const acceptCookies = localStorage.getItem('acceptCookies');
    const promoCode = localStorage.getItem('promoCode');
    const promoLink = getCachedPromoLink();
    localStorage.clear();
    if (location) {
        localStorage.setItem('geolocation', location);
    }
    if (acceptCookies) {
        localStorage.setItem('acceptCookies', acceptCookies);
    }
    // if (promoCode) {
    //     localStorage.setItem('promoCode', promoCode);
    // }
    // if (promoLink) {
    //     cachePromoLink(promoLink);
    // }
    localStorage.setItem('ageVerified', true);
    clearHidePromotions();
}

export const isConsentChecked = () => localStorage.getItem('consentChecked') === 'true';

export const currentTime = () => Math.floor(new Date() / 1000);

export const formatCentsPercentageValue = (value, currency, countryCode) => {
    if (value) {
        if (value?.cents) {
            return priceToString(value?.cents, currency, countryCode);
        }
        if (value?.percentage) {
            return `${value?.percentage}%`;
        }
    }
};

export const formatPickupAddress = ({ street, city, state, zip }) =>
    `${street}, ${city}, ${state} ${zip}`;

export const getOperationHourText = (availability, timeZone, t) => {
    const isOpen = isStoreOpen(availability, timeZone);
    const whenClose = whenStoreCloses(availability, getAdjustedTime(timeZone));
    let { day, time, timeClose } = whenStoreOpen(availability, timeZone);
    time = time?.toUpperCase();
    timeClose = timeClose?.toUpperCase();

    return isOpen
        ? whenClose
            ? t('addressModal.openUntil', {
                  time: whenClose.toUpperCase()
              })
            : t('addressModal.open247')
        : isHebrew()
          ? `${t(`footer.${day}`)} \u200e${t('addressModal.openAt2', {
                closingTime: timeClose
            })}\u200e ${t('addressModal.openAt', {
                openTime: time
            })}`
          : `${t('addressModal.openAt', {
                openTime: time,
                closingTime: timeClose
            })} ${t(`footer.${day}`)}`;
};

export const parsePromoParams = url => {
    const searchParams = new URLSearchParams(url);

    return {
        buy_ctgs: searchParams.get('buy_ctgs') || '',
        get_ctgs: searchParams.get('get_ctgs') || '',
        buy_ctgs_not: searchParams.get('buy_ctgs_not') || '',
        get_ctgs_not: searchParams.get('get_ctgs_not') || '',
        buy_skus: searchParams.get('buy_skus') || '',
        get_skus: searchParams.get('get_skus') || '',
        buy_skus_not: searchParams.get('buy_skus_not') || '',
        get_skus_not: searchParams.get('get_skus_not') || '',
        buy_qty: searchParams.get('buy_qty') || '',
        get_qty: searchParams.get('get_qty') || '',
        d_cents: searchParams.get('d_cents') || '',
        d_percentage: searchParams.get('d_percentage') || '',
        d_fixed: searchParams.get('d_fixed') || '',
        d_free: searchParams.get('d_free') || '',
        page_description: searchParams.get('page_description') || ''
    };
};

export const nextOpenTimeText = (day, time, t) => {
    const dayTranslated = t(`footer.${day}`);
    const momentObj = moment(time, 'HH:mm A');
    const hour =
        momentObj.minutes() == 0
            ? `\u200E${momentObj.format('hA')}`
            : `\u200E${momentObj.format('h:mmA')}`;

    return isHebrew()
        ? day === 'Today' || day === 'Tomorrow'
            ? `\u200E${hour}${t('message.at')}\u200E ${dayTranslated}\u200E ${t('message.closed')}`
            : `\u200E${hour}${t('message.at')}\u200E ${dayTranslated}\u200E${t(
                  'message.at'
              )}\u200E ${t('message.closed')}`
        : t('message.closed', { day: dayTranslated, time: hour });
};

export const isWeightProduct = product =>
    !!(product.measure_type === 'Weight' && product.weight_value && product.weight_unit);
