import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Box, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import Icon from './Icon';

export default function SearchQuery(props) {
    const { text, onClear, searchIcon } = props;
    const { theme, themeColors } = useTheme();

    return (
        <Box display="inline-block">
            <Row
                alignItems="center"
                cursor="pointer"
                padding="5px 8px"
                borderRadius={4}
                backgroundColor={themeColors[theme].secondaryBgColor3}
            >
                {searchIcon && (
                    <Box paddingRight={7} fontWeight={500}>
                        <Icon name="search" width={12} height={12} />
                    </Box>
                )}
                <Box
                    color={themeColors[theme].blackFontColor}
                    letterSpacing={1}
                    fontSize={12}
                    fontWeight={500}
                    lineHeight={1.67}
                >
                    {searchIcon && text.length > 12 ? `${text.slice(0, 12)}...` : text}
                </Box>
                <Row
                    paddingLeft={10}
                    paddingBottom={isMobileOnly && 2}
                    props={{ onClick: onClear }}
                >
                    <Icon name="close" width={9} height={9} />
                </Row>
            </Row>
        </Box>
    );
}
