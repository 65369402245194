import { v4 as uuidv4 } from 'uuid';

import {
    Lambda,
    ORDER_CHANNEL,
    ORDER_TYPE,
    THREAD_STATUS,
    ThreadDeliveryModel
} from 'api/constants';
import { checkOrderMeetsRequirements } from 'api/utils';

import invokeLambda from './../api/invokeLambda';

function getCache(key) {
    const data = localStorage.getItem(key);
    if (data) return JSON.parse(data);
    return null;
}

function updateCache(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
}
/**
 * Gets saved data from cache or create initial cache data
 *
 * @param {string} userId - customer userId
 * @param {string} organizationId - organization id
 * @returns cached data
 */
export async function getCacheData(userId, organizationId) {
    let data = getCache(organizationId);
    if (!data) {
        data = getInitialData(userId, organizationId);
        updateCache(organizationId, data);
    }

    return await Promise.resolve(data);
}

/**
 * Gets saved data from cache or create initial cache data
 *
 * @param {string} organizationId - organization id
 * @param {array} zone taxes
 * @param {object} fees
 * @param {boolean} includeTaxes
 * @param {number} defaultTaxType standard or compliance
 * @returns cached data
 */
export async function getCartData(organizationId, fees, includeTaxes, defaultTaxType) {
    const data = getCache(organizationId);
    if (!data) return null;
    const { cart } = data;
    if (cart && cart.items && cart.items.length) {
        const items = cart.items.map(({ product, product_count }) => {
            const { requires_compliance, promotion_price, total_price, wholesale_price_per_unit } =
                product;

            return {
                product_count,
                product: {
                    requires_compliance,
                    total_price,
                    promotion_price,
                    wholesale_price_per_unit
                }
            };
        });

        cart.charges = await getCartTotal(
            items,
            organizationId,
            defaultTaxType,
            fees,
            includeTaxes
        );

        // min order requirements should apply to item cost including tax,
        // including store discount but NOT including promo code discount
        const baseForCheck = cart.charges.subtotal - cart.charges.discount + cart.charges.fees;

        cart.orderMeetsRequirements = checkOrderMeetsRequirements(baseForCheck, fees);
    }

    return Promise.resolve(cart);
}

export async function getCartTotal(
    items,
    organizationId,
    default_tax_type,
    fees,
    catalog_price_includes_tax
) {
    const data = getCache(organizationId);
    if (!data) return null;
    const { zone, thread } = data;

    const payload = {
        items,
        taxes: zone.taxes,
        default_tax_type,
        fees,
        catalog_price_includes_tax,
        associated_hub: thread.associated_hub,
        order_type: thread.order_type
    };

    const paymentDetails = await invokeLambda(Lambda.GetPaymentCalculation, payload);

    return {
        subtotal: paymentDetails.subtotal,
        discount: paymentDetails.applied_discount,
        fees: paymentDetails.total_taxes,
        delivery: paymentDetails.delivery_rate,
        total: paymentDetails.total_charge_in_cents,
        roundup: paymentDetails.round_up_addition
    };
}

/**
 * Updates customer address in cache
 *
 * @param {object} geoData - location geo data
 * @param {object} zone - zone data for location
 * @param {string} organizationId - organization id
 * @returns undefined
 */
export function updateAddress(geoData, zone, organizationId) {
    const data = getCache(organizationId);
    data.address = geoData.address;
    data.location = geoData;
    data.zone = zone;
    data.thread.location = geoData;
    data.thread.zoneId = zone.zone_id;
    data.thread.associated_hub = zone.associated_hub;
    data.thread.order_type = ORDER_TYPE.DELIVERY;
    data.thread.pickup_destination = null;
    updateCache(organizationId, data);
}

/**
 * Updates zone in cache
 * @param {object} zone - zone data for location
 * @param {string} organizationId - organization id
 * @returns undefined
 */
export function updateZone(zone, organizationId) {
    const data = getCache(organizationId);
    data.zone = zone;
    data.thread.zoneId = zone.zone_id;
    updateCache(organizationId, data);
}

/**
 * Clears customer cart
 * @param {string} organizationId - organization id
 * @returns undefined
 */
export function clearCart(organizationId) {
    const data = getCache(organizationId);
    data.cart = {
        items: []
    };
    updateCache(organizationId, data);
}

/**
 * Saves customer phone number as username in cache
 *
 * @param {string} username - customer username
 * @param {string} organizationId - organization id
 * @returns undefined
 */
export function addUsername(username, organizationId) {
    const data = getCache(organizationId);
    data.username = username;
    updateCache(organizationId, data);
}
/**
 * Saves customer phone number as username in cache
 *
 * @param {string} username - customer username
 * @param {string} organizationId - organization id
 * @returns undefined
 */
export function updateThread(organizationId, deliver_by) {
    const data = getCache(organizationId);
    data.thread.deliver_by = deliver_by;
    updateCache(organizationId, data);
}

/**
 * Saves zone data in cache
 *
 * @param {any} zone - location zone data
 * @param {string} organizationId - organization id
 * @returns undefined
 */
export function setZone(zone, organizationId) {
    const data = getCache(organizationId);
    data.zone = zone;
    data.thread.zoneId = zone.zone_id;
    data.thread.associated_hub = zone.associated_hub;
    updateCache(organizationId, data);
}

/**
 * Adds item to cart
 *
 * @param {string} product_id
 * @param {number} quantity
 * @param {string} organization_id
 */
export function addItemToCart(product_id, quantity, organizationId, product) {
    const data = getCache(organizationId);
    const foundItem = data.cart.items.find(x => x.product.product_id === product_id);
    if (foundItem) {
        const number = quantity + foundItem.product_count;
        return setCartItemQuantity(product_id, number, organizationId);
    } else {
        data.cart.items.push({
            item_id: product_id,
            product_count: quantity,
            product
        });
        updateCache(organizationId, data);
        return Promise.resolve(data.cart.items); //todo :need promise?
    }
}

export function removeItemFromCart(product_id, organizationId) {
    const data = getCache(organizationId);

    const updated = data.cart.items.filter(el => el.product.product_id !== product_id);
    data.cart.items = updated;
    updateCache(organizationId, data);
    return Promise.resolve(data.cart.items);
}

export function setCartItemQuantity(productId, number, organizationId) {
    const data = getCache(organizationId);
    const foundIndex = data.cart.items.findIndex(x => x.product.product_id === productId);
    data.cart.items[foundIndex] = {
        ...data.cart.items[foundIndex],
        product_count: number
    };
    updateCache(organizationId, data);
    return Promise.resolve(data.cart.items);
}

/**
 * update delivery instructions
 *
 * @param {string} organizationId
 * @param {object} deliveryInstructions
 * @returns null
 */
export function updateDeliveryOptions(organizationId, deliveryInstructions) {
    const data = getCache(organizationId);
    data.thread.deliveryInstructions = deliveryInstructions;
    updateCache(organizationId, data);
    return Promise.resolve(data.thread);
}

/**
 * creates initial cache data
 *
 * @param {string} userId
 * @param {string} organizationId
 * @returns initial data
 */
const getInitialData = (userId, organizationId) => {
    const uuid = uuidv4();
    const savedLocation = localStorage.getItem('geolocation');
    const { geoLocation, zoneId, associated_hub } = savedLocation ? JSON.parse(savedLocation) : {};
    return {
        authenticated: false,
        id: userId,
        organizationId,
        threadId: uuid,
        thread: {
            id: uuid,
            thread_id: uuid,
            order_channel: ORDER_CHANNEL.SMS,
            location: geoLocation || null,
            zoneId: zoneId || null,
            associated_hub,
            status: THREAD_STATUS.PENDING
        },
        zone: { taxes: [] },
        cart: {
            items: []
        },
        location: geoLocation || null,
        address: geoLocation ? geoLocation.address : null
    };
};

/**
 * Updates selected delivery model
 *
 * @param {string} organization_id
 * @param {string} delivery_model
 * @param {string} window_id
 */
export function updateThreadDeliveryModel(organization_id, delivery_model, delivery_window) {
    const data = getCache(organization_id);
    if (delivery_model === ThreadDeliveryModel.scheduled && delivery_window) {
        data.thread.delivery_window = {
            ...delivery_window
        };
        data.thread.deliver_by = delivery_window.deliver_by;
    } else if (delivery_model === ThreadDeliveryModel.superfast) {
        if (data.thread) {
            data.thread.deliver_by = null;
            delete data.thread.delivery_window;
        }
    }
    updateCache(organization_id, data);

    return Promise.resolve(data);
}

/**
 * Updates selected pickup location
 *
 * @param {string} organization_id
 * @param {string} pickup_destination
 */
export function updateCachePickupLocation(organization_id, pickup_destination) {
    const data = getCache(organization_id);
    if (pickup_destination) {
        data.thread.pickup_destination = {
            ...pickup_destination
        };
        data.thread.associated_hub = pickup_destination.associated_hub;
        data.thread.order_type = ORDER_TYPE.PICKUP;
    } else {
        delete data.thread.pickup_destination;
        data.thread.associated_hub = data.zone?.associated_hub || null;
        data.thread.order_type = ORDER_TYPE.DELIVERY;
    }

    localStorage.setItem('associatedHub', data.thread.associated_hub);

    updateCache(organization_id, data);

    return Promise.resolve(data);
}
