import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { colors } from 'debug/src/browser';
import { Box } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { THEME } from 'api/constants';

export default function Button(props) {
    const {
        onClick,
        small,
        disabled,
        white,
        children,
        big,
        noHover,
        noShadow,
        noHoverColor,
        disableTheme,
        hoverWhite,
        hoverBlack,
        ...rest
    } = props;
    const { theme, themeColors } = useTheme();

    const COLORS = {
        Black: disableTheme
            ? themeColors[theme].blackFontColor
            : themeColors[theme].primaryFontColor1,
        White: disableTheme
            ? themeColors[theme].whitefontColor
            : themeColors[theme].primaryFontColor2,
        Grey: themeColors[theme].secondaryFontColor3,
        LightGrey: themeColors[theme].buttonDisableBg,
        Transparent: ' transparent'
    };

    const split = 'screen and (max-width: 700px)';

    const backgroundColor = disabled
        ? white
            ? COLORS.Transparent
            : COLORS.LightGrey
        : white
          ? COLORS.White
          : COLORS.Black;
    const fontColor = white ? (disabled ? COLORS.Grey : COLORS.Black) : COLORS.White;

    const hoverFontColor = disabled
        ? null
        : hoverBlack || !isMobileOnly
          ? COLORS.Black
          : COLORS.White;

    const hoverBackgroundColor = disabled || noHover ? null : COLORS.Black;
    const borderColor = disabled ? COLORS.LightGrey : COLORS.Black;
    const hoverBorderColor = noHover
        ? COLORS.Transparent
        : disabled
          ? COLORS.LightGrey
          : white && theme === THEME.DARK
            ? COLORS.White
            : COLORS.Black;

    return (
        <Box
            mediaQueries={{ split }}
            component="button"
            cursor={disabled ? 'default' : 'pointer'}
            padding="15px 25px"
            borderRadius={25}
            minWidth={big ? 250 : 170}
            letterSpacing="1px"
            textAlign="center"
            font={small ? '500 12px Heebo' : '700 16px Heebo'}
            boxShadow={!noShadow && `0 4px 18px 0 ${themeColors[theme].boxShadowColor1}`}
            hoverBoxShadow={(noShadow || (!noHover && !disabled)) && 'none'}
            hoverBackgroundColor={!noHover && !disabled && COLORS.Transparent}
            splitHoverBoxShadow={!noShadow && `0 4px 18px 0 ${themeColors[theme].boxShadowColor1}`}
            border={`1px solid ${borderColor}`}
            color={fontColor}
            backgroundColor={backgroundColor}
            focusBackgroundColor={hoverBackgroundColor}
            hoverBorder={`1px solid ${hoverBorderColor}`}
            hoverColor={!noHover && !disabled && !noHoverColor && hoverFontColor}
            splitHoverBorder={`1px solid ${hoverBorderColor}`}
            splitHoverColor={hoverFontColor}
            splitHoverBackgroundColor={hoverBackgroundColor}
            props={{ onClick, disabled }}
            {...rest}
        >
            {children}
        </Box>
    );
}
