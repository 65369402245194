import DeliveryModel from 'constants/DeliveryModel';
import useSWR from 'swr';

import api from '../api';
import useCustomerData from './useCustomerData';
import useStoreSettings from './useStoreSettings';

export default function useAvailableProductsCount(deliveryModel) {
    const { data: mappedData } = useCustomerData();
    const { thread } = mappedData || {};

    const { data: storeSettings } = useStoreSettings();
    const { isOpen: storeOpen, organization_id, default_destination_id } = storeSettings || {};

    const kit_id = thread?.menu_kit || sessionStorage.getItem('menu_kit') || null;

    const hub = thread?.associated_hub || default_destination_id;
    const pickup_destination_id = thread?.pickup_destination?.destination_id;
    const supportsDelivery =
        storeSettings?.supports_scheduled_menu || storeSettings?.supports_ondemand_menu;
    const supportsPickup = storeSettings?.supports_pickup;
    const pickupOnly = !supportsDelivery && supportsPickup;

    const key = () => [
        `available-products-count`,
        thread.zoneId,
        hub,
        storeOpen,
        deliveryModel,
        kit_id,
        pickup_destination_id
    ];

    const fetch = params => {
        const [url, zone_id, associated_hub, storeOpen, deliveryModel, kit_id] = params;
        const isOndemandOrder = deliveryModel === DeliveryModel.OnDemand;

        return (deliveryModel && thread) || pickup_destination_id || pickupOnly
            ? api.getAvailableProductsCount({
                  organization_id,
                  zone_id: thread?.pickup_destination || pickupOnly ? null : zone_id,
                  associated_hub,
                  isOndemandOrder,
                  kit_id,
                  storeOpen
              })
            : null;
    };

    const options = {
        revalidateOnFocus: true, // need remove if want revalidate on page focus
        shouldRetryOnError: true,
        errorRetryInterval: 30000,
        dedupingInterval: 30000,
        focusThrottleInterval: 60000
    };
    const { data, error, mutate, isValidating } = useSWR(key, fetch, options);

    function getOndemandProductsCount(zoneId) {
        return api.getAvailableProductsCount({
            organization_id,
            zone_id: zoneId || thread.zoneId,
            associated_hub: thread.associated_hub,
            isOndemandOrder: true,
            kit_id,
            storeOpen
        });
    }

    function getDeliveryModelProductsCount(isOndemandOrder) {
        return api.getAvailableProductsCount({
            organization_id,
            zone_id: thread.zoneId,
            associated_hub: thread.associated_hub,
            isOndemandOrder: isOndemandOrder,
            kit_id,
            storeOpen
        });
    }

    return {
        data,
        error,
        mutate,
        isValidating,
        getOndemandProductsCount,
        getDeliveryModelProductsCount
    };
}
