import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@patched/hookrouter';
import DeliveryModel from 'constants/DeliveryModel';
import { Box, Inline } from 'jsxstyle';

import useWindows from 'hooks/useWindows';

import {
    cacheDeliveryModel,
    getAnotherDeliveryModel,
    isHebrew,
    isRussian,
    isScheduledOrder,
    isSuperFastOrder,
    supportsBothMenus
} from 'api/utils';

import { useAppContext } from 'store';

import Icon from 'components/Icon';
import ProductAvailabilityModal from 'components/Modals/ProductAvailabilityModal';

export default function NoProductsAvailable({
    supportsOndemandMenu,
    supportsScheduledMenu,
    nextOpenTime,
    storeOpen,
    cart,
    isSubmitted,
    isPickup,
    clearAppliedFilters
}) {
    const { t } = useTranslation();
    const [params] = useQueryParams();
    const { data: windows } = useWindows();

    const { state, appContextActions } = useAppContext();
    const { currentDeliveryModel } = state;

    const [showProductAvailabilityModal, setShowProductAvailabilityModal] = useState(false);
    const searchText = params.query;
    const filtersApplied = params.filters;

    const showScheduledEmpty =
        supportsBothMenus(supportsScheduledMenu, supportsOndemandMenu) &&
        isScheduledOrder(currentDeliveryModel) &&
        !filtersApplied;
    const showOnDemandEmpty =
        supportsBothMenus(supportsScheduledMenu, supportsOndemandMenu) &&
        isSuperFastOrder(currentDeliveryModel) &&
        !filtersApplied;

    const anotherDeliveryModel = getAnotherDeliveryModel(currentDeliveryModel);

    const changeDeliveryModel = () => {
        if (!isSubmitted && cart.items?.length) {
            setShowProductAvailabilityModal(true);
        } else {
            appContextActions.deliveryModel.set(anotherDeliveryModel);
            cacheDeliveryModel(anotherDeliveryModel);
            if (anotherDeliveryModel === DeliveryModel.Scheduled && windows.length > 1) {
                appContextActions.scWindowModal.open();
            }
        }
    };

    const handleConfirmChange = async () => {
        appContextActions.deliveryModel.set(anotherDeliveryModel);
        setShowProductAvailabilityModal(false);
        if (anotherDeliveryModel === DeliveryModel.Scheduled && windows.length > 1) {
            appContextActions.scWindowModal.open();
        }
    };

    const PickupProductsNotAvailable = () => (
        <NotificationWrapper>
            <Box>{t('noProductsAvailable.pickupNotAvailable')}</Box>
            <Box>{t('noProductsAvailable.pickupNotAvailable2')}</Box>
        </NotificationWrapper>
    );

    const FiltersProductsNotAvailable = () => (
        <NotificationWrapper iconName="filtersGrey" color="blue">
            <Box>{t('noProductsAvailable.noResult')}</Box>
            {!supportsOndemandMenu || !supportsScheduledMenu ? (
                <Link clickHandler={clearAppliedFilters}>
                    {t('noProductsAvailable.clearFilters')}
                </Link>
            ) : (
                <Box>
                    <Link clickHandler={clearAppliedFilters}>
                        {t('noProductsAvailable.clearFilters')}
                    </Link>{' '}
                    {t('noProductsAvailable.checkout')}{' '}
                    <Link clickHandler={changeDeliveryModel}>
                        {t('noProductsAvailable.otherMenu')}
                    </Link>
                </Box>
            )}
            {/* <Box>{t('noProductsAvailable.pickupNotAvailable2')}</Box> */}
        </NotificationWrapper>
    );

    const SearchTextAndOneModelAvailable = () => (
        <Box>
            {t('noProductsAvailable.searchText')}
            <br />
            {isHebrew() && t('noProductsAvailable.searchText1')}
        </Box>
    );

    const SearchTextAndBothModelsAvailable = () => (
        <Box>
            {isHebrew() ? (
                <Box>
                    {t('noProductsAvailable.searchText2')}{' '}
                    <Italic>
                        {t('noProductsAvailable.at')}
                        {t('noProductsAvailable.menu')}{' '}
                        {currentDeliveryModel === 'Scheduled'
                            ? t('clearCartModal.scheduled')
                            : t('clearCartModal.superFast')}
                    </Italic>
                    .{t('noProductsAvailable.searchText3')}{' '}
                    <Link clickHandler={changeDeliveryModel}>
                        {t('noProductsAvailable.menu')}{' '}
                        {anotherDeliveryModel === 'Scheduled'
                            ? t('clearCartModal.scheduled')
                            : t('clearCartModal.superFast')}
                    </Link>{' '}
                    {t('noProductsAvailable.searchText4')}
                </Box>
            ) : (
                <Box>
                    {t('noProductsAvailable.searchText2')}{' '}
                    <Italic>
                        {currentDeliveryModel === 'Scheduled'
                            ? t('clearCartModal.scheduled')
                            : t('clearCartModal.superFast')}
                    </Italic>{' '}
                    {t('noProductsAvailable.searchText3')}{' '}
                    <Link clickHandler={changeDeliveryModel}>
                        {anotherDeliveryModel === 'Scheduled'
                            ? t('clearCartModal.scheduled')
                            : t('clearCartModal.superFast')}{' '}
                        {t('noProductsAvailable.menu')}
                    </Link>{' '}
                    {t('noProductsAvailable.searchText4')}
                </Box>
            )}
        </Box>
    );

    const NoSearchSfOnly = () => (
        <NotificationWrapper iconName="sfLarge">
            <Box marginLeft={20}>{t('noProductsAvailable.sfNotAvailable')}</Box>
        </NotificationWrapper>
    );
    const NoSearchScOnly = () => (
        <NotificationWrapper>
            <Box marginLeft={20}>
                {t('noProductsAvailable.noProducts')}
                {!isRussian && <br />}
                {t('noProductsAvailable.comeBack')}
            </Box>
        </NotificationWrapper>
    );

    return (
        <Box padding="40px 30px">
            {searchText && !isPickup && (
                <NotificationWrapper padding="40px 30px">
                    {(!supportsOndemandMenu || !supportsScheduledMenu) && (
                        <SearchTextAndOneModelAvailable />
                    )}
                    {supportsOndemandMenu && supportsScheduledMenu && (
                        <SearchTextAndBothModelsAvailable />
                    )}
                </NotificationWrapper>
            )}

            {!searchText &&
                !isPickup &&
                !filtersApplied &&
                supportsScheduledMenu &&
                !supportsOndemandMenu && <NoSearchScOnly />}

            {!searchText &&
                !isPickup &&
                !filtersApplied &&
                supportsOndemandMenu &&
                !supportsScheduledMenu && <NoSearchSfOnly />}

            {!searchText && isPickup && <PickupProductsNotAvailable />}
            {searchText && isPickup && <PickupProductsNotAvailable />}

            {filtersApplied && !searchText && <FiltersProductsNotAvailable />}

            {!searchText && !isPickup && (
                <Box>
                    {showScheduledEmpty && (
                        <Box>
                            {isHebrew() ? (
                                <NotificationWrapper iconName="scLarge">
                                    .{t('noProductsAvailable.noItems')}
                                    <br />!{t('noProductsAvailable.noItems2')}{' '}
                                    <Link clickHandler={changeDeliveryModel}>
                                        {t('noProductsAvailable.menu')}{' '}
                                        {anotherDeliveryModel === 'Scheduled'
                                            ? t('clearCartModal.scheduled')
                                            : t('clearCartModal.superFast')}
                                    </Link>
                                </NotificationWrapper>
                            ) : (
                                <NotificationWrapper iconName="scLarge">
                                    {t('noProductsAvailable.noItems')} <br />
                                    {t('noProductsAvailable.noItems2')}{' '}
                                    <Link clickHandler={changeDeliveryModel}>
                                        {anotherDeliveryModel === 'Scheduled'
                                            ? t('clearCartModal.scheduled')
                                            : t('clearCartModal.superFast')}{' '}
                                        {t('noProductsAvailable.menu')}
                                    </Link>
                                    !
                                </NotificationWrapper>
                            )}
                        </Box>
                    )}
                    {showOnDemandEmpty && storeOpen ? (
                        <Box>
                            {isHebrew() ? (
                                window.innerWidth > 362 ? (
                                    <NotificationWrapper iconName="sfLarge">
                                        <Italic>
                                            {t('noProductsAvailable.menu')}{' '}
                                            {currentDeliveryModel === 'Scheduled'
                                                ? t('clearCartModal.scheduled')
                                                : t('clearCartModal.superFast')}
                                        </Italic>{' '}
                                        {t('noProductsAvailable.noDelivery')}{' '}
                                        <Box>
                                            <Link clickHandler={changeDeliveryModel}>
                                                {t('noProductsAvailable.menu')}{' '}
                                                {anotherDeliveryModel === 'Scheduled'
                                                    ? t('clearCartModal.scheduled')
                                                    : t('clearCartModal.superFast')}
                                            </Link>
                                        </Box>
                                    </NotificationWrapper>
                                ) : (
                                    <NotificationWrapper iconName="sfLarge">
                                        <Italic>
                                            {t('noProductsAvailable.menu')}{' '}
                                            {currentDeliveryModel === 'Scheduled'
                                                ? t('clearCartModal.scheduled')
                                                : t('clearCartModal.superFast')}
                                        </Italic>{' '}
                                        {t('noProductsAvailable.noDelivery')}{' '}
                                        <Link clickHandler={changeDeliveryModel}>
                                            {t('noProductsAvailable.menu')}{' '}
                                            {anotherDeliveryModel === 'Scheduled'
                                                ? t('clearCartModal.scheduled')
                                                : t('clearCartModal.superFast')}
                                        </Link>{' '}
                                    </NotificationWrapper>
                                )
                            ) : (
                                <NotificationWrapper iconName="sfLarge">
                                    <Italic>
                                        {currentDeliveryModel === 'Scheduled'
                                            ? t('clearCartModal.scheduled')
                                            : t('clearCartModal.superFast')}
                                    </Italic>{' '}
                                    {t('noProductsAvailable.noDelivery')}{' '}
                                    <Link clickHandler={changeDeliveryModel}>
                                        {anotherDeliveryModel === 'Scheduled'
                                            ? t('clearCartModal.scheduled')
                                            : t('clearCartModal.superFast')}{' '}
                                        {t('noProductsAvailable.menu')}
                                    </Link>
                                </NotificationWrapper>
                            )}
                        </Box>
                    ) : showOnDemandEmpty && !storeOpen ? (
                        <Box>
                            {isHebrew() ? (
                                <NotificationWrapper iconName="sfLarge">
                                    {`\u200E${nextOpenTime.time.toUpperCase()}` || ''}
                                    {(nextOpenTime.day === 'Today' ||
                                        nextOpenTime.day === 'Tomorrow') &&
                                        t('noProductsAvailable.at')}{' '}
                                    {`\u200E`}
                                    {t(`footer.${nextOpenTime.day}`) || ''}
                                    {`\u200E`}
                                    {!(
                                        nextOpenTime.day === 'Today' ||
                                        nextOpenTime.day === 'Tomorrow'
                                    ) && t('noProductsAvailable.at')}{' '}
                                    {`\u200E`}
                                    {t('noProductsAvailable.deliveryWillBe')} {`\u200E`}
                                    <Italic>
                                        {currentDeliveryModel === 'Scheduled'
                                            ? t('clearCartModal.scheduled')
                                            : t('clearCartModal.superFast')}
                                        {`\u200E`} {t('noProductsAvailable.menu')}
                                    </Italic>{' '}
                                    <Box>
                                        <Link clickHandler={changeDeliveryModel}>
                                            {t('noProductsAvailable.menu')}{' '}
                                            {anotherDeliveryModel === 'Scheduled'
                                                ? t('clearCartModal.scheduled')
                                                : t('clearCartModal.superFast')}
                                        </Link>{' '}
                                        {`\u200E`}
                                        {t('noProductsAvailable.deliveryWillBe2')} {`\u200E`}
                                    </Box>
                                </NotificationWrapper>
                            ) : (
                                <NotificationWrapper iconName="sfLarge">
                                    <Italic>
                                        {currentDeliveryModel === 'Scheduled'
                                            ? t('clearCartModal.scheduled')
                                            : t('clearCartModal.superFast')}
                                    </Italic>{' '}
                                    {t('noProductsAvailable.deliveryWillBe')}{' '}
                                    {nextOpenTime.time.toUpperCase() || ''}{' '}
                                    {t(`footer.${nextOpenTime.day}`) || ''}
                                    {t('noProductsAvailable.deliveryWillBe2')}
                                    {'  '}
                                    <Link clickHandler={changeDeliveryModel}>
                                        {anotherDeliveryModel === 'Scheduled'
                                            ? t('clearCartModal.scheduled')
                                            : t('clearCartModal.superFast')}{' '}
                                        {t('noProductsAvailable.menu')}
                                    </Link>
                                </NotificationWrapper>
                            )}
                        </Box>
                    ) : null}
                </Box>
            )}

            <ProductAvailabilityModal
                showModal={showProductAvailabilityModal}
                onClose={() => {
                    setShowProductAvailabilityModal(false);
                }}
                onConfirm={handleConfirmChange}
                onCancel={() => {
                    setShowProductAvailabilityModal(false);
                }}
            />
        </Box>
    );
}

const Italic = ({ children }) => (
    <Inline fontStyle="italic" fontWeight={900}>
        {children}
    </Inline>
);

const Link = ({ clickHandler, children }) => (
    <Inline
        cursor="pointer"
        textDecoration="underline"
        props={{
            onClick: () => {
                clickHandler();
            }
        }}
    >
        {children}
    </Inline>
);

const NotificationWrapper = ({ iconName, children, rest }) => (
    <Box maxWidth={362} width="100%" margin="0 auto" textAlign="center" {...rest}>
        {iconName && <Icon name={iconName} />}
        <Box marginTop={20} paddingTop={3} fontSize={14} fontWeight={500} opacity={0.7}>
            {children}
        </Box>
    </Box>
);
