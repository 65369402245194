import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinesEllipsis from 'react-lines-ellipsis';
import { navigate, usePath } from '@patched/hookrouter';
import { Box, Col, Row } from 'jsxstyle';
import moment from 'moment/min/moment-with-locales';

import useCart from 'hooks/useCart';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';
import useThread from 'hooks/useThread';

import { isHebrew, isThreadCancelled } from 'api/utils';

import { useAppContext } from 'store';

import placeholder from 'assets/images/place-holder.svg';

import Button from 'components/Button';
import CartTotal from 'components/CartTotal';
import CloseBar from 'components/CloseBar';
import CreditCardBody from 'components/CreditCardBody';
import Heading from 'components/Heading';
import Head from 'components/HeadManager';
import Icon from 'components/Icon';
import Image from 'components/Image';
import Spinner from 'components/Spinner';
import Wrapper from 'components/Wrapper';

import { SUPPORTED_TRANSACTION_TYPE, THEME, THREAD_STATUS } from '../api/constants';
import { isWeightProduct } from '../api/utils';
import useCustomerData from '../hooks/useCustomerData';

export default function Order({ id, pageTitle }) {
    const { theme, themeColors } = useTheme();
    const { data: storeSettings } = useStoreSettings();
    const path = usePath();

    const { data: mappedData, store } = useCustomerData();
    const { user, cart: currentCart } = mappedData || {};

    const { data: thread } = useThread(id);
    const { data: cart } = useCart(id, false);

    const [updatingCart, setUpdatingCart] = useState(false);
    const { state } = useAppContext();
    const { t } = useTranslation();

    if (user && !user.authenticated) {
        navigate('/login');
    }

    const pickupLocation = thread?.pickup_destination;

    const getHeadline = () => {
        const locale = localStorage.getItem('language').slice(0, 2);
        if (!thread) {
            return '';
        }
        return `${
            thread.status === THREAD_STATUS.CANCELED
                ? t('orders.cancelled')
                : pickupLocation
                  ? t('orders.pickedup')
                  : t('orders.delivered')
        } ${moment.unix(thread.closedTime).locale(locale).format('MMM Do')}`;
    };

    const getPaymentNote = () => {
        if (!thread) {
            return '';
        }

        if (thread.paymentDetails.paymentTransactionType === SUPPORTED_TRANSACTION_TYPE.CASH) {
            return (
                <Box>
                    <Icon
                        name={theme === THEME.DARK ? 'cashWhite' : 'cash'}
                        marginRight={5}
                        verticalAlign="middle"
                    />
                    {t('orders.cash')}
                </Box>
            );
        }
        if (thread.paymentDetails.paymentTransactionType === SUPPORTED_TRANSACTION_TYPE.CREDIT) {
            return (
                <Row>
                    <Box marginRight={10}> {t('orders.payWith')}</Box>
                    <CreditCardBody
                        last4={thread.paymentDetails.paymentCardLast4}
                        brand={thread.paymentDetails.paymentCardBrand}
                    />
                </Row>
            );
        }
    };

    const isLoading = !thread || !cart;

    const clearCart = () => {
        if (currentCart?.items?.length) {
            const promises = currentCart.items.map(({ item_id }) => store.removeItem(item_id));
            return Promise.all(promises);
        }
    };

    const buyAgain = async () => {
        setUpdatingCart(true);
        await clearCart();
        const promises = cart.items.map(({ product, product_count }) =>
            store.addItem(product.product_id, product_count, product)
        );
        await Promise.all(promises);
        navigate('/cart');
    };

    const getPromocodePrices = promocodePriceString => {
        let priceString = '';

        const isFree = promocodePriceString.includes('Free');

        if (isHebrew()) {
            if (isFree) {
                priceString = promocodePriceString.replace(
                    'for Free',
                    `\u200e${t('cart.item.forFree')}`
                );
            } else if (promocodePriceString.includes('for')) {
                priceString = promocodePriceString.replace('for ', `${t('cart.item.for')}`);
            } else if (promocodePriceString.includes('Free')) {
                priceString = promocodePriceString.replace('Free', `\u200e${t('cart.item.free')}`);
            } else {
                priceString = promocodePriceString;
            }
        } else {
            priceString = promocodePriceString;
        }
        return <Box key={promocodePriceString}>{priceString}</Box>;
    };

    const isCancelled = isThreadCancelled(thread);

    const sm = 'screen and (max-width: 754px)';

    return (
        <Wrapper white narrow>
            <CloseBar
                closeIconName={theme === THEME.DARK ? 'backWhite' : 'back'}
                onClose={() => navigate('/orders')}
            ></CloseBar>
            <Head pageTitle={pageTitle} />
            {isLoading ? (
                <Row alignItems="center" marginTop={30}>
                    <Spinner marginRight={25} /> {t('orders.fetchingOrder')}
                </Row>
            ) : (
                <>
                    <Box
                        marginTop={30}
                        color={themeColors[theme].secondaryFontColor23}
                        fontSize={12}
                    >
                        {isHebrew()
                            ? `\u200E#${thread.trackingNumber} ${t('submitSuccess.orderNum')}`
                            : `${t('submitSuccess.orderNum')}${thread.trackingNumber}`}
                    </Box>
                    <Heading marginBottom={0}>{getHeadline()}</Heading>
                    <Box
                        color={themeColors[theme].secondaryFontColor2}
                        fontWeight={500}
                        fontSize={14}
                    >
                        {t('orders.at')} {moment.unix(thread.closedTime).format('LT')} •{' '}
                        {pickupLocation
                            ? pickupLocation.name
                            : thread.deliveryType
                              ? t(`deliveryType.${thread.deliveryType}`)
                              : ''}
                        <Box>
                            {pickupLocation ? pickupLocation.location.address : thread.address}
                        </Box>
                    </Box>
                    <Row flexWrap="wrap" justifyContent="space-between">
                        <Box
                            flex={1}
                            maxWidth={355}
                            minWidth={355}
                            paddingTop={80}
                            marginRight={50}
                            mediaQueries={{ sm }}
                            smMinWidth="100%"
                            smMaxWidth="100%"
                        >
                            <Row
                                justifyContent="space-between"
                                alignItems="center"
                                marginBottom={35}
                            >
                                <Box
                                    color={themeColors[theme].primaryFontColor1}
                                    fontWeight={500}
                                    fontSize={18}
                                >
                                    {t('account.yourOrder')}
                                </Box>
                                <Button
                                    fontSize={12}
                                    padding="9px 30px"
                                    minWidth={130}
                                    onClick={buyAgain}
                                    disabled={updatingCart}
                                >
                                    {updatingCart ? t('orders.updatingCart') : t('orders.buyAgain')}
                                </Button>
                            </Row>

                            <Col>
                                {cart.items.map((item, index) => {
                                    const { product, product_count, item_id, total_item_weight } =
                                        item;

                                    if (!cart?.order_display_prices) return null;

                                    const itemDisplayPrices = cart.order_display_prices.find(
                                        i => i.item_id === item_id
                                    )?.display_prices;
                                    console.log('prices', itemDisplayPrices);

                                    const { image_url, name, sku, weight_value, weight_unit } =
                                        product;

                                    const showWeight = isWeightProduct(product);

                                    const onClick = () => {
                                        sessionStorage.setItem(
                                            'previousPage',
                                            JSON.stringify({ path })
                                        );
                                        navigate(`/p/${sku}`);
                                    };

                                    return (
                                        <Row
                                            alignItems="center"
                                            marginBottom={10}
                                            cursor="pointer"
                                            padding={10}
                                            borderRadius={10}
                                            background={themeColors[theme].whiteBgColor2}
                                            key={index}
                                            props={{ onClick }}
                                        >
                                            <Image
                                                src={image_url ? image_url : placeholder}
                                                alt={name}
                                                marginRight={20}
                                                width={50}
                                                height={50}
                                                borderRadius={8}
                                                objectFit="cover"
                                            ></Image>
                                            <Row
                                                justifyContent="space-between"
                                                width="100%"
                                                color={themeColors[theme].secondaryFontColor19}
                                                fontSize={14}
                                                fontWeight={700}
                                                lineHeight="20px"
                                            >
                                                <Box
                                                    width="150px"
                                                    flex={2}
                                                    color={themeColors[theme].primaryFontColor1}
                                                    marginBottom={3}
                                                >
                                                    <LinesEllipsis
                                                        text={name}
                                                        maxLine={1}
                                                        ellipsis="..."
                                                        trimRight
                                                        basedOn="letters"
                                                    />
                                                </Box>
                                                <Box textAlign="right" minWidth="110px">
                                                    {/* <Box color={themeColors[theme].primaryFontColor1}>
                                    {priceToString(subtotal, state.currency)}
                                </Box> */}

                                                    <Box>
                                                        {/* Red price crossed through */}
                                                        {/* {itemHasPromotionDiscount ? (
                                        <Box
                                            color={themeColors[theme].quaternaryFontColor4}
                                            textDecoration="line-through"
                                            marginBottom={2}
                                        >
                                            {
                                                itemDisplayPrices.original_prices
                                                    ?.price_string_per_unit
                                            }
                                        </Box>
                                    ) : null} */}
                                                        {/* End Red price crossed through */}

                                                        {itemDisplayPrices.promocode_prices ? (
                                                            // Promocode price for DISCOUNTED_PRODUCT or BUY_1_GET_1 or BUY_1_GET_SAME promocode discount type
                                                            <Box
                                                                color={
                                                                    itemDisplayPrices.regular_prices
                                                                        ? null
                                                                        : themeColors[theme]
                                                                              .primaryFontColor1
                                                                }
                                                            >
                                                                {/* promocode price  */}
                                                                {getPromocodePrices(
                                                                    itemDisplayPrices
                                                                        .promocode_prices
                                                                        .price_string
                                                                )}
                                                                <Box
                                                                    color={
                                                                        themeColors[theme]
                                                                            .primaryFontColor1
                                                                    }
                                                                >
                                                                    {/* regular price, if not all qty discounted */}
                                                                    {itemDisplayPrices.regular_prices
                                                                        ? getPromocodePrices(
                                                                              itemDisplayPrices
                                                                                  .regular_prices
                                                                                  .price_string_per_unit
                                                                          )
                                                                        : null}
                                                                </Box>
                                                            </Box>
                                                        ) : // end Promocode price for DISCOUNTED_PRODUCT or BUY_1_GET_1 or BUY_1_GET_SAME promocode discount type
                                                        itemDisplayPrices.regular_prices ? (
                                                            <Box
                                                                color={
                                                                    themeColors[theme]
                                                                        .primaryFontColor1
                                                                }
                                                            >
                                                                {
                                                                    itemDisplayPrices.regular_prices
                                                                        ?.price_string_per_unit
                                                                }
                                                                {showWeight
                                                                    ? `/${weight_value}${weight_unit}`
                                                                    : ''}
                                                            </Box>
                                                        ) : null}
                                                        {/* {showWeight
                                        ? `${priceToString(price, state.currency)}/${
                                              weight_value > 1 ? weight_value : ''
                                          }${weight_unit}`
                                        : `${t('cart.item.quantity')}: ${product_count}`} */}
                                                        <Box marginBottom={2}>
                                                            {showWeight
                                                                ? total_item_weight + weight_unit
                                                                : 'Qty: ' + product_count}{' '}
                                                            {product_count > 1
                                                                ? `/ ${itemDisplayPrices.total}`
                                                                : ''}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Row>
                                        </Row>
                                    );
                                })}
                            </Col>
                        </Box>
                        <Box
                            mediaQueries={{ sm }}
                            flex={1}
                            maxWidth={320}
                            smMaxWidth="100%"
                            minWidth={320}
                            smMinWidth="100%"
                            borderTop={`1px solid ${themeColors[theme].secondaryBorderColor2}`}
                            sBorderTop="none"
                        >
                            <CartTotal
                                charges={thread.charges}
                                promoCodeId={thread.promoCode}
                                cancelled={isCancelled}
                                promoCode={cart.promo_code}
                                orderType={thread.order_type}
                                roundUpEnabled={storeSettings?.fees?.round_up_enabled}
                                roundUpReason={storeSettings?.fees?.round_up_reason}
                                noBorder
                            />
                            <Box
                                paddingBottom={30}
                                paddingTop={15}
                                fontWeight={700}
                                fontSize={16}
                                borderBottom={`1px solid ${themeColors[theme].secondaryBorderColor2}`}
                            >
                                {getPaymentNote()}
                            </Box>
                            <Box
                                padding="35px 0 40px"
                                color={themeColors[theme].secondaryFontColor2}
                                fontWeight={600}
                                fontSize={14}
                            >
                                {thread.note === 'No special notes'
                                    ? t('orderStatus.noNotes')
                                    : thread.note || t('orders.specialNote')}
                            </Box>
                            {!isCancelled && (
                                <Box
                                    borderTop={`1px solid ${themeColors[theme].secondaryBorderColor2}`}
                                    paddingTop={20}
                                    fontSize={12}
                                    color={themeColors[theme].secondaryFontColor2}
                                    fontWeight={500}
                                    letterSpacing={0}
                                >
                                    {t('orders.receiptTxt')} {thread.members[0].settings.email}.
                                </Box>
                            )}
                        </Box>
                    </Row>
                </>
            )}
        </Wrapper>
    );
}
