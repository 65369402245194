import { getCacheData } from '../utils/cacheDataManager';
import { CHANNEL_TYPE, Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function authenticateCustomer(
    code,
    organizationId,
    order_type,
    pickup_destination
) {
    const { username, location, cart, thread } = await getCacheData(null, organizationId);

    const menu_kit = sessionStorage.getItem('menu_kit') || null;

    const payload = {
        username: username,
        organization_id: organizationId,
        channelType: CHANNEL_TYPE.WEB,
        location: location,
        cart: cart.items,
        geoData: location,
        code,
        delivery_instructions: thread.deliveryInstructions,
        zone_id: thread.zoneId,
        menu_kit,
        window: thread.delivery_window,
        order_type,
        pickup_destination
    };

    return invokeLambda(Lambda.findOrCreateCustomer, payload);
}
