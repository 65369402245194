import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, Col, Row } from 'jsxstyle';

import useCustomerData from 'hooks/useCustomerData';
import useTheme from 'hooks/useTheme';

import attachPaymentMethod from 'api/attachPaymentMethod';
import removePaymentMethod from 'api/removePaymentMethod';

import deleteButton from 'assets/images/trash-icon.svg';

import Button from 'components/Button';
import CreditCardBody from 'components/CreditCardBody';
import Image from 'components/Image';
import { Subtitle } from 'components/shared';
import Spinner from 'components/Spinner';

const PaymentCredit = forwardRef((props, ref) => {
    const { theme, themeColors } = useTheme();
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();

    const { data: mappedData } = useCustomerData();
    const { user } = mappedData || {};

    const [showDeletePaymentMethod, setShowDeletePaymentMethod] = useState(false);
    const [deletingPaymentMethod, setDeletingPaymentMethod] = useState(false);

    const {
        paymentMethod,
        paymentMethodDeleted,
        cardNumberChanged,
        onFocus,
        onBlur,
        creditPaymentOnFocus,
        canAddPaymentMethod
    } = props;

    useImperativeHandle(ref, () => ({
        async savePaymentMethod() {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            const cardElement = elements.getElement(CardElement);

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement
            });

            if (error) {
                return;
            }

            // extract the payment method id
            const { id } = paymentMethod;

            // call the backend api to attach this payment
            // method to the current user
            const data = await attachPaymentMethod(id, user.organizationId);

            setShowDeletePaymentMethod(false);

            return data;
        }
    }));

    const cardElementOptions = {
        hidePostalCode: true,
        style: {
            base: {
                lineHeight: '40px',
                fontSize: '16px'
            }
        }
    };

    return (
        <Box maxWidth={428}>
            {paymentMethod ? (
                <Col>
                    <Row
                        marginTop={10}
                        backgroundColor="rgba(216,216,216,0.4)"
                        height={52}
                        borderRadius={8}
                        paddingLeft={10}
                        paddingRight={10}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <CreditCardBody brand={paymentMethod.brand} last4={paymentMethod.last4} />
                        <Image
                            src={deleteButton}
                            width={16}
                            height={16}
                            cursor="pointer"
                            onClick={() => {
                                setShowDeletePaymentMethod(true);
                            }}
                        />
                    </Row>
                    {showDeletePaymentMethod && (
                        <Col marginTop={22}>
                            {!deletingPaymentMethod && (
                                <Subtitle fontWeight={700} marginBottom={16}>
                                    {t('buyNowModal.paymentWithCredit.removeCard')}
                                </Subtitle>
                            )}
                            {deletingPaymentMethod ? (
                                <Row marginTop={16} width="100%" justifyContent="center">
                                    <Spinner />
                                </Row>
                            ) : (
                                <Row justifyContent="flex-end">
                                    <Button
                                        backgroundColor={themeColors[theme].secondaryBgColor3}
                                        color={themeColors[theme].primaryFontColor1}
                                        border={`1px solid ${themeColors[theme].secondaryBorderColor2}`}
                                        width={125}
                                        minWidth={125}
                                        onClick={async () => {
                                            const { id } = paymentMethod;
                                            const { organizationId } = user;
                                            try {
                                                setDeletingPaymentMethod(true);
                                                await removePaymentMethod(id, organizationId);
                                                if (paymentMethodDeleted) {
                                                    paymentMethodDeleted(id);
                                                }
                                            } finally {
                                                setDeletingPaymentMethod(false);
                                            }
                                        }}
                                    >
                                        {t('buyNowModal.paymentWithCredit.yes')}
                                    </Button>
                                    <Button
                                        width={125}
                                        minWidth={125}
                                        marginLeft={15}
                                        onClick={() => {
                                            setShowDeletePaymentMethod(false);
                                        }}
                                    >
                                        {t('buyNowModal.paymentWithCredit.notNow')}
                                    </Button>
                                </Row>
                            )}
                        </Col>
                    )}
                </Col>
            ) : (
                <Box
                    marginTop={10}
                    backgroundColor={
                        canAddPaymentMethod
                            ? themeColors[theme].tertiaryBgColor
                            : themeColors[theme].secondaryBgColor6
                    }
                    height={40}
                    paddingLeft={5}
                    borderRadius={8}
                    paddingRight={5}
                    border={
                        creditPaymentOnFocus &&
                        `2px solid ${themeColors[theme].tertiaryBorderColor}`
                    }
                >
                    <CardElement
                        options={cardElementOptions}
                        onChange={cardNumberChanged}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Box>
            )}
        </Box>
    );
});

export default PaymentCredit;
