export const actionTypes = {
    SET_CATALOG_SCROLL_Y_OFFSET: 'SET_CATALOG_SCROLL_Y_OFFSET',
    SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
    SET_CURRENCY: 'SET_CURRENCY',
    SET_BOT_NUMBER: 'SET_BOT_NUMBER',
    SET_COUNTRY_CODE: 'SET_COUNTRY_CODE',
    SET_STORE_DOMAIN: 'SET_STORE_DOMAIN',
    SET_CURRENT_DELIVERY_MODEL: 'SET_CURRENT_DELIVERY_MODEL',
    SET_STORE_DESCRIPTION: 'SET_STORE_DESCRIPTION',
    SET_SHOW_SC_WINDOW_MODAL: 'SET_SHOW_SC_WINDOW_MODAL',
    SET_SHOW_DELIVERY_ADDRESS_MODAL: 'SET_SHOW_DELIVERY_ADDRESS_MODAL',
    SET_SHOW_AGE_VERIFICATION_MODAL: 'SET_SHOW_AGE_VERIFICATION_MODAL',
    SET_SHOW_DELIVERY_TYPE_MODAL: 'SET_SHOW_DELIVERY_TYPE_MODAL',
    SET_LOADING_SHOW_DELIVERY_TYPE_MODAL: 'SET_LOADING_SHOW_DELIVERY_TYPE_MODAL',
    SET_PENDING_ACTION: 'SET_PENDING_ACTION'
};

// Define a function that returns an object with context actions
export const contextActions = dispatch => {
    return {
        pageYOffset: {
            setOffset: offset => {
                dispatch({
                    type: actionTypes.SET_CATALOG_SCROLL_Y_OFFSET,
                    catalogScrollYOffset: offset
                });
            },
            reset: () => {
                dispatch({
                    type: actionTypes.SET_CATALOG_SCROLL_Y_OFFSET,
                    catalogScrollYOffset: 0
                });
            }
        },

        paymentMethod: {
            set: paymentMethod => {
                dispatch({ type: actionTypes.SET_PAYMENT_METHOD, paymentMethod });
            }
        },

        currency: {
            set: currency => {
                dispatch({ type: actionTypes.SET_CURRENCY, currency });
            }
        },

        botNumber: {
            set: botNumber => {
                dispatch({ type: actionTypes.SET_BOT_NUMBER, botNumber });
            }
        },

        countryCode: {
            set: countryCode => {
                dispatch({ type: actionTypes.SET_COUNTRY_CODE, countryCode });
            }
        },

        storeDomain: {
            set: storeDomain => {
                dispatch({ type: actionTypes.SET_STORE_DOMAIN, storeDomain });
            }
        },

        deliveryModel: {
            set: currentDeliveryModel => {
                dispatch({
                    type: actionTypes.SET_CURRENT_DELIVERY_MODEL,
                    model: currentDeliveryModel
                });
            }
        },

        storeDescription: {
            set: storeDescription => {
                dispatch({ type: actionTypes.SET_STORE_DESCRIPTION, storeDescription });
            }
        },

        scWindowModal: {
            open: () => {
                dispatch({ type: actionTypes.SET_SHOW_SC_WINDOW_MODAL, show: true });
            },
            close: () => {
                dispatch({ type: actionTypes.SET_SHOW_SC_WINDOW_MODAL, show: false });
            }
        },

        deliveryAddressModal: {
            open: () => {
                dispatch({ type: actionTypes.SET_SHOW_DELIVERY_ADDRESS_MODAL, show: true });
            },
            close: () => {
                dispatch({ type: actionTypes.SET_SHOW_DELIVERY_ADDRESS_MODAL, show: false });
            }
        },

        ageVerificationModal: {
            open: () => {
                dispatch({ type: actionTypes.SET_SHOW_AGE_VERIFICATION_MODAL, show: true });
            },
            close: () => {
                dispatch({ type: actionTypes.SET_SHOW_AGE_VERIFICATION_MODAL, show: false });
            }
        },

        deliveryTypeModal: {
            open: () => {
                dispatch({ type: actionTypes.SET_SHOW_DELIVERY_TYPE_MODAL, show: true });
            },
            close: () => {
                dispatch({ type: actionTypes.SET_SHOW_DELIVERY_TYPE_MODAL, show: false });
            },
            setLoading: loading => {
                dispatch({ type: actionTypes.SET_LOADING_SHOW_DELIVERY_TYPE_MODAL, loading });
            }
        },

        pendingAction: {
            set: pendingAction => {
                dispatch({ type: actionTypes.SET_PENDING_ACTION, pendingAction });
            },
            remove: () => {
                dispatch({ type: actionTypes.SET_PENDING_ACTION, pendingAction: null });
            }
        }
    };
};

export const setLoadingDeliveryTypePopup = (store, loadingDeliveryTypePopup) => {
    store.setState({ loadingDeliveryTypePopup });
};

export const setStoreDescription = (store, storeDescription) => {
    store.setState({ storeDescription });
};

export const setShowScWindowModal = (store, showScWindowModal) => {
    store.setState({ showScWindowModal });
};

export const setShowDeliveryAddressModal = (store, showDeliveryAddressModal) => {
    store.setState({ showDeliveryAddressModal });
};

export const setShowAgeVerificationModal = (store, showAgeVerificationModal) => {
    store.setState({ showAgeVerificationModal });
};

export const setShowDeliveryTypeModal = (store, showDeliveryTypeModal) => {
    store.setState({ showDeliveryTypeModal });
};

export const setPendingAction = (store, pendingAction) => {
    store.setState({ pendingAction });
};
