import React from 'react';
import { Row } from 'jsxstyle';

import PaymentCardIcon from 'components/PaymentCardIcon';
import Caption from 'components/shared/Caption';

export default function CreditCardBody({ last4, brand, ...rest }) {
    return (
        <Row opacity={1.0} {...rest}>
            <PaymentCardIcon width={27} height={18} brand={brand} />
            <Caption fontWeight={900} marginLeft={5}>{`•••• ${last4}`}</Caption>
        </Row>
    );
}
