import AWS from 'aws-sdk';

import { clearCache } from 'api/utils';

import log from '../utils/log';
import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function closeSession() {
    const {
        access_token,
        organization_id,
        handoff_id,
        user_cognito_id: user_id
    } = JSON.parse(localStorage.getItem('handoff'));
    localStorage.removeItem('handoff');
    localStorage.removeItem(organization_id);
    const idProvider = new AWS.CognitoIdentityServiceProvider();

    try {
        const payload = { handoff_id, user_id, organization_id };
        await invokeLambda(Lambda.DeleteHandoff, payload);
        await idProvider
            .globalSignOut({
                AccessToken: access_token
            })
            .promise();
        console.log('logged out');
    } catch (e) {
        console.log('Error: ', e);
        clearCache();
        window.location.reload();
    }
}
