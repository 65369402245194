import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { navigate } from '@patched/hookrouter';
import { Box, Inline } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import { isHebrew } from 'api/utils';

import { THEME } from '../api/constants';
import bg from '../assets/images/background-page-not-found.svg';
import Image from '../components/Image';
import Navbar from '../components/Navbar';
import Wrapper from '../components/Wrapper';

export default function NotFound({ pageTitle }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();

    return (
        <Wrapper>
            <Navbar pageTitle={pageTitle} />
            <Box display="flex" justifyContent="center" alignItems="center">
                <Box textAlign="center" marginTop={100}>
                    <Box
                        fontSize={30}
                        fontWeight={900}
                        lineHeight={1}
                        color={
                            theme === THEME.DARK
                                ? themeColors[theme].whitefontColor
                                : themeColors[theme].tertiaryFontColor2
                        }
                        marginBottom={22}
                    >
                        {t('notFound.title')}
                    </Box>
                    <Box
                        fontSize={16}
                        fontWeight={500}
                        lineHeight={1.25}
                        letterSpacing="1px"
                        color={themeColors[theme].secondaryFontColor1}
                        marginBottom={14}
                    >
                        {t('notFound.desp1')}
                    </Box>
                    <Box
                        fontSize={16}
                        fontWeight={500}
                        lineHeight={1.25}
                        letterSpacing="1px"
                        color={themeColors[theme].secondaryFontColor1}
                        margin="0 auto"
                        maxWidth={isMobileOnly ? 375 : 485}
                    >
                        {t('notFound.desp2')}
                        {isHebrew() && '. '}
                        <Inline
                            cursor="pointer"
                            textDecoration="underline"
                            props={{
                                onClick: () => navigate('/')
                            }}
                        >
                            {t('notFound.catalog')}
                        </Inline>
                    </Box>
                    <Image src={bg} maxWidth={isMobileOnly ? 375 : 485} borderRadius={8} />
                </Box>
            </Box>
        </Wrapper>
    );
}
