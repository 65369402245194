import useSWR from 'swr';

import api from 'api';

import useSession from './useSession';

export default function useUser() {
    const session = useSession();

    const key = () => `user?uid=${session.userId}&org=${session.organization_id}`;

    const fetch = () => api.getUser(session.userId, session.organization_id, session.authenticated);

    const { data, error, mutate } = useSWR(key, fetch);

    return {
        data,
        error,
        mutate
    };
}
