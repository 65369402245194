import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { navigate, useQueryParams } from '@patched/hookrouter';
import { ScrollingCarousel } from '@trendyol-js/react-carousel';
import DeliveryModel from 'constants/DeliveryModel';
import { Box, Col, Row } from 'jsxstyle';
import Layout from 'layout/CatalogLayout';

import useActivePromotionsForCatalog from 'hooks/useActivePromotionsForCatalog';
import useAvailableProductsCount from 'hooks/useAvailableProductsCount';
import useCategorizedCatalog from 'hooks/useCategorizedCatalog';
import useCustomerData from 'hooks/useCustomerData';
import useDeliveryModels from 'hooks/useDeliveryModels';
import usePopupManager from 'hooks/usePopupManager';
import useStoreSettings from 'hooks/useStoreSettings';
import useTheme from 'hooks/useTheme';

import { allProductsCategory, CATEGORY_TYPES, home } from 'api/constants';
import {
    cacheHidePromoPopup,
    cacheHidePromotionBar,
    cachePromoCode,
    cachePromoLink,
    cachePromoMessage,
    clearCachedPromoCode,
    clearCachedPromoLink,
    getCachedPromoCode,
    getCachedPromoLink,
    getDoNotAutoApplyPromo,
    getHidePromoPopup,
    isSuperFastOrder,
    isThreadSubmitted,
    parsePromoParams,
    scrollToTop,
    shuffleArray
} from 'api/utils';

import { useAppContext } from 'store';

import { trackPixel } from 'utils/trackingPixels';

import Alert from 'components/Alert';
import CategoryRow from 'components/Categories/CategoryRow';
import CheckoutBar from 'components/CheckoutBar';
import CookiesAlert from 'components/CookiesAlert';
import Loading from 'components/Loading';
import NoProductsAvailable from 'components/Menu/NoProductsAvailable';
import AgeVerificationModal from 'components/Modals/AgeVerificationModal';
import CampaignModal from 'components/Modals/CampaignModal';
import ReferFriendBanner from 'components/ReferFriendBanner';
import ReferralAlert from 'components/ReferralAlert';
import { Caption, Headline, Subtitle } from 'components/shared';
import WhitePromoAlert from 'components/WhitePromoAlert';

import Catalog from './Catalog';

import '@brainhubeu/react-carousel/lib/style.css';

export default function Categories({ pageTitle }) {
    const { t } = useTranslation();
    const { theme, themeColors } = useTheme();
    const { delayPopup } = usePopupManager();

    const [params, setParams] = useQueryParams();
    const { data: mappedData, store } = useCustomerData();
    const { data: promotionsForCatalog } = useActivePromotionsForCatalog();
    const { user, thread, zone, session, cart } = mappedData || {};
    const { data: storeSettings } = useStoreSettings();
    const {
        state: {
            showDeliveryTypeModal,
            currentDeliveryModel,
            showScWindowModal,
            showDeliveryAddressModal,
            storeDomain,
            storeDescription,
            showAgeVerificationModal,
            loadingDeliveryTypePopup
        },
        appContextActions
    } = useAppContext();

    const { supportsOndemandMenu, supportsScheduledMenu } = useDeliveryModels(zone, storeSettings);
    const sortType = sessionStorage.getItem('selectedSortType');

    const { data: categoriesCatalog, isValidating } = useCategorizedCatalog(
        currentDeliveryModel,
        10,
        params,
        sortType
    );
    const [categoriesMap, setCategoriesMap] = useState({});
    const [mainCategoriesList, setMainCategoriesList] = useState();
    const [loading, setLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState();
    const [productsLoaded, setProductsLoaded] = useState(false);
    const [settings, setSettings] = useState();
    const [alertSettings, setAlertSettings] = useState({
        show: false,
        fadeOut: false,
        message: '',
        icon: null
    });
    const { data: availableProductsCount } = useAvailableProductsCount(
        !user?.address || thread?.pickup_destination
            ? DeliveryModel.Scheduled
            : currentDeliveryModel
    );
    const [catalogProductCount, setCatalogProductCount] = useState();
    const [selectedCategory, setSelectedCategory] = useState(!params.page_description && home);
    const [categoriesProductEmpty, setCategoriesProductEmpty] = useState(false);

    const [promotionRelay, setPromotionRelay] = useState(null);
    const [showCampaignModal, setShowCampaignModal] = useState(false);
    const [showCookiesAlert, setShowCookiesAlert] = useState(true);
    const [showWhitePromoAlert, setShowWhitePromoAlert] = useState(false);

    const [domain, setDomain] = useState('');

    const [totalFiltersCount, setTotalFiltersCount] = useState(0);
    const [selectedSortType, setSelectedSortType] = useState();
    const [filterComponent, setFilterComponent] = useState({});
    const [showCampaignPopupWithDelay, setShowCampaignPopupWithDelay] = useState(false);

    const isOrderInTransit = isThreadSubmitted(thread);

    if (window.location.pathname === '/catalog') {
        const cateParams = params.category;
        navigate('/');
        setParams({ category: cateParams });
    }

    useEffect(() => {
        function loadJointCommerce() {
            // track pixels
            if (storeSettings?.pixel_integrations_config) {
                const { advertiser_id, events, share_with_meta, token } =
                    storeSettings.pixel_integrations_config;

                if (events?.home_page?.pixel_id) {
                    trackPixel({
                        isMeta: share_with_meta,
                        advertiser_id,
                        pixel_id: events.home_page.pixel_id,
                        token,
                        user_data: user
                    });
                }
            }
        }

        if (storeSettings) {
            setDomain(storeSettings.store_domain);
            setSettings(storeSettings);
        }
        loadJointCommerce();
    }, [storeSettings]);

    useEffect(() => {
        function shouldShowAgeVerificationModal() {
            const orgRequireAgeVerification = settings?.requires_age_verification;
            if (
                settings &&
                session &&
                !showAgeVerificationModal &&
                !session?.authenticated &&
                orgRequireAgeVerification &&
                domain === storeDomain
            ) {
                const ageVerified = localStorage.getItem('ageVerified');
                appContextActions.ageVerificationModal[!ageVerified ? 'open' : 'close']();
            } else {
                appContextActions.ageVerificationModal.close();
            }
        }
        shouldShowAgeVerificationModal();
    }, [settings?.requires_age_verification, session?.authenticated, domain, storeDomain]);

    useEffect(() => {
        //here building catalog
        if (
            categoriesCatalog &&
            categoriesCatalog.categories_map &&
            availableProductsCount != null
        ) {
            const categories = settings?.categories || [];

            const categoriesMap = categoriesCatalog.categories_map;

            for (const category in categoriesMap) {
                // for now shuffle disabled
                // if (categoriesMap[category].products && categoriesMap[category].products.length) {
                //     shuffleArray(categoriesMap[category].products);
                // }

                categoriesMap[category].controlRef = React.createRef();
                categoriesMap[category].categoryItem = categories.find(
                    c => c.category_id === category
                );
            }
            setCategoriesMap(categoriesMap);

            const categoriesArray = Object.values(categoriesMap || {}).filter(category => {
                return category.categoryItem?.webstore_visible && category.products?.length;
            });

            if (promotionsForCatalog?.promotions?.length) {
                shuffleArray(promotionsForCatalog?.promotions);
                const promotionsCategory = {
                    categoryItem: {
                        name: t('promotionsCategory.title'),
                        description: t('promotionsCategory.description'),
                        type: CATEGORY_TYPES.PROMOTIONS_CATEGORY
                    },
                    count: promotionsForCatalog?.promotions?.length,
                    promotions: promotionsForCatalog?.promotions
                };

                categoriesArray.unshift(promotionsCategory);
            }

            setMainCategoriesList(categoriesArray);

            const categoriesProductEmpty = !Object.values(categoriesMap).find(
                el => el.products.length
            );
            const isEmpty = availableProductsCount === 0 && categoriesProductEmpty;
            setCategoriesProductEmpty(isEmpty);
            categoriesProductEmpty && setParams({ category: allProductsCategory.category_id });
            setIsEmpty(isEmpty);
            isEmpty && setCatalogProductCount(0);
            setProductsLoaded(true);
        }
    }, [settings?.categories, categoriesCatalog, promotionsForCatalog, availableProductsCount]);

    const supportsDelivery =
        storeSettings?.supports_scheduled_menu || storeSettings?.supports_ondemand_menu;
    const supportsPickup = storeSettings?.supports_pickup;
    const pickupOnly = !supportsDelivery && supportsPickup;

    useEffect(() => {
        if (
            (zone?.zone_id &&
                !currentDeliveryModel &&
                !thread?.pickup_destination &&
                supportsDelivery) ||
            isValidating
        ) {
            setLoading(true);
        } else {
            if (zone && Object.keys(zone).length === 0) {
                setLoading(false);
            }
            if (mainCategoriesList && categoriesCatalog && productsLoaded && (zone || pickupOnly)) {
                setLoading(false);
            }
            if (user && (!user.address || user.error === 'InvalidZone')) {
                setLoading(false);
            }
        }
    }, [
        categoriesCatalog,
        productsLoaded,
        user,
        zone?.zone_id,
        currentDeliveryModel,
        isValidating,
        mainCategoriesList,
        supportsDelivery,
        supportsPickup
    ]);

    const storeOpen = settings?.isOpen;

    const openCategoryPage = categoryId => {
        setParams({}, true);
        setParams({ category: categoryId });
    };

    const showPromoAppliedAlert = () => {
        setAlertSettings({
            show: true,
            fadeOut: false,
            message: t('categories.promoApplied', {
                promocode: getCachedPromoCode()
            }),
            icon: 'whiteCheck'
        });
        setTimeout(() => {
            setAlertSettings({
                ...alertSettings,
                show: false,
                fadeOut: true
            });
        }, 5000);
    };

    useEffect(() => {
        function showOrderCanceledNotification() {
            setParams({
                order_cancelled_success: undefined
            });
            setAlertSettings({
                show: true,
                fadeOut: false,
                message: t('orderStatus.orderCancelSuccess'),
                icon: 'whiteCheck'
            });
            setTimeout(() => {
                setAlertSettings({
                    ...alertSettings,
                    fadeOut: true
                });
            }, 5000);
        }

        if (params.order_cancelled_success === 'true') {
            showOrderCanceledNotification();
        }
    }, [params]);

    useEffect(() => {
        async function getActiveTopPromotion() {
            if (thread && !thread?.promoCode && !getCachedPromoCode() && productsLoaded) {
                const hasSelectedCampaign =
                    sessionStorage.getItem('current_selected_campaign') !== 'undefined';
                const promotion_relay = await store.getActiveTopPromotion(
                    hasSelectedCampaign ? sessionStorage.getItem('current_selected_campaign') : null
                );
                if (promotion_relay?.promo_code) {
                    sessionStorage.setItem(
                        'current_selected_campaign',
                        promotion_relay?.promo_code
                    );

                    if (
                        promotion_relay?.url &&
                        promotion_relay?.auto_apply_to_cart &&
                        !getDoNotAutoApplyPromo()
                    ) {
                        cachePromoCode(promotion_relay.promo_code);
                        cachePromoLink(promotion_relay.url);
                    }
                    setPromotionRelay(promotion_relay);
                }

                if (!loadingDeliveryTypePopup) {
                    const shouldShowPromotionPopup =
                        !getCachedPromoCode() &&
                        promotion_relay?.show_popup &&
                        !sessionStorage.getItem('hidePromotionPopups') &&
                        !isOrderInTransit &&
                        !getHidePromoPopup(promotion_relay?.promo_code) &&
                        productsLoaded &&
                        !showDeliveryTypeModal;

                    if (shouldShowPromotionPopup) {
                        sessionStorage.setItem('hidePromotionPopups', true);
                        setShowCampaignModal(true);
                    }
                    if (showDeliveryTypeModal) {
                        setShowCampaignModal(false);
                    }
                }
            }
        }
        getActiveTopPromotion();
    }, [
        thread,
        productsLoaded,
        cart?.items?.length,
        showScWindowModal,
        showDeliveryTypeModal,
        loadingDeliveryTypePopup
    ]);

    useEffect(() => {
        const showCampaignPopup =
            !showAgeVerificationModal &&
            !showScWindowModal &&
            !showDeliveryAddressModal &&
            !showDeliveryTypeModal &&
            showCampaignModal;

        if (showCampaignPopup) {
            delayPopup(() => setShowCampaignPopupWithDelay(true));
        }
    }, [
        showAgeVerificationModal,
        showScWindowModal,
        showDeliveryAddressModal,
        showDeliveryTypeModal,
        showCampaignModal
    ]);

    useEffect(() => {
        if (thread && params.promoRelay) {
            if (!isOrderInTransit) {
                navigateToEligible(getCachedPromoLink());
                setParams({ promoRelay: undefined });
                showPromoAppliedAlert();
            } else {
                setAlertSettings({
                    show: true,
                    fadeOut: false,
                    message: t('categories.promoNotAppliedOngoingOrder'),
                    icon: 'whiteClear'
                });
                setTimeout(() => {
                    setAlertSettings({
                        ...alertSettings,
                        show: false,
                        fadeOut: true
                    });
                }, 5000);
                clearCachedPromoLink();
                clearCachedPromoCode();
            }
        }
    }, [user, promotionRelay, thread]);

    useEffect(() => {
        const refLink = sessionStorage.getItem('refLink');

        if (user && !user?.authenticated && !params.hid && !params.ref && !refLink) {
            clearCachedPromoCode();
        }
    }, [user, user?.authenticated]);

    useEffect(() => {
        const showWhitePromoAlert =
            user?.authenticated &&
            getCachedPromoCode() &&
            getCachedPromoLink() &&
            selectedCategory &&
            !cart?.items?.length;

        setShowWhitePromoAlert(showWhitePromoAlert);

        if (showWhitePromoAlert && localStorage.getItem('showPromoAppliedNotification')) {
            showPromoAppliedAlert();
            !getCachedPromoLink().includes('p/') &&
                localStorage.removeItem('showPromoAppliedNotification');
        }
    }, [selectedCategory, cart, cart?.items, thread?.promoCode]);

    const handleAcceptCookies = () => {
        localStorage.setItem('acceptCookies', true);
        setShowCookiesAlert(false);
    };

    const navigateToEligible = url => {
        setSelectedCategory('');
        const parsedLink = `${url?.split('{store_domain}/').join('')}`;
        if (parsedLink.includes('p/')) {
            localStorage.setItem('showPromoAppliedNotification', true);
            navigate(parsedLink);
        } else {
            const promoParams = parsePromoParams(parsedLink);
            setParams({ ...promoParams, category: undefined });
        }
    };

    const applyPromoCode = async promoCode => {
        const promoToApply = promoCode || promotionRelay;

        cachePromoCode(promoToApply.promo_code);
        if (promoToApply.url) {
            cachePromoLink(promoToApply.url);
        }

        cachePromoMessage(promoToApply.message);
        cacheHidePromoPopup(promoToApply.promo_code);
        if (user?.authenticated) {
            cacheHidePromoPopup(promoToApply.promo_code);
            cacheHidePromotionBar(promoToApply.promo_code);
            if (promoToApply.url) {
                navigateToEligible(promoToApply.url);
            }
        } else {
            navigate('/login', false, {
                from: 'promoRelay'
            });
            localStorage.setItem('showPromoAppliedNotification', true);
        }
    };

    const clearFilters = () => {
        const unselectCategories = filterComponent.filterCategories.map(filter => {
            return {
                ...filter,
                categories: filter.categories.map(cate => {
                    return {
                        ...cate,
                        selected: false
                    };
                })
            };
        });

        const unselctSortType = filterComponent.sort_types.map(sort => {
            return {
                ...sort,
                selected: false
            };
        });

        setSelectedSortType(null);

        setFilterComponent({
            ...filterComponent,
            filterCategories: unselectCategories,
            sort_types: unselctSortType
        });
        setTotalFiltersCount(0);
        setParams({ ...params, filters: undefined, sort: undefined }, true);
    };

    const clearAppliedFilters = () => {
        clearFilters();
        setTotalFiltersCount(0);

        setParams({}, true);
        navigate('/');
    };

    const refCode = localStorage.getItem('promoCode');
    const acceptCookies = localStorage.getItem('acceptCookies');

    const showCheckoutBar = cart?.items?.length > 0 && !isOrderInTransit;

    const alertBottomSpace =
        (!acceptCookies && isMobileOnly && 160) ||
        (!acceptCookies && 140) ||
        (showWhitePromoAlert && 100) ||
        (showCheckoutBar && 100);

    if (settings && storeDomain && storeDomain !== domain) {
        return null;
    }

    return (
        <Layout
            pageTitle={pageTitle}
            onCategorySelected={category => {
                if (params.query) {
                    setParams({
                        ...params,
                        query: undefined
                    });
                }
                if (category) {
                    openCategoryPage(category.category_id);
                }
            }}
            orderInTransit={isOrderInTransit}
            resetCategoriesAfterLoad={true}
            zone={zone}
            catalogProductCount={catalogProductCount}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            promotionRelay={promotionRelay}
            applyPromoCode={applyPromoCode}
            showPromoAppliedAlert={showPromoAppliedAlert}
            totalFiltersCount={totalFiltersCount}
            setTotalFiltersCount={setTotalFiltersCount}
            selectedSortType={selectedSortType}
            setSelectedSortType={setSelectedSortType}
            filterComponent={filterComponent}
            setFilterComponent={setFilterComponent}
            clearFilters={clearFilters}
            categoriesProductEmpty={categoriesProductEmpty}
        >
            {loading ? (
                <Loading title={t('categories.loadingProduct')} />
            ) : !thread?.pickup_destination &&
              zone &&
              Object.keys(zone).length === 0 &&
              !categoriesMap ? (
                <Box />
            ) : isEmpty ||
              (!thread?.pickup_destination &&
                  !storeOpen &&
                  supportsScheduledMenu &&
                  isSuperFastOrder(currentDeliveryModel)) ? (
                <NoProductsAvailable
                    supportsOndemandMenu={supportsOndemandMenu}
                    supportsScheduledMenu={supportsScheduledMenu}
                    nextOpenTime={settings.whenOpen}
                    nextShiftHours={categoriesCatalog?.nextShiftHours}
                    storeOpen={storeOpen}
                    cart={cart}
                    isSubmitted={isOrderInTransit}
                    isPickup={thread?.pickup_destination}
                    clearAppliedFilters={clearAppliedFilters}
                />
            ) : params.category || params.query || params.page_description || params.filters ? (
                <Catalog
                    setCatalogProductCount={setCatalogProductCount}
                    catalogProductCount={catalogProductCount}
                    clearAppliedFilters={clearAppliedFilters}
                />
            ) : (
                <Box>
                    {mainCategoriesList &&
                        mainCategoriesList.map(category => {
                            const { categoryItem, products, promotions, count } = category;
                            return (
                                <CategoryRow
                                    key={categoryItem.category_id || categoryItem.type}
                                    onCategoryClick={() => {
                                        scrollToTop();
                                        openCategoryPage(categoryItem.category_id);
                                        setSelectedCategory(categoryItem.name);
                                    }}
                                    categoryItem={categoryItem}
                                    products={products}
                                    promotions={promotions}
                                    queryParams={params}
                                    itemsCount={count}
                                    storeSettings={settings}
                                    isOrderInTransit={isOrderInTransit}
                                    thread={thread}
                                    userIsAuth={user?.authenticated}
                                    applyPromoCode={applyPromoCode}
                                    showPromoAppliedAlert={showPromoAppliedAlert}
                                    supportsDelivery={supportsDelivery}
                                    supportsPickup={supportsPickup}
                                />
                            );
                        })}
                    {Object.keys(categoriesMap || {}).length > 0 && (
                        <Col>
                            <Row marginBottom={10} paddingLeft={20} paddingRight={20}>
                                <Headline color={themeColors[theme].primaryFontColor1}>
                                    {t('categories.title')}
                                </Headline>
                            </Row>

                            <Box className="slider-wrapper category-nav">
                                <ScrollingCarousel>
                                    {Object.keys(categoriesMap).map(key => {
                                        const { categoryItem, products } = categoriesMap[key];
                                        if (!categoryItem) return null;

                                        if (!categoryItem) return null;

                                        const { color, name, category_id, webstore_visible } =
                                            categoryItem;

                                        return webstore_visible && products && products.length ? (
                                            <Box
                                                paddingLeft={20}
                                                paddingRight={0}
                                                key={category_id}
                                            >
                                                <Col
                                                    padding="0 20px"
                                                    borderRadius={10}
                                                    justifyContent="center"
                                                    alignContent="flex-end"
                                                    minWidth={150}
                                                    maxWidth={250}
                                                    height={190}
                                                    backgroundColor={color}
                                                    cursor="pointer"
                                                    key={category_id}
                                                    props={{
                                                        onClick: () => {
                                                            scrollToTop();
                                                            openCategoryPage(category_id);
                                                            setSelectedCategory(name);
                                                        }
                                                    }}
                                                >
                                                    <Row
                                                        height={44}
                                                        alignItems="center"
                                                        width="100%"
                                                    >
                                                        <Headline
                                                            whiteSpace="normal"
                                                            color={
                                                                themeColors[theme].primaryFontColor2
                                                            }
                                                            fontSize={20}
                                                        >
                                                            {name}
                                                        </Headline>
                                                    </Row>

                                                    <Caption marginTop={8} fontWeight={500}>
                                                        {t('categories.seeAllProducts')}
                                                    </Caption>
                                                </Col>
                                            </Box>
                                        ) : null;
                                    })}
                                </ScrollingCarousel>
                            </Box>
                        </Col>
                    )}
                    {settings.referral_program &&
                        settings.supports_referral &&
                        !(user?.error === 'InvalidZone') && (
                            <Col padding={20} marginTop={40}>
                                <Headline color={themeColors[theme].primaryFontColor1}>
                                    {t('referFriend.title')}
                                </Headline>
                                <SectionSubtitle theme={theme} themeColors={themeColors}>
                                    {t('referFriend.shareCode')}
                                </SectionSubtitle>

                                <ReferFriendBanner
                                    discount={settings?.referral_program.referring_customer_reward}
                                />
                            </Col>
                        )}
                </Box>
            )}

            {alertSettings.show && (
                <Alert
                    message={alertSettings.message}
                    iconName={alertSettings.icon}
                    isFadingOut={false}
                    addedBottomSpace={alertBottomSpace}
                />
            )}

            {refCode && productsLoaded && user?.address && (
                <Box>
                    <ReferralAlert
                        pageLoading={loading}
                        user={user}
                        promoCode={refCode}
                        cookiesAlertShown={!acceptCookies}
                        setShowWhitePromoAlert={setShowWhitePromoAlert}
                    />
                </Box>
            )}

            {!acceptCookies && productsLoaded && (
                <CookiesAlert
                    showCookiesAlert={showCookiesAlert}
                    handleAcceptCookies={handleAcceptCookies}
                    addBottomSpace={showWhitePromoAlert || showCheckoutBar}
                />
            )}
            {showAgeVerificationModal && (
                <AgeVerificationModal
                    showModal={showAgeVerificationModal}
                    storeDescription={storeDescription}
                    minAge={storeSettings?.min_age}
                />
            )}

            {showCampaignPopupWithDelay && showCampaignModal && (
                <CampaignModal
                    showModal={true}
                    onClose={() => {
                        setShowCampaignModal(false);
                        cacheHidePromoPopup(promotionRelay.promo_code);
                    }}
                    setShowCampaignModal={setShowCampaignModal}
                    promotionRelay={promotionRelay}
                    isAuth={user?.authenticated}
                    applyPromoCode={applyPromoCode}
                    showPromoAppliedAlert={showPromoAppliedAlert}
                    showClose
                />
            )}
            {showWhitePromoAlert && (
                <WhitePromoAlert
                    setShowWhitePromoAlert={setShowWhitePromoAlert}
                    setSelectedCategory={setSelectedCategory}
                    promoCode={getCachedPromoCode() || thread?.promoCode}
                />
            )}
            {showCheckoutBar && <CheckoutBar />}
        </Layout>
    );
}

const SectionSubtitle = ({ children, smMargin, theme, themeColors }) => (
    <Subtitle
        color={themeColors[theme].secondaryFontColor9}
        // color={'blue'}
        marginTop={10}
        marginBottom={smMargin ? 10 : 30}
        fontWeight={500}
        opacity={1}
    >
        {children}
    </Subtitle>
);
