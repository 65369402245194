import React from 'react';
import { createRoot } from 'react-dom/client';
import { installMantisScript } from 'analytics/pixels';

import { initializeDataLayer, installGTM } from 'utils/dataLayer';

import i18n from './i18n';
import Router from './pages/Router';
import * as serviceWorker from './serviceWorker';

import 'reset-css';
import './index.css';

const req = new XMLHttpRequest();
req.open('GET', document.location, false);
req.send(null);
const headers = req.getAllResponseHeaders().split('\r\n');

const hostHeader = headers.find(header => header.includes('sdm: '));
const storeDomain = hostHeader ? hostHeader.substr(5) : process.env.REACT_APP_STORE_DOMAIN;

/**
 * Get only bot number from the header
 * better if we can get it from env variable or by calling to
 * an API to get it
 */
const channelIdHeader = headers.find(header => header.includes('chpth: '));
const channelId = channelIdHeader ? channelIdHeader.substr(7) : process.env.REACT_APP_BOT_NUMBER;

const botNumberHeader = headers.find(header => header.includes('bnmbr: '));
const botNumber = botNumberHeader ? botNumberHeader.substr(7) : process.env.REACT_APP_BOT_NUMBER;

const languageHeader = headers.find(header => header.includes('lngg: '));
const language = languageHeader ? languageHeader.substr(6) : 'en-US';
localStorage.setItem('language', language);

const countryCodeHeader = headers.find(header => header.includes('cntcd: '));
const countryCode = countryCodeHeader ? countryCodeHeader.substr(7) : 'US';

const themeHeader = headers.find(header => header.includes('theme: '));
const theme = themeHeader ? themeHeader.substr(7) : 'light';

i18n.changeLanguage(language);

const organizationIdHeader = headers.find(header => header.includes('orgid: '));
const organizationId = organizationIdHeader
    ? organizationIdHeader.substr(7)
    : process.env.REACT_APP_ORGANIZATION_ID;

const gtagIdHeader = headers.find(header => header.includes('gtagid: '));
const gtagId = gtagIdHeader ? gtagIdHeader.substr(8) : process.env.REACT_APP_GTM_ID || null;

const mantisaidHeader = headers.find(header => header.includes('mantisaid: '));

const mantisaid = mantisaidHeader
    ? mantisaidHeader.substr(11)
    : process.env.REACT_APP_MANTIS_AID || null;

const despHeader = headers.find(header => header.includes('desp: '));
const siteDesp = despHeader ? despHeader.substr(6) : null;

const identityPoolId = process.env.REACT_APP_IDENTITY_POOL;
const key = process.env.REACT_APP_GOOGLE_API_KEY;

const body = document.querySelector('body');
const script = document.createElement('script');

script.setAttribute(
    'src',
    `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&language=${language}&region=${countryCode}`
);
body.appendChild(script);

if (gtagId) {
    installGTM(gtagId);
    initializeDataLayer();
}

if (mantisaid) {
    installMantisScript(mantisaid);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Router
        storeDomain={storeDomain}
        countryCode={countryCode}
        identityPool={identityPoolId}
        channelId={channelId}
        botPhoneNumber={botNumber}
        organizationId={organizationId}
        storeDescription={siteDesp?.replace(/%0A/g, ' ')}
        storeTheme={theme}
    />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function initDataLayer(gtagId) {
    if (gtagId) {
        const script = document.createElement('script');
        script.setAttribute('async', 'true');

        script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gtagId}`);
        body.appendChild(script);

        const script2 = document.createElement('script');
        script2.setAttribute('type', 'text/javascript');
        script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)};
            gtag('js', new Date());
            gtag('config', '${gtagId}');
        `;
        body.appendChild(script2);
    }
}
