import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SuperfastWindow, ThreadDeliveryModel, WindowSubType } from 'api/constants';
import { cacheSelectedWindow, formatDeliveryWindowText, getCachedSelectedWindow } from 'api/utils';

import useCustomerData from './useCustomerData';
import useStoreSettings from './useStoreSettings';
import useWindows from './useWindows';

const useDeliveryWindows = orderIsSuperFast => {
    const { data: storeSettings } = useStoreSettings();
    const { data: mappedData } = useCustomerData();
    const { t } = useTranslation();
    const { thread } = mappedData || {};
    const { data: windows, updateThreadDeliveryModel } = useWindows();
    const [deliveryWindows, setDeliveryWindows] = useState();
    const [currentWindow, setCurrentWindow] = useState();
    const [currentWindowName, setCurrentWindowName] = useState(null);
    const [defaultWindowText, setDefaultWindowText] = useState();
    const [defaultWindowShortText, setDefaultWindowShortText] = useState(
        localStorage.getItem('delivery_window_short_text') || ''
    );

    useEffect(() => {}, [currentWindow]);

    useEffect(() => {
        const buildDeliveryWindows = async () => {
            if (thread && windows) {
                const storeClosed = !storeSettings?.isOpen;

                // if there is saved last selected window in cache use it,
                // otherwise we prefer to use range window as default, but if not exist using first
                const lastSelectedWindow = sessionStorage.getItem('lastSelectedDeliveryWindow')
                    ? JSON.parse(sessionStorage.getItem('lastSelectedDeliveryWindow'))
                    : null;

                const lastSelectedIsValid =
                    lastSelectedWindow &&
                    windows.find(
                        window =>
                            `${window.window_id}${window.deliver_by}${window.deliver_from}` ===
                            `${lastSelectedWindow.window_id}${lastSelectedWindow.deliver_by}${lastSelectedWindow.deliver_from}`
                    );

                const defaultWindow =
                    lastSelectedWindow && lastSelectedIsValid
                        ? lastSelectedWindow
                        : windows.find(
                              window =>
                                  window.window_subtype === WindowSubType.asap ||
                                  window.window_subtype === WindowSubType.range
                          ) || windows[0];

                setCurrentWindow(defaultWindow);

                const windowText = formatDeliveryWindowText(defaultWindow, storeClosed, t);
                const shortWindowText = formatDeliveryWindowText(
                    defaultWindow,
                    storeClosed,
                    t,
                    true
                );

                setDefaultWindowText(windowText);
                setDefaultWindowShortText(shortWindowText);
                if (defaultWindow) {
                    localStorage.setItem('delivery_window_short_text', shortWindowText);
                }

                if (!getCachedSelectedWindow() && !orderIsSuperFast) {
                    defaultWindow && cacheSelectedWindow(defaultWindow);
                }
                const windowExist = windows.find(
                    window =>
                        `${window.window_id}${window.deliver_by}${window.deliver_from}` ===
                        `${thread?.delivery_window?.window_id}${thread?.delivery_window?.deliver_by}${thread?.delivery_window?.deliver_from}`
                );

                const isPastTime =
                    thread?.delivery_window?.window_subtype === WindowSubType.range &&
                    thread?.delivery_window?.deliver_from < Math.floor(new Date() / 1000);

                const shouldUpdateThread =
                    orderIsSuperFast === false && ((defaultWindow && !windowExist) || isPastTime);

                if (shouldUpdateThread) {
                    cacheSelectedWindow(defaultWindow);
                    await updateThreadDeliveryModel(ThreadDeliveryModel.scheduled, defaultWindow);
                }

                if (orderIsSuperFast) {
                    setCurrentWindow(SuperfastWindow);
                    setCurrentWindowName(t('scheduledModal.superFast'));
                } else if (windows.length === 0 && !orderIsSuperFast) {
                    setCurrentWindowName(t('stickyBar.nextday'));
                } else {
                    const currentWindow =
                        thread.delivery_window && !isPastTime && windowExist
                            ? thread.delivery_window
                            : defaultWindow;

                    if (currentWindow) {
                        setCurrentWindowName(
                            formatDeliveryWindowText(currentWindow, storeClosed, t)
                        );
                    } else {
                        setCurrentWindowName(t('scheduledModal.superFast'));
                    }
                }

                setDeliveryWindows(windows);
            }
        };
        buildDeliveryWindows();
    }, [windows, orderIsSuperFast, thread?.delivery_window, thread?.deliver_by, thread?.zoneId]);

    return {
        deliveryWindows,
        currentWindowName,
        defaultWindowText,
        currentWindow,
        defaultWindowShortText
    };
};

export default useDeliveryWindows;
