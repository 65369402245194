import { clearCache } from 'api/utils';

import { getCacheData } from '../utils/cacheDataManager';
import { CHANNEL_TYPE, Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default async function getUser(id, organization_id, authenticated) {
    if (!id || !organization_id) return null;
    if (authenticated === true) {
        const payload = {
            user_id: id,
            organization_id: organization_id,
            channelType: CHANNEL_TYPE.WEB
        };

        const { customer, error, statusCode } = await invokeLambda(Lambda.GetUser, payload);

        if (statusCode === 400 && (!error || !error.code)) {
            clearCache();
            window.location.replace('/');
        } else {
            const user = mapUser(customer);
            if (error && error.code) {
                user.error = error.code;
            }
            return user;
        }
    } else if (authenticated === false) {
        const cache = await getCacheData(id, organization_id);
        return cache;
    }
}

const mapUser = data => {
    return {
        authenticated: true,
        id: data.user_id,
        name: data.full_name,
        address: data.address,
        isCompliant: data.is_compliant,
        organizationId: data.organization_id,
        threadId: data.active_thread,
        username: data.username,
        handoffId: data.handoff_id,
        picture: data.picture,
        email: data.email,
        createdTime: data.created_time,
        isBlocked: data.is_blocked,
        location: data.location,
        referral_code: data.referral_code
    };
};
