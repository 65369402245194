import { Lambda } from './constants';
import invokeLambda from './invokeLambda';

export default function getActivePromotionsForCatalog(
    user,
    organization_id,
    associated_hub,
    cart_items
) {
    let payload;

    if (user.authenticated) {
        payload = {
            organization_id,
            user_id: user.id
        };
    } else {
        payload = {
            organization_id,
            associated_hub,
            cart_items: cart_items?.items
        };
    }
    return invokeLambda(Lambda.getActivePromotionsForCatalog, payload);
}
