import debug from 'debug';

const isDev = process.env.NODE_ENV !== 'production';

let log;

if (isDev) {
    localStorage.setItem('debug', '*');
    log = debug('web-store');
} else {
    // Don't log in production
    // We could disable this but we need to make sure are logs are clean
    log = () => {};
}

export default log;
